import React from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"

import { DLDialog, DLComboBox } from "../../../../../components/basic-elements"

import { MessageColorType } from "../../../../../common-models/enumerations/common-enums"
import Icon from "@mdi/react"
import { mdiPlaylistEdit } from "@mdi/js"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import styled from "styled-components"

const EditConnectedChecklistDialog = observer(
	({ i18n }: { i18n: DLI18nProps }) => {
		const orgStore = useOrgStore()
		const actionName = "editProjectTypeConnectedChecklist"

		const handleOnSubmit = () => {}

		// const checklists = [
		// 	{
		// 		selected: false,
		// 		id: "...",
		// 		name: "blah",
		// 	},
		// ]

		const engTypeName = orgStore.setupEngTypes.getEngTypeById(
			orgStore.setupEngTypes.engagementId
		)?.name

		const checklistTemplates = orgStore.setupEngTypes.checklistTemplates

		console.log(checklistTemplates)

		return (
			<DLDialog
				eleTestId="edit-engtype-dialog"
				isOpen={orgStore.setupEngTypes.openEditConnectedChecklist}
				setIsOpen={orgStore.setupEngTypes.setOpenEditConnectedChecklist}
				showCloseBtn={true}
				showOpenBtn={false}
				dialogTitle={
					<div className="FR AC">
						<Icon
							path={mdiPlaylistEdit}
							size={1}
							color={MessageColorType.default}
							style={{ marginRight: 8 }}
						/>
						{i18n.twUpdateConnectedChecklist ||
							"Update Connected Checklist"}{" "}
						- {engTypeName}
					</div>
				}
				dialogContents={
					<DialogContents
						i18n={i18n}
						checklists={checklistTemplates}
					/>
				}
				actionReady={true}
				handleAction={handleOnSubmit}
				actionBtn={i18n.twUpdate}
				cancelBtnText={i18n.twCancel}
				showSpinner={
					orgStore.setupEngTypes.getActionStatus(actionName) ===
					"LOADING"
				}
				cannotUpdate={
					orgStore.setupEngTypes.getActionStatus(actionName) ===
					"LOADING"
				}
				useEnterKeyForSubmit
			/>
		)
	}
)

const DialogContents = observer(
	({
		i18n,
		checklists,
	}: {
		i18n: DLI18nProps
		checklists: { id: string; name: string }[]
	}) => {
		console.log(checklists)
		return (
			<StyledDialog>
				Connected Checklist Template
				<DLComboBox
					eleTestId="connect-checklist-template"
					eleClassName="select-checklist-template"
					withoutLabel
					placeholder={"Please select a checklist template"}
					options={checklists}
					getOptionLabel={(option: any) => option.name}
					onChange={
						(value: any) => {}
						// handleCreatorRole(value)
					}
					// defaultValue={checklists.find(
					// 	(item: any) =>
					// 		item.id === creatorRole
					// )}
				/>
			</StyledDialog>
		)
	}
)

const StyledDialog = styled.div``

export default EditConnectedChecklistDialog
