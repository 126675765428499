import { types } from "mobx-state-tree"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"

const EliminatingEntryUiHelper = types
	.model({
		clickPoint: ClickPoint,
		selectedEEId: "",
		//
		addEliminatingEntryDialogOpen: false,
		editEliminatingEntryDialogOpen: false,
	})
	.actions((self) => ({
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setSelectedEEId(eeId: string) {
			self.selectedEEId = eeId
		},
		//
		setAddEliminatingEntryDialogOpen(request?: boolean) {
			if (request !== undefined) {
				self.addEliminatingEntryDialogOpen = request
			} else {
				self.addEliminatingEntryDialogOpen =
					!self.addEliminatingEntryDialogOpen
			}
		},
		setEditEliminatingEntryDialogOpen(request?: boolean) {
			if (request !== undefined) {
				self.editEliminatingEntryDialogOpen = request
			} else {
				self.editEliminatingEntryDialogOpen =
					!self.editEliminatingEntryDialogOpen
			}
		},
	}))

export default EliminatingEntryUiHelper
