import React from "react"
import { useProjStore } from "../../../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import { DLDialog } from "../../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"
import { getProjIdFromUrl } from "../../../../../../library/api-requests/request-get-others"

export default observer(function ArchivPreProcessCancelDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	const projStore = useProjStore()
	// const actionName = "removePreConditionFiles"

	const cancelPreProcess = () => {
		const projId = getProjIdFromUrl()
		const userId = localStorage.getItem("orgUserId")
		if (projId && userId) {
			projStore.archivePreProcess.removePreConditionFiles(projId, userId)
		}
	}

	return (
		<DLDialog
			eleTestId="cancel-pre-processing-dialog"
			isOpen={projStore.archivePreProcess.cancelPreProcessDialogOpen}
			setIsOpen={
				projStore.archivePreProcess.setCancelPreProcessDialogOpen
			}
			handleAction={cancelPreProcess}
			showCloseBtn={true}
			dialogTitle={`${i18n.twCancelPreProcess} !`}
			dialogContents={<CancelPreProcessDialogForm i18n={i18n} />}
			actionReady={true}
			maxWidth="xs"
			actionBtn={i18n.twCancel}
			cancelBtnText={i18n.twClose}
		/>
	)
})

const CancelPreProcessDialogForm = ({ i18n }: { i18n: DLI18nProps }) => {
	return (
		<div>
			<ol>
				<li>{i18n.tsCancelMsg1}</li>
				<li>{i18n.tsCancelMsg2}</li>
			</ol>
			{i18n.tsCancelMsg3}
		</div>
	)
}
