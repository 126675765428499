import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import { getToDownload } from "../../../../../library/api-requests/request-get-others"
import { getRequest, putRequest } from "../../../../../library/api-requests"

export async function read() {
	const response = await getRequest({
		url: "/GetArchiveDiagonsis", // WARNING: Do not update the typo...
		params: {},
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function readDetail(conditionId: number) {
	const endpoint = "/GetPendingDetails"
	const response = await getRequest({
		url: endpoint,
		params: {
			type: conditionId,
		},
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function unlock() {
	const response = await putRequest({
		url: "/ProjectLockUnlock/2",
		params: {},
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function lock() {
	const response = await putRequest({
		url: "/ProjectLockUnlock/1",
		params: {},
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function apiExportPdf() {
	const response = await getToDownload({
		url: "/Export_ArchiveDiagonsis_PDF",
		params: {},
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export type ArchDiagnosisApiProps = {
	apiRead?: () => Promise<AxiosResponse<any>>
	apiUnlock?: () => Promise<AxiosResponse<any>>
	apiLock?: () => Promise<AxiosResponse<any>>
	apiReadDetail?: (conditionId: number) => Promise<AxiosResponse<any>>
	apiReadRationales?: () => Promise<AxiosResponse<any>>
	apiExportPdf?: () => Promise<AxiosResponse<any>>
}
