import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { ConsoleLog, DLSpinner } from "../../../components/basic-elements"
import {
	ActionStatus,
	DLSector,
} from "../../../common-models/enumerations/common-enums"
import { decryptUrl } from "../../../library/url-encryption"
import { findProjToken } from "../../../library/token-utils"
import { ProjectStatus } from "../../../common-models/enumerations/project-related-enums"
import StyledCheckin from "../../../components/app-frame-elements/checkin-progress-circles.style"
import { IdType, idToNumber } from "../../../library/converters/id-converter"

export const editor_checkin_steps = {
	step1: "convertUrl",
	step2: "validateOrgToken",
	step3: "validateProjToken",
	step4: "getAssignedRole",
	step5: "getFileStatus",
	step6: "automaticCheckout",
	step7: "combineIframeUrl",
}

export default observer(function EditorCheckin() {
	const store = useRootStore()
	const eStore = store.editor

	const url = window.location.pathname.slice(8)
	/**
	 *  NOTE: Do not use useParams of react-router-dom
	 * because it takes the first section between the '/' slash
	 * encrypted URL has slashes in it, so we need to get it by window.localtion.pathname
	 */

	let step1_status = eStore.getActionStatus(editor_checkin_steps.step1)
	let step2_status = eStore.getActionStatus(editor_checkin_steps.step2)
	let step3_status = eStore.getActionStatus(editor_checkin_steps.step3)
	let step4_status = eStore.getActionStatus(editor_checkin_steps.step4)
	let step5_status = eStore.getActionStatus(editor_checkin_steps.step5)
	let step6_status = eStore.getActionStatus(editor_checkin_steps.step6)
	let step7_status = eStore.getActionStatus(editor_checkin_steps.step7)

	ConsoleLog([
		"step 1 ~ 7 status:",
		step1_status,
		step2_status,
		step3_status,
		step4_status,
		step5_status,
		step6_status,
		step7_status,
	])

	// ? ---- STEP 1: convert URL and save it to the store
	useEffect(() => {
		const decryptedUrl = decryptUrl(url)
		// WARNING: Remove below line for production
		console.log("decryptedUrl:", decryptedUrl)
		eStore.setUrlInfo(decryptedUrl)
	}, [url])

	// ? ---- STEP 2: compare the Org ID and validate the token
	useEffect(() => {
		if (step1_status === ActionStatus.success) {
			const orgId = eStore.urlInfo.orgId
			const localOrgId = localStorage.getItem("orgId")
			if (localOrgId === orgId) {
				const actionName = editor_checkin_steps.step2
				const postAction = () =>
					eStore.responses.setResponse(actionName, {
						actionName,
						status: ActionStatus.success,
					})
				eStore.getOrgInfo(orgId, postAction)
			} else {
				// NOTE: set it as fail..? or try to get the org token?
				console.log(
					"Editor Checkin failed on Step 2 -",
					orgId,
					localOrgId
				)
				eStore.setCheckinStatus(ActionStatus.fail)
			}
		}
	}, [step1_status === ActionStatus.success])

	// ? ---- STEP 3: compare the Proj ID and validate the token, and get the user role
	useEffect(() => {
		if (step2_status === ActionStatus.success) {
			const projId = eStore.urlInfo.projId
			const projNumId = idToNumber(projId, IdType.project)
			const localToken = findProjToken(projId)

			const actionName = editor_checkin_steps.step3
			const getProjInfoAndSave = (projInfo: any) => {
				// 1. save the project info
				// + user information is saved in the getProjInfo
				eStore.setProjInfo(projInfo)

				// 2. update the step status to proceed
				eStore.responses.setResponse(actionName, {
					actionName,
					status: ActionStatus.success,
				})
			}

			if (typeof projNumId !== "number") {
				console.log("Failed to convert the project ID as number")
				return
			}
			if (localToken) {
				// When there is a matched token in the localStorage
				eStore.getProjInfo(projNumId, getProjInfoAndSave)
				//
			} else {
				// when there is no matched project token
				console.log(
					"Editor Checkin Step 3 - No matched token --> try to get the token again"
				)

				const userId = eStore.orgInfo.userId
				const whenSuccess = () => {
					eStore.getProjInfo(projNumId, getProjInfoAndSave)
				}
				const whenFail = () => {
					eStore.setCheckinStatus(ActionStatus.fail)
				}

				// this 'getProjToken' is same with the project checkin process
				eStore.getProjToken({
					projId,
					userId,
					whenSuccess,
					whenFail,
				})
			}
		}
	}, [step2_status === ActionStatus.success])

	// ? ---- Step 4: Get Assigned Role
	useEffect(() => {
		if (step3_status === ActionStatus.success) {
			const actionName = editor_checkin_steps.step4
			const postAction = () =>
				eStore.responses.setResponse(actionName, {
					actionName,
					status: ActionStatus.success,
				})
			const roleId = eStore.user.roleId
			const isReadonly = eStore.user.isReadonly
			const tempRight = eStore.user.prevRight
			eStore.getProjAssignedRole(
				tempRight,
				roleId,
				isReadonly,
				postAction
			)
		}
	}, [step3_status === ActionStatus.success])

	// ? ---- STEP 5: get the file status and save it to the store
	useEffect(() => {
		if (step4_status === ActionStatus.success) {
			const actionName = editor_checkin_steps.step5
			const postAction = () =>
				eStore.responses.setResponse(actionName, {
					actionName,
					status: ActionStatus.success,
				})
			eStore.getFileStatus(postAction)
		}
	}, [step4_status === ActionStatus.success])

	// ? ---- STEP 6: Automatic Checkout
	useEffect(() => {
		if (step5_status === ActionStatus.success) {
			const actionName = editor_checkin_steps.step6
			const postActionForCancel = () => {
				// WARNING: for this, need some update on the devexpress side
				eStore.responses.setResponse(actionName, {
					actionName,
					status: ActionStatus.success,
				})
			}
			const postActionForSuccess = () => {
				eStore.setFileStatus({
					...eStore.fileStatus,
					checkedOutByName: eStore.orgInfo.userName,
					checkedOutById: userId,
					isOpened: true,
					userRole: eStore.assignedRole.name,
				})
				eStore.responses.setResponse(actionName, {
					actionName,
					status: ActionStatus.success,
				})
			}
			/**
			 * - When the sector is project
			 * - project status is not normal locked, archived, unarchived locked, replica
			 * - (= project is normal or unarchived (no locked!!))
			 * - and no one use this file
			 * - and user has editing permission
			 */
			const { sector, menuId, srcId, projId } = eStore.urlInfo
			const projStage = eStore.projInfo.statusName
			const { isLocked, isArchived } = eStore.projInfo

			const permission = eStore.getActionsAsObject(
				menuId,
				isLocked,
				isArchived
			)
			const userId = eStore.orgInfo.userId
			const checkedOutById = eStore.fileStatus.checkedOutById
			console.log(
				"userId: ",
				userId,
				"checkedOutById:",
				checkedOutById,
				permission
			)

			if (
				sector === DLSector.proj &&
				(projStage === ProjectStatus.normal ||
					projStage === ProjectStatus.unarchived) &&
				!isLocked &&
				permission.update &&
				checkedOutById === null
			) {
				console.log("Okay, you can do checkout")

				eStore.checkoutFile({
					srcId,
					userId,
					sector,
					sectorId: projId,
					menuId,
					postActionForCancel,
					postActionForSuccess,
				})
			} else {
				if (userId === checkedOutById) {
					alert(
						"This file is already checked out by you. Please check if there are other opened windows or not. And if you complete to update, save and checkin this file for other users."
					)
				}
				console.log("You cannot checkout this file")
				postActionForCancel()
			}
		}
	}, [step5_status === ActionStatus.success])

	// ? ---- STEP 7: combine devexpress URL for iframe
	useEffect(() => {
		if (step6_status === ActionStatus.success) {
			const actionName = editor_checkin_steps.step7
			const postAction = () => {
				eStore.responses.setResponse(actionName, {
					actionName,
					status: ActionStatus.success,
				})
				eStore.setCheckinStatus(ActionStatus.success)
			}

			eStore.setEditorUrl(postAction)

			// WARNING: Consider more about better way
			// to update the file status (because automatic checkout cannot update this)
			// const postAction_x = () => {
			// 	const actionName_x = editor_checkin_steps.step5
			// 	eStore.responses.setResponse(actionName_x, {
			// 		actionName: actionName_x,
			// 		status: ActionStatus.success,
			// 	})
			// }
			// is this required?
			eStore.getFileStatus()
		}
	}, [step6_status === ActionStatus.success])

	return (
		<StyledCheckin>
			{/* NOTE: hen success, FileEditor will display the editor viewer */}
			{eStore.editorCheckin === "STANDBY" && (
				<div
					className="FR AC JC"
					style={{ width: "100%", height: "100%" }}
				>
					<DLSpinner />
				</div>
			)}
			{eStore.editorCheckin === "LOADING" && (
				<>
					<div className="loading-informations">
						<div className={`progress-step ${step1_status}`} />
						<div className={`progress-step ${step2_status}`} />
						<div className={`progress-step ${step3_status}`} />
						<div className={`progress-step ${step4_status}`} />
						<div className={`progress-step ${step5_status}`} />
						<div className={`progress-step ${step6_status}`} />
						<div className={`progress-step ${step7_status}`} />
					</div>
				</>
			)}
			{eStore.editorCheckin === "FAIL" && (
				<div>Sorry, you can't use this file...</div>
			)}
		</StyledCheckin>
	)
})
