/**
 * AJE: Adjustment
 * PAJE: Passed Adjustment
 * APG: Audit Program
 * LS: Lead Sheet
 * TB: Trial Balnace
 * * FileGroup === (previous) Upload Files
 */

import { DLSrcFormat } from "../../../../../common-models/enumerations/common-enums"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { combineUrl } from "@datalobby/common"

const endpoint = process.env.REACT_APP_DEV_EXPRESS_ENDPOINT

const urlPrefix = {
	excel: "/Excel_Sheet.aspx",
	word: "/OpenDocument.aspx",
	pdf: "/OpenPdf.aspx",
	//
	qcUpload: "/QcUploadDocument.aspx",
	apgInProjTemplate: "/AuditProgram_ClientTemplate.aspx",
	//
	apg: "/AuditProgram.aspx",
	fluxAnalysis: "/FluxAnalytics.aspx",
	finalAnalysis: "",
	leadsheet: "/LeadSheet.aspx",
	fileGroup: "/NewUploadFile.aspx", // TODO: Need confirmation from Jahnavi
	//
	aje: "/Adjustments.aspx",
	paje: "/PassAdjustments.aspx",
}

// NOTE: common url for excel, word, pdf
export const generateNormalFileUrl = ({
	srcFormat,
	srcId,
	menuId,
	userNumId,
	token,
	asReadonly,
}: {
	srcFormat: DLSrcFormat
	srcId: number
	menuId: DLOrgSubMenus | DLProjSubMenus
	userNumId: number
	token: string
	asReadonly: boolean
}) => {
	const prefix =
		srcFormat === DLSrcFormat.excel
			? urlPrefix["excel"]
			: srcFormat === DLSrcFormat.word
			? urlPrefix["word"]
			: srcFormat === DLSrcFormat.pdf
			? urlPrefix["pdf"]
			: "???"
	const menuForUrl =
		menuId === DLOrgSubMenus.proj_templates
			? "template"
			: menuId === DLProjSubMenus.wp
			? "wp"
			: menuId === DLProjSubMenus.pf
			? "Pf"
			: "??"

	const url = combineUrl(endpoint + prefix, {
		Id: srcId,
		doctype: menuForUrl,
		AR: asReadonly ? 1 : 0,
		userId: userNumId,
		Lang: "en",
		token,
	})

	return url
}

//
//
//
// NOTE: ---------- In Organization Side
export const makeAPGForProjTemplateUrl = ({
	userNumId,
	srcId,
	lang,
	token,
}: {
	userNumId: number
	srcId: number
	lang: string
	token: string
}) => {
	return combineUrl(endpoint + urlPrefix["apgInProjTemplate"], {
		userid: userNumId,
		templateid: srcId,
		Lang: lang,
		token,
	})
}

export const makeFileLibFileUrl = () => {
	// previous workpaper-template
	// /WorkPaperTemplate.aspx
}

export const makeFileLibAPGUrl = () => {
	// previous workpaper-template
	// /AuditProgram_Template.aspx
}

export const makeQcUploadUrl = ({
	userNumId,
	orgNumId,
	lang,
	token,
}: {
	userNumId: number
	orgNumId: number
	lang: string // TODO: need to update as enum
	token: string
}) => {
	return combineUrl(endpoint + urlPrefix["qcUpload"], {
		userid: userNumId,
		custid: orgNumId,
		Lang: lang,
		token,
	})
}

//
//
//
// NOTE: ---------- In Project Side
export const makeAJEUrl = ({
	userNumId,
	year,
	projNumId,
	srcNumId,
	lang,
	token,
}: {
	userNumId: number
	year: number
	projNumId: number
	srcNumId: number
	lang: string
	token: string
}) => {
	return combineUrl(endpoint + urlPrefix["aje"], {
		userid: userNumId,
		finyear: year,
		clientid: projNumId,
		AdjId: srcNumId,
		Lang: lang,
		token,
	})
}

export const makeAPGUrl = ({
	userNumId,
	objNumId,
	projNumId,
	lang,
	token,
}: {
	userNumId: number
	objNumId: number
	projNumId: number
	lang: string
	token: string
}) => {
	return combineUrl(endpoint + urlPrefix["apg"], {
		userid: userNumId,
		wprid: objNumId,
		clientid: projNumId,
		Lang: lang,
		token,
	})
}

export const makeFluxAnalysisUrl = ({
	userNumId,
	projNumId,
	projParentCabinetNumId,
	// year,
	objNumId,
	// lang,
	token,
}: {
	userNumId: number
	projNumId: number
	projParentCabinetNumId: number
	// year: number
	objNumId: number
	// lang: string
	token: string
}) => {
	return combineUrl(endpoint + urlPrefix["fluxAnalysis"], {
		userid: userNumId,
		clientid: projNumId,
		cabinetid: projParentCabinetNumId,
		// finyear: year, // there are no process for this on devexpress code
		LeadsheetId: objNumId,
		// Lang: lang,
		token,
	})
}

export const makeFinalAnalysisUrl = () => {
	return combineUrl(endpoint + urlPrefix["finalAnalysis"], {})
}

export const makeCreateLSUrl = () => {}

export const makeLSUrl = ({
	userNumId,
	projNumId,
	projParentCabinetNumId, // ?? is this src Id?
	// year,
	// lang,
	token,
}: {
	userNumId: number
	projNumId: number
	projParentCabinetNumId: number
	// year: number
	// lang: string
	token: string
}) => {
	return combineUrl(endpoint + urlPrefix["leadsheet"], {
		userid: userNumId,
		clientid: projNumId,
		cabinetid: projParentCabinetNumId,
		// finyear: year,
		// Lang: lang,
		token,
	})
}

export const makePAJEUrl = () => {}

export const makePBCListUrl = () => {}

export const makeFileGroupUrl = ({
	userNumId,
	objNumId,
	projNumId,
	lang,
	token,
}: {
	userNumId: number
	objNumId: number
	projNumId: number
	lang: string
	token: string
}) => {
	return combineUrl(endpoint + urlPrefix["fileGroup"], {
		userid: userNumId,
		wprid: objNumId,
		clientid: projNumId,
		Lang: lang,
		token,
	})
}

export const makeTimelineUrl = () => {}

export const makeTBUrl = () => {}
