import { types } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../common-models/responses"
import ResponseSnackbar from "../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	ViewResponseHelper,
} from "../../../../common-models/common-view-model-actions"

import GetOrgList from "./view-model-actions/get-org-list"

import { OrgListItem } from "./data-models/org-list.data-model"
import { compareValues } from "../../../../components/basic-elements/tree-list/getTreeFromFlatData"

const OrgListViewModel = types
	.model({
		orgList: types.optional(types.array(OrgListItem), []),
		// ---------- common models
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		resetList() {
			self.orgList.length = 0
		},
		pushItemToList(item: any) {
			self.orgList.push(item)
		},
		updateWholeList(newList: typeof self.orgList) {
			self.orgList.length = 0
			self.orgList = newList
		},
	}))

	// static actions
	.views((self) => ({
		get getLanguageInfo() {
			const country = self.orgList[0].countryCode
			const language = self.orgList[0].language
			return { country, language, languageCode: language + "-" + country }
		},
		getUserIdInOrg(orgId: string) {
			const org = self.orgList.find(
				(element: any) => element.orgId === orgId
			)

			return org?.userId ?? false
		},
		get getUserInfo() {
			const tempTarget = self.orgList[0]
			if (tempTarget) {
				return {
					name: tempTarget.userName,
					aliasId: tempTarget.userAliasId,
					email: tempTarget.email,
				}
			}
			return {
				name: "",
				aliasId: "",
				email: "",
			}
		},
		get sortedList() {
			return self.orgList.slice().sort(compareValues("orgName"))
		},
	}))
	// AGER (Add, Get, Edit, Remove)
	.actions(GetOrgList)
	// common actions
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default OrgListViewModel
