import React, { useEffect, useState } from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import {
	DLDialog,
	DLIconButton,
	DLSpinner,
	DLInputField,
	DLListTransfer,
	DLDialogHeader,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import { DLCheckbox } from "@datalobby/components"
import styled from "styled-components"
import {
	mdiArrowRightBoldCircle,
	mdiArrowLeftBoldCircleOutline,
	mdiSync,
	mdiMagnify,
	mdiCloseCircleOutline,
} from "@mdi/js"
import Icon from "@mdi/react"
import UnAssignUsersFromGroupDialog from "./UnAssignUsersFromGroup"
import { DLI18nProps } from "../../../../../common-models/types/common-props"

export default observer(function AssignUsersToGroupDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	ConsoleLog(" -=-=-=-=- AssignUsersToGroupDialog -=-=-=-=- ")
	const orgStore = useOrgStore()
	const partialStore = orgStore.setupGroups
	const actionName = "getUnassignedUsersOfGroup"
	const secondaryActionName = "getAssignedUsersOfGroup"

	const [searchUser, setSearchUser] = useState<string>("")
	const [searchAssignedUser, setSearchAssignedUser] = useState<string>("")
	const [selectedUsers, setSelectedUsers] = useState<any>([])
	const [unSelectAllUsers, setUnSelectedAllUsers] = useState<any>([])
	const [selectedAssignedUsers, setSelectedAssignedUsers] = useState<any>([])

	const groupId = partialStore.selectedGroup
	const groupInfo = partialStore.getGroupInfoById(groupId)
	const groupName = groupInfo?.groupName

	useEffect(() => {
		partialStore.getUnassignedUsersOfGroup(groupId)
		partialStore.getAssignedUsersOfGroup(groupId)
	}, [groupId])

	const selectHandler = (userId: string, type: number) => {
		let tempArray =
			type === 1 ? [...selectedUsers] : [...selectedAssignedUsers]
		if (tempArray.some((x) => x.UserId === userId)) {
			tempArray.splice(
				tempArray.findIndex((item: any) => item.UserId === userId),
				1
			)
		} else tempArray.push({ UserId: userId })

		if (type === 1) {
			setSelectedUsers(tempArray)
			orgStore.setupGroups.setUserSelect(userId)
		} else {
			setSelectedAssignedUsers(tempArray)
			orgStore.setupGroups.setAssignedUserSelect(userId)
		}
	}

	const unSelectHandler = () => {
		selectedUsers.forEach((user: any) => {
			selectHandler(user.UserId, 1)
		})
	}

	const handleCheckedRight = () => {
		const groupId = orgStore.setupGroups.selectedGroup
		orgStore.setupGroups.addUsersToGroup(groupId, selectedUsers)
		setSelectedUsers([])
	}

	const handleCheckedLeft = (isVerified: boolean) => {
		const groupId = orgStore.setupGroups.selectedGroup
		orgStore.setupGroups.removeUsersFromGroup(
			groupId,
			selectedAssignedUsers,
			isVerified
		)
		if (isVerified) {
			setSelectedAssignedUsers([])
		}
	}

	const handleSearchLeft = (event: any) => {
		const query = event.target.value
		setSearchUser(query)
	}

	const handleSearchRight = (event: any) => {
		const query = event.target.value
		setSearchAssignedUser(query)
	}

	const handleSelect50Items = (event: any) => {
		const notAssignedUsers = partialStore.unassigedUsersOfGroup
		notAssignedUsers.forEach((user: any, i) => {
			const isUserSelected = selectedUsers.find(
				(item: any) => item.UserId === user.id
			)
			if (!isUserSelected) {
				if (i < 50) {
					setSelectedUsers((prev: any) => [
						...prev,
						{ UserId: user.id },
					])
					orgStore.setupGroups.setUserSelect(user.id)
				}
			} else {
				if (i < 50) {
					return
				} else {
					setSelectedUsers((prev: any) => {
						return prev.filter(
							(item: any) => item.UserId !== user.id
						)
					})
					orgStore.setupGroups.setUserSelect(user.id)
				}
			}
		})
	}

	const isLoading =
		partialStore.getActionStatus(actionName) === "LOADING" ||
		partialStore.getActionStatus(secondaryActionName) === "LOADING" ||
		partialStore.getActionStatus("addUsersToGroup") === "LOADING" ||
		partialStore.getActionStatus("removeUsersFromGroup") === "LOADING"

	const assignedUsersLength = partialStore.viewAssignedUsersList("").length
	const unassignedUsersLength = partialStore.viewUsersList("").length

	return (
		<>
			<DLDialog
				eleTestId="assign-users-to-group-dialog"
				isOpen={partialStore.assignUsersToGroupDialogOpen}
				setIsOpen={partialStore.setAssignUsersToGroupDialogOpen}
				showOpenBtn={false}
				showCloseBtn
				dialogTitle={
					<DLDialogHeader
						dialogName={
							i18n.twAssignUsersToGroup || "Assign Users to Group"
						}
						targetName={groupName ? groupName : "(Unknown group)"}
					/>
				}
				dialogContents={
					<DLListTransfer
						unassignedList={partialStore.viewUsersList(searchUser)}
						assignedList={partialStore.viewAssignedUsersList(
							searchAssignedUser
						)}
						selectListHandler={selectHandler}
						assignedListName={`${i18n.twAssignedUserList} (${assignedUsersLength})`}
						unassignedListName={`${i18n.twUserList} (${unassignedUsersLength})`}
						handleCheckedRight={handleCheckedRight}
						handleCheckedLeft={() => handleCheckedLeft(false)}
						handleSearchLeft={handleSearchLeft}
						handleSearchRight={handleSearchRight}
						handleSelect50Items={handleSelect50Items.bind(
							undefined,
							selectedUsers.length
						)}
					/>
				}
				showSpinner={isLoading}
				cannotUpdate={isLoading}
				maxWidth="md"
			/>
			{orgStore.setupGroups.unAssignUsersToGroupDialogOpen && (
				<UnAssignUsersFromGroupDialog
					unassignGroups={() => handleCheckedLeft(true)}
					i18n={i18n}
				/>
			)}
		</>
	)
})

const AssignUserToGroup = observer(() => {
	const orgStore = useOrgStore()
	const actionName = "temp_action_name"
	const groupInfo = orgStore.setupGroups.getGroupInfoById(
		orgStore.setupGroups.selectedGroup
	)

	const refreshUserList = () => {
		ConsoleLog("refresh org user list")
		orgStore.setupUsers.getUserList()
	}

	const [assignedUsers, setAssignedUsers] = useState<any>([])
	const [notAssignedUsers, setNotAssignedUsers] = useState<any>([])
	//
	// const [searchInLeft, setSearchInLeft] = useState("")
	// const [searchInRight, setSearchInRight] = useState("")
	//
	const [searchedInLeft, setSearchedInLeft] = useState<any>([])
	const [searchedInRight, setSearchedInRight] = useState<any>([])
	//
	const [selectedInLeft, setSelectedInLeft] = useState([""])
	const [selectedInRight, setSelectedInRight] = useState([""])

	const handleSearchLeft = (event: any) => {
		const query = event.target.value
		notAssignedUsers.map((user: any) => {
			if (user.name.includes(query)) {
				searchedInLeft.push(user)
			}
		})
	}

	useEffect(() => {
		if (orgStore.setupUsers.orgUserList.length === 0) {
			refreshUserList()
		}
	}, [])

	useEffect(() => {
		if (groupInfo) {
			const separatedList = orgStore.setupUsers.getUsersByGroup(
				groupInfo.groupName
			)
		}
	}, [orgStore.setupUsers.orgUserList.length])

	return (
		<StyledAssignUserToGroup className="assign-user-to-group-container">
			<div className="vertical-section FC">
				<div className="info-area FC JSB">
					<div>Organization </div>
					<div onClick={refreshUserList}>
						<Icon path={mdiSync} size={0.8} />
					</div>
					<div className="count">
						Not Assigned users: {notAssignedUsers.length}
					</div>
					<div className="search-area FR AC">
						<Icon path={mdiMagnify} size={0.8} />
						<DLInputField
							eleTestId="search-not-assigned-users"
							elePlaceholder="Search User"
							eleHandleChange={handleSearchLeft}
						/>
						<DLIconButton eleTestId="reset-left-items">
							<Icon path={mdiCloseCircleOutline} size={0.8} />
						</DLIconButton>
					</div>
					<div className="select-all">
						<DLCheckbox
							eleTestId="select-all-not-assigned-user"
							labelText="Select All"
						/>
					</div>
				</div>
				<div className="contents-area">
					{orgStore.setupUsers.getActionStatus(actionName) ===
						"LOADING" && <DLSpinner />}
					{groupInfo &&
						orgStore.setupUsers.getActionStatus(actionName) ===
							"SUCCESS" &&
						searchedInLeft.length === 0 &&
						notAssignedUsers.map((user: any) => {
							return (
								<div>
									{user.accessRight} _ {user.name}
								</div>
							)
						})}
					{orgStore.setupUsers.getActionStatus(actionName) ===
						"SUCCESS" &&
						searchedInLeft.length > 0 &&
						searchedInLeft.length}
				</div>
			</div>
			{/* <div className="vertical-section FC">
				<div className="info-area FC JSB">
					<div />
					<div className="count">Selected Users: 000</div>
				</div>
				<div className="contents-area"></div>
			</div> */}
			<div className="vertical-section FC action-buttons-container">
				<div className="info-area FC JSB"></div>
				<div className="contents-area FR AC JC">
					<div>
						<DLIconButton
							disabled
							size="large"
							eleTestId="assign-to-group"
							color="primary"
						>
							<Icon path={mdiArrowRightBoldCircle} size={1.6} />
						</DLIconButton>
						<DLIconButton
							disabled
							size="large"
							eleTestId="unassign-from-group"
						>
							<Icon
								path={mdiArrowLeftBoldCircleOutline}
								size={1.6}
							/>
						</DLIconButton>
					</div>
				</div>
			</div>
			<div className="vertical-section FC">
				<div className="info-area FC JSB">
					<div>{groupInfo?.groupName}</div>
					<div className="count">
						Users in the group: {groupInfo?.userCount}
					</div>
					<div className="search-area">
						<DLInputField
							eleTestId="search-assigned-users"
							elePlaceholder="Search User"
						/>
					</div>
					<div className="select-all">
						<DLCheckbox
							eleTestId="select-all-assigned-user"
							labelText="Select All"
						/>
					</div>
				</div>
				<div className="contents-area">
					{assignedUsers.map((user: any) => {
						return <div key={user.id}>{user.name}</div>
					})}
				</div>
			</div>
		</StyledAssignUserToGroup>
	)
})

const StyledAssignUserToGroup = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	.vertical-section {
		border: 1px solid red;
		width: 14rem;
		.info-area {
			border: 1px solid green;
			height: 8rem;
			margin-bottom: 1rem;
			.count {
			}
		}
		.contents-area {
			/* border: 1px solid orange; */
			min-height: 10rem;
		}
		&.action-buttons-container {
			width: 4rem;
		}
	}
`
