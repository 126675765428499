import React from "react"
// store related
import { observer } from "mobx-react-lite"
// components
import { DLContextMenu } from "../../../../../components/basic-elements"
import FileContextMenuOptions from "./file-ctx-menu-options"
import { initialClickPoint } from "../../../../../common-models/types/dialog.props"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"

export default observer(function FileContextMenu({
	partialStore,
	permission,
	commentPermission,
}: {
	partialStore: any
	permission: PermissionAsObjectProps
	commentPermission: PermissionAsObjectProps
}) {
	const handleClose = () => {
		partialStore.setFileClickPoint(initialClickPoint)
	}

	const clickPoint = partialStore.fileClickPoint
	const selectedItems = partialStore.selectedItems // TODO: menuType should be catched...
	const fileId = selectedItems[0]?.id

	const fileInfo = partialStore.getItemInfo(fileId)
	//
	// let divider = [0, 4, 8]
	// if (fileInfo) {
	// 	if (fileInfo?.isOpen) {
	// 		divider = [0, 4, 6, 10]
	// 	}
	// } else {
	// 	alert("(FileContextMenu) Cannot find the selected file")
	// 	handleClose()
	// }

	return (
		<DLContextMenu
			eleId="proj-files-file-ctx-menu"
			clickPoint={{
				mouseX: clickPoint.mouseX,
				mouseY: clickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={FileContextMenuOptions({
				partialStore,
				fileInfo,
				handleClose,
				permission,
				commentPermission,
			})}
			hasDivider={[1, 3, 7, 8, 9, 11, 12, 14, 17]} // pending for checkout location update
		/>
	)
})
