import React from "react"
import { DLButton } from "."
import { mdiFilePlusOutline } from "@mdi/js"
import Icon from "@mdi/react"
import styled from "styled-components"
import { formatFileSize } from "@datalobby/common"
import { allowedFormats } from "../../../service-modules/file-module/data-models/dl-file-control-model"

// NOTE: There is anohter LocalFileProps in 'file-module/data-models/file.data-props'
export type CommonLocalFileProps = {
	file: any
	id: string
	name: string
	extension: string
	type: string
	size: number
	formattedSize?: string
	lastModified: any
}

const DLLocalFileUploadButton = ({
	multiple,
	color = "default",
	disabled = false,
	fileHandler,
}: {
	multiple: boolean
	color?: "primary" | "default" | "secondary"
	disabled?: boolean
	fileHandler: (localFiles: CommonLocalFileProps[]) => void
}) => {
	const handleLocalFiles = (event: any) => {
		const localFiles = event.target.files

		let fileList: CommonLocalFileProps[] = []

		for (let i = 0, numFiles = localFiles.length; i < numFiles; i++) {
			const file = localFiles[i]
			const { name, type, size, lastModified } = file
			const fileName = name
				.slice(0, name.lastIndexOf("."))
				.replace(/\//g, "_")
			const timelog = new Date().getTime()
			const fileExtension = name.slice(name.lastIndexOf(".") + 1)
			const formattedSize = formatFileSize(size, 2)
			fileList.push({
				file,
				id: timelog + "_" + fileName, //uuid(),
				name: fileName,
				extension: fileExtension,
				type,
				size,
				formattedSize,
				lastModified,
			})
		}

		fileHandler(fileList)
	}
	return (
		<StyledLocalFileUploadButton>
			<DLButton
				eleTestId="choose-local-files-btn-container"
				startIcon={<Icon path={mdiFilePlusOutline} size={0.8} />}
				color={color}
				disabled={disabled}
			>
				<label className="choose-local-files-btn-label">
					{multiple ? "Choose Local Files" : "Choose a Local File"}
					<input
						type="file"
						name="file"
						onChange={handleLocalFiles}
						multiple={multiple}
						data-testid="file-input"
						accept={allowedFormats}
					/>
				</label>
			</DLButton>
		</StyledLocalFileUploadButton>
	)
}

const StyledLocalFileUploadButton = styled.div`
	.choose-local-files-btn-label {
		input[type="file"] {
			display: none;
		}
	}
`

export default DLLocalFileUploadButton
