import React from "react"
import { observer } from "mobx-react-lite"
import ProjInfoUpdateDialog from "./sub-components/ProjInfoUpdateDialog"
import { DLSpinner, DLButton } from "../../../components/basic-elements"
import styled from "styled-components"
import { ActionStatus } from "../../../common-models/enumerations/common-enums"
import { formattedDateWithoutTzLabel } from "../../../library/converters/date-utc-converter"
import CommonSnackbarComponent from "../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import { PermissionAsObjectProps } from "../../../common-models/permission"
import {
	DLI18nProps,
	DnTFormatProps,
} from "../../../common-models/types/common-props"
import ProjLocationDetailsDialog from "./sub-components/ProjLocationDetailsDialog"

const InfoRow = ({
	label,
	value,
	testId,
}: {
	label: string
	value: any
	testId: string
}) => {
	return (
		<div className="info-row" data-testid={testId}>
			<div className="label-area">{label}</div>
			<div className="value-area">{value}</div>
		</div>
	)
}

const ProjInfo = observer(
	({
		partialStore,
		fetchingStatus,
		permission,
		dntFormat,
		i18n,
	}: {
		partialStore: any
		fetchingStatus: ActionStatus
		permission: PermissionAsObjectProps
		dntFormat: DnTFormatProps
		i18n: DLI18nProps
	}) => {
		const {
			projTitle,
			projAliasId,
			version,
			engTypeName,
			statusName,
			isLocked,
			clientName,
			clientAliasId,
			cabinetName,
			groupName,
			engPartner,
			financialYear,
			periodName,
			raName,
			periodEndDate,
			archPolicyPeriodName,
			expectedReportDate,
			expectedArchDeadlineDate,
			finalReportDate,
			finalArchDeadlineDate,
			createdAt,
			clientAddress,
			fee,
		} = partialStore.projInfo
		return (
			<>
				{/* ----- Spinner ----- */}
				{fetchingStatus === "LOADING" && (
					<div
						className="FR AC JC"
						style={{ width: "100%", height: "100%" }}
					>
						<DLSpinner />
					</div>
				)}
				{fetchingStatus === "SUCCESS" && (
					<div
						className="info-container"
						style={{ width: "50%", marginBottom: 25 }}
					>
						<div
							className="FR AC proj-info-section-header"
							style={{
								paddingLeft: "3rem",
								marginBottom: "1rem",
							}}
						>
							<h4>Workspace Information</h4>
							{permission.update && (
								<DLButton
									variant="outlined"
									eleTestId="proj-info-edit-btn"
									// endIcon={
									// 	<Icon path={mdiPencil} size={0.8} />
									// }
									eleStyle={{ marginLeft: 10 }}
									size="thin"
									align="right"
									clickHandler={() =>
										partialStore.setUpdateDialogOpenStatus(
											true
										)
									}
								>
									{i18n.twEdit || "Edit"}
								</DLButton>
							)}
						</div>
						<StyledProjInfo>
							{/* 
							    New project information arrangement 
								- Project: project name (project aliasId) project version
								- Project Type: (engagement type)
								- Project Status: Normal / Archived... 
								- Client: client name (client aliasId)
								- Group: Group name
								- Cabinet: Cabinet name
								- Engagement Partner: user name (user aliasId)
								- Risk Assessment
								- Year / Period Type: 2022 (Quarter 3)
								- Perioed End Date: 
								- Archive Policy - Period:
								- Expected Report Date
								- ExpectedArchive Deadline Date
								- Final Report Date
								- Final Archive Deadline Date
								- CreatedBy
								- CreatedAt
								- Used Template / RF Source
								- Replica
							*/}
							{/* NOTE: */}
							<div className="ws-info-section location-info">
								<InfoRow
									testId="info-row-groupName"
									label={i18n.twGroup || "Group"}
									value={groupName}
								/>
								<div className="FR AC">
									<InfoRow
										testId="info-row-cabinetName"
										label={i18n.twCabinet || "Cabinet"}
										value={cabinetName}
									/>
									<span
										className="cabinet-as-link cabinet-info-link"
										onClick={() =>
											partialStore.setOpenProjLocationDialog()
										}
									>
										Cabinet Info
									</span>
									{/* <DLIconButton
										eleTestId="cabinet-location-details"
										tooltipText="Location Details"
										color="warning"
										clickHandler={() =>
											partialStore.setOpenProjLocationDialog()
										}
										variant="iconOnly"
									>
										<Icon path={mdiEye} size={0.8} />
									</DLIconButton> */}
								</div>
							</div>
							{/* NOTE: */}
							<div className="ws-info-section ws-owner-info">
								<InfoRow
									testId="info-row-ep"
									label={
										i18n.twEngagementPartner ||
										"Engagement Partner"
									}
									value={engPartner.replace(",", ", ")}
								/>
							</div>
							{/* NOTE: */}

							<div className="ws-info-section client-info">
								<InfoRow
									testId="info-row-clientName"
									label={i18n.twClientName || "Client Name"}
									value={clientName}
								/>
								<InfoRow
									testId="info-row-clientAliasId"
									label={i18n.twClientId || "Client ID"}
									value={clientAliasId}
								/>
							</div>
							<div className="ws-info-section ws-info">
								<InfoRow
									testId="info-row-projTitle"
									label={i18n.twProjectName || "Project Name"}
									value={`${projTitle} (${projAliasId}) v.${version}`}
								/>
								<InfoRow
									testId="info-row-projID"
									label={i18n.twProjectName || "Project ID"}
									value={`${projAliasId}`}
								/>
								<InfoRow
									testId="info-row-projStatus"
									label={
										i18n.twProjectStatus || "Project Status"
									}
									value={
										statusName +
										(isLocked ? " (Locked)" : "")
									}
								/>
								<InfoRow
									testId="info-row-projVersion"
									label={"Project Version"}
									value={`v.${version}`}
								/>
							</div>
							{/* NOTE: */}
							{/* NOTE: */}

							<div className="ws-info-section ra-info">
								<InfoRow
									testId="info-row-engType"
									label={i18n.twProjType || "Engagement Type"}
									value={engTypeName}
								/>
								<InfoRow
									testId="info-row-ra"
									label={
										i18n.twRiskAssessment ||
										"Risk Assessment"
									}
									value={raName}
								/>
							</div>
							{/* NOTE: */}
							<div className="ws-info-section schedule-info-1">
								<InfoRow
									testId="info-row-financialYear"
									label="Year"
									value={`${financialYear}`}
								/>
								<InfoRow
									testId="info-row-engType"
									label={"Period Type"}
									value={periodName}
								/>
								<InfoRow
									testId="info-row-periodEndDate"
									label={
										i18n.twPeriodEndDate ||
										"Period End Date"
									}
									value={
										periodEndDate
											? formattedDateWithoutTzLabel({
													date: periodEndDate,
													dntFormat,
											  })
											: "-"
									}
								/>
							</div>
							{/* NOTE: */}
							<div className="ws-info-section schedule-info-2">
								<InfoRow
									testId="info-row-archPolicyPeriod"
									label={
										i18n.twArchiveDatePolicy ||
										"Archive Date Policy"
									}
									value={archPolicyPeriodName}
								/>
							</div>
							{/* NOTE: */}
							<div className="ws-info-section schedule-info-3">
								<InfoRow
									testId="info-row-expectedReportDate"
									label={
										i18n.twExpectedReportDate ||
										"Expected Report Date"
									}
									value={
										expectedReportDate
											? formattedDateWithoutTzLabel({
													date: expectedReportDate,
													dntFormat,
											  })
											: "-"
									}
								/>
								<InfoRow
									testId="info-row-expectedArchDeadlineDate"
									label={
										i18n.twExpectedArchDeadlineDate ||
										"Expected Archive Deadline Date"
									}
									value={
										expectedArchDeadlineDate
											? formattedDateWithoutTzLabel({
													date: expectedArchDeadlineDate,
													dntFormat,
											  })
											: "-"
									}
								/>
							</div>
							{/* NOTE: */}
							<div className="ws-info-section schedule-info-4">
								<InfoRow
									testId="info-row-finalReportDate"
									label={
										i18n.twFinalReportDate ||
										"Final Report Date"
									}
									value={
										finalReportDate
											? formattedDateWithoutTzLabel({
													date: finalReportDate,
													dntFormat,
											  })
											: "-"
									}
								/>
								<InfoRow
									testId="info-row-finalArchDeadlineDate"
									label={
										i18n.twFinalArchiveDeadlineDate ||
										"Final Archive Deadline Date"
									}
									value={
										finalArchDeadlineDate
											? formattedDateWithoutTzLabel({
													date: finalArchDeadlineDate,
													dntFormat,
											  })
											: "-"
									}
								/>
							</div>
							{/* NOTE: */}
							<div className="ws-info-section others">
								<InfoRow
									testId="info-row-createdBy"
									label={i18n.twCreatedBy || "Created By"}
									value={partialStore.projInfo.createdBy} // TODO: have to check this variable
								/>
								<InfoRow
									testId="info-row-createdAt"
									label="Created At"
									value={
										createdAt
											? formattedDateWithoutTzLabel({
													date: createdAt,
													dntFormat,
											  })
											: "-"
									}
								/>
								<InfoRow
									testId="info-row-wsRoot"
									label="Used Template / RF Source"
									value="(TODO) Need to update"
								/>
							</div>
							<div className="ws-info-section others">
								<InfoRow
									testId="info-row-clientAddress"
									label={
										i18n.twClientAddress || "Client Address"
									}
									value={clientAddress || "-"}
								/>

								<InfoRow
									testId="info-row-projFee"
									label={i18n.twProjectFee || "Project Fee"}
									value={fee || "-"}
								/>
								{/* TODO: currency formatting method is required */}
							</div>
						</StyledProjInfo>
					</div>
				)}
				{partialStore.updateDialogOpenStatus && (
					<ProjInfoUpdateDialog
						i18n={i18n}
						partialStore={partialStore}
						// open={partialStore.updateDialogOpenStatus}
						// setOpen={partialStore.setUpdateDialogOpenStatus}
					/>
				)}
				{partialStore.openProjLocationDialog && (
					<ProjLocationDetailsDialog
						partialStore={partialStore}
						view="proj"
					/>
				)}
				<CommonSnackbarComponent
					i18n={i18n}
					partialStore={partialStore}
				/>
			</>
		)
	}
)

const StyledProjInfo = styled.div`
	.ws-info-section {
		margin-bottom: 1rem;
	}
	.info-row {
		display: flex;
		padding: 0.3rem 0 0.2rem 3rem;
		:hover {
			background-color: ${(props) => props.theme.shade10};
			border-radius: 3px;
		}
		.label-area {
			min-width: 14.5rem;
		}
		.value-area {
		}
	}
	.cabinet-as-link {
		text-decoration: underline;
		cursor: pointer;
		color: #337ab7;
	}
	.cabinet-as-link:hover {
		text-decoration: none;
	}
	.cabinet-info-link {
		margin-left: 15px; /* Adjust the margin value as needed */
		font-weight: bold;
	}
`

export default ProjInfo
