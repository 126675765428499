import { types } from "mobx-state-tree"
import ReadTrialBalanceDetails from "../../financial-statements/store/model-actions/read-trial-balance-details"
import { AdjustmentsApiProps } from "./adjustments.apis"
import CreateAdjustment from "./model-actions/create-adjustment"
import DeleteAdjustment from "./model-actions/delete-adjustment"
import ReadAdjustments from "./model-actions/read-adjustments"
import ReadWPReferences from "./model-actions/read-wp-references"
import ReadAdjustmentUrl from "./model-actions/read-adjustment-url"

const AdjustmentsModel = ({
	apiRead,
	apiReadTrialBalance,
	apiReadWP,
	apiCreate,
	apiDelete,
}: AdjustmentsApiProps) =>
	types
		.model({})
		.actions((self) => ({
			// -p----- Read
			// ------ Update
		}))
		.actions((self, read = apiRead) => ReadAdjustments(self, read))
		.actions((self, readTrialBalance = apiReadTrialBalance) =>
			ReadTrialBalanceDetails(self, readTrialBalance)
		)
		.actions((self, readWP = apiReadWP) => ReadWPReferences(self, readWP))
		.actions((self, create = apiCreate) => CreateAdjustment(self, create))
		.actions((self, del = apiDelete) => DeleteAdjustment(self, del))
		.actions(ReadAdjustmentUrl)

export default AdjustmentsModel
