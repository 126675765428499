import React, { useEffect } from "react"
import { useProjStore } from "../../../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import { DLButton, DLDialog } from "../../../../../../components/basic-elements"
import styled from "styled-components"
import RationalesController from "../../../rationales/RationalesController"
import { mdiDownload } from "@mdi/js"
import Icon from "@mdi/react"

export default observer(function RationaleDialog() {
	const projStore = useProjStore()
	const actionName = "getRationales"
	useEffect(() => {
		projStore.rationales.getRationales()
	}, [])

	return (
		<DLDialog
			isOpen={projStore.archive.rationaleDialogOpen}
			setIsOpen={projStore.archive.setRationaleDialogOpen}
			showCloseBtn
			eleTestId="rationale-dialog"
			dialogTitle={<div className="FR AC">Rationale</div>}
			dialogContents={
				<>
					{projStore.rationales.getActionStatus(actionName) ===
						"SUCCESS" && <RationaleDialogContents />}
					{projStore.rationales.getActionStatus(actionName) ===
						"FAIL" && (
						<div>
							Please set the Final Report Date on the Project
							Information to check workpapers and permanent files
							which need to add Rationale.
						</div>
					)}
				</>
			}
			// handleAction={handleOnSubmit}
			maxWidth="xl"
			actionBtn={false}
			openBtn={false}
			showSpinner={
				projStore.rationales.getActionStatus(actionName) === "LOADING"
			}
		/>
	)
})

const RationaleDialogContents = observer(() => {
	const projStore = useProjStore()

	return (
		<StyledRationaleDialog>
			<div className="FR JSB">
				<div className="summary-area FC">
					<div className="FR AC">
						Total Files :{" "}
						{projStore.rationales.viewRationaleFiles().length}
					</div>
					<div className="FR AC">
						(Workpapers: {projStore.rationales.getRationaleWpLength}{" "}
						/ Permanent Files:{" "}
						{projStore.rationales.getRationalePfLength})
					</div>
				</div>
				<div>
					<DLButton
						eleTestId="export-rationale-btn"
						startIcon={<Icon path={mdiDownload} size={0.8} />}
						clickHandler={() =>
							projStore.rationales.downloadRationales()
						}
					>
						Download
					</DLButton>
				</div>
			</div>
			<hr />
			{/* <ReactTableV8
				tableColumns={RationaleFilesColumns(handleEdit, handleDelete)}
				customFilters={filterComponent}
				data={projStore.archive.viewRationaleFiles()}
				menuId="RationaleDialog"
				showPageSetting={false}
				hasPagination={false}
			/> */}
			<RationalesController fromDialog={true} />
		</StyledRationaleDialog>
	)
})

const StyledRationaleDialog = styled.div`
	.rt-table {
		padding-left: 0rem !important;
	}
	.page-container {
		width: 100% !important;
		height: 550px !important;
	}
`
