import React from "react"
import { DLIconButton } from "../../../../components/basic-elements"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { mdiBellOutline } from "@mdi/js"
import { Icon } from "@mdi/react"
import { observer } from "mobx-react-lite"

export default observer(function NotificationBtn({
	tooltipText,
}: {
	tooltipText: string
}) {
	const store = useRootStore()

	return (
		<DLIconButton
			variant="iconOnly"
			color="default"
			clickHandler={() =>
				store.ui.setRightDrawerOpen(!store.ui.isRightDrawerOpen)
			}
			eleTestId="notification btn"
			tooltipText={tooltipText}
		>
			{/*<DLBadge
				// NOTE: Update is required (show all the notification count / or just display circle)
				content={
					store.notis.viewNotifications(0).length +
					store.notis.viewNewOrgNotis
				}
				eleTestId="notification-count-badge"
				color={MessageColorType.red}
			>*/}
			<Icon path={mdiBellOutline} size={0.8} />
			{/*</DLBadge>*/}
		</DLIconButton>
	)
})
