import React from "react"
import styled from "styled-components"
import { mdiEmoticonSadOutline } from "@mdi/js"
import Icon from "@mdi/react"

export default function SomethingWrong() {
	return (
		<StyledSomethingWrong className="FC AC JC">
			<Icon
				path={mdiEmoticonSadOutline}
				size={"88px"}
				style={{ opacity: 0.3 }}
			/>
			<p>Something wrong... Please retry.</p>
			<p>
				If the problem persists, please contact the system
				administrator.
			</p>
			{/* TODO: connect emailJS here to get issue report */}
			{/* <DLButton
				eleClassName="send-btn"
				eleTestId="report-issue-btn"
				variant="text"
				endIcon={<Icon path={mdiSend} size={0.8} />}
			>
				Report this issue
			</DLButton> */}
		</StyledSomethingWrong>
	)
}

const StyledSomethingWrong = styled.div`
	/* width: 320px;
	height: 320px; */
	padding: 2rem;
	color: ${(props) => props.theme.shade40};
	.send-btn {
		margin-top: 2rem;
	}
`
