import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 */
const GetOrgTrashItem = (self: any) => ({
	getOrgTrashList(type: string) {
		// 0.
		const actionName = "getOrgTrash"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.request API call
		self.readOrgTrashList(type)
			.then((response: any) => {
				// if success
				if (response.status === 200 && response.data.Status === 1) {
					// 1) Initialize existing list
					self.resetList()
					// 2) Re-organize data
					console.time()
					let trashList: any[] = []
					const existingTrashList = response.data.TrashDetails
					existingTrashList.map((trashItem: any) => {
						const reOrganizedTrashItem = {
							id: trashItem.TrashID.toString(), // [TrashID] ex) 2461
							type: trashItem.Type, // [Type] ex) "Project"
							previousId: trashItem.FileID, // [FileID] ex) "3429"
							name: trashItem.Name, // [Name] ex) "Half year 2 2020 NameC : Remove (123) (Unarchived v2)"
							deletedBy: trashItem.DeleteBy, // [DeleteBy] ex) "Tejaswini [tkande]"
							deletedAt: trashItem.DeletedOn, // [DeletedOn] ex) "2020-01-06T11:49:23.898965+00:00"
						}
						trashList.push(reOrganizedTrashItem)
					})
					self.setTrashList(trashList)
					console.timeEnd()
					self.setNeedRefresh(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 200, // temp
						color: MessageColorType.orange,
						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetOrgTrashItem
