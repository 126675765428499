import React from "react"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import {
	DLDialog,
	DLButton,
	DLDialogHeader,
} from "../../../../components/basic-elements"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { mdiTrashCan } from "@mdi/js"
import Icon from "@mdi/react"
import { RestoreIcon } from "../../../../components/basic-elements/icons/common-icons"
import { TrashType } from "../store/data-models/org-trash.data-models"

const RemoveAndRestoreDialog = observer(
	({
		open,
		setOpen,
		type,
		i18n,
	}: {
		open: boolean
		setOpen: any
		type: "Restore" | "Delete"
		i18n: DLI18nProps
	}) => {
		const orgStore = useOrgStore()
		// const userId = orgStore.checkin.orgUserId
		const itemCount = orgStore.trash.selectedItems.length
		const {
			twDelete,
			twRestore,
			tsConfirmTrashRestoreMsg,
			tsConfirmPermDeleteMsg,
			twCancel,
		} = i18n
		const selectedTrashType = orgStore.trash.selectedTrashType
		const trashInfo: any = orgStore.trash.getTrashInfoById(
			orgStore.trash.selectedItems[0],
			selectedTrashType
		)

		// const postAction = (itemId: string) => {
		// 	orgStore.trash.deleteItem(itemId, selectedTrashType)
		// 	orgStore.trash.spliceSelectedItem(itemId)
		// }

		const handleOnSubmit = () => {
			if (type === "Restore") {
				if (itemCount > 1) {
					// if (selectedTrashType === TrashType.org) {
					orgStore.trash.restoreMultipleItems(
						orgStore.trash.selectedItems
					)
					// } else {
					// 	orgStore.trash.selectedItems.map((itemId: string) => {
					// 		const targetInfo: any =
					// 			orgStore.trash.getTrashInfoById(
					// 				itemId,
					// 				selectedTrashType
					// 			)
					// 		orgStore.trash.removeOrRestorePost({
					// 			postId: itemId,
					// 			menuId: targetInfo.menu,
					// 			toDelete: false,
					// 			by: userId,
					// 			postAction: () => postAction(itemId),
					// 		})
					// 	})
					// }
				} else if (itemCount === 1) {
					// if (selectedTrashType === TrashType.org) {
					orgStore.trash.restoreOrgTrashItem(
						orgStore.trash.selectedItems[0]
					)
					// } else {
					// 	orgStore.trash.removeOrRestorePost({
					// 		postId: orgStore.trash.selectedItems[0],
					// 		menuId: trashInfo?.menu,
					// 		toDelete: false,
					// 		by: userId,
					// 		postAction: () =>
					// 			postAction(orgStore.trash.selectedItems[0]),
					// 	})
					// }
				}
			} else if (type === "Delete") {
				if (itemCount > 1) {
					// if (selectedTrashType === TrashType.org) {
					orgStore.trash.removeMultipleItems(
						orgStore.trash.selectedItems
					)
					// } else {
					// 	orgStore.trash.selectedItems.map((itemId: string) => {
					// 		const targetInfo: any =
					// 			orgStore.trash.getTrashInfoById(
					// 				itemId,
					// 				selectedTrashType
					// 			)
					// 		orgStore.trash.permanentRemovePost({
					// 			postId: itemId,
					// 			menuId: targetInfo?.menu,
					// 			postAction: () => postAction(itemId),
					// 		})
					// 	})
					// }
				} else if (itemCount === 1) {
					// if (selectedTrashType === TrashType.org) {
					orgStore.trash.removeOrgTrashItem(
						orgStore.trash.selectedItems[0]
					)
					// } else {
					// 	orgStore.trash.permanentRemovePost({
					// 		postId: orgStore.trash.selectedItems[0],
					// 		menuId: trashInfo?.menu,
					// 		postAction: () =>
					// 			postAction(orgStore.trash.selectedItems[0]),
					// 	})
					// }
				}
			} else {
				alert("incorrect type")
			}
			setOpen(false)
		}

		return (
			<DLDialog
				eleTestId={`org-trash-${type}-dialog`}
				isOpen={open}
				setIsOpen={setOpen}
				showCloseBtn={true}
				showOpenBtn={false}
				dialogTitle={
					<DLDialogHeader
						dialogName={type === "Restore" ? twRestore : twDelete}
						targetName={
							itemCount === 1 &&
							selectedTrashType === TrashType.org
								? trashInfo?.type + "-" + trashInfo?.name
								: ""
						}
					/>
				}
				dialogContents={
					type === "Restore" ? (
						<span>
							{itemCount > 1
								? itemCount + " items are "
								: itemCount + " item is "}
							selected. <br />
							{tsConfirmTrashRestoreMsg}
						</span>
					) : (
						<span>
							{itemCount > 1
								? itemCount + " items are "
								: itemCount + " item is "}
							selected. <br />
							{tsConfirmPermDeleteMsg}
						</span>
					)
				}
				actionReady={true}
				// handleAction={handleOnSubmit}
				// actionBtn={type === "Restore" ? twRestore : twDelete}
				actionBtn={
					<DLButton
						variant="text"
						// eleTestId={`dialog-${type}-btn`}
						eleTestId="dialog-action-btn-submit" // this is default dialog submit button testid
						startIcon={
							type === "Restore" ? (
								<RestoreIcon />
							) : (
								<Icon path={mdiTrashCan} size={0.8} />
							)
						}
						clickHandler={handleOnSubmit}
						color="warning"
					>
						{type === "Restore" ? twRestore : twDelete}
					</DLButton>
				}
				cancelBtnText={twCancel}
			/>
		)
	}
)

export default RemoveAndRestoreDialog
