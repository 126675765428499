import {
	ActionStatus,
	MessageColorType,
	DLObjectFormat,
} from "../../../../common-models/enumerations/common-enums"
import {
	AddSingleFileProps,
	LocalFileProps,
} from "../../data-models/file.data-props"
import { initialDLFile } from "../../data-models/initial-file"
import { FileScreenDialog } from "../../data-models/dl-file-control-model"
import { ConsoleLog } from "../../../../components/basic-elements"
import { reorganizeFile } from "./util-reorganize-file"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { getProjIdFromUrl } from "../../../../library/api-requests/request-get-others"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - loading, success, fail
 *
 */

const AddFile = (self: any) => ({
	addSingleFile(fileInfo: AddSingleFileProps, userRole: string) {
		// 0.
		const actionName = "addSingleFile"
		// 1.
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			open: true,
		})
		// 3.
		// console.log(actionName, projId, fileInfo)
		self.createSingleFile(fileInfo)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					const fetchedInfo = response.data
					// reflect the result to stored data
					let newFile: any = {}
					// WARNING: @Noah. Noah, initalDLFile is not working well for PT. It means it has incorrect default values in it.
					// WARNING:
					// WARNING:
					// WARNING:
					// WARNING: YOU SHOULD CLEAR THIS ADD and GET PART FOR WP, PF, PT
					if (self.storeName === DLOrgSubMenus.proj_templates) {
						newFile = reorganizeFile({
							fetchedFile: {
								...initialDLFile,
								// ...fetchedInfo,
								WPRID: fetchedInfo.TempFileID,
								ActualFilePath: fetchedInfo.FilePath,
								WPDesc: fetchedInfo.TempName,
								WPEF: fetchedInfo.WPREF,
								LastModifiedBy: {
									userName: null,
									roleName: null,
								},
								Extension: fetchedInfo.Extension,
								FileSize: fetchedInfo.FileSize,
								MediaType: fetchedInfo.MediaType,
								Final: "Completed",
								IsPrepared: false,
								IsReviewed: false,
								// MediaID: null,  // NOTE: there are no Media ID in the PT response
								//
								//
								//
								PreparerList: fetchedInfo.PreparerList
									? [fetchedInfo.PreparerList]
									: null, // NOTE: because this API returns single object
								ReviewerList: fetchedInfo.ReviewerList
									? [fetchedInfo.ReviewerList]
									: null, // NOTE: because this API returns single object
								RolesList: null,
								EngagementPartner: null,
								ConcurringPartner: null,
								QualityControl: null,
							},
							index: 999,
							menuId: self.storeName,
							expanded: false,
							folderId: fileInfo.parentId,
						})
					} else {
						newFile = reorganizeFile({
							fetchedFile: {
								...fetchedInfo,
								PreparerList: fetchedInfo.PreparerList
									? [fetchedInfo.PreparerList]
									: null, // NOTE: because this API returns single object
								ReviewerList: fetchedInfo.ReviewerList
									? [fetchedInfo.ReviewerList]
									: null, // NOTE: because this API returns single object
								EngagementPartner:
									userRole === "id_ep" &&
									fetchedInfo.ReviewerList
										? [fetchedInfo.ReviewerList]
										: null,
								RolesList: null,
								ConcurringPartner: null,
								QualityControl: null,
							},
							index: 999,
							menuId: self.storeName,
							expanded: false,
							folderId: fileInfo.parentId,
						})
					}

					self.pushFlatItem(newFile)

					// close the dialog, open the parent folder
					self.setFileTreeDialogOpen(
						FileScreenDialog.createFile,
						false
					)
					self.toggleExpanded(fileInfo.parentId, true)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
	addMultipleFiles(files: any, folderId: string) {
		const actionName = "addMultipleFiles"
		// 1.
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			customMessage: "Upload is in progress...",
			open: true,
		})
		ConsoleLog([actionName, "files on action", files])
		let projId = getProjIdFromUrl()
		let userId = localStorage.getItem("orgUserId")
		files.map((file: LocalFileProps) => {
			self.addSingleFile(projId, userId, {
				title: file.name,
				aliasId: file.aliasId,
				localFileFormat: DLObjectFormat.normalFile,
				extension: file.extension,
				parentId: folderId,
				prepared: file.prepared,
				reviewed: file.reviewed,
			})
		})
		// // 3. api call // NOTE: use Form Data)

		// files.map((file: LocalFileProps) => {
		// 	// >>> per items <<<
		// 	let signOffs: number[] = []
		// 	file.prepared && signOffs.push(1)
		// 	file.reviewed && signOffs.push(2)
		// 	//
		// 	let formData = new FormData()
		// 	formData.append("ParentMediaID", folderId)
		// 	formData.append("WPEF", file.aliasId)
		// 	formData.append("WPDesc", file.name)
		// 	formData.append("MediaType", convertExtensionToFormat(file.extension))
		// 	formData.append("signOffType", JSON.stringify(signOffs))
		// 	formData.append("ClientID", "")
		// 	formData.append("UserID", "")
		// 	formData.append("file", file.file)
		// 	formData.append("CreationMode", "1") // TODO: usage?)?
		// 	ConsoleLog("formData", formData)
		// 	self.createSingleFile(formData)
		// 		.then((response: any) => {
		// 			ConsoleLog(actionName, "sub_response", response)
		// 		})
		// 		.catch((error: Error) => ConsoleLog(error))
		// })
	},
})

export default AddFile
