import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import {
	DLDialog,
	InputWithLabel,
	DLInputField,
	DLSystemMsg,
	DLButton,
} from "../../../../components/basic-elements"
import sharedRegEx from "../../../../library/sharedRegEx"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { MessageColorType } from "../../../../common-models/enumerations/common-enums"

export default observer(function ForceArchiveDialog({
	i18n,
	isOpen,
	setIsOpen,
	partialStore,
}: {
	i18n: DLI18nProps
	isOpen: boolean
	setIsOpen: any
	partialStore: any
}) {
	const userEmail = localStorage.getItem("lobbyUserId")
	const initialValues = {
		value: "",
		isValid: true,
	}
	const [email, setEmail] = useState(initialValues)

	const handleEmailInput = (event: any) => {
		if (!sharedRegEx.email.test(event.target.value)) {
			setEmail({ value: event.target.value, isValid: false })
		} else {
			setEmail({ value: event.target.value, isValid: true })
		}
	}

	const isReady = email.value !== "" && email.isValid

	const forceArchiveProject = () => {
		partialStore.forceArchive({
			projectId: partialStore.selectedProj,
			reason: "",
		})
	}

	const projectInfo = partialStore.viewSelectedArcRemindProjInfo(
		partialStore.selectedProj
	)

	return (
		<DLDialog
			eleTestId="force-archive-project-dialog"
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			showCloseBtn={true}
			dialogTitle="Force Archive!"
			dialogContents={
				<StyledDialog>
					<div className="alert">
						Are you sure you want to Force Archive the project?
					</div>
					<ProjectInfoContainer
						projectDetails={projectInfo}
						i18n={i18n}
					/>
					<div className="input-section FR">
						<InputWithLabel
							required
							label={i18n.twEmail || "Email"}
						>
							<DLInputField
								autoFocus
								eleTestId="email_input"
								eleFullWidth
								eleName="email"
								eleValue={email.value}
								eleHandleChange={handleEmailInput}
								eleRequired
								warningMsg={
									!email.isValid
										? `${i18n.tsEmailInvalid}`
										: undefined
								}
							/>
							{email.value !== "" &&
								userEmail !== email.value && (
									<DLSystemMsg
										eleTestId="msg-warning-email-match"
										type={MessageColorType.red}
										msg={
											i18n.twEmailDoesNotMatch ||
											"Email does not match"
										}
									/>
								)}
						</InputWithLabel>
					</div>
				</StyledDialog>
			}
			// actionReady={isReady || userEmail === inputs.email.value}
			maxWidth="xs"
			actionBtn={
				<DLButton
					variant="text"
					eleTestId="force-archive-btn-on-dialog"
					// startIcon={<Icon path={mdiTrashCan} size={0.8} />}
					clickHandler={forceArchiveProject}
					color="warning"
					disabled={!(isReady && userEmail === email.value)}
				>
					Force Archive
				</DLButton>
			}
			cancelBtnText={i18n.twCancel || "Cancel"}
		/>
	)
})

export const ProjectInfoContainer = ({
	projectDetails,
	i18n,
}: {
	projectDetails: any
	i18n: DLI18nProps
}) => {
	return (
		<StyledProjInfo>
			<h4 style={{ marginBottom: 15 }}>Target Project Info</h4>
			<InfoRow
				label={i18n.twPeriodType || "Period Type"}
				value={projectDetails.periodName}
				testId="periodType"
			/>

			<InfoRow
				label={"Year"}
				value={projectDetails.financialYear}
				testId="financialYear"
			/>

			<InfoRow
				label={i18n.twClient || "Client"}
				value={projectDetails.clientName}
				testId="client"
			/>

			<InfoRow
				label={i18n.twProjectTitle || "Project Name"}
				value={projectDetails.title}
				testId="proj-title"
			/>
			<InfoRow
				label={i18n.twProjectId || "Project ID"}
				value={projectDetails.aliasId}
				testId="proj-alias-id"
			/>
			<InfoRow
				label={"E.P."}
				value={projectDetails.epUsers
					.map((i: any) => i.name)
					.join(", ")}
				testId="ep"
			/>

			<InfoRow
				label={"Q.C."}
				value={
					projectDetails.qcUsers.length
						? projectDetails.qcUsers
								.map((i: any) => i.name)
								.join(", ")
						: "-"
				}
				testId="qc"
			/>
		</StyledProjInfo>
	)
}

const InfoRow = ({
	label,
	value,
	testId,
}: {
	label: string
	value: any
	testId: string
}) => {
	return (
		<div className="info-row">
			<div className="label-area">{label} :</div>
			<div className="value-area" data-testid={`projDetails-${testId}`}>
				{value}
			</div>
		</div>
	)
}

const StyledProjInfo = styled.div`
	padding-bottom: 1rem;
	.info-row {
		display: flex;
		padding: 0.3rem 0 0.3rem 1rem;
		:hover {
			background-color: ${(props) => props.theme.secondary};
		}
		.label-area {
			min-width: 8rem;
		}
		.value-area {
		}
	}
	.partition {
		height: 1.8rem;
	}
`

const StyledDialog = styled.div`
	.alert {
		padding: 1rem 0;
	}
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 3rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 15rem;
		}
	}
`
