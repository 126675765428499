import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
import {
	CommonViewModelActions,
	ViewResponseHelper,
	Refresh,
} from "../../../../../common-models/common-view-model-actions"
import { initialStore } from "./checklists.provider"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"
import { ChecklistModel } from "../../online-projects/store/data-models/checklist-data-models"
import { ChecklistDetailModel } from "../../online-projects/store/reports-management.view-model"
import GetChecklists from "../../online-projects/store/view-model-actions/get-checklists"

const ChecklistsViewModel = types
	.model({
		checklists: types.array(ChecklistModel),
		checklistDetail: types.union(ChecklistDetailModel, types.null), // for the checklist dialog
		//
		clickPoint: ClickPoint,
		// selectedItems: types.array(CommonSelectedItem),
		selectedProjId: "",
		// ----- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setChecklists(checklists: any) {
			self.checklists.length = 0
			self.checklists = checklists
		},
		pushItemToChecklist(checklist: any) {
			self.checklists.push(checklist)
		},
		setChecklistDetail(detail: any) {
			self.checklistDetail = detail
		},
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setSelectedProjId(projId: string) {
			self.selectedProjId = projId
		},
	}))
	.actions(GetChecklists)
	.views((self) => ({
		getSelectedChecklistId() {
			const projId = self.selectedProjId
			const wsId = "ws" + projId.slice(6)
			const checklist = self.checklists.find(
				(c) => c.workspaceId === wsId
			)
			return checklist ? checklist.id : null
		},
	}))
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default ChecklistsViewModel
