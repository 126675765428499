import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import ArchiveDiagnosis from "./ArchiveDiagnosis"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { ProjI18n } from "../../../../common-models/enumerations/translation-sheets"

export default observer(function ArchiveDiagnosisController() {
	const store = useRootStore()
	const projStore = useProjStore()
	const menuId = DLProjSubMenus.arch_diagnosis
	const actionName = "getArchiveDiagnosis"
	const i18nSheet = ProjI18n.archDiagnosis
	//
	const isLocked = projStore.projInfo.projInfo.isLocked
	const isArchived = projStore.projInfo.projInfo.isArchived

	const menuAccess = projStore.checkin.checkAccess(menuId)
	const permission = projStore.checkin.getActionsAsObject(
		menuId,
		isLocked,
		isArchived
	)

	const needRefresh = projStore.archive.needRefresh
	//
	useEffect(() => {
		const projId = projStore.checkin.projId
		if (menuAccess && needRefresh) {
			projStore.archive.getArchiveDiagnosis()
			projStore.projInfo.getProjInfo(projId)
			projStore.archivePreProcess.getPreConditionStatus()
		}
	}, [menuAccess, needRefresh])

	const i18n = store.i18n.combineI18n(i18nSheet)

	return (
		<>
			{menuAccess ? (
				<>
					<ArchiveDiagnosis
						partialStore={projStore.archive}
						fetchingStatus={projStore.archive.getActionStatus(
							actionName
						)}
						permission={permission}
						i18n={i18n}
					/>
					<CommonSnackbarComponent
						i18n={i18n}
						partialStore={projStore.archive}
					/>
				</>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
