import { flow } from "mobx-state-tree"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const DeletePreConditionFiles = (self: any, removePreConditionFiles: any) => ({
	deletePreConditionFiles: flow(function* (projId: string, userId: string) {
		const actionName = "deletePreConditionFiles"

		const projNumberId = idToNumber(projId, IdType.project)
		const userNumberId = idToNumber(userId, IdType.user)

		if (
			typeof projNumberId === "number" &&
			typeof userNumberId === "number"
		) {
			try {
				// const payload = {
				// 	ArchivedBy: userNumberId,
				// 	ClientID: projNumberId,
				// 	ReplicaClientID: 0, // ? --- Default 0 passing
				// }
				const response = yield removePreConditionFiles()

				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName, '__error', error)
				self.handleModelError({ actionName, error, open: true })

				return false
			}
		} else {
			alert(
				`(${actionName}) invalid prop exist. ${projNumberId} | ${userNumberId}`
			)
		}
	}),
})

export default DeletePreConditionFiles
