import React, { useEffect, useState } from "react"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import {
	DLButton,
	DLDialog,
	DLTextArea,
} from "../../../../../components/basic-elements"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import styled from "styled-components"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"

export default observer(function RationaleEditDialog() {
	const store = useRootStore()
	const projStore = useProjStore()
	// const actionName = "getRationaleFiles"

	const [contents, setContents] = useState<any>()

	const dnt = store.global.getDntFormat

	const parentId = projStore.rationales.selectedParentId

	useEffect(() => {
		setContents(projStore.rationales.viewRationale(parentId, dnt))
	}, [])

	const changeHandler = (value: string) => {
		setContents((content: any) => ({
			...content,
			rationaleDescription: value,
		}))
	}

	const saveRationaleInfo = () => {
		const fileMenu =
			contents.type === "WP" ? DLProjSubMenus.wp : DLProjSubMenus.pf
		projStore.rationales.editRationale(
			fileMenu,
			contents.rationaleDescription,
			contents.rationaleId,
			contents.podId
		)
	}

	return (
		<DLDialog
			isOpen={projStore.rationales.rationaleEditDialogOpen}
			setIsOpen={projStore.rationales.setRationaleEditDialogOpen}
			showCloseBtn
			eleTestId="edit-rationale-dialog"
			dialogTitle={<div className="FR AC">Edit Rationale</div>}
			dialogContents={
				<>
					<RationaleParentFile
						fileInfo={projStore.rationales.viewRationale(
							parentId,
							dnt
						)}
						finalReportDate={
							// TODO: variable name need update
							projStore.rationales.finalReportDate
								? projStore.rationales.finalReportDate
								: "Please set the final Report date"
						}
						// diff={projStore.rationales.diff}
					/>
					<div className="rationale-editor">
						<DLTextArea
							eleTestId="rationale-description"
							elePlaceholder="Start Typing..."
							eleValue={contents?.rationaleDescription}
							eleHandleChange={(e: any) =>
								changeHandler(e.target.value)
							}
							eleName="rationale-description"
							eleClassName="rationale-description-text-input-area"
						/>
					</div>
				</>
			}
			actionBtn={
				<DLButton
					eleTestId="save-btn"
					disabled={
						projStore.rationales.viewRationale(parentId, dnt)
							?.rationaleDescription ===
						contents?.rationaleDescription
					}
					clickHandler={saveRationaleInfo}
					color="primary"
				>
					Save
				</DLButton>
			}
			// handleAction={handleOnSubmit}
			maxWidth="lg"
			openBtn={false}
			cancelBtnText="Cancel"
		/>
	)
})

const RationaleParentFile = ({
	fileInfo,
	finalReportDate, // TODO: variable name need update
}: // diff,
{
	fileInfo: any
	finalReportDate: string
	// diff: number | string
}) => {
	return (
		<StyledRationaleParentFile className="about-parent">
			<h3>Workpaper Information</h3>
			<div className="FR AC" style={{ marginTop: 10 }}>
				<div className="info-row FR AC">
					<span className="bold">Ref. num: </span>
					<div data-testid="file-aliasId">{fileInfo?.aliasId}</div>
				</div>
				<div className="info-row FR AC">
					<span className="bold">Modified at: </span>
					<div data-testid="file-modifiedAt">
						{fileInfo?.modifiedAt}
					</div>{" "}
				</div>
			</div>
			<div className="FR AC">
				<div className="info-row FR AC">
					<span className="bold">File name: </span>
					<div data-testid="file-title">{fileInfo?.description}</div>
				</div>
				<div className="info-row FR AC">
					<span className="bold">Modified by: </span>
					<div data-testid="file-modifiedBy">
						{fileInfo?.modifiedBy}
					</div>
				</div>
			</div>
		</StyledRationaleParentFile>
	)
}

const StyledRationaleParentFile = styled.div`
	border-top: 1px solid ${(props) => props.theme.shade20};
	border-bottom: 1px solid ${(props) => props.theme.shade20};
	padding: 1rem 0;
	margin: 1rem 0;
	.info-row {
		width: 50%;
		margin-bottom: 0.5rem;
		.bold {
			font-weight: 600;
			margin-right: 0.5rem;
		}
	}
`
