import React from "react"
import { Link } from "react-router-dom"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import { DLIconButton, DLButton } from "../../../../components/basic-elements"
// WARNING:
import Drawer from "@material-ui/core/Drawer"
import Icon from "@mdi/react"
import { mdiChevronRight, mdiArrowRightThick } from "@mdi/js"
import styled from "styled-components"

export default observer(function DiagnosisDrawer() {
	const store = useRootStore()
	const projStore = useProjStore()
	const defaultUrl = projStore.checkin.defaultUrl
	//
	// const open = projStore.archive.openDrawer
	const setOpen = projStore.archive.setOpenDrawer

	const drawerPadding = store.ui.isPageToolbarOpen ? "9rem" : "6rem"
	const openStatus = projStore.archive.drawerOpenStatus

	return (
		<StyledDiagnosisDrawer paddingTop={drawerPadding}>
			<Drawer
				className="drawer-root"
				variant="persistent"
				anchor="right"
				open={openStatus}
			>
				<div className="drawer-container">
					<div className="drawer-header FR JSB AC">
						<div className="FR JL AC">
							<DLIconButton
								variant="iconOnly"
								size="large"
								align="left"
								clickHandler={() => setOpen(false)}
								eleTestId="library drawer close button"
							>
								<Icon path={mdiChevronRight} size={1} />
							</DLIconButton>
							Archive Diagnosis
						</div>
						<Link
							to={`${defaultUrl}/archive/archive-diagnosis`}
							className="FR JR AC"
						>
							<DLButton
								variant="text"
								size="regular"
								align="right"
								eleTestId="library drawer close button"
								endIcon={
									<Icon path={mdiArrowRightThick} size={1} />
								}
							>
								View Detail
							</DLButton>
						</Link>
					</div>
					<div className="drawer-body">
						<div className="lib-contents"></div>
					</div>
				</div>
			</Drawer>
		</StyledDiagnosisDrawer>
	)
})

interface StyledDiagnosisDrawerProps {
	paddingTop: any
}

const StyledDiagnosisDrawer = styled.div<StyledDiagnosisDrawerProps>`
	transition: 0.2s;

	.drawer-root > div {
		box-shadow: -2px 10px 10px 1px ${(props) => props.theme.shadowColorDeep};
		background-color: ${(props) => props.theme.primaryLightDeep};
		border-left: none;
		margin-top: ${(props) => props.paddingTop};
		border-top-left-radius: 4px;
		.drawer-container {
			width: 21rem;
			height: 100%;
			.drawer-header {
			}
			.drawer-body {
				padding: 0 2rem;
				.lib-select {
				}
				.lib-contents {
				}
			}
		}
	}
`
