import React from "react"
// components
import {
	DLIconButton,
	DLButton,
	DLContextMenuWithButton,
} from "../../../../../components/basic-elements"

// icons
import Icon from "@mdi/react"
import {
	mdiCheck,
	mdiCheckAll,
	mdiMinusCircleOutline,
	mdiMinusCircle,
	mdiMenuDown,
	mdiMagnify,
} from "@mdi/js"
import styled from "styled-components"
import { OldSignOffType } from "../../../../../service-modules/file-module/data-models/sign-off.data-model"
import { useHistory } from "react-router-dom"
import { observer } from "mobx-react-lite"
import FilePageSettingDialog from "../dialogs/FilesPageSettingDialog"
import { FileMenuToolbarProps } from "./file-menu-toolbar-props"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import {
	DefaultSignOffPolicy,
	PfSignOffPolicy,
} from "../../../../../temporary-data/project-side/signoff-policy"
import {
	FileMenuViewType,
	FileScreenDialog,
} from "../../../../../service-modules/file-module/data-models/dl-file-control-model"
import {
	ActionStatus,
	DLCommentType,
	DLSrcFormat,
	DialogActionType,
} from "../../../../../common-models/enumerations/common-enums"
import { checkFileProps } from "../../../../../service-modules/file-module/check-file-props-to-open"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { ProjectStatus } from "../../../../../common-models/enumerations/project-related-enums"

export default observer(function FileMenuToolbar({
	permission,
	ctxCreateMenus,
	//
	structureLock,
	showCheckbox,
	viewType,
	//
	commentDrawerStatus,
	commentBadgeContent,
	diagnosisDrawerStatus,
	libDrawerStatus,
	//
	countSelectedFiles,
	//
	handleAllowDrag,
	handleShowCheckbox,
	//
	handleExpandAll,
	handleCollapseAll,
	needExpandAll,
	needCollapseAll,
	handleSignOffFocusedView,
	//
	handleViewType,
	//
	handleCommentDrawer,
	handleDiagnosisDrawer,
	handleOpenedFilesDialog,
	handleLibraryDrawer,
	handleSearchView,
	//
	allMenuOptions,
	//
	availableSignOffs,
	handleSignOffByToolbar,
	handleUnSignOffByToolbar,
	//
	partialStore,
	i18n,
	//
	isAsc,
	handleSortRootFolders,
	projMenuTabStore,
}: FileMenuToolbarProps) {
	// ConsoleLog("availableSignOffs in comp", availableSignOffs)
	const store = useRootStore()
	const projStore = useProjStore()
	const defaultUrl = projStore.checkin.defaultUrl
	const setFileTreeDialogOpen = partialStore.setFileTreeDialogOpen
	const projId = projStore.checkin.projId
	const projectStatus = projStore.projInfo.projInfo.statusName
	let history = useHistory()

	const commentPermission = projStore.checkin.getActionsAsObject(
		DLProjSubMenus.comments
	)
	const projRole = projStore.checkin.user.roleShortName

	const menuId = partialStore.storeName
	const signOffPolicy =
		menuId === DLProjSubMenus.wp
			? DefaultSignOffPolicy
			: menuId === DLProjSubMenus.pf
			? PfSignOffPolicy
			: []
	const userRole = projStore.checkin.user.roleId

	const ableToPrepare = signOffPolicy[0].availableRoles.includes(userRole)
	const ableToReview = signOffPolicy[1].availableRoles.includes(userRole)

	const selectedItems = partialStore.selectedFiles
	// const objInfo = partialStore.getItemInfo(selectedItems[0])

	const fileInfo = partialStore.getItemInfo(selectedItems[0])
	const itemsCount = selectedItems.length
	console.log(fileInfo, partialStore.selectedItems, "selectedItems")

	const isSignOffLocked = selectedItems.some((itemId: any) =>
		partialStore.flatList.find(
			(obj: any) => obj.id === itemId && obj.signOffLock === true
		)
	)

	const handleSignOff = (type: OldSignOffType) => {
		if (isSignOffLocked) {
			alert(
				`The file is in "Sign Off Locked" status. This action cannot be performed. Please change the Sign Off Lock Status to perform ${type} on the file.`
			)
			return
		}
		handleSignOffByToolbar(type)
	}

	const handleUnSignOff = (type: OldSignOffType) => {
		if (isSignOffLocked) {
			alert(
				`The file is in "Sign Off Locked" status. This action cannot be performed. Please change the Sign Off Lock Status to perform ${type} on the file.`
			)
			return
		}
		handleUnSignOffByToolbar(type)
	}

	const handleFileDownload = () => {
		const fileId = selectedItems[0]
		partialStore.downloadFile(fileId)
	}

	const handleFileDelete = () => {
		partialStore.removeFile(selectedItems[0])
	}

	const handleOpenCommentAddDialog = (type: DLCommentType) => {
		if (itemsCount > 1) {
			alert("Cannot add comment on multiple files, please select again")
			return
		} else if (itemsCount <= 0) {
			alert("Please select a file first")
			return
		}
		partialStore.setCommentDetailDialogMode(DialogActionType.add)
		partialStore.setCommentType(type)
		partialStore.setFileTreeDialogOpen(FileScreenDialog.commentDetail, true)
	}

	const userId = projStore.checkin.userId
	const isReadonly = projStore.checkin.user.isReadonly
	const userType = projStore.checkin.user.userType
	const finalAnalysisLink = `${defaultUrl}/files/workpaper-files/${fileInfo?.id}`
	const handleFileOpen = () => {
		const fileSize = fileInfo.size.split("K")[0]
		if (parseInt(fileSize) > 15000) {
			alert(
				"Online editor cannot open the file size over 15 MB. Please use file download and replace. To have download permission, a user must be assigned to this project."
			)
			return
		}
		if (itemsCount === 1) {
			if (fileInfo.srcFormat === DLSrcFormat.finalAnalysis) {
				const menuId = `ProjMenu_FinalAnalysis${fileInfo.title}`
				const newMenu = {
					id: menuId,
					title: `Final Analysis - ${fileInfo.title}`,
					url: finalAnalysisLink,
				}
				// NOTE: @Noah: how to control final anaylysis with DLProjSubMenus enum
				projStore.menuTabs.setCurrentMainMenu(menuId)
				projStore.menuTabs.addOpenedMenu(newMenu)
			} else {
				const userInfo = {
					id: userId,
					isReadonly,
					isExternal: userType === "external",
				}
				const orgId = localStorage.getItem("orgId") ?? "X"
				const fileProps = checkFileProps({
					orgId,
					projId,
					fileInfo,
					userInfo,
					asReadonly: false, // before update the 'open as readonly', default is 'false'
				})
				if (fileProps && fileProps.useEditor) {
					store.editor.openEditor(fileProps.props)
				} else if (fileProps && fileProps.useEditor === false) {
					partialStore.openFileWithoutEditor(fileProps?.props)
				} else {
					console.log("What should I do on here..?")
				}
			}
		}
	}

	const handleOpenArchiveDiagnosis = () => {
		history.push(`${defaultUrl}/archive/archive-diagnosis`)
	}

	const handleOpenSignOffHistory = () => {
		history.push(`${defaultUrl}/archive/sign-off-history`)
	}

	const handleOpenComments = () => {
		history.push(`${defaultUrl}/note-and-comments/comments`)
	}

	const isLocked = projStore.projInfo.projInfo.isLocked

	const handleAssignRole = () => {
		const actionName = "assignMultipleRoleToFile"
		// NOTE: wp???? WARNING: WARNING:
		partialStore.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.standby,
		})
		setFileTreeDialogOpen(FileScreenDialog.assign, true)
	}

	return (
		<StyledFileMenuToolbar
			className="toolbar-container FC JSB"
			style={{ minWidth: 1250 }}
		>
			<div className={`FR AC JSB file-tree-basic-btns-container`}>
				<DLContextMenuWithButton
					eleTestId="folders-btn"
					menuOptions={[
						{
							label: "[1] Expand Root Folders",
							value: "ctx-expand-all",
							testId: "ctx-expand-all",
							disabled:
								!needExpandAll ||
								partialStore.viewType !== FileMenuViewType.tree,
							clickEvent: handleExpandAll,
						},
						{
							label: "[2] Collapse All Folders",
							value: "ctx-collapse-all",
							testId: "ctx-collapse-all",
							disabled:
								!needCollapseAll ||
								partialStore.viewType !== FileMenuViewType.tree,
							clickEvent: handleCollapseAll,
						},
						{
							label: "[3] Create Root Folder",
							value: "ctx-add-root-folder",
							testId: "ctx-add-root-folder",
							available: !isLocked,
							clickEvent: () => {
								partialStore.resetSelectedItems()
								partialStore.setFileTreeDialogOpen(
									FileScreenDialog.createFolder,
									true
								)
							},
						},
					]}
					useScroll={false}
					showButton={true}
					showIcon={false}
					btnText="Folders"
					btnVariant="contained"
					btnColor="primary"
					btnSize="regular"
					btnClassName="top-row-btns"
					eleIcon={<Icon path={mdiMenuDown} size={1} />}
				/>
				{/* <div
					className="prepare-review-buttons-partition"
					style={{ width: 16 }}
				></div> */}
				{/* <DLContextMenuWithButton
					eleTestId="create-btn"
					menuOptions={ctxCreateMenus}
					useScroll={false}
					showButton={true}
					showIcon={false}
					btnText="Create"
					btnVariant="contained"
					btnColor="primary"
					btnSize="regular"
					eleIcon={<Icon path={mdiMenuDown} size={1} />}
				/> */}
				<div
					className="prepare-review-buttons-partition"
					style={{ width: 16 }}
				></div>
				<DLContextMenuWithButton
					eleTestId="actions-btn"
					menuOptions={[
						{
							label: "Checked-Out Files",
							value: "ctx-opened-files",
							testId: "ctx-opened-files",
							available: !isLocked,
							clickEvent: handleOpenedFilesDialog,
						},
						{
							label: structureLock
								? "Unlock Structure"
								: "Lock Structure",
							value: "ctx-structure-lock-btn",
							testId: "ctx-structure-lock-btn",
							available: permission.structureLock,
							clickEvent: handleAllowDrag,
						},
						{
							label: "Export",
							value: "ctx-export-btn",
							testId: "ctx-export-btn",
							disabled: !permission.export,
							clickEvent: () => {
								// if (
								// 	partialStore.storeName === DLProjSubMenus.wp
								// ) {
								partialStore.setDownloadType("WP")
								partialStore.setFileTreeDialogOpen(
									FileScreenDialog.exportFolderDialog
								)
								// } else {
								// 	partialStore.downloadAllAsZip({ projId })
								// }
							},
						},
					]}
					useScroll={false}
					showButton={true}
					showIcon={false}
					btnText="Actions"
					btnVariant="contained"
					btnColor="primary"
					btnSize="regular"
					btnClassName="top-row-btns"
					eleIcon={<Icon path={mdiMenuDown} size={1} />}
				/>
				<div
					className="prepare-review-buttons-partition"
					style={{ width: 16 }}
				></div>
				<DLContextMenuWithButton
					eleTestId="go-to-btn"
					menuOptions={[
						{
							label: "Archive Diagnosis",
							value: "ctx-archive-diagnosis",
							testId: "ctx-archive-diagnosis",
							clickEvent: handleOpenArchiveDiagnosis,
						},
						{
							label: "Sign Off History",
							value: "ctx-open-signoff-history-screen",
							testId: "open-signoff-history-screen",
							clickEvent: handleOpenSignOffHistory,
						},
						{
							label: commentDrawerStatus
								? i18n.twHideComments || "Hide Comments Drawer"
								: i18n.twShowComments || "Show Comments Drawer",
							value: "ctx-show-comments",
							testId: "ctx-show-comments",
							clickEvent: handleOpenComments,
						},
					]}
					useScroll={false}
					showButton={true}
					showIcon={false}
					btnText="Go To"
					btnVariant="contained"
					btnColor="primary"
					btnSize="regular"
					btnClassName="top-row-btns"
					eleIcon={<Icon path={mdiMenuDown} size={1} />}
				/>
				<div
					className="prepare-review-buttons-partition"
					style={{ width: 16 }}
				></div>
				<DLContextMenuWithButton
					eleTestId="view-options-btn"
					menuOptions={[
						{
							label: `${
								showCheckbox ? "Hide Checkbox" : "Show Checkbox"
							}`,
							value: "ctx-show-checkbox",
							testId: "ctx-show-checkbox",
							clickEvent: handleShowCheckbox,
						},
						{
							label:
								partialStore.viewType ===
								FileMenuViewType.fileTable
									? "Table View Off"
									: "Table View On",
							value: "ctx-table-view-onoff",
							testId: "ctx-table-view-onoff",
							clickEvent: () => {
								partialStore.resetSelectedItems()
								handleViewType(
									partialStore.viewType ===
										FileMenuViewType.fileTable
										? FileMenuViewType.tree
										: FileMenuViewType.fileTable
								)
							},
						},
						{
							label:
								partialStore.viewType ===
								FileMenuViewType.qcView
									? "Normal View"
									: "QC View",
							value: "ctx-qc-view-on",
							testId: "ctx-qc-view-off",
							available: menuId === DLProjSubMenus.wp,
							clickEvent: () => {
								partialStore.resetSelectedItems()
								handleViewType(
									partialStore.viewType ===
										FileMenuViewType.qcView
										? FileMenuViewType.tree
										: FileMenuViewType.qcView
								)
							},
						},
					]}
					useScroll={false}
					showButton={true}
					showIcon={false}
					btnText="View Options"
					btnVariant="contained"
					btnColor="primary"
					btnSize="regular"
					btnClassName="top-row-btns"
					eleIcon={<Icon path={mdiMenuDown} size={1} />}
				/>
				<div className="divider" />
				{partialStore.storeName === DLProjSubMenus.wp &&
				!isLocked &&
				partialStore.viewType === FileMenuViewType.tree &&
				!showCheckbox ? (
					<>
						<DLButton
							variant="outlined"
							eleTestId="library-onoff"
							color="primary"
							size="regular"
							clickHandler={handleLibraryDrawer}
							// endIcon={eleIcon}
							disabled={!partialStore.selectedItems.length}
						>
							Template Library
						</DLButton>
						<div className="divider" />
					</>
				) : (
					<div
						className="prepare-review-buttons-partition"
						style={{ width: "10rem" }}
					></div>
				)}
				<div className="button-group FR AC">
					<DLIconButton
						variant={
							partialStore.viewType === FileMenuViewType.search
								? "contained"
								: "iconOnly"
						}
						eleTestId="search-view-onoff"
						clickHandler={handleSearchView}
						tooltipText={i18n.twSearch || "Search"}
						color={
							partialStore.viewType === FileMenuViewType.search
								? "primary"
								: "default"
						}
					>
						<Icon path={mdiMagnify} size={0.8} />
					</DLIconButton>
					<FilePageSettingDialog partialStore={partialStore} />
				</div>
			</div>

			{/* ========== Main Sign Off buttons ========== */}

			<div className={`sign-off-main-btns-container FR`}>
				{!isLocked && (
					<>
						<DLButton
							color="primary"
							variant="contained"
							disabled={
								!availableSignOffs.prepare || !ableToPrepare
								// || isSignOffLocked
							}
							eleTestId="prepare-btn-in-toolbar"
							startIcon={<Icon path={mdiCheck} size={0.8} />}
							clickHandler={() =>
								handleSignOff(OldSignOffType.prepare)
							}
							eleClassName="signoff-btns"
						>
							{i18n.twPrepare || "Prepare"}
						</DLButton>
						<div
							className="prepare-review-buttons-partition"
							style={{ width: 16 }}
						></div>
						<DLButton
							color="primary"
							variant="contained"
							disabled={
								!availableSignOffs.unPrepare || !ableToPrepare
								// || isSignOffLocked
							}
							eleTestId="unprepare-btn-in-toolbar"
							startIcon={
								<Icon path={mdiMinusCircleOutline} size={0.8} />
							}
							clickHandler={() =>
								handleUnSignOff(OldSignOffType.prepare)
							}
							eleClassName="signoff-btns"
						>
							{i18n.twUnPrepare || "Un Prepare"}
						</DLButton>
						<div
							className="prepare-review-buttons-partition"
							style={{ width: 16 }}
						></div>

						<DLButton
							color="primary"
							variant="contained"
							disabled={
								!availableSignOffs.review || !ableToReview
								// || isSignOffLocked
							}
							eleTestId="review-btn-in-toolbar"
							startIcon={<Icon path={mdiCheckAll} size={0.8} />}
							clickHandler={() =>
								handleSignOff(OldSignOffType.review)
							}
							eleClassName="signoff-btns"
						>
							{i18n.twReview || "Review"}
						</DLButton>
						<div
							className="prepare-review-buttons-partition"
							style={{ width: 16 }}
						></div>
						<DLButton
							color="primary"
							variant="contained"
							disabled={
								!availableSignOffs.unReview || !ableToReview
								//|| isSignOffLocked
							}
							eleTestId="unreview-btn-in-toolbar"
							startIcon={
								<Icon path={mdiMinusCircle} size={0.8} />
							}
							clickHandler={() =>
								handleUnSignOff(OldSignOffType.review)
							}
							eleClassName="signoff-btns"
						>
							{i18n.twUnReview || "Un Review"}
						</DLButton>
						<div
							className="prepare-review-buttons-partition"
							style={{ width: 16 }}
						></div>
					</>
				)}
				<DLContextMenuWithButton
					eleTestId="file-operation-btn"
					menuOptions={[
						{
							label: "File Download (d)",
							value: "ctx-file-download",
							testId: "ctx-file-download",
							available: projectStatus !== ProjectStatus.archived,
							disabled: itemsCount > 1 || itemsCount === 0,
							clickEvent: handleFileDownload,
						},
						{
							label: "Open with Online Editor (w)",
							value: "ctx-file-open",
							available: permission.read,
							disabled: itemsCount > 1 || itemsCount === 0,
							// isLink:
							// 	fileInfo.srcFormat ===
							// 	DLSrcFormat.finalAnalysis,
							// linkTo: finalAnalysisLink,
							clickEvent: handleFileOpen,
						},
						{
							label: "Replace (s)",
							value: "ctx-replace",
							testId: "ctx-replace",
							available: permission.update,
							disabled:
								itemsCount > 1 ||
								itemsCount === 0 ||
								fileInfo?.isOpen,
							clickEvent: () =>
								setFileTreeDialogOpen(
									FileScreenDialog.replaceFile,
									true
								),
						},
						{
							label: "Edit File Info (e)",
							value: "ctx-edit-file",
							testId: "ctx-edit-file",
							available: !isLocked,
							disabled:
								itemsCount > 1 ||
								itemsCount === 0 ||
								fileInfo.isOpen,
							clickEvent: () =>
								setFileTreeDialogOpen(
									FileScreenDialog.fileEdit,
									true
								),
						},
						{
							label: `Sign Off ${
								fileInfo?.signOffLock ? "Unlock" : "Lock"
							} (l)`,
							value: "ctx-table-view-onoff",
							testId: "ctx-table-view-onoff",
							disabled: itemsCount > 1 || itemsCount === 0,
							available: !isLocked,
							clickEvent: () => {
								setFileTreeDialogOpen(
									FileScreenDialog.signOffLockDialog,
									true
								)
							},
						},
						{
							label: "Delete (x)",
							value: "ctx-delete-file",
							testId: "ctx-delete-file",
							available: !isLocked,
							disabled:
								itemsCount > 1 ||
								itemsCount === 0 ||
								fileInfo?.isOpen,
							clickEvent: handleFileDelete,
						},
					]}
					useScroll={false}
					showButton={true}
					disableBtn={!fileInfo}
					showIcon={false}
					btnText="File Operation"
					btnVariant="outlined"
					btnColor="primary"
					btnClassName="ctx-menu-styles"
					btnSize="regular"
					eleIcon={<Icon path={mdiMenuDown} size={1} />}
				/>
				<div
					className="prepare-review-buttons-partition"
					style={{ width: 16 }}
				></div>
				{!isLocked && (
					<>
						<DLContextMenuWithButton
							eleTestId="role-assign-btn"
							menuOptions={[
								{
									label: "Role Assign / Unassign (a)",
									value: "ctx-assign-role",
									testId: "ctx-assign-role",
									disabled:
										itemsCount > 1 || itemsCount === 0,
									clickEvent: handleAssignRole,
								},
							]}
							useScroll={false}
							showButton={true}
							disableBtn={!fileInfo}
							showIcon={false}
							btnText="Assign Role"
							btnVariant="outlined"
							btnColor="primary"
							btnClassName="ctx-menu-styles"
							btnSize="regular"
							eleIcon={<Icon path={mdiMenuDown} size={1} />}
						/>
						<div
							className="prepare-review-buttons-partition"
							style={{ width: 16 }}
						></div>
						<DLContextMenuWithButton
							eleTestId="add-comment-btn"
							menuOptions={[
								{
									label: "Add Review Comment",
									value: "ctx-add-comment",
									available:
										commentPermission.create && !isLocked,
									disabled:
										itemsCount > 1 || itemsCount === 0,
									clickEvent: () =>
										handleOpenCommentAddDialog(
											DLCommentType.normal
										),
								},
								{
									label: "Add QC Comment",
									value: "ctx-add-qc-comment",
									available:
										commentPermission.create && !isLocked,
									disabled:
										itemsCount > 1 ||
										itemsCount === 0 ||
										projRole !== "QC",
									clickEvent: () =>
										handleOpenCommentAddDialog(
											DLCommentType.qc
										),
								},
							]}
							useScroll={false}
							showButton={true}
							disableBtn={!fileInfo}
							showIcon={false}
							btnText="Add Comment"
							btnVariant="outlined"
							btnColor="primary"
							btnClassName="ctx-menu-styles"
							btnSize="regular"
							eleIcon={<Icon path={mdiMenuDown} size={1} />}
						/>
						<div
							className="prepare-review-buttons-partition"
							style={{ width: 16 }}
						></div>
					</>
				)}
				<DLContextMenuWithButton
					eleTestId="file-info-btn"
					menuOptions={[
						{
							label: "Sign Off History",
							value: "ctx-file-information",
							available: permission.read,
							disabled: itemsCount > 1 || itemsCount === 0,
							clickEvent: () =>
								setFileTreeDialogOpen(
									FileScreenDialog.fileInfo,
									true
								),
						},
						{
							label: "Sign Off Lock History",
							value: "ctx-file-signoff-lock-history",
							available: permission.read,
							disabled: itemsCount > 1 || itemsCount === 0,
							clickEvent: () => {
								setFileTreeDialogOpen(
									FileScreenDialog.signOffLockUnlockHistoryDialog,
									true
								)
							},
						},
						{
							label: "File History",
							value: "ctx-file-history",
							available:
								permission.read && menuId === DLProjSubMenus.wp,
							disabled: itemsCount > 1 || itemsCount === 0,
							clickEvent: () => {
								setFileTreeDialogOpen(
									FileScreenDialog.FileHistoryDialog,
									true
								)
							},
						},
					]}
					useScroll={false}
					showButton={true}
					disableBtn={!fileInfo}
					showIcon={false}
					btnText="File Info"
					btnVariant="outlined"
					btnColor="primary"
					btnClassName="ctx-menu-styles"
					btnSize="regular"
					eleIcon={<Icon path={mdiMenuDown} size={1} />}
				/>
			</div>
		</StyledFileMenuToolbar>
	)
})

const StyledFileMenuToolbar = styled.div`
	&.toolbar-container {
		transition: 0.4s;
		.sign-off-main-btns-container {
			background-color: #f2f2f4;
			margin-top: 5px;
			// opacity: 0;
			// width: 0;
			overflow: hidden;
			// transition: 0.4s;
			// &.sign-off-mode {
			// 	opacity: 1;
			// 	width: 100%;
			// }
		}
		.file-tree-basic-btns-container {
			width: 100%;
			// &.sign-off-mode {
			// 	opacity: 0;
			// 	width: 0;
			// }
			.create-menu-btn {
				.button-group {
					.comment-drawer-btn {
						span.comment-counting-badge {
							span {
								border: 1px solid black;
							}
						}
					}
				}
			}
			.divider {
				width: 1px;
				height: 1.8rem;
				background-color: gray;
				/* margin: 0 0.8rem; */
			}
		}
	}

	.ctx-menu-styles {
		border-radius: 20px;
		&:not(.disabled) {
			color: black !important;
			border-color: black;
			// font-weight: bold;
			svg {
				fill: black;
				color: black;
			}
			:hover {
				border-color: black;
				svg {
					fill: black;
					color: black;
				}
			}
		}
	}

	.signoff-btns:not(.disabled) {
		color: black !important;
		svg {
			fill: black;
			color: black;
		}
	}

	.top-row-btns {
		min-width: 150px;
		background-color: #d2cde4;
		color: black;
		font-weight: bold;
		svg {
			fill: black;
			color: black;
		}
		:hover {
			background-color: #c5bee0;
			color: black;
			svg {
				fill: black;
				color: black;
			}
		}
	}
`
