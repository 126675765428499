import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import LobbyRoute from "./routers/LobbyRoute"
import { useRootStore } from "./stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import {
	LoginScreenController2,
	LobbyCheckout,
	ForgotPassword,
	ConfirmSignup,
	OrgListController,
	ResetPasswordController,
} from "./screens/lobby-side"
import OrgRouter from "./routers/OrgRouter"
import ProjRouter from "./routers/ProjRouter"
import { ThemeProvider } from "styled-components"
import { defaultTheme, blueTheme1, darkTheme } from "./assets/styles/themes"
import "./App.scss"
import LobbyCheckin from "./screens/lobby-side/lobby-access-control/lobby-checkin/LobbyCheckin"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import FileEditor from "./screens/lobby-side/file-editor/FileEditor"
import { LobbyStoreProvider } from "./stores/lobby-store/lobby-store.provider"
import RightDrawer from "./components/app-frame-elements/global-drawer/RightDrawer"
import Amplify from "aws-amplify"
import config from "./aws-exports"
import { HTML5Backend } from "react-dnd-html5-backend"
import { DndProvider } from "react-dnd"
Amplify.configure(config)

const App = observer(() => {
	const store = useRootStore()

	function catchWindowSize() {
		store.ui.setWindowInnerWidth(window.innerWidth)
		store.ui.setWindowInnerHeight(window.innerHeight)
	}

	window.onresize = catchWindowSize

	const theme = store.ui.theme
	const dntFormat = store.global.getDntFormat
	return (
		// @ts-ignore
		<DndProvider backend={HTML5Backend}>
			<Router>
				<ThemeProvider
					theme={
						theme === "blue1"
							? defaultTheme
							: // : theme === "blue2"
							// ? blueTheme2
							theme === "dark"
							? darkTheme
							: // : theme === "green1"
							  // ? greenTheme1
							  // : theme === "green2"
							  // ? greenTheme2
							  // : theme === "gray1"
							  // ? grayTheme1
							  // : theme === "gray2"
							  // ? grayTheme2
							  // : theme === "solarized"
							  // ? solarizedTheme
							  // : theme === "default"
							  // ? defaultTheme
							  blueTheme1
					}
				>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						{/* TODO: Need to place LobbyStoreProvider better.... */}
						<LobbyStoreProvider>
							<Switch>
								<LobbyRoute path="/" exact>
									<LoginScreenController2 />
								</LobbyRoute>

								<LobbyRoute path="/login" exact>
									<LoginScreenController2 />
								</LobbyRoute>

								<Route
									exact
									path="/lobby-checkin"
									component={LobbyCheckin}
								/>

								<Route
									exact
									path="/lobby-checkout"
									component={LobbyCheckout}
								/>

								<Route
									exact
									path="/forgot-password"
									component={ForgotPassword}
								/>
								<Route
									exact
									path="/confirm-signup"
									component={ConfirmSignup}
								/>

								<Route
									exact
									path="/reset-password"
									component={ResetPasswordController}
								/>

								<LobbyRoute path="/lobby/organization-list">
									<OrgListController />
								</LobbyRoute>

								{/* WARNING: Why this LobbyRoute affect on the ProjStore???? */}
								<LobbyRoute path="/organization">
									<OrgRouter />
								</LobbyRoute>

								<LobbyRoute path="/project">
									<ProjRouter />
								</LobbyRoute>

								<LobbyRoute path="/editor/:params">
									<FileEditor />
								</LobbyRoute>
							</Switch>
							<RightDrawer dntFormat={dntFormat} userList={[]} />
						</LobbyStoreProvider>
					</MuiPickersUtilsProvider>
				</ThemeProvider>
			</Router>
		</DndProvider>
	)
})

export default App
