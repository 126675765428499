import { types, applySnapshot } from "mobx-state-tree"
import Responses from "../../../../../common-models/responses"
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// separated actions
import GetLastSignoffHistory from "./view-model-actions/get-signoff-history"
import {
	SignoffHistoryFlatItem,
	SignoffHistoryDetail,
} from "./data-models/signoff-history.data-model"
import GetSignoffHistoryDetail from "./view-model-actions/get-signoff-history-detail"
import {
	ActionsForLastSignoffs,
	ViewsForLastSignoffs,
} from "./view-model-actions/actions-for-last-signoffs"
import {
	ActionsForSignoffDetails,
	ViewsForSignoffDetails,
} from "./view-model-actions/actions-for-signoff-details"
import ExportSignOffHistoryZip from "./view-model-actions/export-signoff-history-zip"
import { initialStore } from "./sign-off-history.provider"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

const SignOffHistoryViewModel = types
	.model({
		totalWp: types.number,
		totalPf: types.number,

		// last signoffs
		wpLastSignoffs: types.array(SignoffHistoryFlatItem),
		pfLastSignoffs: types.array(SignoffHistoryFlatItem),
		// detail
		wpHistoryDetail: types.map(SignoffHistoryDetail),
		pfHistoryDetail: types.map(SignoffHistoryDetail),
		// common parts
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setTotalWp(count: number) {
			self.totalWp = count
		},
		setTotalPf(count: number) {
			self.totalPf = count
		},
		getAllFileIds(menuId: DLProjSubMenus) {
			const list =
				menuId === DLProjSubMenus.wp
					? self.wpLastSignoffs
					: menuId === DLProjSubMenus.pf
					? self.pfLastSignoffs
					: [""]
			let fileIds: string[] = []

			list.map((item: any) => {
				fileIds.push(item.id)
			})
			return fileIds
		},
		getAllDetails(menuId: DLProjSubMenus) {
			const data =
				menuId === DLProjSubMenus.wp
					? self.wpHistoryDetail
					: menuId === DLProjSubMenus.pf
					? self.pfHistoryDetail
					: { xxx: {} }
			let asArray: any[] = []

			for (const x in data) {
				asArray.push(x)
			}

			return asArray
		},
	}))
	// for last signoffs
	.actions(ActionsForLastSignoffs)
	.views(ViewsForLastSignoffs)
	// for history details
	.actions(ActionsForSignoffDetails)
	.views(ViewsForSignoffDetails)
	// -----------------------------------------
	// ----- AGER (Add, Get, Edit, Remove) -----
	.actions(GetLastSignoffHistory)
	.actions(GetSignoffHistoryDetail)
	.actions(ExportSignOffHistoryZip)
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default SignOffHistoryViewModel
