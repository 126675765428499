import {
	ActionStatus,
	MessageColorType,
	ProgressStatus,
} from "../../../../common-models/enumerations/common-enums"
import { convertAttention } from "../../proj-files-shared-functions"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const EditFileStatus = (self: any) => ({
	editFileStatus(fileId: string, currentStatus: ProgressStatus) {
		// 0.
		const actionName = "editFileStatus"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.updateFileStatus(fileId, currentStatus)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					const newStatus = convertAttention(response.data.WPRStatus)
					self.reflectStatus(fileId, newStatus)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default EditFileStatus
