import { types, applySnapshot } from "mobx-state-tree"
import {
	ArchiveCondition,
	RationaleFileItem,
	ConditionDetailItem,
} from "./data-models/archive-diagnosis.data-models"

// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"

import {
	GetArchiveDiagnosis,
	ProjectLockUnlock,
	Archive,
	GetRationaleFiles,
	GetConditionDetail,
	ExportArchiveDiagnosisPdf,
} from "./view-model-actions"

import { initialStore } from "./archive-diagnosis.provider"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import ExportArchivedZipFile from "../../../../organization-side/archive-management/am-archived-projects/store/view-model-actions/export-archived-zip-file"
import { ConsoleLog } from "../../../../../components/basic-elements"

const ArchiveDiagnosisViewModel = types
	.model({
		archiveConditionList: types.array(ArchiveCondition), // MandatoryList, NonMandatoryList
		conditionDetailList: types.array(ConditionDetailItem),
		title: types.string, // [ArchivePolicyName] ex) "Default Archive Policy"
		rationaleFiles: types.array(RationaleFileItem),
		totalRationaleFiles: 0,
		filesWithoutRationaleCount: 0,
		projectSize: "",
		// opened files
		storeName: types.enumeration<DLProjSubMenus>(
			Object.values(DLProjSubMenus)
		),
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		resetList() {
			self.archiveConditionList.length = 0
		},
		pushItemToList(item: any) {
			self.archiveConditionList.push(item)
		},
		setTitle(title: string) {
			self.title = title
		},
		setTotalRationaleFiles(count: number) {
			self.totalRationaleFiles = count
		},
		setFilesWithoutRationaleCount(count: number) {
			self.filesWithoutRationaleCount = count
		},
		setProjectSize(size: string) {
			self.projectSize = size
		},
		resetRationaleList() {
			self.rationaleFiles.length = 0
		},
		pushItemToRationaleList(item: any) {
			self.rationaleFiles.push(item)
		},
		setStoreName(type: DLProjSubMenus) {
			self.storeName = type
		},
		pushDetailItem(item: any) {
			self.conditionDetailList.push(item)
		},
		initializeDetailItems() {
			self.conditionDetailList.length = 0
		},
	}))
	// ----------
	// AGER (Add, Get, Edit, Remove)
	.actions(GetArchiveDiagnosis)
	.actions(ProjectLockUnlock) // Edit parts
	.actions(Archive)
	// ----------
	.actions((self) => ({
		// TODO: Looks weird below code...
		setProjectLockStatus(request: any) {
			const targetItem = self.archiveConditionList.find(
				(item: any) => item.id === "archiveConditionId16"
			)
			if (targetItem) {
				targetItem.status = request
				targetItem.counts.data =
					request === "DONE" ? "Locked" : "UnLocked"
			} else {
				console.error(
					"Cannot find target item for setProjectLockStatus"
				)
			}
		},
	}))
	.views((self) => ({
		get getMandatoryList() {
			return self.archiveConditionList.filter(
				(item: any) => item.isMandatory
			)
		},
		get getNonMandatoryList() {
			return self.archiveConditionList.filter(
				(item: any) => !item.isMandatory
			)
		},
		getCountRatio(conditionId: string) {
			// type A: 0/0 or 100/100 (completed count / total count)
			// type B: 0
			// type C: 1/0 (completed count / total count)

			const targetCondition = self.archiveConditionList.find(
				(element: any) => element.id === conditionId
			)
			const intId = parseInt(conditionId)

			if (targetCondition) {
				if (targetCondition.countingMethod === "TYPE_A") {
					return `${targetCondition.counts.completedCount} / ${targetCondition.counts.totalCount}`
				} else if (targetCondition.countingMethod === "TYPE_B") {
					return targetCondition.counts.totalCount
				} else if (targetCondition.countingMethod === "TYPE_C") {
					return
				} else {
					ConsoleLog([
						intId,
						targetCondition.title,
						"is faied to find matched type",
					])
				}
			} else {
				console.error("target condition not exist")
			}
		},
		getCompletedCount(target: any) {
			let completedCount
			if (target === "mandatory") {
				completedCount = self.archiveConditionList.filter(
					(item: any) => item.isMandatory && item.status === "DONE"
				).length
			}
			if (target === "non-mandatory") {
				completedCount = self.archiveConditionList.filter(
					(item: any) => !item.isMandatory && item.status === "DONE"
				).length
			}
			return completedCount
		},
		getConditionInfo(conditionId: string) {
			return self.archiveConditionList.find(
				(element: any) => element.id === conditionId
			)
		},
		viewRationaleFiles() {
			return self.rationaleFiles
		},
		get getRationaleWpLength() {
			return self.rationaleFiles.filter((item: any) => item.type === "WP")
				.length
		},
		get getRationalePfLength() {
			return self.rationaleFiles.filter((item: any) => item.type === "PF")
				.length
		},
	}))
	.views((self) => ({
		get getRequiredWpLength() {
			return self.conditionDetailList.filter(
				(item: any) => item.type === "WP"
			).length
		},
		get getRequiredPfLength() {
			return self.conditionDetailList.filter(
				(item: any) => item.type === "PF"
			).length
		},
	}))
	.actions(GetRationaleFiles)
	.actions(ExportArchiveDiagnosisPdf)
	.actions(GetConditionDetail)
	//
	.actions(ExportArchivedZipFile) // TODO: Need to check more
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	// .actions((self) => ({
	// 	refresh() {
	// 		self.initializeStore()
	// 		self.getArchiveDiagnosis()
	// 	},
	// }))
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default ArchiveDiagnosisViewModel
