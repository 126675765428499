import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import TrialBalance from "./TrialBalance"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"

export default observer(function TrialBalanceController() {
	const store = useRootStore()
	const projStore = useProjStore()
	const menuId = DLProjSubMenus.tb
	const actionName = "getTrialBalanceUrl"

	//
	// const isLocked = projStore.projInfo.projInfo.isLocked
	// const isArchived = projStore.projInfo.projInfo.isArchived
	const menuAccess = projStore.checkin.checkAccess(menuId)
	// const permission = projStore.checkin.getActionsAsObject(
	// 	menuId,
	// 	isLocked,
	// 	isArchived
	// )
	const needRefresh = projStore.trialBalance.needRefresh

	useEffect(() => {
		const role = projStore.checkin.assignedRole.roleId
		const cabinetId = projStore.projInfo.projInfo.cabinetId
		const isArchived = projStore.projInfo.projInfo.isArchived
		const finYear = projStore.projInfo.projInfo.financialYear // ?Is this the right one
		let accessRight
		if (role === "existing_proj_arch_admin" || isArchived) {
			accessRight = 1
		} else {
			accessRight = 0
		}
		if (menuAccess && needRefresh) {
			projStore.trialBalance.getTrialBalanceUrl(
				cabinetId,
				accessRight,
				finYear
			)
		}
	}, [menuAccess, needRefresh])

	return (
		<>
			{menuAccess ? (
				<TrialBalance
					partialStore={projStore.trialBalance}
					contentsHeight={store.ui.contentsAreaHeight}
					fetchingStatus={projStore.trialBalance.getActionStatus(
						actionName
					)}
				/>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
