// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
// import FileSaver from "file-saver"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - loading, success, fail
 *
 */

const DownloadAllAsZip = (self: any) => ({
	downloadAllAsZip({
		projId,
		templateId,
		fileNameZip,
	}: {
		projId?: string
		templateId?: string
		fileNameZip?: string
	}) {
		// 0.
		const actionName = "downloadAllAsZip"
		// 1.
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 999,
			color: MessageColorType.green,
			open: true,
			// autoHide: true,
		})
		// 3.
		self.requestDownloadAllAsZip({ projId, templateId })
			.then((response: any) => {
				if (response.status === 200) {
					// console.log(actionName+ "__response " + response)
					//
					const fileName =
						self.storeName === DLProjSubMenus.wp
							? "All Workpaper Files"
							: self.storeName === DLProjSubMenus.pf
							? "All Permanent Files"
							: self.storeName === DLOrgSubMenus.proj_templates
							? "All Template Files"
							: "Unknown Menu"

					// Create a new Blob object using the response data
					const blob = new Blob([response.data], {
						type: "application/zip",
					})

					// Create a link element to trigger the download
					const link = document.createElement("a")
					link.href = window.URL.createObjectURL(blob)
					link.download = fileNameZip || fileName

					// Append the link to the body and trigger the download
					document.body.appendChild(link)
					link.click()

					link?.parentNode?.removeChild(link)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						// autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default DownloadAllAsZip
