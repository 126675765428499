import { flow } from "mobx-state-tree"
import { TOKEN_TYPE } from "../../../../library/api-requests/shared-for-api-requests"
import { postRequest } from "../../../../library/api-requests"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { OldSignOffType } from "../../data-models/sign-off.data-model"

const RequestUnSignOff = (self: any) => ({
	requestUnSignOff: flow(function* (
		fileId: number,
		type: OldSignOffType,
		menuId: DLProjSubMenus,
		userRole: string,
		userId: any
	) {
		const actionName = "requestUnSignOff"
		if (!fileId || !type || !menuId) {
			alert(
				`(${actionName}) properties are incorrect: ${fileId} ${type} ${menuId}`
			)
		}
		//
		/**
		 * 1 = perpare
		 * 2 = review
		 * 3 = qc review... I don't like this...
		 */
		let requestType = 1
		if (type === OldSignOffType.prepare) {
			requestType = 1
		}
		if (type === OldSignOffType.review) {
			requestType = 2
		}
		if (type === OldSignOffType.review && userRole === "id_qc") {
			requestType = 3
		}
		const params = {
			WPRID: fileId,
			UserID: userId,
			unSignOffType: requestType,
		}
		let endpoint = ""
		if (menuId === DLProjSubMenus.wp) {
			endpoint =
				"/UnReview" +
				"?WPRID=" +
				params.WPRID +
				"&UserID=" +
				params.UserID +
				"&unSignOffType=" +
				params.unSignOffType
		} else if (menuId === DLProjSubMenus.pf) {
			endpoint =
				"/PermanentFileUnReview" +
				"?WPRID=" +
				params.WPRID +
				"&UserID=" +
				params.UserID +
				"&unSignOffType=" +
				params.unSignOffType
		}
		try {
			// NOTE: WARNING: Need to check. This API is POST but use params

			const response = yield postRequest({
				url: endpoint,
				// params: params,
				tokenType: TOKEN_TYPE.projToken,
			})
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, open: true })
			return false
		}
	}),
})

export default RequestUnSignOff
