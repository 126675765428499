import React, { useState } from "react"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
// components
import { DLIconButton, DLSpinnerByIcon } from "../../basic-elements"
import { DLCheckbox } from "@datalobby/components"
// icons
import Icon from "@mdi/react"
import {
	mdiFolder,
	mdiFilePlusOutline,
	mdiSync,
	mdiFolderOpen,
	mdiDotsHorizontal,
	mdiPencil,
	mdiFolderPlus,
	mdiDrag,
	mdiCursorMove,
	mdiArrowExpand,
	mdiArrowCollapse,
} from "@mdi/js"

import { FileTreeFolderRowProps } from "./dl-file-tree-props"
import styled from "styled-components"
import { convertTestId } from "../../../library/converters/id-converter"
import { ActionStatus } from "../../../common-models/enumerations/common-enums"
import { FileScreenDialog } from "../../../service-modules/file-module/data-models/dl-file-control-model"

export default observer(function FileTreeFolderRow({
	data,
	permission,
	menuId,
	//
	indentWidth,
	canDrag,
	showCheckbox,
	//
	isSelected,
	isCurrentParent,
	isLastSelectedItem,
	setPrevTreeItem,
	setNextTreeItem,
	getLoadingStatus,
	setSelectedItem,

	//
	handleCheckbox,
	handleRightClick,
	handleOpenAddFolderDialog,
	handleOpenAddFileDialog,
	handleClick,
	handleDuplicate,
	handleDelete,
	handleExpandSubFolder,
	handleCollapseSubFolder,
	sortChildren,
	//
	partialStore, // for get children
	i18n,
	totalRowWidth,
	isLocked,
}: FileTreeFolderRowProps) {
	// TODO: Do not allow partial store
	const store = useRootStore()
	const folderId = data.node.id
	const path = data.path
	const hasChildren = data.node.children.length > 0
	//
	const folderInfo = data.node
	const folderTitle = folderInfo.title
	const hasParent = !!folderInfo.parentId

	const actionNameForUiDetail = "getChildrenOfFolder" + folderId
	const structureLock = partialStore.structureLock
	//
	// const [showButtons, setShowButtons] = useState(false)

	// const handleMouseEnter = () => {
	// 	setShowButtons(true)
	// }
	// const handleMouseLeave = () => {
	// 	setShowButtons(false)
	// }

	// sort by ref
	const [isAsc, setIsAsc] = useState(true)
	const handleSort = () => {
		// sortChildren({ folderId, isAsc })
		setIsAsc(!isAsc)
	}

	const handleRefresh = () => {
		partialStore.getChildrenOfFolder(folderId)
	}

	let forIndent: number[] = []

	for (let i = 0; i < path.length; i++) {
		forIndent.push(i)
	}

	let inSameFolderIndex = 0
	let inSameFolder = false

	path.map((nodeId: string, i: number) => {
		if (isCurrentParent(nodeId)) {
			inSameFolderIndex = i + 1
			inSameFolder = true
		}
	})

	const contentsWidth = store.ui.contentsAreaWidth
	const depth = path.length
	const rowWidth = contentsWidth - indentWidth * depth

	const fetchingStatus = getLoadingStatus(actionNameForUiDetail)
	const selected = isSelected(folderId)
	const isParentSelected = hasParent ? isSelected(folderInfo.parentId) : false
	const currentParent =
		partialStore.selectedFiles.length && isCurrentParent(folderId)
	//
	const isLastSelected = isLastSelectedItem(folderId)
	isLastSelected && setPrevTreeItem(data)
	isLastSelected && setNextTreeItem(data)

	const baseTestId = convertTestId(folderTitle)

	const hasFolders = data.node.children.filter((item: any) => item.isParent)
	const isEveryFolderExpanded = hasFolders.length
		? hasFolders.every((item: any) => item.expanded)
		: false

	return (
		<StyledFileTreeFolderRow
			className={`FR depth-${depth < 2 ? depth : "too-deep"} ${
				selected && "selected"
			} ${currentParent && "currentParent"}`}
			// onMouseEnter={handleMouseEnter}
			// onMouseLeave={handleMouseLeave}
			data-testid={"folder-" + baseTestId}
		>
			<div className="padding-placeholder" />
			{canDrag ? (
				<DLIconButton eleTestId="drag-icon">
					<Icon path={mdiDrag} size={0.8} className="drag-icon" />
				</DLIconButton>
			) : (
				<div style={{ width: 32 }}></div>
			)}
			{showCheckbox && (
				<DLCheckbox
					eleTestId={`checkbox-${baseTestId}`}
					color="primary"
					isChecked={selected}
					clickHandler={(e: any) => handleCheckbox(e, folderId, true)}
				/>
			)}
			{forIndent.map((depth, i) => {
				return (
					<div
						className={`indent-placeholder index-${i} ${
							i === inSameFolderIndex &&
							inSameFolder &&
							"in-same-folder"
						} ${isParentSelected && "parentSelected"} ${
							i === 1 && inSameFolder && "highlight-root"
						}`}
						style={{
							width: indentWidth,
							height: 32,
						}}
						key={i}
					/>
				)
			})}

			<div
				// style={{ width: rowWidth }}
				className={`file-folder-row ${selected && "selected"} FR AC`}
				onContextMenu={(e: any) => handleRightClick(e, folderId)}
			>
				<div data-testid={`file-folder-icon-${baseTestId}`}>
					{folderInfo.expanded ? (
						<div
							className="FR AC chevron-and-folder-icon opened"
							onClick={(e: any) =>
								handleClick(e, folderInfo.expanded, folderId)
							}
						>
							{fetchingStatus === "LOADING" ? (
								<DLSpinnerByIcon eleClassName="folder-icon" />
							) : (
								<Icon
									path={mdiFolderOpen}
									size={0.8}
									className="folder-icon expanded"
								/>
							)}
						</div>
					) : (
						<div
							className="FR AC chevron-and-folder-icon closed"
							onClick={(e: any) =>
								handleClick(e, folderInfo.expanded, folderId)
							}
						>
							<Icon
								path={mdiFolder}
								size={0.8}
								className="folder-icon"
							/>
						</div>
					)}
				</div>

				<div
					className="FR AC normal-mode"
					style={{ width: totalRowWidth }}
				>
					<div
						className="title-area"
						onClick={(e: any) =>
							handleClick(e, folderInfo.expanded, folderId)
						}
					>
						<div
							className="folder-title"
							data-testid="folder-title"
						>
							{folderTitle}
							{folderInfo.expanded &&
							!hasChildren &&
							fetchingStatus !== "LOADING" ? (
								<span className="no-children">(No items)</span>
							) : folderInfo.expanded &&
							  fetchingStatus === ActionStatus.loading ? (
								<span className="folder-loading-text">
									(Loading...)
								</span>
							) : (
								<span />
							)}
						</div>
					</div>
					{folderInfo.expanded && (
						<div
							className="icons-for-folder FR AC JSB"
							data-testid="folder-hover-icons"
						>
							{isEveryFolderExpanded ? (
								<DLIconButton
									eleTestId="add-expand-icon-btn"
									tooltipText="Collapse Folder"
									clickHandler={() =>
										handleCollapseSubFolder(folderId)
									}
									disabled={!hasFolders.length}
								>
									<Icon path={mdiArrowCollapse} size={0.8} />
								</DLIconButton>
							) : (
								<DLIconButton
									eleTestId="add-expand-icon-btn"
									tooltipText="Expand Folder"
									clickHandler={() =>
										handleExpandSubFolder(folderId)
									}
									disabled={!hasFolders.length}
								>
									<Icon path={mdiArrowExpand} size={0.8} />
								</DLIconButton>
							)}
							{permission.update && !isLocked && (
								<DLIconButton
									eleTestId="edit-folder-name-icon-btn"
									tooltipText={
										i18n.twRenameFolder || "Rename folder"
									}
									clickHandler={(e: any) => {
										setSelectedItem(folderId, true)
										partialStore.setFileTreeDialogOpen(
											FileScreenDialog.editFolder
										)
									}}
									eleClassName="open-input-folder-title"
								>
									<Icon path={mdiPencil} size={0.8} />
								</DLIconButton>
							)}
							{permission.create && !isLocked && (
								<DLIconButton
									eleTestId="add-folder-icon-btn"
									tooltipText={
										i18n.twAddFolder || "Add Folder"
									}
									clickHandler={(e: any) =>
										handleOpenAddFolderDialog(e, folderId)
									}
								>
									<Icon path={mdiFolderPlus} size={0.8} />
								</DLIconButton>
							)}
							{permission.create && !isLocked && (
								<DLIconButton
									eleTestId="add-file-icon-btn"
									tooltipText={i18n.twAddFile || "Add File"}
									clickHandler={(e: any) =>
										handleOpenAddFileDialog(e, folderId)
									}
								>
									<Icon
										path={mdiFilePlusOutline}
										size={0.8}
									/>
								</DLIconButton>
							)}
							{permission.update && !isLocked && (
								<DLIconButton
									eleTestId="move-folder-icon-btn"
									tooltipText={
										hasParent
											? i18n.twMoveFolder || "Move folder"
											: "Cannot move the root folder"
									}
									clickHandler={(e: any) => {
										setSelectedItem(folderId, true)
										partialStore.setFileTreeDialogOpen(
											FileScreenDialog.moveFileOrFolder,
											true
										)
									}}
									disabled={!hasParent || structureLock}
								>
									<Icon path={mdiCursorMove} size={0.8} />
								</DLIconButton>
							)}
							{/*{permission.create &&
								menuId === DLOrgSubMenus.proj_templates && (
									<DLIconButton
										eleTestId="folder-duplicate-icon-btn"
										tooltipText={
											i18n.twDuplicate || "Duplicate"
										}
										clickHandler={() =>
											handleDuplicate({
												folderId,
											})
										}
									>
										<Icon
											path={mdiContentCopy}
											size={0.8}
										/>
									</DLIconButton>
								)}*/}

							<DLIconButton
								eleTestId="folder-refresh-icon-btn"
								tooltipText={i18n.twRefresh || "Refresh"}
								clickHandler={handleRefresh}
							>
								<Icon path={mdiSync} size={0.8} />
							</DLIconButton>
							{/*{permission.update && !structureLock && (
								<SortingIconButton
									isAsc={isAsc}
									handleSort={handleSort}
									by="children by Ref. Num"
								/>
							)}
							 {permission.delete && (
								<DLIconButton
									eleTestId="folder-delete-icon-btn"
									tooltipText={i18n.twDelete || "Delete"}
									clickHandler={() =>
										handleDelete({ folderId })
									}
								>
									<Icon path={mdiTrashCan} size={0.8} />
								</DLIconButton>
							)} */}
							{(permission.create ||
								permission.update ||
								permission.delete ||
								permission.export) && (
								<DLIconButton
									eleTestId={`file-folder-ctx-menu-btn-${baseTestId}`}
									eleClassName="file-folder-ctx-menu-btn"
									clickHandler={(e: any) =>
										handleRightClick(e, folderId)
									}
								>
									<Icon path={mdiDotsHorizontal} size={0.8} />
								</DLIconButton>
							)}
						</div>
					)}
				</div>
			</div>
		</StyledFileTreeFolderRow>
	)
})

const StyledFileTreeFolderRow = styled.div`
	overflow: hidden;
	font-weight: 600;
	.padding-placeholder {
		width: 0.4rem;
	}
	&.currentParent {
		color: black;
		background-color: #ff9900 !important;
	}
	&.depth-1 {
		// .indent-placeholder {
		// 	border-right: none;
		// }
		background-color: #d2cde4;
	}
	// &.depth-2 {
	// 	background-color: ${(props) => props.theme.shade05};
	// }
	// &.depth-3 {
	// 	background-color: ${(props) => props.theme.shade10};
	// }
	// &.depth-4 {
	// 	background-color: ${(props) => props.theme.shade20};
	// }
	// &.depth-5 {
	// 	background-color: ${(props) => props.theme.shade30};
	// }
	&.depth-too-deep {
		background-color: #f0ecff;
	}
	&.selected {
		background-color: #ff9900;
		color: black;
		font-weight: 700;
	}
	.indent-placeholder {
		border-right: 1px solid #ff9900;
		&.index-0 {
			border-right: none;
		}
		&.in-same-folder {
			border-right: 2px solid #ff9900;
		}
	}
	.index-1 {
		border-right: 2px solid blue !important;
	}
	.parentSelected {
		border-right: 2px solid #ff9900;
	}
	.file-folder-row {
		.chevron-and-folder-icon {
			padding-left: 0.6rem;
		}
		.folder-icon {
			cursor: pointer;
			transition: 0.4s;
			:hover {
				color: ${(props) => props.theme.emphasis};
			}
		}
		&.selected {
			color: black;
			font-weight: 700;
		}
		&.edit-mode {
			justify-content: flex-start;
		}
		.normal-mode {
			.title-area {
				padding-left: 0.4rem;
				padding-right: 1rem;
				.folder-title {
					span.no-children {
						opacity: 0.6;
						margin-left: 0.4rem;
					}
					span.folder-loading-text {
						opacity: 0.6;
						margin-left: 0.4rem;
					}
				}
				.open-input-folder-title {
					transition: 0.4s;
					opacity: 0;
					margin-right: 2rem;
				}
				:hover {
					.open-input-folder-title {
						opacity: 1;
					}
				}
			}
			// .icons-for-folder {
			// 	transition: 0.4s;
			// 	opacity: 0;
			// }
			// :hover {
			// 	.icons-for-folder {
			// 		opacity: 0.8;
			// 	}
			// }
		}
	}
`
