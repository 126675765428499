import { types } from "mobx-state-tree"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"

const AdjustmentsUiHelper = types
	.model({
		clickPoint: ClickPoint,
		selectedEntity: 0,
		selectedAjeId: "",
		//
		addAdjustmentDialogOpen: false,
	})
	.actions((self) => ({
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setSelectedEntity(entity: number) {
			self.selectedEntity = entity
		},
		setSelectedAjeId(ajeId: string) {
			self.selectedAjeId = ajeId
		},
		//
		setAddAdjustmentDialogOpen(request?: boolean) {
			if (request !== undefined) {
				self.addAdjustmentDialogOpen = request
			} else {
				self.addAdjustmentDialogOpen = !self.addAdjustmentDialogOpen
			}
		},
	}))

export default AdjustmentsUiHelper
