import { flow } from "mobx-state-tree"
import { FileSystemApiTypes } from "../../apis/file-system-api-types"
import { DLMenuId } from "../../../../components/combined-elements/file-tree/dl-file-tree-props"

const ReadFileHistory = (
	self: any,
	apiReadFileHistory: FileSystemApiTypes["apiReadFileHistory"]
) => ({
	readFileHistory: flow(function* (
		activityId: number,
		projectId: number,
		target: string,
		targetId: number,
		menuId: DLMenuId
	) {
		const actionName = "readFileHistory"

		try {
			const params = {
				activityId,
				projectId,
				target,
				targetId,
				menuId,
			}
			const response = yield apiReadFileHistory(params)

			// console.log(actionName, "__ response", response)
			return response
		} catch (error) {
			// console.log(actionName, "__ error", error)
			self.handleModelError({ actionName, error, open: true })

			// return
		}
	}),
})

export default ReadFileHistory
