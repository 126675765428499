import { types } from "mobx-state-tree"
import { NotesModel } from "./notes.model"
import NotesViewModel from "./notes.view-model"
//apis
import * as api from "./notes.apis"

export const NotesStore = (apiCreate: any, apiRead: any) =>
	types.compose(NotesModel(apiCreate, apiRead), NotesViewModel).named("Store")

export const initialStore = {
	notes: "",
	noteId: 0,
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const NotesStoreInstance = NotesStore(api.create, api.read).create(initialStore)

export default NotesStoreInstance
