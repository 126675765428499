import { types, applySnapshot } from "mobx-state-tree"
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
import {
	Refresh,
	CommonViewModelActions,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import { initialStore } from "./checklist.provider"
import { ChecklistDetailModel } from "../../../../organization-side/reports-management(Deprecated)/online-projects/store/reports-management.view-model"
import GetChecklists from "../../../../organization-side/reports-management(Deprecated)/online-projects/store/view-model-actions/get-checklists"
import GetChecklistTemplates from "../../../../organization-side/org-setup/org-setup-eng-types/store/view-model-actions/get-checklist-templates"
import AddChecklist from "../../../../organization-side/reports-management(Deprecated)/online-projects/store/view-model-actions/addChecklist"

const ChecklistViewModel = types
	.model({
		checklistTemplates: types.array(
			types.model({
				id: types.string,
				name: types.string,
			})
		),
		checklistDetail: types.union(ChecklistDetailModel, types.null),
		openFileSelectionDialog: false,
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setChecklistTemplates(templates: any) {
			self.checklistTemplates = templates
		},
		setChecklistDetail(detail: any) {
			self.checklistDetail = detail
		},
		setOpenFileSelectionDialog(request?: boolean) {
			self.openFileSelectionDialog =
				request || !self.openFileSelectionDialog
		},
	}))
	.actions(GetChecklists)
	.actions(GetChecklistTemplates)
	.actions(AddChecklist)
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	// .actions((self) => ({
	// 	refresh() {
	// 		self.initializeStore()
	// 		self.getProjUsers()
	// 	},
	// }))
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default ChecklistViewModel
