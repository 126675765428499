import { flow } from "mobx-state-tree"
import { deleteRequest } from "../../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../../library/api-requests/shared-for-api-requests"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"
import { commentIdToNumber } from "../view-model-actions/utils-for-comment"

export type CommentDeleteProps = {
	commentId: string
	deleteBy: string
}

const DeleteComment = (self: any) => ({
	deleteComment: flow(function* ({
		commentId,
		deleteBy,
	}: CommentDeleteProps) {
		const actionName = "deleteComment"
		// 1.
		//
		//
		// 2.
		const commentNumberId = commentIdToNumber(commentId)
		const userNumberId = idToNumber(deleteBy, IdType.user)
		//
		//
		// 3.
		if (
			typeof commentNumberId === "number" &&
			typeof userNumberId === "number"
		) {
			// NOTE: Let's wokring on the comment module.
			try {
				const endpoint = commentId.includes("wp")
					? "/DeleteReviewComments"
					: commentId.includes("pf")
					? "/DeletePermanentFileReviewComments"
					: "/unknown"
				// ReviewCommentsId: 735,
				// DeletedBy: 16,
				// Type: "ReviewComments",
				// NOTE: QC comment type: ReviewComments
				const params = {
					ReviewCommentsId: commentNumberId,
					DeletedBy: userNumberId,
					Type: "ReviewComments", // NOTE: all comment has same type... maybe. Need to check with Backend
				}
				const response = yield deleteRequest({
					url: endpoint,
					params,
					tokenType: TOKEN_TYPE.projToken,
				})
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert(
				`(${actionName}) Invalid prop exist: ${commentNumberId} | ${userNumberId}`
			)
		}
	}),
})

export default DeleteComment
