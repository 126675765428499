import { flow } from "mobx-state-tree"
import { FileSystemApiTypes } from "../../apis/file-system-api-types"
import { DLMenuId } from "../../../../components/combined-elements/file-tree/dl-file-tree-props"

const ReadSignOffLockHistory = (
	self: any,
	apiReadSignOffLockHistory: FileSystemApiTypes["apiReadSignOffLockHistory"]
) => ({
	readSignOffLockHistory: flow(function* (
		activityType: string,
		projectId: number,
		target: string,
		targetId: number,
		menuId: DLMenuId
	) {
		const actionName = "readSignOffLockHistory"

		try {
			const params = {
				activityType,
				projectId,
				target,
				targetId,
				menuId,
			}
			const response = yield apiReadSignOffLockHistory(params)

			// console.log(actionName, "__ response", response)
			return response
		} catch (error) {
			// console.log(actionName, "__ error", error)
			self.handleModelError({ actionName, error, open: true })

			// return
		}
	}),
})

export default ReadSignOffLockHistory
