import { getRequest, postRequest } from "../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

export async function readFA(params: {
	clientId: number
	fsTypeId: number
	tbNameId: number
	wprId: number
}) {
	const response = await getRequest({
		url: "/GetFinalAnalytics",
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function saveFA(payload: {
	Conclusion: string
	Purpose: string
	Scope: string
	lstmodel: any
	fsTypeId: number
	tbNameId: number
	wprId: number
}) {
	const response = await postRequest({
		url: "/SaveFinalAnalytics",
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function getFileSize({
	menuId,
	folderId,
}: {
	menuId: string
	folderId?: number
}) {
	const response = await getRequest({
		url:
			menuId === DLProjSubMenus.wp
				? "/WorkspaceWorkPaperFileSize"
				: "/WorkspacePermanentFileSize",
		params: { ...(folderId && { folderId }) },
		tokenType: TOKEN_TYPE.projToken,
	})

	return response.data
}

export async function getProjectUsers({ projectId }: { projectId: number }) {
	const response = await getRequest({
		url: "/GetProjectUsers",
		params: { projectId },
		tokenType: TOKEN_TYPE.projToken,
	})

	return response.data
}
