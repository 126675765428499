import { types } from "mobx-state-tree"
import ReadTrialBalanceDetails from "../../financial-statements/store/model-actions/read-trial-balance-details"
import { EliminatingEntryApiProps } from "./eliminating-entry.apis"
import CreateEliminatingEntry from "./model-actions/create-eliminating-entry"
import DeleteEliminatingEntry from "./model-actions/delete-eliminating-entry"
import ReadAccountDetails from "./model-actions/read-account-details"
import ReadEliminatingEntryDetails from "./model-actions/read-eliminating-entry-details"
import UpdateEliminatingEntry from "./model-actions/update-eliminating-entry"

const EliminatingEntryModel = ({
	apiRead,
	apiCreate,
	apiUpdate,
	apiReadTrialBalance,
	apiReadAccDetails,
	apiDeleteEE,
}: EliminatingEntryApiProps) =>
	types
		.model({})
		.actions((self) => ({
			// -p----- Read
			// ------ Update
		}))
		.actions((self, read = apiRead) =>
			ReadEliminatingEntryDetails(self, read)
		)
		.actions((self, create = apiCreate) =>
			CreateEliminatingEntry(self, create)
		)
		.actions((self, update = apiUpdate) =>
			UpdateEliminatingEntry(self, update)
		)
		.actions((self, readTrialBalance = apiReadTrialBalance) =>
			ReadTrialBalanceDetails(self, readTrialBalance)
		)
		.actions((self, readAccDetails = apiReadAccDetails) =>
			ReadAccountDetails(self, readAccDetails)
		)
		.actions((self, deleteEE = apiDeleteEE) =>
			DeleteEliminatingEntry(self, deleteEE)
		)

export default EliminatingEntryModel
