import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../components/basic-elements"
import { OpenFileProps } from "../../check-file-props-to-open"

const OpenFileWithoutEditor = (self: any) => ({
	openFileWithoutEditor(props: OpenFileProps) {
		// ({ file, userInfo }: OpenFileProps) {
		// 0.
		const actionName = "openFileWithoutEditor"
		const { fileInfo, userInfo } = props
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		// const srcId = fileInfo.srcId

		// const projId = getProjIdFromUrl()
		// const orgUserId = userInfo.id // localStorage.getItem("orgUserId") // TODO: from localStorage? or store?
		// const projToken = localStorage.getItem("latestProjToken") //("dlProjToken")
		// //
		// let projNumberId: any = ""
		// let userNumberId: any = ""
		// let srcNumId = idToNumber(srcId, IdType.src)

		// console.log(actionName, projId, orgUserId, srcId)

		// ! Condition <1>
		if (fileInfo.url !== null && fileInfo.url.length > 0) {
			// No use editor
			if (
				fileInfo.extension === ".xml" ||
				fileInfo.extension === ".png" ||
				fileInfo.extension === ".jpg" ||
				fileInfo.extension === ".xbrl" ||
				fileInfo.extension === ".ixbrl" ||
				fileInfo.extension === ".xsd"
			) {
				// ! 1.1 __ file extension is .xml, .png, .jpg, .xbrl, ixbrl, .xsd --> open a new window by URL & media ID
				ConsoleLog(["case 1.1", fileInfo.url])
				window.open(fileInfo.url, fileInfo.srcId)
			} else {
				// ! 1.2 __ file extension is not in above, and [archived] or [external user] or [readonly] --> Do not open the file and stop the process
				if (
					// projInfo.isArchived || // TODO: is this required to restrict the downalod some files when the project is archived? (Need to confrim with Bo)
					userInfo.isExternal ||
					userInfo.isReadonly
				) {
					alert(`(${actionName}) Cannot open the file`)
					return
				} else {
					// ! 1.3 __ file extension is not in above, and not matched with 1.2 --> download the file by URL
					ConsoleLog("case 1.3")
					const downloadFile = document.createElement("a")
					downloadFile.href = fileInfo.url
					downloadFile.setAttribute("download", "")
					document.body.appendChild(downloadFile)
					downloadFile.click()
					downloadFile.remove()
				}
			}
			return
		} else {
			// ! --> #4~#8 --> add a new tab in the same window
			const customFormats = [
				// NOTE: server response use mixed value for APG and mixed small letter and capital
				"LeadSheet",
				"UploadFile",
				"AuditProgram", // "auditProgram", "AuditProgramTemplate",
				"FluxAnalysis",
				"FinalAnalysis",
			]
			if (customFormats.includes(fileInfo.srcFormat)) {
				alert("Pending these type of files")
				return
			}
			// ! <9> file is not matched with the above cases, and archived
			// ! <10> file is not matched with the above cases, and not archived
			// when the file data has no url (= using editor)
			// TODO: Need to check the device and the file type (pdf)
		}

		// if (sector === DLSector.org) {
		// 	// NOTE: Organization side

		// } else if (sector === DLSector.proj) {
		// 	// NOTE: Project side case
		// 	if (projId !== null && orgUserId !== null) {
		// 		projNumberId = idToNumber(projId, IdType.project)
		// 		userNumberId = idToNumber(orgUserId, IdType.user)
		// 	}

		// 	if (
		// 		typeof projNumberId === "number" &&
		// 		typeof userNumberId === "number" &&
		// 		typeof srcNumId === "number" &&
		// 		projToken !== null
		// 	) {
		// 		const token = JSON.parse(projToken)

		// 		// ! Condition <1>
		// 		if (fileInfo.url !== null && fileInfo.url.length > 0) {
		// 			// No use editor
		// 			if (
		// 				fileInfo.extension === ".xml" ||
		// 				fileInfo.extension === ".png" ||
		// 				fileInfo.extension === ".jpg" ||
		// 				fileInfo.extension === ".xbrl" ||
		// 				fileInfo.extension === ".ixbrl" ||
		// 				fileInfo.extension === ".xsd"
		// 			) {
		// 				// ! 1.1 __ file extension is .xml, .png, .jpg, .xbrl, ixbrl, .xsd --> open a new window by URL & media ID
		// 				ConsoleLog("case 1.1", fileInfo.url)
		// 				window.open(fileInfo.url, fileInfo.srcId)
		// 			} else {
		// 				// ! 1.2 __ file extension is not in above, and [archived] or [external user] or [readonly] --> Do not open the file and stop the process
		// 				if (
		// 					// projInfo.isArchived || // TODO: is this required to restrict the downalod some files when the project is archived? (Need to confrim with Bo)
		// 					userInfo.isExternal ||
		// 					userInfo.isReadonly
		// 				) {
		// 					alert(`(${actionName}) Cannot open the file`)
		// 					return
		// 				} else {
		// 					// ! 1.3 __ file extension is not in above, and not matched with 1.2 --> download the file by URL
		// 					ConsoleLog("case 1.3")
		// 					const downloadFile = document.createElement("a")
		// 					downloadFile.href = fileInfo.url
		// 					downloadFile.setAttribute("download", "")
		// 					document.body.appendChild(downloadFile)
		// 					downloadFile.click()
		// 					downloadFile.remove()
		// 				}
		// 			}
		// 			return
		// 		} else {
		// 			// ! --> #4~#8 --> add a new tab in the same window
		// 			const customFormats = [
		// 				"Leadsheet",
		// 				"UploadFile",
		// 				"Auditprogram",
		// 				"AuditProgram",
		// 				"AuditProgramTemplate",
		// 				"FluxAnalytics",
		// 				"FinalAnalytics",
		// 			]
		// 			if (customFormats.includes(fileInfo.srcFormat)) {
		// 				alert("Pending these type of files")
		// 				return
		// 			}
		// 			// ! <9> file is not matched with the above cases, and archived
		// 			// ! <10> file is not matched with the above cases, and not archived
		// 			// when the file data has no url (= using editor)
		// 			// TODO: Need to check the device and the file type (pdf)
		// 		}
		// 	}
		// 	// after the request to open the editor window, reset the selected item info
		// 	self.resetSelectedItems() // is this required even if we open a new window..?
		// }
	},
})

export default OpenFileWithoutEditor
