import React, { useEffect, useState } from "react"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { DLCheckbox } from "@datalobby/components"
import { observer } from "mobx-react-lite"
import styled from "styled-components"

export default observer(function SignOffHistoryToolbar() {
	const projStore = useProjStore()

	const [columns, setColumns] = useState<
		{ label: string; id: string; display: boolean }[]
	>([])

	useEffect(() => {
		setColumns(projStore.signOff.viewColumns)
	}, [])

	// const handleSelectAll = () => {
	// 	setColumns(initialSelectedColumns) // TODO: WHY THIS IS NOT WORKING????
	// 	projStore.signOff.setColumns(initialSelectedColumns)
	// }

	const handleSelectColumn = (e: any) => {
		setColumns(
			columns.map((item) =>
				item.id === e.target.value
					? { ...item, display: !item.display }
					: item
			)
		)

		projStore.signOff.toggleColumn(e.target.value)
	}

	return (
		<StyledSignOffHistoryToolbar className="signoff-page-toolbar FR AC">
			{/* <DLButton
					eleTestId="select-all-checkbox-btn"
					variant="text"
					size="thin"
					clickHandler={handleSelectAll}
				>
					Select All
				</DLButton> */}
			<div className="checkbox-container FR AC">
				{columns.map((item: any) => {
					return (
						<div className="FR AC checkbox">
							<DLCheckbox
								eleTestId={`column-select-${item.id}`}
								isChecked={item.display}
								color="default"
								size="small"
								value={item.id} // TODO: Need to update DL Checkbox component...
								clickHandler={handleSelectColumn}
							/>
							<span>{item.label}</span>
						</div>
					)
				})}
			</div>
			{/* <DLButton
					eleTestId="select-all-checkbox-btn"
					variant="text"
					size="thin"
				>
					Uncheck All
				</DLButton> */}
		</StyledSignOffHistoryToolbar>
	)
})

const StyledSignOffHistoryToolbar = styled.div`
	.checkbox-container {
		.checkbox {
			margin-right: 1rem;
		}
	}
`
