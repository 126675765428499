import React from "react"
import { dateRangeFilter } from "../../../../library/react-table-utils"
import { DLIconButton } from "../../../../components/basic-elements"
import {
	DLIcon,
	DLIconName,
} from "../../../../components/basic-elements/icons/common-icons"

const AuditProgramTableColumns = ({
	handleRemove,
	handleEdit,
	fromDialog,
	renderEditableFileName,
}: {
	handleRemove: (rowId: string) => void
	handleEdit: (rowId: string) => void
	fromDialog: boolean
	renderEditableFileName: any
}) => {
	const columns = [
		{
			header: "Legend",
			accessorKey: "legend",
			size: 120,
			cell: renderEditableFileName,
		},
		{
			header: "Audit Steps",
			accessorKey: "auditSteps",
			size: 120,
			cell: renderEditableFileName,
			// cell: (props: any) => {
			// 	return (
			// 		<div
			// 			data-testid="auditSteps-cell"
			// 			style={{ width: 150, wordWrap: "break-word" }}
			// 		>
			// 			{props.getValue().length > 50
			// 				? props.getValue().slice(0, 50) + "..."
			// 				: props.getValue()}
			// 		</div>
			// 	)
			// },
		},
		{
			header: "Linked WP Ref. num",
			accessorKey: "apgWPRef",
			size: 120,
			cell: renderEditableFileName,
		},
		{
			header: "Assertions",
			accessorKey: "assertions",
			size: 120,
			cell: renderEditableFileName,
		},
	]

	const additionalColums = [
		{
			header: "Notes",
			accessorKey: "note",
			size: 200,
			cell: renderEditableFileName,
			// cell: (props: any) => {
			// 	return (
			// 		<div
			// 			data-testid="auditSteps-cell"
			// 			style={{ width: 150, wordWrap: "break-word" }}
			// 		>
			// 			{props.getValue().length > 50
			// 				? props.getValue().slice(0, 50) + "..."
			// 				: props.getValue()}
			// 		</div>
			// 	)
			// },
		},
		{
			header: "Required Role - Prepare",
			accessorKey: "requiredRolePrepare",
			size: 60,
		},
		{
			header: "Assigned Preparer",
			accessorKey: "preparerUserId",
			size: 60,
			cell: renderEditableFileName,
		},
		{
			header: "Due Date for Prepare",
			accessorKey: "preparerDueDate",
			filterFn: dateRangeFilter,
			cell: renderEditableFileName,
		},
		{
			header: "Prepared By/At",
			accessorKey: "preparedByAt",
			size: 60,
			cell: (props: any) => {
				return <div data-testid="prepared-cell">{props.getValue()}</div>
			},
		},
		{
			header: "Required Role - Review",
			accessorKey: "requiredRoleReview",
			size: 60,
		},
		{
			header: "Assigned Reviewer",
			accessorKey: "reviewerUserId",
			size: 60,
			cell: renderEditableFileName,
		},
		{
			header: "Due Date for Review",
			accessorKey: "reviewerDueDate",
			filterFn: dateRangeFilter,
			cell: renderEditableFileName,
		},
		{
			header: "Reviewed By/At",
			accessorKey: "reviewedByAt",
			size: 60,
			cell: (props: any) => {
				return <div data-testid="reviewed-cell">{props.getValue()}</div>
			},
		},
	]

	const actionColumn = [
		{
			header: "Actions",
			size: 60,
			cell: (props: any) => {
				const rowId = props.row.original.auditProgramId
				return (
					<div data-testid="actions-cell" className="FR">
						<DLIconButton
							eleTestId="edit-btn"
							tooltipText="Edit"
							clickHandler={() => handleEdit(rowId)}
						>
							<DLIcon name={DLIconName.edit} />
						</DLIconButton>
						<DLIconButton
							eleTestId="delete-btn"
							tooltipText="Delete"
							clickHandler={() => handleRemove(rowId)}
						>
							<DLIcon name={DLIconName.delete} />
						</DLIconButton>
					</div>
				)
			},
		},
	]

	let allColumns

	if (fromDialog) {
		allColumns = [...actionColumn, ...columns]
	} else {
		allColumns = [...actionColumn, ...columns, ...additionalColums]
	}

	return allColumns
}

export default AuditProgramTableColumns
