import { types } from "mobx-state-tree"
import ReadProjBoardList from "../../../../../service-modules/post/store-model-actions/read-proj-board-list"
import DeleteOrRestorePost from "../../../../../service-modules/post/store-model-actions/delete-or-restore-post"
import UpdatePost from "../../../../../service-modules/post/store-model-actions/update-post"
import CreatePost from "../../../../../service-modules/post/store-model-actions/create-post"

const BoardModel = types
	.model({})
	.actions(ReadProjBoardList)
	.actions(CreatePost)
	.actions(DeleteOrRestorePost)
	.actions(UpdatePost)

export default BoardModel
