import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	ViewResponseHelper,
} from "../../../../../../common-models/common-view-model-actions"
import GetEmailNotificationSetting from "./view-model-actions/get-email-notification-settings"
import EditEmailNotificationSetting from "./view-model-actions/edit-email-notification-settings"
import {
	OrgSysSettingItemModel,
	AvailableOptionsModel,
	ModifiedDetails,
} from "../../../../../../service-modules/sys-setting-module/sys-setting.data-model"
import EditOtherSettings from "./view-model-actions/edit-other-settings"
import { getTzDate } from "../../../../../../library/converters/date-utc-converter"
import { initialStore } from "./email-notification.provider"

const EmailNotificationViewModel = types
	.model({
		atTheEndOfDay: OrgSysSettingItemModel,
		everyOtherDay: OrgSysSettingItemModel,
		atTheEndOfWeek: OrgSysSettingItemModel,
		every3Days: OrgSysSettingItemModel,
		daily: OrgSysSettingItemModel,
		modifiedDetails: ModifiedDetails,
		//
		hasPBCUpload: OrgSysSettingItemModel,
		//
		availableOptions: types.array(AvailableOptionsModel),
		// ---------- common models
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		resetAvailableOptions() {
			self.availableOptions.length = 0
		},
		pushItemToAvailableOptions(item: any) {
			self.availableOptions.push(item)
		},
		setAtTheEndOfDay(item: any) {
			self.atTheEndOfDay = item
		},
		setEveryOtherDay(item: any) {
			self.everyOtherDay = item
		},
		setAtTheEndOfWeek(item: any) {
			self.atTheEndOfWeek = item
		},
		setEvery3Days(item: any) {
			self.every3Days = item
		},
		setDaily(item: any) {
			self.daily = item
		},
		setPBCUpload(item: any) {
			self.hasPBCUpload = item
		},
		setModifiedDetails(details: any) {
			self.modifiedDetails = details
		},
	}))
	// ---- AGER (Add, Get, Edit, Remove)
	.actions(GetEmailNotificationSetting)
	.actions(EditEmailNotificationSetting)
	// --- Others related actions
	.actions(EditOtherSettings)
	.views((self) => ({
		viewModifiedDetails(dateFormat: string, timeZone: string) {
			const formattedModified = self.modifiedDetails.modifiedDate
				? getTzDate({
						date: self.modifiedDetails.modifiedDate,
						timeZone,
						dateFormat,
				  })
				: ""

			return {
				...self.modifiedDetails,
				modifiedDate: formattedModified,
			}
		},
	}))
	.actions((self) => ({
		initializeStore() {
			// ConsoleLog("initialize store - org/setupRa")
			applySnapshot(self, initialStore)
		},
	}))
	// common parts
	.actions(CommonViewModelActions)
	.actions((self) => ({
		initializeStore() {
			// ConsoleLog("initialize store - org/setupRa")
			applySnapshot(self, initialStore)
		},
	}))
	.views(ViewResponseHelper)

export default EmailNotificationViewModel
