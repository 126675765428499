import React, { useState, useEffect, useCallback } from "react"
import { useProjStore } from "../../../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import {
	DLDialog,
	DLSpinner,
	DLButton,
	ConsoleLog,
} from "../../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"
import { formatFileSize } from "@datalobby/common"
import sharedRegEx from "../../../../../../library/sharedRegEx"
import ArchiveProjectForm from "./ArchiveProjectDialogForm"
import useForm from "../../../../../../library/use-form"
import { v4 as uuidv4 } from "uuid"
import Icon from "@mdi/react"
import { mdiShieldLockOutline } from "@mdi/js"
import { ActionStatus } from "../../../../../../common-models/enumerations/common-enums"
import { ProjectStatus } from "../../../../../../common-models/enumerations/project-related-enums"
import { useHistory } from "react-router-dom"
import { getProjIdFromUrl } from "../../../../../../library/api-requests/request-get-others"
import { useRootStore } from "../../../../../../stores/root-store/root-store.provider"

export default observer(function ArchivProjectDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	const projStore = useProjStore()
	const actionName = "archiveProject"
	const projectStatus = projStore.projInfo.projInfo.statusName
	const userEmail = localStorage.getItem("lobbyUserId")

	const inputsSchema = {
		email: { value: "", error: "", requestInput: false },
		reason: { value: "", error: "", requestInput: false },
	}
	const validationSchema = {
		email: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.email, // TODO: update is required
				error: "Invalid format",
			},
		},
		reason: {
			isRequired: projectStatus === ProjectStatus.normal ? false : true,
		},
	}

	const [hasTotalSizeErr, setHasTotalSizeErr] = useState(false)
	const [filesData, setFilesData] = useState<any>([])

	useEffect(() => {
		let total = 0
		filesData.map((file: any) => {
			total = total + file.size
		})
		const fmtSize = formatFileSize(total).split(" ")
		setHasTotalSizeErr(
			fmtSize[1] === "MB"
				? Math.ceil(+fmtSize[0]) > 100
					? true
					: false
				: false
		)
		ConsoleLog(["updateTotalSize", hasTotalSizeErr])
	}, [filesData.length])

	const handleChangeFile = (event: any) => {
		const choosedFiles = Array.from(event.target.files)
		choosedFiles.map((item: any) => {
			const { size } = item
			const formattedSize = formatFileSize(item.size, 2)
			const fileExtension = item.name.slice(item.name.lastIndexOf("."))
			const fileName = item.name.slice(0, item.name.lastIndexOf("."))
			// const fmtSize = formattedSize.split(" ")
			const hasSizeErr =
				fileExtension === ".zip" ? size > 100000000 : size > 30000000
			setFilesData((filesData: any) => [
				...filesData,
				{
					tempId: uuidv4(),
					MediaType: fileExtension,
					WPEF: "",
					file: item,
					WPDesc: fileName,
					isChecked: false,
					size: size,
					formattedSize: formattedSize,
					isLoading: false,
					hasSizeErr: hasSizeErr,
				},
			])
		})
	}

	const removeFilefromList = useCallback((id: any) => {
		setFilesData((files: any) =>
			files.filter((file: any) => file.tempId !== id)
		)
	}, [])

	const archiveProject = () => {
		const projId = getProjIdFromUrl()
		const userId = localStorage.getItem("orgUserId")
		const reason = inputs.reason.value
		const projectSize =
			projStore.archivePreProcess.preConditionData.projectSize

		if (projId && userId) {
			if (projectStatus === ProjectStatus.normal) {
				projStore.archivePreProcess.archiveProject(
					projId,
					userId,
					reason,
					projectSize
				)
			} else {
				projStore.archivePreProcess.archiveProject(
					projId,
					userId,
					reason,
					projectSize,
					filesData
				)
			}
		}
	}

	const validations = () => {
		if (
			projectStatus === ProjectStatus.normal &&
			isReady &&
			userEmail === inputs.email.value
		) {
			return true
		} else {
			if (
				filesData.length > 0 &&
				!hasTotalSizeErr &&
				isReady &&
				userEmail === inputs.email.value &&
				filesData.every((i: any) => !i.hasSizeErr)
			) {
				return true
			} else {
				return false
			}
		}
	}

	const {
		inputs,
		handleOnChange,
		handleOnSubmit,
		isReady,
		// initializeInputs
	} = useForm(inputsSchema, validationSchema, archiveProject)

	const actionStatus = projStore.archivePreProcess.getActionStatus(actionName)
	return (
		<>
			<DLDialog
				eleTestId="archive-project-dialog"
				isOpen={projStore.archivePreProcess.archiveProjectDialogOpen}
				setIsOpen={
					projStore.archivePreProcess.setArchiveProjectDialogOpen
				}
				// handleAction={handleOnSubmit}
				showCloseBtn={actionStatus !== ActionStatus.success}
				dialogTitle={
					<div className="FR AC">
						<Icon
							path={mdiShieldLockOutline}
							size={1.2}
							style={{ marginRight: 8 }}
						/>{" "}
						{i18n.twProjectArchive}
					</div>
				}
				dialogContents={
					actionStatus === ActionStatus.loading ? (
						<ArchivingLoader i18n={i18n} />
					) : actionStatus === ActionStatus.success ? (
						<ArchiveDetails i18n={i18n} />
					) : (
						<ArchiveProjectForm
							inputs={inputs}
							handleOnChange={handleOnChange}
							filesData={filesData}
							handleChangeFile={handleChangeFile}
							removeFilefromList={removeFilefromList}
							hasUpload={
								projectStatus === ProjectStatus.normal
									? false
									: true
							}
							i18n={i18n}
						/>
					)
				}
				actionBtn={
					actionStatus !== ActionStatus.success && (
						<DLButton
							startIcon={
								<Icon path={mdiShieldLockOutline} size={0.8} />
							}
							eleTestId="archive-btn"
							disabled={
								!validations() ||
								actionStatus === ActionStatus.loading
							}
							clickHandler={handleOnSubmit}
							color="primary"
						>
							{i18n.twArchive}
						</DLButton>
					)
				}
				actionReady={
					validations() &&
					actionStatus !== ActionStatus.loading &&
					actionStatus !== ActionStatus.success
				}
				maxWidth="sm"
				// cancelBtnText={
				// 	actionStatus !== ActionStatus.success
				// 		? i18n.twCancel
				// 		: undefined
				// }
			/>
		</>
	)
})

const ArchivingLoader = ({ i18n }: { i18n: DLI18nProps }) => {
	return (
		<div>
			<h3>{i18n.tsArchiveLoading}</h3>
			<div
				className="FR AC JC"
				style={{
					width: "100%",
					height: "100%",
					marginTop: 70,
					marginBottom: 50,
				}}
			>
				<DLSpinner />
			</div>
		</div>
	)
}

const ArchiveDetails = ({ i18n }: { i18n: DLI18nProps }) => {
	const store = useRootStore()
	const projStore = useProjStore()
	let history = useHistory()

	const handleRedirect = () => {
		history.push({
			pathname: "/organization/my-page",
			state: { refresh: true },
		})
	}

	const dntFormat = store.global.getDntFormat

	return (
		<div
			className="archive-details"
			style={{
				alignItems: "center",
				textAlign: "center",
			}}
		>
			<p>
				<b>
					Successfully Archived. You will be shortly notified
					regarding the Replica and Archived Zip file status through
					email notification.
				</b>
			</p>
			<p>
				{projStore.archivePreProcess.getFormattedArchiveDate(dntFormat)}
			</p>
			<p>
				Engagement Partner:{" "}
				{projStore.archivePreProcess.archiveDetails.epName}
			</p>
			<p>
				Archived By:{" "}
				{projStore.archivePreProcess.archiveDetails.archivedBy}
			</p>
			<DLButton
				eleTestId="archive-btn-ok"
				clickHandler={handleRedirect}
				color="primary"
			>
				OK
			</DLButton>
		</div>
	)
}
