import { FileScreenDialog } from "../../../../../service-modules/file-module/data-models/dl-file-control-model"
import { DLI18nProps } from "../../../../../common-models/types/common-props"

const CtxMenusForCreate = (partialStore: any, i18n: DLI18nProps) => {
	return [
		{
			label: i18n.twAddRootFolder || "Create Root Folder",
			value: "ctx-add-root-folder",
			testId: "ctx-add-root-folder",
			clickEvent: () => {
				partialStore.resetSelectedItems()
				partialStore.setFileTreeDialogOpen(
					FileScreenDialog.createFolder,
					true
				)
			},
		},
		// {
		// 	label: "Create Workpaper",
		// 	value: "ctx-add-workpaper",
		// 	clickEvent: () => actions[menuType].setOpenCreateFileDialog(true),
		// },
	]
}

export default CtxMenusForCreate
