import { addDays } from "date-fns"
import { ProjectStatus } from "../../../../../../common-models/enumerations/project-related-enums"
import { DnTFormatProps } from "../../../../../../common-models/types/common-props"
import { ConsoleLog } from "../../../../../../components/basic-elements"
import { getTzDate2 } from "../../../../../../library/converters/date-utc-converter"

export const getProgressRateDetails2 = (projects: any) => {
	const archived = projects.filter(
		(item: any) => item.statusName === ProjectStatus.archived
	)
	const normal = projects.filter(
		(item: any) => item.statusName === ProjectStatus.normal
	)
	const unarchived = projects.filter(
		(item: any) => item.statusName === ProjectStatus.unarchived
	)
	const normal_section1 = normal.filter(
		(item: any) =>
			parseInt(item.reviewedStatus) >= 0 &&
			parseInt(item.reviewedStatus) < 26
	)
	const normal_section2 = normal.filter(
		(item: any) =>
			parseInt(item.reviewedStatus) >= 25 &&
			parseInt(item.reviewedStatus) < 51
	)
	const normal_section3 = normal.filter(
		(item: any) =>
			parseInt(item.reviewedStatus) >= 50 &&
			parseInt(item.reviewedStatus) < 76
	)
	const normal_section4 = normal.filter(
		(item: any) =>
			parseInt(item.reviewedStatus) >= 75 &&
			parseInt(item.reviewedStatus) < 100
	)

	const getCount = (data: any, isLate: undefined | boolean) => {
		const count = data.filter((item: any) => item.isLate === isLate).length
		return count
	}
	const normalDetail = [
		{
			label: "0 - 25%",
			total: normal_section1.length,
			late: getCount(normal_section1, true),
			timely: getCount(normal_section1, false),
			cannotCalculate: getCount(normal_section1, undefined),
			projects: normal_section1,
		},
		{
			label: "25 - 50%",
			total: normal_section2.length,
			late: getCount(normal_section2, true),
			timely: getCount(normal_section2, false),
			cannotCalculate: getCount(normal_section2, undefined),
			projects: normal_section2,
		},
		{
			label: "51 - 75%",
			total: normal_section3.length,
			late: getCount(normal_section3, true),
			timely: getCount(normal_section3, false),
			cannotCalculate: getCount(normal_section3, undefined),
			projects: normal_section3,
		},
		{
			label: "76 - 100%",
			total: normal_section4.length,
			late: getCount(normal_section4, true),
			timely: getCount(normal_section4, false),
			cannotCalculate: getCount(normal_section4, undefined),
			projects: normal_section4,
		},
	]

	const data = [
		{
			label: ProjectStatus.archived,
			total: archived.length,
			late: getCount(archived, true),
			timely: getCount(archived, false),
			cannotCalculate: getCount(archived, undefined),
			projects: archived,
		},
		{
			label: ProjectStatus.normal,
			total: normal.length,
			late: getCount(normal, true),
			timely: getCount(normal, false),
			cannotCalculate: getCount(normal, undefined),
			projects: normal,
			detail: normalDetail,
		},
		{
			label: ProjectStatus.unarchived,
			total: unarchived.length,
			late: getCount(unarchived, true),
			timely: getCount(unarchived, false),
			cannotCalculate: getCount(unarchived, undefined),
			projects: unarchived,
		},
	]

	return data
}

const DashboardCardViews = (self: any) => ({
	getFilteredData() {
		let filteredData = self.orgDashboardList
		if (self.yearFilter.length > 0) {
			filteredData = filteredData.filter((item: any) =>
				self.yearFilter.includes(item.financialYear)
			)
		}
		if (self.periodTypeFilter.length > 0) {
			filteredData = filteredData.filter((item: any) =>
				self.periodTypeFilter.includes(item.periodName)
			)
		}
		if (self.engTypeFilter.length > 0) {
			filteredData = filteredData.filter((item: any) =>
				self.engTypeFilter.includes(item.engTypeName)
			)
		}
		if (self.groupFilter.length > 0) {
			filteredData = filteredData.filter((item: any) =>
				self.groupFilter.includes(item.groupName)
			)
		}
		if (self.epUserFilter.length > 0) {
			let arr: any[] = []
			filteredData.map((item: any) => {
				item.epUsers.map((i: any) => {
					if (self.epUserFilter.includes(i.name)) {
						arr.push(item)
					}
				})
			})
			filteredData = arr
		}
		if (self.qcUserFilter.length > 0) {
			let arr: any[] = []
			filteredData.map((item: any) => {
				item.qcUsers.map((i: any) => {
					if (self.qcUserFilter.includes(i.name)) {
						arr.push(item)
					}
				})
			})
			filteredData = arr
		}
		return filteredData
	},
	getProgressRateCardData(
		filterdData: any,
		mainColor: string,
		subColor: string,
		emphasis: string
	) {
		const statusFilter = (status: ProjectStatus) => {
			return filterdData.filter((item: any) => item.statusName === status)
		}
		const archivedCount = statusFilter(ProjectStatus.archived).length
		const normalCount = statusFilter(ProjectStatus.normal).length
		const unarchivedCount = statusFilter(ProjectStatus.unarchived).length
		const cardDetails = {
			labels: ["Archived", "Normal", "Unarchived"],
			datasets: [
				{
					label: "Progress Rate",
					data: [archivedCount, normalCount, unarchivedCount],
					backgroundColor: [subColor, mainColor, emphasis],
					borderWidth: 0,
				},
			],
			text: "Archived",
		}
		return cardDetails
	},
	getProgressRateDetails() {
		let projects = self.getFilteredData()
		const progressDetails = [
			{
				title: ProjectStatus.archived,
				total: projects.filter(
					(item: any) => item.statusName === ProjectStatus.archived
				).length,
				late: 0,
				timely: 0,
				cannotCalculate: 0,
				isGroup: true,
			},
			{
				title: ProjectStatus.normal,
				total: projects.filter(
					(item: any) => item.statusName === ProjectStatus.normal
				).length,
				late: 0,
				timely: 0,
				cannotCalculate: 0,
				isGroup: true,
			},
			{
				title: "0 - 25%",
				total: projects.filter(
					(item: any) =>
						item.statusName === ProjectStatus.normal &&
						parseInt(item.reviewedStatus) >= 0 &&
						parseInt(item.reviewedStatus) < 26
				).length,
				late: 0,
				timely: 0,
				cannotCalculate: 0,
				isGroup: false,
			},
			{
				title: "25 - 50%",
				total: projects.filter(
					(item: any) =>
						item.statusName === ProjectStatus.normal &&
						parseInt(item.reviewedStatus) > 25 &&
						parseInt(item.reviewedStatus) < 51
				).length,
				late: 0,
				timely: 0,
				cannotCalculate: 0,
				isGroup: false,
			},
			{
				title: "51 - 75%",
				total: projects.filter(
					(item: any) =>
						item.statusName === ProjectStatus.normal &&
						parseInt(item.reviewedStatus) > 50 &&
						parseInt(item.reviewedStatus) < 76
				).length,
				late: 0,
				timely: 0,
				cannotCalculate: 0,
				isGroup: false,
			},
			{
				title: "76 - 100%",
				total: projects.filter(
					(item: any) =>
						item.statusName === ProjectStatus.normal &&
						parseInt(item.reviewedStatus) > 75 &&
						parseInt(item.reviewedStatus) <= 100
				).length,
				late: 0,
				timely: 0,
				cannotCalculate: 0,
				isGroup: false,
			},
		]
		projects.map((item: any) => {
			const finalReportDate = item.finalReportDate
			const days = item.archPolicyPeriod
				? parseInt(item.archPolicyPeriod.split(" "))
				: 0
			if (finalReportDate) {
				const expectedDeadlineDate = addDays(
					new Date(finalReportDate),
					days
				)
				if (item.statusName === ProjectStatus.archived) {
					if (
						new Date(expectedDeadlineDate).getTime() >
						new Date(item.archivedAt).getTime()
					) {
						progressDetails[0].late++
					} else {
						progressDetails[0].timely++
					}
				} else {
					if (
						new Date(expectedDeadlineDate).getTime() >
						new Date().getTime()
					) {
						progressDetails[1].late++
						if (
							parseInt(item.reviewedStatus) >= 0 &&
							parseInt(item.reviewedStatus) < 26
						) {
							progressDetails[2].late++
						}
						if (
							parseInt(item.reviewedStatus) > 25 &&
							parseInt(item.reviewedStatus) < 51
						) {
							progressDetails[3].late++
						}
						if (
							parseInt(item.reviewedStatus) > 50 &&
							parseInt(item.reviewedStatus) < 76
						) {
							progressDetails[4].late++
						}
						if (
							parseInt(item.reviewedStatus) > 75 &&
							parseInt(item.reviewedStatus) <= 100
						) {
							progressDetails[5].late++
						}
					} else {
						if (
							parseInt(item.reviewedStatus) >= 0 &&
							parseInt(item.reviewedStatus) < 26
						) {
							progressDetails[2].timely++
						}
						if (
							parseInt(item.reviewedStatus) > 25 &&
							parseInt(item.reviewedStatus) < 51
						) {
							progressDetails[3].timely++
						}
						if (
							parseInt(item.reviewedStatus) > 50 &&
							parseInt(item.reviewedStatus) < 76
						) {
							progressDetails[4].timely++
						}
						if (
							parseInt(item.reviewedStatus) > 75 &&
							parseInt(item.reviewedStatus) <= 100
						) {
							ConsoleLog([item, "item"])
							progressDetails[5].timely++
						}
						progressDetails[1].timely++
					}
				}
			} else {
				if (item.statusName === ProjectStatus.archived) {
					progressDetails[0].cannotCalculate++
				} else {
					progressDetails[1].cannotCalculate++
					if (
						parseInt(item.reviewedStatus) >= 0 &&
						parseInt(item.reviewedStatus) < 26
					) {
						progressDetails[2].cannotCalculate++
					}
					if (
						parseInt(item.reviewedStatus) > 25 &&
						parseInt(item.reviewedStatus) < 51
					) {
						progressDetails[3].cannotCalculate++
					}
					if (
						parseInt(item.reviewedStatus) > 50 &&
						parseInt(item.reviewedStatus) < 76
					) {
						progressDetails[4].cannotCalculate++
					}
					if (
						parseInt(item.reviewedStatus) > 75 &&
						parseInt(item.reviewedStatus) <= 100
					) {
						progressDetails[5].cannotCalculate++
					}
				}
			}
		})
		return progressDetails
	},

	getBGColor(index: number) {
		let colors = [
			"#3E6289",
			"#6592F0",
			"#93B6FF",
			"#AAC6FE",
			"#DDDFFF",
			"#4BA3BD",
			"#65CFF0",
			"#93DFFF",
			"#AAEBFE",
			"#D3F1FB",
		]
		return colors[index % 3]
	},

	getProgressRateSummary(projects: any) {
		// let projects = self.getFilteredData()
		const archived = projects.filter(
			(item: any) => item.statusName === ProjectStatus.archived
		)
		const normal = projects.filter(
			(item: any) => item.statusName === ProjectStatus.normal
		)
		const unarchived = projects.filter(
			(item: any) => item.statusName === ProjectStatus.unarchived
		)

		const getCount = (data: any, isLate: undefined | boolean) => {
			const count = data.filter(
				(item: any) => item.isLate === isLate
			).length
			return count
		}

		const progressSummary = [
			{
				title: ProjectStatus.archived,
				total: archived.length,
				late: getCount(archived, true),
				timely: getCount(archived, false),
				cannotCalculate: getCount(archived, undefined),
			},
			{
				title: ProjectStatus.normal,
				total: normal.length,
				late: getCount(normal, true),
				timely: getCount(normal, false),
				cannotCalculate: getCount(normal, undefined),
			},
			{
				title: ProjectStatus.unarchived,
				total: unarchived.length,
				late: getCount(unarchived, true),
				timely: getCount(unarchived, false),
				cannotCalculate: getCount(unarchived, undefined),
			},
		]

		return progressSummary
	},
	getEngTypePieData(colors: string[]) {
		const engTypes = self.viewEngTypes()
		const projects = self.getFilteredData()
		let dataArray: any[] = []
		let bgColors: any[] = []

		engTypes.map((item: any, i: number) => {
			const len = projects.filter(
				(proj: any) => proj.engTypeName === item
			).length
			dataArray.push({ label: item, value: len })
			bgColors.push(colors[i % 10])
		})
		dataArray = dataArray.sort((a, b) => b.value - a.value)

		const labels = dataArray.map(function (item) {
			return item["label"]
		})
		const data = dataArray.map(function (item) {
			return item["value"]
		})

		const cardDetails = {
			labels: labels,
			datasets: [
				{
					data: data,
					backgroundColor: bgColors,
					borderWidth: 0,
				},
			],
		}
		return cardDetails
	},

	getTableSummaryData(data: any, projects: any, column: string) {
		let summaryData: any[] = []
		data.map((item: any) => {
			const totalProject =
				column === "epUsers" || column === "qcUsers"
					? projects.filter((proj: any) =>
							proj[column].some((i: any) => i.name === item)
					  )
					: projects.filter((proj: any) => proj[column] === item)
			if (column === "epUsers" && item === "AT1 (customer)") {
				ConsoleLog(item)
				ConsoleLog("projects length " + projects.length)
				ConsoleLog(["epUsers projects ", totalProject])
				// projects.map((x: any) => {
				// 	ConsoleLog(x["epUsers"])
				// 	ConsoleLog(x["epUsers"].some((i: any) => i.name === item))
				// })
				ConsoleLog([
					"v2 finding",
					projects.filter((i: any) => i.version === "2"),
				])
				ConsoleLog([
					"filter again",
					projects.filter((x: any) =>
						x["epUsers"].some((y: any) => y.name === item)
					).length,
				])
			}
			if (totalProject.length) {
				const summary = {
					total: totalProject.length,
					title: item,
					archived: totalProject.filter(
						(proj: any) =>
							proj.statusName === ProjectStatus.archived
					).length,
					normal: totalProject.filter(
						(proj: any) => proj.statusName === ProjectStatus.normal
					).length,
					unarchived: totalProject.filter(
						(proj: any) =>
							proj.statusName === ProjectStatus.unarchived
					).length,
					late: totalProject.filter(
						(proj: any) =>
							proj.isLate === true &&
							proj.statusName !== ProjectStatus.unarchived
					).length,
				}
				summaryData.push(summary)
			}
		})
		return summaryData.sort((a, b) => b.total - a.total)
	},

	getGroupsPieData(colors: string[]) {
		const groups = self.viewGroupNames()
		const projects = self.getFilteredData()
		let dataArray: any[] = []
		let bgColors: any[] = []
		groups.map((item: any, i: number) => {
			const len = projects.filter(
				(proj: any) => proj.groupName === item
			).length
			dataArray.push({ label: item, value: len })
			bgColors.push(colors[i % 10])
		})
		dataArray = dataArray.sort((a, b) => b.value - a.value)
		const labels = dataArray.map(function (item) {
			return item["label"]
		})
		const data = dataArray.map(function (item) {
			return item["value"]
		})
		const cardDetails = {
			labels: labels,
			datasets: [
				{
					data: data,
					backgroundColor: bgColors,
					borderWidth: 0,
				},
			],
		}
		return cardDetails
	},

	getEPPieData(colors: string[]) {
		const epUsers = self.viewEPUsers()
		const projects = self.getFilteredData()
		let dataArray: any[] = []
		let bgColors: any[] = []
		epUsers.map((item: any, i: number) => {
			const len = projects.filter((proj: any) =>
				proj.epUsers.some((i: any) => i.name === item)
			).length
			dataArray.push({ label: item, value: len })
			bgColors.push(colors[i % 10])
		})
		dataArray = dataArray.sort((a, b) => b.value - a.value)
		const labels = dataArray.map(function (item) {
			return item["label"]
		})
		const data = dataArray.map(function (item) {
			return item["value"]
		})
		const cardDetails = {
			labels: labels,
			datasets: [
				{
					data: data,
					backgroundColor: bgColors,
					borderWidth: 0,
				},
			],
		}
		return cardDetails
	},

	getQCPieData(colors: string[]) {
		const qcUsers = self.viewQCUsers()
		const projects = self.getFilteredData()
		let dataArray: any[] = []
		let bgColors: any[] = []
		qcUsers.map((item: any, i: number) => {
			const len = projects.filter((proj: any) =>
				proj.qcUsers.some((i: any) => i.name === item)
			).length
			dataArray.push({ label: item, value: len })
			bgColors.push(colors[i % 10])
		})
		dataArray = dataArray.sort((a, b) => b.value - a.value)
		const labels = dataArray.map(function (item) {
			return item["label"]
		})
		const data = dataArray.map(function (item) {
			return item["value"]
		})
		const cardDetails = {
			labels: labels,
			datasets: [
				{
					data: data,
					backgroundColor: bgColors,
					borderWidth: 0,
				},
			],
		}
		return cardDetails
	},
	getPeriodTypePieData(colors: string[]) {
		const periodTypes = self.viewPeriodTypes()
		const projects = self.getFilteredData()
		let dataArray: any[] = []
		let bgColors: any[] = []
		periodTypes.map((item: any, i: number) => {
			const len = projects.filter(
				(proj: any) => proj.periodName === item
			).length
			dataArray.push({ label: item, value: len })
			bgColors.push(colors[i % 10])
		})
		dataArray = dataArray.sort((a, b) => b.value - a.value)
		const labels = dataArray.map(function (item) {
			return item["label"]
		})
		const data = dataArray.map(function (item) {
			return item["value"]
		})
		const cardDetails = {
			labels: labels,
			datasets: [
				{
					data: data,
					backgroundColor: bgColors,
					borderWidth: 0,
				},
			],
		}
		return cardDetails
	},
	viewHBarTableList(dntFormat: DnTFormatProps) {
		const defaultList = self.hBarTableList
		let formattedList: any[] = []

		function formatDate(date: string | null) {
			if (date) {
				return getTzDate2({
					date,
					dntFormat,
				})
			} else {
				return ""
			}
		}

		defaultList.map((project: any) => {
			const fmtStartDate = formatDate(project.startDate)
			//
			const fmtExpectedReportDate = formatDate(project.expectedReportDate)
			const fmtFinalReportDate = formatDate(project.finalReportDate)
			//
			const fmtExpectedArchiveDeadlineDate = formatDate(
				project.expectedArchiveDeadlineDate
			)
			const fmtFinalArchiveDeadlineDate = formatDate(
				project.finalArchiveDeadlineDate
			)
			//
			const fmtArchivedDate = formatDate(project.archivedAt)
			const fmtCreatedDate = formatDate(project.createdAt)

			const formattedProject = {
				...project,
				startDate: fmtStartDate,
				//
				expectedReportDate: fmtExpectedReportDate,
				finalReportDate: fmtFinalReportDate,
				//
				expectedArchiveDeadlineDate: fmtExpectedArchiveDeadlineDate,
				finalArchiveDeadlineDate: fmtFinalArchiveDeadlineDate,
				//
				archivedAt: fmtArchivedDate,
				createdAt: fmtCreatedDate,
			}
			formattedList.push(formattedProject)
		})

		return formattedList
	},
})

export default DashboardCardViews
