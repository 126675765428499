import React, { useEffect, useCallback } from "react"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import CommentsTable from "./CommentsTable"
import { PageContainer } from "../../../../components/app-frame-elements"
import CommentsPageToolbar from "./sub-components/CommentsPageToolbar"
import { DLSpinnerCenterAligned } from "../../../../components/basic-elements"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import CommentsCtxMenus from "./sub-components/CommentsCtxMenus"
import ProjCommentDetailDialogController from "./sub-components/ProjCommentDetailDialogController"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import {
	DialogActionType,
	ActionStatus,
	DLCommentType,
	DLSector,
} from "../../../../common-models/enumerations/common-enums"
import CommentCloseDialog from "../../../../components/combined-elements/comment/CommentCloseDialog"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"
import { ProjI18n } from "../../../../common-models/enumerations/translation-sheets"
import CommonDeleteDialog from "../../../../components/combined-elements/dialogs/ApprovalDialog"

export default observer(function CommmentsController() {
	const store = useRootStore()
	const projStore = useProjStore()
	const actionName = "getComments"
	const menuId = DLProjSubMenus.comments
	const orgUserId = localStorage.getItem("orgUserId")
	const i18nSheet = ProjI18n.comments

	const isLocked = projStore.projInfo.projInfo.isLocked
	const isArchived = projStore.projInfo.projInfo.isArchived
	const menuAccess = projStore.checkin.checkAccess(menuId)
	const permission = projStore.checkin.getActionsAsObject(
		menuId,
		isLocked,
		isArchived
	)
	const cmtStore = projStore.comments
	const needRefresh = cmtStore.needRefresh
	//
	const projRole = projStore.checkin.user.roleShortName
	const currentUserId = projStore.checkin.userId
	const currentUserName = projStore.projUsers.viewUserInfo(currentUserId).name
	//

	useEffect(() => {
		if (menuAccess && needRefresh) {
			cmtStore.getComments(
				"all",
				projStore.checkin.projId,
				DLProjSubMenus.wp
			)
			cmtStore.getComments(
				"all",
				projStore.checkin.projId,
				DLProjSubMenus.pf
			)
			cmtStore.setNeedRefresh(false)
			// NOTE: get project users if the getProjUser status is not success
			if (
				projStore.projUsers.getActionStatus("getProjUsers") !==
				ActionStatus.success
			) {
				projStore.projUsers.getProjUsers()
				projStore.roles.getRoleSet(DLSector.proj)
			}
		}
	}, [menuAccess, needRefresh])

	//
	//
	// * ----- selection related parts
	const handleSelectItem = useCallback((e: any, id: string, type: string) => {
		e.preventDefault()
		// ConsoleLog("handleSelectItem, id:", id)
		// TODO:: Keyboard shift, alt setting is required
		// setSelectedItemsWithKeyboard({
		// 	itemId: fileId,
		// 	shiftKey: event.shiftKey,
		// 	altKey: event.altKey,
		// })
		cmtStore.setSelectedItem({ id, type })
	}, [])

	const handleToggleSelectAllFiles = () => {
		let deleteCmts: any[] = []
		cmtStore.comments.map((cmt: any) => {
			if (hasAccessPermission(cmt.id, cmt.type)) {
				deleteCmts.push(cmt)
			}
		})
		cmtStore.toggleSelectAll(deleteCmts)
	}

	const handleCheckbox = (e: any, id: string, type: string) => {
		e.preventDefault()
		// ConsoleLog("handleCheckbox, id:", id)
		if (hasAccessPermission(id, type)) {
			cmtStore.pushOrSpliceSelectedItem({ id, type })
		}
	}
	const handleRightClick = (e: any) => {
		e.preventDefault()
		cmtStore.setClickPoint({
			mouseX: e.clientX - 2,
			mouseY: e.clientY - 4,
		})
	}
	//
	//

	// * ----- CRUD related parts
	// * ----- 1. Create
	// * ----- 2. Read
	// * ----- 3. Update
	const handleDetailDialogOpen = (mode: DialogActionType) => {
		cmtStore.setDetailDialogOpenStatus(true)
		cmtStore.setDetailDialogMode(mode)
	}
	// * ----- 4. Delete

	const handleDeleteComment = () => {
		if (orgUserId !== null) {
			const commentId = cmtStore.selectedItems[0].id // TODO: WARNING: UPDATE is required
			const props = {
				commentId,
				// commentType: ,
				// deleteBy: projStore.user.userId,
				deleteBy: orgUserId,
			}

			cmtStore.removeComment({
				props,
				handleResponse: cmtStore.handleResponse,
			})
		} else {
			alert(`User ID is invalid: ${orgUserId}`)
		}
	}

	const hasAccessPermission = (id: string, type: string) => {
		const dntFormat = store.global.getDntFormat
		const isNormalComment = type === DLCommentType.normal
		const commentInfo = projStore.comments.viewCommentById(id, dntFormat)
		let createdBy = commentInfo?.createdBy.name
		let hasAccess
		if (isNormalComment) {
			// review comment
			if (createdBy === currentUserName) {
				hasAccess = permission.deleteOwn
			} else {
				hasAccess = permission.deleteOthers
			}
		} else {
			// QC comment
			if (projRole === "QC") {
				if (createdBy === currentUserName) {
					hasAccess = permission.deleteOwn
				} else {
					hasAccess = permission.deleteOthers
				}
			}
		}
		return hasAccess
	}

	const handleDeleteMultipleComments = () => {
		if (orgUserId !== null) {
			let deleteCmts: any[] = []
			cmtStore.selectedItems.map((cmt: any) => {
				if (hasAccessPermission(cmt.id, cmt.type)) {
					deleteCmts.push(cmt)
				}
			})
			if (deleteCmts.length > 0) {
				cmtStore.removeMultipleComments({
					selectedCommentIds: deleteCmts,
					deleteBy: orgUserId,
					index: 0,
				})
			}
		} else {
			alert(`User ID is invalid: ${orgUserId}`)
		}
	}

	const handleCloseComment = () => {
		cmtStore.setCommentClosingDialogOpenStatus(true)
	}

	const dntFormat = store.global.getDntFormat
	const { dateFormat } = dntFormat
	const comments = cmtStore.formattedComments(dntFormat)

	let wpCommentsLoading = cmtStore.getActionStatus(
		actionName + DLProjSubMenus.wp
	)
	let pfCommentsLoading = cmtStore.getActionStatus(
		actionName + DLProjSubMenus.pf
	)

	const contentsHeight = store.ui.currentPageContentsAreaHeight - 48
	const i18n = store.i18n.combineI18n(i18nSheet, "CommentController")

	return (
		<>
			{menuAccess ? (
				<PageContainer pageTools={<CommentsPageToolbar />} fullWidth>
					{(wpCommentsLoading === "LOADING" ||
						pfCommentsLoading === "LOADING") && (
						<DLSpinnerCenterAligned />
					)}
					{wpCommentsLoading === "SUCCESS" &&
						pfCommentsLoading === "SUCCESS" && (
							<CommentsTable
								comments={comments}
								permission={permission}
								handleToggleSelectAllFiles={
									handleToggleSelectAllFiles
								}
								handleSelectItem={handleSelectItem}
								handleCheckbox={handleCheckbox}
								handleRightClick={handleRightClick}
								contentsHeight={contentsHeight}
								handleDetailDialogOpen={handleDetailDialogOpen}
								dateFormat={dateFormat}
								i18n={i18n}
							/>
						)}
					{cmtStore.clickPoint.mouseX && (
						<CommentsCtxMenus
							clickPoint={cmtStore.clickPoint}
							handleCtxMenuClose={cmtStore.resetClickPoint}
							handleDetailDialogOpen={handleDetailDialogOpen}
							handleDelete={handleDeleteComment}
							handleCloseComment={handleCloseComment}
							permission={permission}
							i18n={i18n}
						/>
					)}

					{cmtStore.detailDialogOpenStatus && (
						<ProjCommentDetailDialogController i18n={i18n} />
					)}
					{cmtStore.commentClosingDialogOpenStatus && (
						<CommentCloseDialog
							partialStore={cmtStore}
							commentId={cmtStore.selectedFirstItemId}
						/>
					)}
					{cmtStore.deleteAllCommentsDialogOpen && (
						<CommonDeleteDialog
							title="Delete Selected Comments"
							msg="Do you really want to delete selected comments?"
							approvalType="delete"
							dialogAction={() => handleDeleteMultipleComments()}
							dialogBtnText={i18n.twDelete}
							open={cmtStore.deleteAllCommentsDialogOpen}
							setOpen={cmtStore.setDeleteAllTrashDialogOpen}
							actionStatus={cmtStore.getActionStatus(
								"removeAllProjTrashItems"
							)}
						/>
					)}
					<CommonSnackbarComponent
						i18n={i18n}
						partialStore={cmtStore}
					/>
				</PageContainer>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
