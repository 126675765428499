import React from "react"
import styled from "styled-components"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import DLBasicDialog from "../../basic-elements/dialogs/DLBasicDialog"
import PageSettingDialog from "./PageSettingDialog"
import { DLIconButton } from "../../basic-elements"
import { DLIcon, DLIconName } from "../../basic-elements/icons/common-icons"

interface PageToolbarProps {
	tools: any
	showPageSettingBtn?: boolean
	pageSettings?: React.ReactElement
}

export default observer(function PageToolbar({
	tools,
	showPageSettingBtn = false,
	pageSettings,
}: PageToolbarProps) {
	const store = useRootStore()

	// let location = useLocation()
	// const currentUrl = location.pathname
	// const currentMenu = orgMenuFlatList.find(
	// 	element => element.url === currentUrl
	// )

	const i18n = store.i18n.globalI18n("pageToolbar")
	return (
		<StyledPageToolbar
			className={
				store.ui.isPageToolbarOpen
					? "page-toolbar"
					: "page-toolbar hidden"
			}
		>
			<div className="tools-area">{tools}</div>
			{/* <DLBasicDialog
					openBtn={
						<DLIconButton
							eleTestId="module-help"
							tooltipText={i18n.twHelp}
							eleClassName="page-toolbar-icon help-icon"
						>
							<Icon path={mdiHelpCircleOutline} size={0.8} />
						</DLIconButton>
					}
					closeBtnText="Close"
					dialogId="xxx"
					dialogContents={<div>Help contents will be provided</div>}
					dialogHeaderText={`${store.global.currentProjMenu} Page Helps`}
					actionBtnText=""
					maxWidth="md"
					fullWidth={true}
				/>
				*/}
			{showPageSettingBtn && (
				<DLBasicDialog
					openBtn={
						<DLIconButton
							eleTestId="screen-setting"
							tooltipText={i18n.twScreenSetting}
							eleClassName="page-toolbar-icon setting-icon"
						>
							<DLIcon name={DLIconName.setting} noMargin />
						</DLIconButton>
					}
					closeBtnText="Close"
					dialogId="xxx"
					dialogContents={
						pageSettings ? pageSettings : <PageSettingDialog />
					}
					dialogHeaderText={`${store.global.currentProjMenu} Page Setting`}
					actionBtnText="Save"
					actionHandler={() => alert("Wating for stpper")}
					maxWidth="md"
					fullWidth={true}
				/>
			)}
		</StyledPageToolbar>
	)
})

const StyledPageToolbar = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	height: var(--standard-unit);
	/* padding: 0 1rem 0 var(--spacing-xl); */
	padding: 0 1rem;
	flex-shrink: 0;
	background-color: ${(props) => props.theme.secondary};
	transition: 0.4s;
	overflow: hidden;
	/* overflow-x: scroll; * TODO: NEED TO CONSIDER MORE */
	&.hidden {
		height: 0;
	}
	.tools-area {
		width: 100%;
		/* width: calc(100% - 1rem); */
		/* if there is setting icon... need 1rem right-padding... */
	}
	.page-toolbar-icon {
		width: 1rem;
		height: 1rem;
		/* right: 1rem; */
		/* margin-left: 0.4rem; */
		fill: ${(props) => props.theme.shade40};
		transition: 0.2s;
		:hover {
			fill: ${(props) => props.theme.shade60};
			cursor: pointer;
		}
	}
`
