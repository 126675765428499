import React from "react"
import InputAdornment from "@material-ui/core/InputAdornment"
import Icon from "@mdi/react"
import { mdiEye, mdiEyeOff } from "@mdi/js"
import DLInputField from "./DLInputField"

interface PasswordInputFieldProps {
	eleLabel?: string
	eleName?: string
	eleValue?: any
	elePlaceholder?: string
	eleOnChange?: React.EventHandler<any>
	eleReadOnly?: boolean
	eleFullWidth?: boolean
	eleTestId: string
	eleHelperText?: string
	eleWarningMsg?: string
	eleWarningType?: "default" | "blue" | "orange" | "coral" | "red"
	requestInput?: boolean
}

/**
 * This component just use DLInputField and endAdornment
 */
export default function DLPasswordField({
	eleLabel = undefined,
	eleName = undefined,
	eleValue = undefined,
	elePlaceholder = undefined,
	eleOnChange = undefined,
	eleReadOnly = undefined,
	eleFullWidth = undefined,
	eleTestId = "testId-DLPasswordField",
	eleHelperText = undefined,
	eleWarningMsg = undefined,
	eleWarningType = undefined,
	requestInput = undefined,
}: PasswordInputFieldProps) {
	const [showPassword, setShowPassword] = React.useState(false)

	return (
		<div
			className={
				eleReadOnly
					? "custom-text-field custom-readonly custom-required"
					: eleReadOnly
					? "custom-text-field custom-readonly"
					: "custom-text-field"
			}
		>
			<DLInputField
				eleRequired
				eleType={showPassword ? "text" : "password"}
				eleLabel={eleLabel}
				eleName={eleName}
				eleValue={eleValue}
				elePlaceholder={elePlaceholder}
				eleHandleChange={eleOnChange}
				eleReadOnly={eleReadOnly}
				eleFullWidth={eleFullWidth}
				eleTestId={eleTestId}
				eleHelperText={eleHelperText}
				warningMsg={eleWarningMsg}
				warningType={eleWarningType}
				requestInput={requestInput}
				eleEndAdornment={
					<InputAdornment
						position="end"
						onClick={() => setShowPassword(!showPassword)}
					>
						<Icon
							path={showPassword ? mdiEye : mdiEyeOff}
							size={0.8}
						/>
					</InputAdornment>
				}
			/>
		</div>
	)
}
