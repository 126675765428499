import { DLCommentType } from "../../../../../../common-models/enumerations/common-enums"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"
import { convertSourceFormat } from "../../../../../../library/converters/source-format-converter"
import { DLProjSubMenus } from "../../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { CommentStatus } from "../data-models/comments.data-model"
import { commentIdToString } from "./utils-for-comment"

const reOrganizeComment = ({
	fetchedComment,
	menuId,
}: {
	fetchedComment: any
	menuId: DLProjSubMenus
}) => {
	const {
		ReviewCommentsId,
		AssignedTo,
		CreatedBy,
		CreatedUser,
		ReviewDate,
		SignOffDate,
		Signoff,
		ReviewStatus,
		Response,
		Comment,
		AssignedUser,
		Priority,
		WPREF,
		WPRID,
		WPDesc,
		MediaType,
		MediaID,
		Type,
	} = fetchedComment

	const userId = idToString(CreatedBy, IdType.user)

	let assignedIds: any[] = []
	AssignedTo.split(",").map((item: any) => {
		assignedIds.push(idToString(item, IdType.user))
	})

	return {
		id: commentIdToString(ReviewCommentsId, menuId),
		// createdUser: , // [CreatedUser] ex) "김철수" --> Let's try to use the name on the proj user list
		createdBy: {
			id: userId,
			aliasId: "",
			name: CreatedUser,
		}, // comment.CreatedBy.toString(), // [CreatedBy] ex) 16
		//
		objAliasId: WPREF, // [WPREF] ex) "1111-A" --> How about to use wp list information on the store?
		objectId: WPRID ? idToString(WPRID, IdType.file) : "",
		objectName: WPDesc, // [WPDesc] ex) "Test source A"
		srcFormat: convertSourceFormat(
			MediaType.toLowerCase(),
			"",
			"util-reorganize-comment"
		),

		srcId: idToString(MediaID, IdType.src), // [MediaID] ex) 275200
		//
		type: Type === "QC Comment" ? DLCommentType.qc : DLCommentType.normal, // [Type] ex) "QC Comment"
		menuId,
		priority: Priority, // [Priority] ex) "Medium"
		assigneeIds: assignedIds, // [AssignedTo] ex) "16" or "16,17,18" // TODO: need update
		assigneeNames: AssignedUser.split(","), // [AssignedUser] ex) "김철수" // TODO: need update
		//
		contents: Comment, // [Comment] ex) "QC Comment test"
		response: Response ? Response : "-", // [Response] ex) "" | sometimes null
		//
		status:
			ReviewStatus === "Open" ? CommentStatus.todo : CommentStatus.done, // [ReviewStatus] ex) "Open"
		closedBy: Signoff ? Signoff : "-", // [Signoff] ex) "" --> ??? userID? or.. message?
		closedAt: SignOffDate, // NOTE: Do not convert date info as "-" it will occur error with tzFormatting [SignOffDate] ex) null
		createdAt: ReviewDate, // [ReviewDate] ex) "2020-01-08T01:52:59.498527+00:00"
	}
}

export default reOrganizeComment
