// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { idToString, IdType } from "../../../../library/converters/id-converter"
import { convertProjectStatus } from "../../../../library/get-project-status"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"
import { ConsoleLog } from "../../../../components/basic-elements"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import {
	formattedDateWithTz2,
	getTzDate,
} from "../../../../library/converters/date-utc-converter"
import { DnTFormatProps } from "../../../../common-models/types/common-props"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 */

const GetOrgProjectList = (self: any) => ({
	getOrgProjectList({
		projStatus,
		year,
		PTMAssignedOnly,
		menuId,
		ForROAM,
		searchText,
		dntFormat,
	}: {
		projStatus: ProjectStatus
		year: "All Years" | string
		PTMAssignedOnly: boolean
		menuId: DLOrgSubMenus
		ForROAM?: boolean
		searchText?: string
		dntFormat: DnTFormatProps
	}) {
		// 0.
		const actionName = "getOrgProjectList_" + projStatus
		// 1.
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 111, // temp
			customMessage: `Loading all ${projStatus} projects in ${year}...`,
			color: MessageColorType.green,
			open: true,
		})
		// 3.
		self.readOrgProjectList({
			projStatus,
			year,
			searchText,
			PTMAssignedOnly,
			ForROAM,
		})
			.then((response: any) => {
				// if success to get the project list, save it to the store
				if (response.status === 200 && response.data.Status === 1) {
					ConsoleLog([actionName, "__response ", response])
					// TODO: is this fine when the response has 0 projects?
					// reset the stored project list
					// TODO: Consider better method then length = 0. It seems occurred warning
					// if (projStatus === ProjectStatus.normal) {
					// 	self.resetOriginalNormalProjectList()
					// }
					// if (projStatus === ProjectStatus.replica) {
					// 	self.resetOriginalReplicaProjectList()
					// }
					// if (projStatus === ProjectStatus.archived) {
					// 	self.resetOriginalArchivedProjectList()
					// }
					// if (projStatus === ProjectStatus.unarchived) {
					// 	self.resetOriginalUnarchivedProjectList()
					// }
					const { dateFormat, timeFormat, timeZone } = dntFormat
					function formatDate(date: string | null) {
						if (date) {
							return getTzDate({
								date,
								timeZone,
								dateFormat,
							})
						} else {
							return ""
						}
					}
					function formatTime(date: string | null) {
						if (date) {
							return formattedDateWithTz2({
								date,
								timeZone,
								timeFormat,
								dateFormat,
							})
						}
						return ""
					}
					console.time("expensive code")
					let projects: any[] = []
					response.data.AssignedProjectsList.map((project: any) => {
						let epUsers: any = []
						if (project.EpUsers !== "") {
							project.EpUsers.split(",").map(
								(user: string, i: number) => {
									epUsers.push({
										id: "xxx",
										aliasId: "xxx",
										name: user,
									})
								}
							)
						}
						let qcUsers: any = []
						if (project.QCUsers !== "") {
							project.QCUsers.split(",").map(
								(user: string, i: number) => {
									qcUsers.push({
										id: "xxx",
										aliasId: "xxx",
										name: user,
									})
								}
							)
						}
						//
						//
						let archivedBy = null
						if (project.ArchivedBy !== 0) {
							archivedBy = {
								id: idToString(project.ArchivedBy, IdType.user),
								aliasId: "xxx",
								name: project.ArchivedByUser,
							}
						}
						let unarchivedBy = null
						if (project.UnArchivedBy !== 0) {
							unarchivedBy = {
								id: idToString(
									project.UnArchivedBy,
									IdType.user
								),
								aliasId: "xxx",
								name: project.UnArchivedByUser,
							}
						}
						let lastAccessBy = null
						if (project.LastAccessedBy !== null) {
							lastAccessBy = {
								id: "xxx", // Not getting from backend
								aliasId: "xxx",
								name: project.LastAccessedBy,
							}
						}

						const formattedPeriodEndDate = formatDate(
							project.FinancialYear
						)
						const formattedLastAccess = formatTime(
							project.LastAccessedDate
						)

						const formattedCreatedAt = formatDate(
							project.CreatedDate
						)
						//
						const formattedArchived = formatTime(
							project.ProjectArchivedDate
						)
						const formattedUnArchivedAt = formatDate(
							project.UnarchivedDate
						)
						const formattedFinalReportDate = formatDate(
							project.FinalReportDate
						)
						const formattedExpectedReportDate = formatDate(
							project.ExpectedReportDate
						)
						const formattedExpectedArchiveDate = formatDate(
							project.ExpectedArchiveDeadlineDate
						)
						const formattedFinalArchiveDeadlineDate = formatDate(
							project.FinalArchiveDeadlineDate
						)
						const reOrganizedProjectInfo = {
							// * 1. location info: group, cabinet
							groupId: idToString(project.GroupID, IdType.group), // [GroupID], ex) 444
							groupName: project.GroupName, // [GroupName], ex) "Prepare and Review"
							cabinetId: idToString(
								project.CabinetID,
								IdType.cabinet
							),
							cabinetName: project.CabinetName,
							//
							//
							// * 2. client info
							clientId: idToString(
								project.ClientMasterId,
								IdType.client
							), // [ClientMasterId], ex) 104
							clientName: project.ClientName, // [ClientName], ex) "ABCDEFGH"
							clientAliasId: project.ClientAliasId, // Client Alias ID
							//
							//
							// * 3. project basic info
							id: idToString(project.ClientID, IdType.project), // [ClientID], ex) 2507
							aliasId: project.LinkName, // [ProjectBusinessId], ex) "" --> is null possible..?
							title: project.ProjectName, // [ProjectName], ex) "57yth"
							//
							engTypeId: idToString(
								project.EngagementId,
								IdType.engType
							), // [EngagementId], ex) 115
							engTypeName: project.EngagementType, // [EngagementType], ex) "CUULWRRKSC"
							raId: "xxx",
							raName:
								project.RiskAssessmentName === null
									? "null"
									: project.RiskAssessmentName,
							//
							//
							// * 4. project status info
							statusId: convertProjectStatus(project.ProjectType)
								.id, // [ProjectType], ex) 1 --> normal, replica, archived, unarchived...
							statusName: convertProjectStatus(
								project.ProjectType
							).name,
							periodId: idToString(
								project.PeriodId,
								IdType.period
							), // [PeriodId], ex) 1
							periodName: project.PeriodName, // [PeriodName], ex) "Full year"
							archiveDatePolicy: project.ArchiveDatePolicy,
							//
							replicaStatus:
								project.ReplicaStatus === "Yes" ? true : false,
							archiveZipStatus:
								project.ArchiveZipStatus === "Yes"
									? true
									: false,
							unarchiveStatus:
								project.UnArchiveStatus === "Yes"
									? true
									: false,
							//
							version: project.Version, // [Version], ex) 0
							isArchived: project.Archive, // [Archive], ex) false
							isLocked: project.IsLocked, // [IsLocked], ex) false
							//
							//
							// * 5. access info
							roleId: project.RoleID.toString(), // Getting 0 for all records
							roleName:
								project.RoleName !== null
									? project.RoleName
									: "",
							accessible: !project.isGrayout,
							//
							createdBy: {
								id: idToString(
									project.ClientCreatedBy,
									IdType.user
								),
								aliasId: "()",
								name: project.CreatedUser ?? "?",
							}, // [ClientCreatedBy], ex) 262
							createdAt: formattedCreatedAt,
							//
							//
							// * 6. schedule info
							financialYear: project.Year, // [Year], ex) 2019
							periodEndDate: formattedPeriodEndDate, // [FinancialYear], ex) "10/23/2019"
							//
							expectedReportDate: formattedExpectedReportDate,
							finalReportDate: formattedFinalReportDate, // [ReportIssuranceDate], ex) ""
							//
							// archPolicyPeriodId: types.string, // system ID
							// archPolicyPeriodName: types.string,
							// archPolicyPeriodValue: types.integer,
							//
							expectedArchiveDate: formattedExpectedArchiveDate,
							finalArchiveDeadlineDate:
								formattedFinalArchiveDeadlineDate, // watch out. This is different with ArchivedAt
							//
							//
							// * 7. history info
							// createdBy: types.union(types.null, SimpleUserModel),
							// createdAt: types.string,
							//
							lastAccessBy, // [LastAccessedBy] // WARNING: Need to update
							lastAccessAt: formattedLastAccess
								? formattedLastAccess.date +
								  " " +
								  formattedLastAccess.time
								: "", // [LastAccessedDate]
							lastAccessDate: formattedLastAccess
								? formattedLastAccess.date
								: " ",
							lastAccessTime: formattedLastAccess
								? formattedLastAccess.time
								: "", // [LastAccessedDate]//
							//
							archivedBy: archivedBy,
							archivedDate: formattedArchived
								? formattedArchived.date
								: " ",
							archivedTime: formattedArchived
								? formattedArchived.time
								: "",
							//
							unarchivedBy: unarchivedBy,
							unarchivedAt: formattedUnArchivedAt,
							//
							// unarchivedBy: types.union(types.null, SimpleUserModel),
							// unarchivedAt: "",
							//
							//
							// * 8. assigned users
							epUsers, // [EpUsers], ex) "" // TODO: @Noah: update for multiple EP Users
							qcUsers, // [QCUsers], ex) "" // TODO: @Noah: update for multiple QC Users
							//
							//
							// * 9. extras
							// NOTE: "ProjectBusinessId" in fetched data has no value. LinkName is alias ID here. But in the other parts, LinkName is not the alias ID of project... Need to check
							// WARNING: LinkName should be removed.. it is alias ID in somewhere and sometimes the title of the project or some mixed text...
							hasExportPermission: project.HasExportAccess,
							canRollForward: project.CanRollForward,
						}
						projects.push(reOrganizedProjectInfo)
					})
					console.timeEnd("expensive code")
					self.customRefresh(menuId, false)
					if (projStatus === ProjectStatus.normal) {
						self.setNormalProjectList(projects)
					}
					if (projStatus === ProjectStatus.replica) {
						self.setReplicaProjectList(projects)
					}
					if (projStatus === ProjectStatus.archived) {
						self.setArchivedProjectList(projects)
					}
					if (projStatus === ProjectStatus.unarchived) {
						self.setUnarchivedProjectList(projects)
					}
					if (projStatus === ProjectStatus.roamArchived) {
						self.setROAMArchivedProjectList(projects)
					}
					if (projStatus === ProjectStatus.forceArchived) {
						self.setForceArchivedProjList(projects)
					}
					if (response.data.TotalCount === 0) {
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage:
								"Success to fetch but project not exist",
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
						})
					}
				} else {
					// 200 but fail
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetOrgProjectList
