import React from "react"
import { DLChip } from "../../../../../../components/basic-elements"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"
import { dateByFormat } from "../store/date-and-time-setting.ui-helper"

import Icon from "@mdi/react"
import { mdiArrowRight } from "@mdi/js"
import { OrgSysSettingItemProps } from "../../../../../../service-modules/sys-setting-module/sys-setting.data-model"
import { useRootStore } from "../../../../../../stores/root-store/root-store.provider"

export default observer(function DntUsageInfo({
	i18n,
	selectedTimeZone,
	selectedTimeFormat,
	selectedDateFormat,
}: {
	i18n: DLI18nProps
	selectedTimeZone: OrgSysSettingItemProps
	selectedTimeFormat: OrgSysSettingItemProps
	selectedDateFormat: OrgSysSettingItemProps
}) {
	// default setting
	const store = useRootStore()
	const orgStore = useOrgStore()
	//
	const { twCurrentUserLocaleDate, twCurrentFormattedTimeZoneDate } = i18n

	const newTimeZone = selectedTimeZone.uiValue
	const newTimeFormat = selectedTimeFormat.uiValue
	const newDateFormat = selectedDateFormat.value
	//
	const timeZone = store.global.timeZone.uiValue
	const dateFormat = store.global.dateFormat.value
	const timeFormat = store.global.timeFormat.uiValue
	const localeDateOnStore = orgStore.setupDateAndTimeSetting.localeDate

	return (
		<div className="usage-informations">
			{/* <p>{twUsageInformation}</p> */}
			<p>Example</p>
			{/* <div className="usage-info-item">
				<div className="item-title">
					{twCurrentSystemSettingTimeZone}
				</div>

				<div className="sample-area FR AC">
					<div>
						(Current)
						<DLChip
							size="small"
							eleTestId="timezone-info"
							label={timeZone}
						/>
					</div>
					<Icon className="arrow" path={mdiArrowRight} size={1} />
					<div>
						(New)
						<DLChip
							size="small"
							eleTestId="next-timezone"
							label={newTimeZone}
							color={
								timeZone === newTimeZone
									? "default"
									: "primary"
							}
						/>
					</div>
				</div>
			</div> */}
			<div className="usage-info-item">
				<div className="item-title">{twCurrentUserLocaleDate}</div>(
				{orgStore.setupDateAndTimeSetting.getLocaleTimeZone()})
				{orgStore.setupDateAndTimeSetting.localeDate.toLocaleString()}
			</div>
			{/* <div className="usage-info-item">
				<div className="item-title">{twLocaleDateToJSON}</div> (will
				be hidden) (UTC){" "}
				{orgStore.setupDateAndTimeSetting.localeDate.toJSON()}
			</div> */}
			{/* <div className="usage-info-item">
				<div className="item-title">
					{twLocaleDateToTimeZoneDate}
				</div>
				<div className="sample-area FR AC">
					<div>
						(Current)
						<DLChip
							size="small"
							eleTestId="current-timezone"
							label={timeZone}
						/>
						{dateByTimeZone({
							timeZone,
							date: localeDateOnStore,
						})}
					</div>
					<Icon className="arrow" path={mdiArrowRight} size={1} />
					<div>
						(New)
						<DLChip
							size="small"
							eleTestId="next-timezone"
							label={newTimeZone}
							color={
								timeZone === newTimeZone
									? "default"
									: "primary"
							}
						/>
						{dateByTimeZone({
							timeZone: newTimeZone,
							date: localeDateOnStore,
						})}
					</div>
				</div>
			</div> */}
			<div className="usage-info-item">
				<div className="item-title">
					{twCurrentFormattedTimeZoneDate}
				</div>
				<div className="sample-area FR AC">
					<div>
						<DLChip
							size="small"
							eleTestId="current-timezone"
							label={timeZone}
						/>
						{dateByFormat({
							date: localeDateOnStore,
							dateFormat,
							timeZone,
							timeFormat,
						})}
					</div>
					{timeZone !== newTimeZone && (
						<>
							<Icon
								className="arrow"
								path={mdiArrowRight}
								size={1}
							/>
							<div>
								<DLChip
									size="small"
									eleTestId="next-timeZone"
									label={newTimeZone}
									color={
										timeZone === newTimeZone
											? "default"
											: "primary"
									}
								/>
								{dateByFormat({
									date: localeDateOnStore,
									dateFormat: newDateFormat,
									timeZone: newTimeZone,
									timeFormat: newTimeFormat,
								})}
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	)
})
