import React, { useState } from "react"
import {
	DLButton,
	DLDatepicker,
} from "../../../../../components/basic-elements"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import { PageContainer } from "../../../../../components/app-frame-elements"
import styled from "styled-components"
import { ProjectStatus } from "../../../../../common-models/enumerations/project-related-enums"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"

export default observer(function RationaleFinalReportDate({
	permission,
}: {
	permission: PermissionAsObjectProps
}) {
	const store = useRootStore()
	const projStore = useProjStore()

	const [finalReportDate, setFinalReportDate] = useState<any>("")
	const [finalArchDeadlineDate, setFinalArchDeadlineDate] = useState<any>("")

	const projInfo = projStore.projInfo.projInfo

	const handleDateInput = (date: string) => {
		const days = projInfo.archPolicyPeriodName.split(" ")[0]
		setFinalReportDate(new Date(date))
		if (days) {
			const newDate = new Date(date)
			newDate.setDate(newDate.getDate() + parseInt(days))
			setFinalArchDeadlineDate(newDate)
		}
	}

	const handleUpdate = () => {
		const projInformation = {
			...projInfo,

			finalReportDate,
			finalArchDeadlineDate,
		}
		projStore.projInfo.editProjInfo(projInformation)
	}

	const dateFormat = store.global.dateFormat.value
	// If success call ratinale file
	if (projStore.projInfo.getActionStatus("editProjInfo") === "SUCCESS") {
		projStore.rationales.getRationales()
	}
	return (
		<PageContainer hasToolbar={false}>
			<StyledCannotAccess className="FC AC JC">
				<p>
					Please set <b>Final Report Date</b> to get Rationale
					required file list
				</p>
				{permission.update &&
					!projInfo.isLocked &&
					(projInfo.statusName === ProjectStatus.normal ||
						projInfo.statusName === ProjectStatus.unarchived) && (
						<>
							<div>
								<DLDatepicker
									eleTestId="final-report-date-field"
									eleName="finalReportDate"
									selected={
										finalReportDate
											? new Date(finalReportDate)
											: null
									}
									eleLabel="Final Report Date"
									onChange={(date: any) =>
										handleDateInput(date)
									}
									format={dateFormat}
									fullWidth
								/>
							</div>
							<div>
								<DLButton
									eleTestId="save-final-report-date-btn"
									clickHandler={handleUpdate}
									color="primary"
									disabled={
										finalReportDate === "" ||
										finalArchDeadlineDate === ""
									}
									eleClassName="save-btn"
								>
									Save
								</DLButton>
							</div>
						</>
					)}
			</StyledCannotAccess>
		</PageContainer>
	)
})

const StyledCannotAccess = styled.div`
	width: 100%;
	height: 100%;
	.save-btn {
		margin-top: 1rem;
	}
`
