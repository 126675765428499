import React, { ReactElement, useCallback, useState } from "react"
import styled from "styled-components"
import { AddSingleFileProps } from "../../../../../service-modules/file-module/data-models/file.data-props"
import {
	DLButton,
	DLSpinnerCenterAligned,
	InputWithLabel,
	DLSingleSelect,
	DLInputField,
} from "../../../../basic-elements"
import { observer } from "mobx-react-lite"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import {
	ActionStatus,
	MediaTypeForApi,
} from "../../../../../common-models/enumerations/common-enums"
import { FolderSelectField } from "."
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import sharedRegEx from "../../../../../library/sharedRegEx"
import { isEmptyString } from "@datalobby/common"
import { NoSpecialChar2Msg } from "../../../../basic-elements/system-msg/DLSystemMsg"

const AddNewFileForm = observer(
	({
		menuId,
		addSingleFile,
		parentInfo,
		partialStore,
		projId, // for the project template, projectId = templateId
		i18n,
		userRole,
	}: {
		menuId: DLOrgSubMenus | DLProjSubMenus
		addSingleFile: (fileInfo: AddSingleFileProps, userRole: string) => void
		parentInfo: any
		partialStore: any // @Noah: update
		projId: string
		i18n: DLI18nProps
		userRole: string
	}) => {
		const actionName = "addSingleFile"

		const [inputs, setInput] = useState<AddSingleFileProps>({
			projId,
			parentId: parentInfo.id,
			aliasId: "",
			title: "",
			//
			localFileFormat: MediaTypeForApi.excel, // placeholder
			extension: "",
			//
			prepared: false,
			reviewed: false,
		})

		const { parentId, aliasId, title, localFileFormat } = inputs
		const [prepare, setPrepare] = useState(false)
		const [review, setReview] = useState(false)

		const [highlight, setHighlight] = useState(false)

		const handleInputs = useCallback((event: any) => {
			const name = event.target.name
			let value = event.target.value

			if (value === "true") {
				value = true
			} else if (value === "false") {
				value = false
			}

			setInput((inputs) => ({
				...inputs,
				[name]: value, // Do not use trim here. It restrict all the space even if it between the words
			}))
		}, [])

		const checkActionReady = () => {
			if (
				projId !== "" &&
				parentId !== "" &&
				!isEmptyString(aliasId) &&
				!isEmptyString(title)
			) {
				return true
			} else {
				return false
			}
		}

		console.log(userRole, "userRole")

		const handleAction = () => {
			if (projId === null) {
				alert("(AddNewFileForm) Project ID is null")
			} else {
				addSingleFile(
					{
						...inputs,
						prepared: prepare,
						reviewed: review,
					},
					userRole
				)
			}
		}

		const editableFields = {
			folder: false,
			aliasId: true,
			title: true,
			format: true,
			extension: false,
		}
		const requiredFields = {
			folder: false,
			aliasId: true,
			title: true,
			format: true,
			extension: false,
		}

		const aliasInputLengthStandard = 50
		const titleinputLengthStandard = 100
		let sameNameWarning =
			i18n.tsSameNameExist || "The same name already exist"
		let specialCharWarning = NoSpecialChar2Msg() // i18n.warningSpecialChar2 ||
		let exceedWarning =
			i18n.warningCharExceed ||
			"Exceeded the limited number of characters."

		const formatOptions = [
			{
				value: "Excel",
				label: "Excel",
				testId: "excel",
			},
			{
				value: "Word",
				label: "Word",
				testId: "word",
			},
			{
				value: "Leadsheet",
				label: "Lead Sheet",
				testId: "leadsheet",
			},
			{
				value: "UploadFile",
				label: "File Group",
				testId: "file-group",
			},
			{
				value: "Auditprogram",
				label: "Audit Program",
				testId: "apg",
			},
			{
				value: "FluxAnalytics",
				label: "Flux Analysis",
				testId: "flux",
			},
			{
				value: "FinalAnalytics",
				label: "Final Analysis",
				testId: "final",
			},
		]

		const ptOptions = [...formatOptions]
			.splice(0, 2)
			.concat(formatOptions[4])
		const pfOptions = [...formatOptions]
			.splice(0, 2)
			.concat(formatOptions[3])

		const isLoading =
			partialStore.getActionStatus(actionName) === ActionStatus.loading

		// NOTE: AliasID and Title Check: Must take same logic with global
		let aliasIdWarningMsg: string | ReactElement = ""
		let titleWarningMsg: string | ReactElement = ""

		const duplicatedAliasId = partialStore.isDuplicatedAliasId(aliasId)
		const validAliasId = sharedRegEx.noSpecialChar3.test(aliasId)
		const validAliasIdLength = aliasId.length <= aliasInputLengthStandard

		const duplicatedTitle = partialStore.isDuplicatedName(title)
		const validTitle = sharedRegEx.noSpecialChar2.test(title)
		const validTitleLength = title.length <= titleinputLengthStandard

		if (duplicatedAliasId) {
			aliasIdWarningMsg = sameNameWarning
		} else if (!validAliasId && aliasId !== "") {
			aliasIdWarningMsg = specialCharWarning
		} else if (aliasId.length > aliasInputLengthStandard) {
			aliasIdWarningMsg = exceedWarning
		}

		if (duplicatedTitle) {
			titleWarningMsg = sameNameWarning
		} else if (!validTitle && title !== "") {
			titleWarningMsg = specialCharWarning
		} else if (title.length > titleinputLengthStandard) {
			titleWarningMsg = exceedWarning
		}

		return (
			<>
				{isLoading && (
					<DLSpinnerCenterAligned absolute backgroundOpacity={0.5} />
				)}

				<StyledAddNewFileForm className="FC JSB">
					<div>
						<FolderSelectField
							i18n={i18n}
							partialStore={partialStore}
							folderTitle={parentInfo?.title}
							highlight={highlight}
						/>
						<div className="form-container">
							<div className="input-section FR">
								<InputWithLabel
									label={i18n.twRefNum || "Ref. Num"}
									required={requiredFields.aliasId}
									highlight={
										highlight && isEmptyString(aliasId)
									}
								>
									<DLInputField
										eleTestId="aliasId-input"
										eleName="aliasId"
										elePlaceholder={
											i18n.tsRequestInputRefNum ||
											"Please input reference number"
										}
										eleValue={aliasId}
										autoFocus
										eleRequired
										eleHandleChange={handleInputs}
										eleReadOnly={!editableFields.aliasId}
										warningMsg={aliasIdWarningMsg}
										warningType="orange"
									/>
								</InputWithLabel>
							</div>
							<div className="input-section FR">
								<InputWithLabel
									label={i18n.twFileName || "File Name"}
									required={requiredFields.title}
									highlight={
										highlight && isEmptyString(title)
									}
								>
									<DLInputField
										eleTestId="fileName-input"
										eleName="title"
										elePlaceholder="Please input file title"
										eleValue={title}
										eleRequired
										eleHandleChange={handleInputs}
										eleReadOnly={!editableFields.title}
										warningMsg={titleWarningMsg}
										warningType="orange"
									/>
								</InputWithLabel>
							</div>
							<div className="input-section FR">
								<InputWithLabel
									label="Format"
									required={requiredFields.format}
									// NOTE: localFileFormat has value always
								>
									<DLSingleSelect
										eleTestId="select-file-format"
										eleValue={localFileFormat}
										eleOnChange={handleInputs}
										eleName="localFileFormat"
										eleFullWidth
										placeholder="Please select a file format"
										withLabel={false}
										eleDisabled={!editableFields.format}
										minHeight="auto"
										optionList={
											menuId ===
											DLOrgSubMenus.proj_templates
												? ptOptions
												: menuId === DLProjSubMenus.pf
												? pfOptions
												: formatOptions
										}
									/>
								</InputWithLabel>
							</div>
							{/* {menuId !== DLOrgSubMenus.proj_templates && (
								<AddFileSignOffForm
									prepare={prepare}
									setPrepare={setPrepare}
									review={review}
									setReview={setReview}
								/>
							)} */}
						</div>
					</div>
					<DLButton
						eleTestId="add-file-btn"
						color="primary"
						clickHandler={handleAction}
						eleHeight="auto"
						disabled={
							!checkActionReady() ||
							isLoading ||
							duplicatedAliasId ||
							duplicatedTitle ||
							!validAliasId ||
							!validTitle ||
							!validAliasIdLength ||
							!validTitleLength
						}
						onMouseOver={() => setHighlight(true)}
						onMouseLeave={() => setHighlight(false)}
					>
						Create
					</DLButton>
				</StyledAddNewFileForm>
			</>
		)
	}
)

const StyledAddNewFileForm = styled.div`
	height: 100%;
	.form-container {
		transition: 0.4s;
	}
`

export default AddNewFileForm
