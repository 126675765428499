import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog, DLSpinnerByIcon, ConsoleLog } from "../../../basic-elements"
import { DLCheckbox, DLTooltip } from "@datalobby/components"
import styled from "styled-components"
import Icon from "@mdi/react"
import { mdiCheckCircle, mdiAlertCircle } from "@mdi/js"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { RoleModelProps } from "../../../../service-modules/file-module/data-models/file.data-model"
import { FileScreenDialog } from "../../../../service-modules/file-module/data-models/dl-file-control-model"
import { assignRoleActionPrefix } from "../../../../service-modules/file-module/view-model-actions/assign/assign-role-to-file"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import keyboardJS from "keyboardjs"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

type AssignRoleInputProps = {
	label: string
	shortName: string
	value: string
	checked: boolean
}

export default observer(function RoleAssignDialog({
	partialStore,
	roleSet,
	i18n,
}: {
	partialStore: any
	roleSet: any
	i18n: DLI18nProps
}) {
	const dialogName = FileScreenDialog.assign
	//
	const [defaultValues, setDefaultValues] = useState<string[]>([])
	const [inputs, setInputs] = useState<AssignRoleInputProps[]>([])
	const [ready, setReady] = useState(false)

	const fileId = partialStore.selectedItems[0].id
	const fileInfo = partialStore.getItemInfo(fileId)

	// NOTE: Initialize all roles' assign status to standby
	useEffect(() => {
		ConsoleLog("initialize action status")
		roleSet.map((role: any) => {
			const actionName = assignRoleActionPrefix + role.roleId
			partialStore.responses.setResponse(actionName, {
				actionName,
				status: ActionStatus.standby,
			})
		})
	}, [])

	useEffect(() => {
		// set current (fetched) setting to check update
		let roleList: string[] = []
		const assignedRoles = fileInfo && fileInfo?.assignedRoles

		assignedRoles &&
			assignedRoles.map((role: RoleModelProps) => roleList.push(role.id))
		setDefaultValues(roleList)
		// set inputs for update
		let roleCheckboxes: AssignRoleInputProps[] = []
		ConsoleLog(["roleSet", roleSet])
		roleSet?.map((role: any) => {
			roleCheckboxes.push({
				label: role.name,
				shortName: role.shortName,
				value: role.roleId,
				checked: roleList.includes(role.roleId),
			})
		})
		ConsoleLog(["roleCheckboxes", roleCheckboxes])
		setInputs(roleCheckboxes)
		return () => {
			keyboardJS.resume()
		}
	}, [])

	const handleInputs = (e: any) => {
		const targetRole = e.target.value
		setInputs((inputs) =>
			inputs.map((input) =>
				input.value === targetRole
					? { ...input, checked: !input.checked }
					: input
			)
		)
	}

	useEffect(() => {
		let list = [...defaultValues]
		let newList: string[] = []
		inputs.map((input) => input.checked && newList.push(input.value))

		list.sort()
		newList.sort()
		if (JSON.stringify(list) === JSON.stringify(newList)) {
			setReady(false)
		} else {
			setReady(true)
			// partialStore.responses.setResponse("assignMultipleRoleToFile", {
			// 	actionName: "assignMultipleRoleToFile",
			// 	status: ActionStatus.standby,
			// })
		}
	}, [inputs, defaultValues, fileInfo])

	const handleAssign = () => {
		let requestList: { id: string; checked: boolean }[] = []
		inputs.map((input) =>
			requestList.push({
				id: input.value,
				checked: input.checked,
			})
		)

		partialStore.assignMultipleRoleToFile({
			fileId,
			prevRoles: defaultValues,
			roles: requestList,
		})
	}

	const open = partialStore.fileTreeDialogOpenStatus[dialogName]
	const setOpen = () => {
		partialStore.setFileTreeDialogOpen(dialogName)
	}

	console.log(inputs, "inputs")

	return (
		<DLDialog
			eleTestId="assign-role-dialog"
			isOpen={open}
			setIsOpen={setOpen}
			handleAction={handleAssign}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={"Role - Assign / Unassign"}
			dialogContents={
				<RoleAssignDialogForm
					inputs={inputs}
					handleInputs={handleInputs}
					fileInfo={fileInfo}
					partialStore={partialStore}
					dialogName={dialogName}
					i18n={i18n}
				/>
			}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionReady={ready}
			actionBtn={i18n.twUpdate || "Update"}
			maxWidth="sm"
			fullWidth={true}
		/>
	)
})

const RoleAssignDialogForm = observer(
	({
		inputs,
		handleInputs,
		fileInfo,
		partialStore,
		dialogName,
		i18n,
	}: {
		inputs: AssignRoleInputProps[]
		handleInputs: any
		fileInfo: any
		partialStore: any
		dialogName: FileScreenDialog
		i18n: DLI18nProps
	}) => {
		// when success all, close the dialog
		let statusList: any = []
		inputs.map((role: any) => {
			const actionStatus = partialStore.getActionStatus(
				assignRoleActionPrefix + role.value
			)
			statusList.push(actionStatus)
		})

		const standByList = statusList.filter(
			(item: any) => item === ActionStatus.standby
		)

		if (
			!statusList.includes(ActionStatus.fail) &&
			!statusList.includes(ActionStatus.loading) &&
			standByList.length !== inputs.length
		) {
			partialStore.setFileTreeDialogOpen(dialogName, false)
		}

		return (
			<StyledRoleAssignDialogForm className="FR">
				<div className="file-info">
					{/* <div>Folder: {fileInfo.parentId}</div> */}
					<div>Ref. num: {fileInfo.aliasId}</div>
					<div>File title: {fileInfo.title}</div>
					<div>File format: {fileInfo.srcFormat}</div>
					<div>File extension: {fileInfo.extension}</div>
					<div>File Size: {fileInfo.size}</div>
				</div>
				<div className="input-area">
					{inputs.map((role) => {
						const condition =
							role.value === "id_qc" &&
							role.checked &&
							fileInfo.qcReviewList.length > 0
						const actionStatus = partialStore.getActionStatus(
							assignRoleActionPrefix + role.value
						)

						const wp =
							role.shortName !== "OA" &&
							role.shortName !== "TEMP_EX" &&
							role.shortName !== "EX"

						const hide =
							partialStore.storeName === DLProjSubMenus.wp
								? wp
								: wp && role.shortName !== "QC"
						if (hide) {
							return (
								<div
									className={`FR AC role-item ${
										condition && "disabled"
									}`}
									key={role.value}
								>
									<DLCheckbox
										eleDisabled={condition}
										eleClassName="checkbox"
										value={role.value}
										eleTestId={`checkbox-${role.shortName}`}
										isChecked={role.checked}
										clickHandler={handleInputs}
										// labelText={role.label}
										color="primary"
									/>
									{condition ? (
										<DLTooltip
											title={
												i18n.tsCannotUnassignQcWithQcSignOff ||
												"Cannot unassign QC role because there are QC sign off already"
											}
										>
											<span className="role-name">
												({role.shortName}) {role.label}
												{"  "}
												<span style={{ color: "red" }}>
													[QC Reviewed]
												</span>
											</span>
										</DLTooltip>
									) : (
										<div className="role-name FR AC">
											{actionStatus ===
											ActionStatus.loading ? (
												<DLSpinnerByIcon eleClassName="status-icon" />
											) : actionStatus ===
											  ActionStatus.success ? (
												<Icon
													path={mdiCheckCircle}
													size={0.8}
													className="status-icon success"
												/>
											) : actionStatus ===
											  ActionStatus.fail ? (
												<Icon
													path={mdiAlertCircle}
													size={0.8}
													className="status-icon fail"
												/>
											) : (
												""
											)}
											({role.shortName}) {role.label}
										</div>
									)}
								</div>
							)
						}
					})}
				</div>
			</StyledRoleAssignDialogForm>
		)
	}
)

const StyledRoleAssignDialogForm = styled.div`
	.file-info {
		height: 100%;
		background-color: ${(props) => props.theme.secondary};
		padding: 1rem;
		border-radius: 3px;
		margin-right: 1rem;
	}
	.input-area {
		.role-item {
			margin-bottom: 0.4rem;
			.role-name {
				transition: 0.4s;
				margin-left: 0.6rem;
				.status-icon {
					margin-right: 0.6rem;
					&.success {
						color: ${(props) => props.theme.shared.messageBlue};
					}
					&.fail {
						color: ${(props) => props.theme.shared.messageCoral};
					}
				}
			}
		}
		.disabled {
			opacity: 0.4;
			cursor: not-allowed;
		}
	}
`
