import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { DLRadioGroup } from "../../../../../components/basic-elements"
import { DLCheckbox } from "@datalobby/components"
import { FileColumn } from "../../../../../service-modules/file-module/data-models/dl-file-control-model"
import styled from "styled-components"

export default observer(function FilesPageSettingDialogContents({
	partialStore,
	columns,
	signOffDisplayTypeOptions,
	signOffColumns,
}: {
	partialStore: any
	columns: { value: string; label: string }[]
	signOffDisplayTypeOptions: {
		value: string
		label: string
		disabled: boolean
	}[]
	signOffColumns: { value: string; label: string }[]
}) {
	const signOffDisplayType = partialStore.signOffCellType
	const [signOffDisplayTemporaryType, setSignOffDisplayTemporaryType] =
		useState(signOffDisplayType)
	//
	const handleSignOffDisplay = (e: any) => {
		setSignOffDisplayTemporaryType(e.target.value)
		partialStore.setSignOffCellType(e.target.value)
	}
	return (
		<StyledPageSetting className="FR">
			<div className="column-onoff" data-testid="files-column-onoff">
				<div className="setting-title">Displayed Columns</div>
				<div className="FC">
					{columns.map((item: any) => {
						const { value, label, disabled } = item
						const storeValue = partialStore.showColumns[value]
						return (
							<div key={value}>
								<DLCheckbox
									eleTestId={"column-onoff-checkbox-" + value}
									labelText={label}
									isChecked={storeValue}
									color="primary"
									clickHandler={() =>
										!disabled &&
										partialStore.updateColumnDisplayStatus(
											value,
											!storeValue
										)
									}
									eleDisabled={disabled}
								/>
							</div>
						)
					})}
				</div>
			</div>
			<div
				className="signoff-columns-onoff"
				data-testid="signoff-columns-onoff"
			>
				<div className="setting-title">Sign Off Columns</div>
				<div className="FC">
					{signOffColumns.map((item: any) => {
						const { value, label } = item
						const storeValue =
							partialStore.showSignOffColumns[value]
						return (
							<div key={value}>
								<DLCheckbox
									eleTestId={
										"signoff-column-onoff-checkbox-" + value
									}
									labelText={label}
									isChecked={storeValue}
									color="primary"
									clickHandler={() =>
										partialStore.updateSignOffColumnDisplay(
											value,
											!storeValue
										)
									}
								/>
							</div>
						)
					})}
				</div>
			</div>
			<div className="sign-off-display-select">
				{/* NOTE: DO NOT ADD individual sign off columns. 
				It will be customizable and cannot be controlled by store.
				It should be controlled by internal useState */}
				<div className="setting-title">Sign Off Display Type</div>
				<DLRadioGroup
					// formTitle="Sign Off Display Type"
					items={signOffDisplayTypeOptions}
					selectedValue={signOffDisplayTemporaryType}
					eleOnChange={handleSignOffDisplay}
					disabled={!partialStore.showColumns[FileColumn.signOffs]}
				/>
			</div>
		</StyledPageSetting>
	)
})

const StyledPageSetting = styled.div`
	.column-onoff {
		margin-right: 3rem;
	}
	.signoff-columns-onoff {
		margin-right: 3rem;
	}
	.sign-off-display-select {
	}
	.setting-title {
		margin-bottom: 1rem;
	}
`
