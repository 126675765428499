import { observer } from "mobx-react-lite"
import React, { useEffect, useState } from "react"
import {
	DLButton,
	DLDialog,
	ConsoleLog,
	DLSpinnerCenterAligned,
} from "../../../../../components/basic-elements"
import { DLTooltip } from "@datalobby/components"
import styled from "styled-components"
import Icon from "@mdi/react"
import {
	mdiArrowUp,
	mdiArrowDown,
	mdiPlus,
	mdiPause,
	mdiInformationOutline,
} from "@mdi/js"
import * as XLSX from "xlsx"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import BulkAddUsersDialogTable from "./BulkAddUsersDialogTable"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import sharedRegEx from "../../../../../library/sharedRegEx"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import UserSubmitStatusList from "./UserSubmitStatusList"
import DuplicatedUserList from "./DuplicatedUserList"
import { handleDownloadExcelTemplate } from "../../../../../library/bulk-import-utils/handle-download-template"

export default observer(function BulkAddUsersDialog({
	partialStore,
	i18n,
}: {
	partialStore: any
	i18n: DLI18nProps
}) {
	const orgStore = useOrgStore()
	const actionName = "createMultipleUsers"
	useEffect(() => {
		partialStore.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.standby,
		})
		partialStore.resetBulkUserList()
	}, [])

	const handleAddRow = () => {
		const users = JSON.parse(JSON.stringify(partialStore.bulkUserList))
		const userId = users.reduce((acc: any, val: any) => {
			const id = idToNumber(val.userId, IdType.user)
			acc = acc === undefined || id > acc ? id : acc
			return acc
		}, 0)
		const newRow = {
			userId: `userId${userId + 1}`,
			type: "internal",
			email: "",
			name: "",
			aliasId: "",
			accessRight: "User Access",
			title: "",
			// hasValidEmail: false,
			isDuplicate: false,
			// duplicatedAliasId: false,
			// duplicatedEmail: false,
			status: ActionStatus.standby,
			statusMessage: "",
		}
		partialStore.pushItemToBulkList(newRow)
	}

	const handleBulkAdd = () => {
		const userList = partialStore.bulkUserList.filter(
			(user: any) => user.status === ActionStatus.standby
		)
		const creatorAliasId = orgStore.checkin.orgInfo.userAliasId
		if (userList.length > 0) {
			partialStore.setPauseMultipleUsers(false)
			partialStore.responses.setResponse(actionName, {
				actionName,
				status: ActionStatus.loading,
			})
			partialStore.addMultipleUsers(userList, creatorAliasId, 0)
		}
	}

	const handlePauseMultipleUsers = () => {
		partialStore.setPauseMultipleUsers(true)
		partialStore.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.standby,
		})
	}

	// const handleResumeMultipleUsers = () => {
	// 	partialStore.setPauseMultipleUsers(false)
	// 	partialStore.responses.setResponse(actionName, {
	// 		actionName,
	// 		status: ActionStatus.loading,
	// 	})
	// 	handleBulkAdd()
	// }

	const isReady = () => {
		const users = JSON.parse(JSON.stringify(partialStore.bulkUserList))
		let emptyCheck
		let duplicateAliasId
		let duplicateEmail
		let duplicateStoreEmail = 0
		users.map((item: any) => {
			emptyCheck =
				users.filter(
					(x: any) =>
						x.aliasId === "" ||
						x.email === "" ||
						x.name === "" ||
						!sharedRegEx.email.test(item.email)
				).length > 0
			duplicateAliasId =
				users.filter((x: any) => x.aliasId === item.aliasId).length > 1
			duplicateEmail =
				users.filter((x: any) => x.email === item.email).length > 1
			if (
				partialStore.isDuplicatedAliasId(item.aliasId) ||
				partialStore.isDuplicatedEmail(item.email)
			) {
				duplicateStoreEmail += 1
			}
		})
		return (
			emptyCheck ||
			duplicateAliasId ||
			duplicateEmail ||
			duplicateStoreEmail > 0
		)
	}
	const users = JSON.parse(JSON.stringify(partialStore.bulkUserList))
	const multipleUsersStatus = partialStore.getActionStatus(actionName)
	const standByUsers = users.filter(
		(user: any) => user.status === ActionStatus.standby
	)
	return (
		<DLDialog
			eleTestId="bulk-add-users-dialog"
			// isOpen
			isOpen={partialStore.bulkAddUsersDialogOpen}
			setIsOpen={partialStore.setBulkAddUsersDialogOpen}
			showOpenBtn={false}
			showCloseBtn
			dialogTitle={
				i18n.twAddMultipleUsersDialogTitle || "Add Multiple Users"
			}
			dialogContents={
				<BulkAddUsersDialogContents
					partialStore={partialStore}
					i18n={i18n}
				/>
			}
			draggable
			actionBtn={
				<div className="FR JSB" style={{ width: "100%" }}>
					<DLButton
						eleTestId="add-new-row"
						startIcon={<Icon path={mdiPlus} size={0.8} />}
						clickHandler={() => handleAddRow()}
						disabled={multipleUsersStatus !== ActionStatus.standby}
					>
						Add Row
					</DLButton>

					{multipleUsersStatus === ActionStatus.loading ? (
						<DLButton
							eleTestId="pause-create-mul-users"
							startIcon={<Icon path={mdiPause} size={0.8} />}
							clickHandler={() => handlePauseMultipleUsers()}
						>
							Pause
						</DLButton>
					) : (
						<div className="FR JSB">
							{partialStore.viewSubmitStatusCount(
								ActionStatus.success
							) > 0 && (
								<div
									className="FR AC"
									style={{ marginRight: 8 }}
								>
									Submitted:{" "}
									{partialStore.viewSubmitStatusCount(
										ActionStatus.success
									)}
									<span style={{ marginLeft: 8 }}>|</span>
								</div>
							)}
							{partialStore.viewSubmitStatusCount(
								ActionStatus.fail
							) > 0 && (
								<div
									className="FR AC"
									style={{ marginRight: 8 }}
								>
									Failed:{" "}
									{partialStore.viewSubmitStatusCount(
										ActionStatus.fail
									)}
									<span style={{ marginLeft: 8 }}>|</span>
								</div>
							)}
							{standByUsers.length > 0 && (
								<span style={{ padding: 8 }}>
									Submit {standByUsers.length} Users
								</span>
							)}
							<DLButton
								eleTestId="proceed-bulk-add"
								clickHandler={() => handleBulkAdd()}
								disabled={
									standByUsers.length === 0 || isReady()
								}
							>
								Submit
							</DLButton>
						</div>
					)}
				</div>
			}
			fullWidth
			maxWidth="lg"
		/>
	)
})

type UserItemProps = {
	userId: string
	type: string
	email: string
	name: string
	aliasId: string
	accessRight: string
	title: string
	// hasValidEmail: boolean
	isDuplicate: boolean
	// duplicatedAliasId: boolean
	// duplicatedEmail: boolean
	status: ActionStatus
	statusMessage: string
}

// type DuplicatedItemProps = {
// 	type: string
// 	email: string
// 	name: string
// 	aliasId: string
// 	accessRight: string
// 	title: string
// 	duplicatedPart: "email" | "aliasId" | "all"
// }

const BulkAddUsersDialogContents = observer(
	({ partialStore, i18n }: { partialStore: any; i18n: DLI18nProps }) => {
		const [localFileName, setLocalFileName] = useState("")
		// const [users, setUsers] = useState<UserItemProps[]>([])
		// const [duplicatedItems, setDuplicatedItems] = useState<
		// 	DuplicatedItemProps[]
		// >([])
		const actionName = "createMultipleUsers"

		function handleFile(e: any) {
			var file = e.target.files[0]
			var reader = new FileReader()

			reader.onload = function (e) {
				if (e.target) {
					if (
						e.target.result !== null &&
						typeof e.target.result !== "string"
					) {
						var data = new Uint8Array(e.target.result)
						// ConsoleLog(data, "data")
						var workbook = XLSX.read(data, { type: "array" })

						const jsonData = XLSX.utils.sheet_to_json(
							workbook.Sheets.Sheet1
						)
						ConsoleLog([jsonData, "jsonData"])
						let organizedList: UserItemProps[] = []
						// let duplicatedList: DuplicatedItemProps[] = []
						const hasEmptyFields = jsonData.every(
							(item: any) =>
								Object.keys(item).includes("type") &&
								Object.keys(item).includes("email") &&
								Object.keys(item).includes("name") &&
								Object.keys(item).includes("aliasId") &&
								Object.keys(item).includes("accessRight")
						)

						if (!hasEmptyFields) {
							alert(
								"Excel file contains empty fields. Please input correct data."
							)
							partialStore.setBulkUserFileReadStatus(
								ActionStatus.fail
							)
							return false
						}
						jsonData.map((item: any, i: any) => {
							// const aliasIdCheck =
							// 	jsonData.filter(
							// 		(x: any) => x.aliasId === item.aliasId
							// 	).length > 1
							// const emailCheck =
							// 	jsonData.filter((x: any) => x.email === item.email)
							// 		.length > 1
							// const hasValidEmail = sharedRegEx.email.test(item.email)
							const userType =
								item.type.toLowerCase() === "internal"
									? item.type.toLowerCase()
									: "external"
							const accessRight = [
								"Archive Admin",
								"Super Admin",
								"Group Admin",
								"User Access",
							].includes(item.accessRight)
								? item.accessRight
								: "User Access"
							organizedList.push({
								userId: `userId${i + 1}`,
								type: userType,
								email: item.email,
								name: item.name,
								aliasId: item.aliasId.toString(),
								accessRight: accessRight,
								title: item.title || "",
								// hasValidEmail: hasValidEmail,
								isDuplicate:
									partialStore.isDuplicatedEmail(
										item.email
									) ||
									partialStore.isDuplicatedAliasId(
										item.aliasId.toString()
									),
								// duplicatedAliasId: aliasIdCheck,
								// duplicatedEmail: emailCheck,
								status: ActionStatus.standby,
								statusMessage: "",
							})
						})
						ConsoleLog([organizedList, "organizedList"])
						partialStore.setBulkUserList(organizedList)
						partialStore.setBulkUserFileReadStatus(
							ActionStatus.success
						)
						// setDuplicatedItems(duplicatedList)
					}
				} else {
					ConsoleLog("event target is null")
				}
			}
			reader.readAsArrayBuffer(file)
		}

		const handleSelectFilesFromLocal = (e: any) => {
			const localFile = e.target.files[0]
			// ConsoleLog("localFile", localFile)
			setLocalFileName(localFile.name)
			handleFile(e)
			partialStore.setBulkUserFileReadStatus(ActionStatus.loading)
		}

		const users = partialStore.viewBulkUserList
		const viewDuplicatedInput = partialStore.viewDuplicatedInput
		const { duplicatedItems, duplicatedItemsInSheet } = viewDuplicatedInput
		const multipleUsersStatus = partialStore.getActionStatus(actionName)
		const fileReadStatus = partialStore.bulkUserFileReadStatus
		const template = [
			{
				type: "Internal",
				email: "example1@datalobby.com",
				name: "example_name1",
				aliasId: "unique_id_1",
				accessRight: "Archive Admin",
				title: "",
			},
			{
				type: "Internal",
				email: "example2@datalobby.com",
				name: "example_name2",
				aliasId: "unique_id_2",
				accessRight: "Super Admin",
				title: "",
			},
			{
				type: "Internal",
				email: "example3@datalobby.com",
				name: "example_name3",
				aliasId: "unique_id_3",
				accessRight: "Group Admin",
				title: "",
			},
			{
				type: "Internal",
				email: "example4@datalobby.com",
				name: "example_name4",
				aliasId: "unique_id_4",
				accessRight: "User Access",
				title: "",
			},
			{
				type: "External",
				email: "example4@other.com",
				name: "example_name5",
				aliasId: "unique_id_5",
				accessRight: "-",
				title: "",
			},
		]

		return (
			<StyledDialogContents className="bulk-add-users-dialog">
				{fileReadStatus === ActionStatus.loading && (
					<DLSpinnerCenterAligned absolute backgroundOpacity={0.6} />
				)}
				<div className="FR JSB">
					{multipleUsersStatus !== ActionStatus.success && (
						<div className="FR JSB">
							<DLButton
								eleTestId="download-template"
								startIcon={
									<Icon path={mdiArrowDown} size={0.8} />
								}
								clickHandler={() =>
									handleDownloadExcelTemplate(
										template,
										"UsersTemplate"
									)
								}
								// disabled={users.length === 0}
							>
								Download Template
							</DLButton>
							<DLButton
								eleTestId="choose-files-btn"
								startIcon={
									<Icon path={mdiArrowUp} size={0.8} />
								}
								color="primary"
							>
								<label className="file-upload-btn-wrapper">
									Choose A file
									<input
										type="file"
										name="file"
										onChange={handleSelectFilesFromLocal}
										data-testid="file-input"
										accept=".xlsx,.xls"
									/>
								</label>
							</DLButton>
							<div style={{ marginTop: "11px" }}>
								<DLTooltip
									title={
										<div>
											<b>Note :</b>
											<br />
											<span>
												1.Please make sure the sheet
												name is "Sheet1"
											</span>
											<br />
											<span>
												2.Please make sure the column
												names are "type", "email",
												"name", "aliasId", "accessRight"
												and "title"
											</span>
										</div>
									}
									elePlacement="right"
								>
									<Icon
										path={mdiInformationOutline}
										size={1}
									/>
								</DLTooltip>
							</div>
						</div>
					)}
					{multipleUsersStatus !== ActionStatus.success && (
						<div className="FR JSB">
							<DuplicatedUserList
								inputTotal={users.length}
								dupItems={duplicatedItems}
								dupItemsInSheet={duplicatedItemsInSheet}
							/>
						</div>
					)}
				</div>
				<div>
					{multipleUsersStatus === ActionStatus.loading ||
					multipleUsersStatus === ActionStatus.success ? (
						<UserSubmitStatusList
							successCount={partialStore.viewSubmitStatusCount(
								ActionStatus.success
							)}
							failCount={partialStore.viewSubmitStatusCount(
								ActionStatus.fail
							)}
							list={partialStore.viewBulkUserList}
						/>
					) : (
						<BulkAddUsersDialogTable partialStore={partialStore} />
					)}
				</div>
			</StyledDialogContents>
		)
	}
)

const StyledDialogContents = styled.div`
	.section {
	}
	input[type="file"] {
		display: none;
	}
	.header {
		font-weight: bold;
	}
	.FAIL {
		color: red;
	}
	.SUCCESS {
		color: blue;
	}
	.LOADING {
		color: orange;
	}
	.result-row {
		padding: 1rem;
	}
`
