import { types } from "mobx-state-tree"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"
import { ConsoleLog } from "../../../../../components/basic-elements"
import DialogRelatedViews from "./ui-helper-actions/dialog-related-views"

// ---------- common models

const navOpenWidth = 400
const navClosedWidth = 48

export enum ProjTemplateDialog {
	createTemplateGroup = "createTemplateGroupDialog",
	deleteTemplateGroup = "deleteTemplateGroupDialog",
	createTemplate = "createTemplateDialog",
}

const OrgTemplateUiHelper = types
	.model({
		navQueryText: "",
		navWidth: navOpenWidth,
		navOpen: true,
		canDrag: false,
		//
		currentTargetId: "",
		templateClickPoint: ClickPoint,
		templateGroupClickPoint: ClickPoint,
		//
		templateGroupEditMode: false,
		templateEditModeInTreeNav: false,
		templateEditMode: false, // for detail area
		//
		// dialog: NOTE: file tree related dialogs are in the file-control model
		dialogOpenStatus: types.model({
			createTemplateGroupDialog: false,
			deleteTemplateGroupDialog: false,
			createTemplateDialog: false,
		}),
	})
	.actions((self) => ({
		setTemplateGroupEditMode(request?: boolean) {
			self.templateGroupEditMode = request ?? !self.templateGroupEditMode
		},
		setTemplateEditModeInTreeNav(request?: boolean) {
			self.templateEditModeInTreeNav =
				request ?? !self.templateEditModeInTreeNav
		},
		setTemplateEditMode(request?: boolean) {
			self.templateEditMode = request ?? !self.templateEditMode
		},
		setCurrentTargetId(id: string) {
			self.currentTargetId = id
		},
		//
		setTemplateClickPoint(clickPoint: ClickPointProps) {
			self.templateClickPoint = clickPoint
		},
		setTemplateGroupClickPoint(clickPoint: ClickPointProps) {
			self.templateGroupClickPoint = clickPoint
		},
		setNavQueryText(query: string) {
			self.navQueryText = query
		},
		setNavWidth(width: number) {
			self.navWidth = width
		},
		setNavOpen(request?: boolean) {
			if (request !== undefined) {
				self.navOpen = request
			} else {
				self.navOpen = !self.navOpen
			}
			if (self.navOpen === true) {
				self.navWidth = navOpenWidth
			} else {
				self.navWidth = navClosedWidth
			}
		},
		updateNavWidth(value: number) {
			self.navWidth = self.navWidth + value
		},
	}))
	.actions((self) => ({
		// dialog related actions
		setDialogOpen(dialogName: ProjTemplateDialog, request?: boolean) {
			if (self.dialogOpenStatus[dialogName] !== undefined) {
				if (request !== undefined) {
					self.dialogOpenStatus[dialogName] = request
				} else {
					self.dialogOpenStatus[dialogName] =
						!self.dialogOpenStatus[dialogName]
				}
			} else {
				ConsoleLog(
					"This dialog does not exist on the store" + dialogName
				)
			}
		},
	}))
	.views((self) => ({
		get viewNavWidth() {
			return self.navWidth
		},
		get viewNavQuery() {
			return self.navQueryText
		},
	}))
	.views(DialogRelatedViews)

export default OrgTemplateUiHelper
