import React, { useEffect } from "react"
import { DLButton, DLDialog } from "../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import { useHistory } from "react-router-dom"
import { IStateTreeNode, getSnapshot } from "mobx-state-tree"
import ProjectLocationBreadCrumb, {
	BreadCrumbsPathModel,
} from "../../../../components/combined-elements/project-dialogs/ProjectLocationBreadCrumb"

export default observer(function ProjLocationDetailsDialog({
	partialStore,
	view,
}: {
	partialStore: any
	view: "org" | "proj"
}) {
	const history = useHistory()
	// const { cabinetId } = partialStore.projInfo
	const cabinetId =
		view === "org"
			? partialStore.selectedCabinetId
			: partialStore.projInfo.cabinetId

	const projectId =
		view === "org"
			? partialStore.selectedProj
			: partialStore.projInfo.projectId
	// const projTitle =
	// 	view === "org"
	// 		? partialStore.selectedProj
	// 		: partialStore.projInfo.projectId
	localStorage.setItem("highlightedProjectId", projectId)

	useEffect(() => {
		partialStore.getCabinetLocation(cabinetId)
	}, [cabinetId])

	const handleClick = () => {
		// history.push({
		// 	pathname: "/organization/groups",
		// 	state: path,
		// })
		const pathAsNode =
			partialStore.cabinetLocationDetails as IStateTreeNode<
				typeof BreadCrumbsPathModel
			>
		const pathSnapshot = getSnapshot(pathAsNode)
		localStorage.setItem("pathSnapshot", JSON.stringify(pathSnapshot))
		history.push("/organization/groups", pathSnapshot)
	}

	return (
		<DLDialog
			eleTestId="add-proj-user-dialog"
			isOpen={partialStore.openProjLocationDialog}
			setIsOpen={partialStore.setOpenProjLocationDialog}
			showCloseBtn={true}
			dialogTitle="Project Location Details"
			dialogContents={
				<div>
					<ProjectLocationBreadCrumb
						path={partialStore.cabinetLocationDetails}
						setIsOpen={partialStore.setOpenProjLocationDialog}
					/>
				</div>
			}
			cancelBtnText="Cancel"
			maxWidth="sm"
			fullWidth={true}
			showSpinner={
				partialStore.getActionStatus("getCabinetLocation") === "LOADING"
			}
			actionBtn={
				<DLButton
					variant="text"
					eleTestId="go-to-cabinet-btn-on-dialog"
					// startIcon={<Icon path={mdiTrashCan} size={0.8} />}
					clickHandler={handleClick}
					color="primary"
					disabled={
						partialStore.getActionStatus("getCabinetLocation") ===
						"LOADING"
					}
				>
					Go To Cabinet
				</DLButton>
			}
		/>
	)
})

// const BreadCrumbContainer = ({ path, setIsOpen }: { path: BreadCrumbsPathProps }) => {
// const BreadCrumbContainer = ({ path, setIsOpen }: any) => {
// 	const pathLength = path.length
// 	const history = useHistory()

// 	const BreadCrumbsPathModel = types.model({
// 		id: types.string,
// 		name: types.string,
// 		type: types.string,
// 	})

// 	const handleClickCrumb = () => {
// 		const pathAsNode = path as IStateTreeNode<typeof BreadCrumbsPathModel>
// 		const pathSnapshot = getSnapshot(pathAsNode)
// 		localStorage.setItem("pathSnapshot", JSON.stringify(pathSnapshot))
// 		history.push("/organization/groups", pathSnapshot)
// 		setIsOpen(false)
// 	}
// 	return (
// 		<StyledDLBreadCrumbs className="dl-bread-crumbs-2-container FR AC">
// 			{path.map((item: { id: string; name: string }, i: number) => {
// 				const { id, name } = item
// 				const isLastItem = i === pathLength - 1
// 				const crumbNameStyle = isLastItem
// 					? { textDecoration: "underline" }
// 					: {}
// 				return (
// 					<div
// 						className={`FR AC ${isLastItem} ? crumb-container : ""`}
// 						key={id + i}
// 					>
// 						<div
// 							className={`crumb FR AC ${
// 								isLastItem ? "last-crumb" : ""
// 							}`}
// 						>
// 							<span
// 								style={crumbNameStyle}
// 								className={`crumb-name ${
// 									isLastItem ? "click-able" : ""
// 								}`}
// 								onClick={
// 									isLastItem ? handleClickCrumb : undefined
// 								}
// 							>
// 								{name}
// 								{/* Link to={`/organization/groups`}>{name}</Link> */}
// 							</span>
// 						</div>
// 						{!isLastItem && <BreadCrumbsArrow />}
// 					</div>
// 				)
// 			})}
// 		</StyledDLBreadCrumbs>
// 	)
// }
