import React, { useMemo } from "react"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { Link } from "react-router-dom"
import {
	multiSelectFilter,
	dateRangeFilter,
	filterMethodForQCandEP,
	yearFilter,
} from "../../../../library/react-table-utils"
import { DLButton } from "../../../basic-elements/buttons"
import { Icon } from "@mdi/react"
import { mdiDotsHorizontal, mdiLock } from "@mdi/js"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"
import { DLCheckbox } from "@datalobby/components"
import styled from "styled-components"

// function IndeterminateCheckbox({
// 	indeterminate,
// 	className = "",
// 	...rest
// }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
// 	const ref = React.useRef<HTMLInputElement>(null!)

// 	React.useEffect(() => {
// 		if (typeof indeterminate === "boolean") {
// 			ref.current.indeterminate = !rest.checked && indeterminate
// 		}
// 	}, [ref, indeterminate])

// 	return (
// 		<input
// 			type="checkbox"
// 			ref={ref}
// 			className={className + " cursor-pointer"}
// 			{...rest}
// 		/>
// 	)
// }

const NormalProjectsTableColumns = (
	partialStore: any,
	handleContextMenu: any,
	handleCheckbox: (id: string) => void
) => {
	const columns = useMemo(
		() => [
			{
				header: "",
				accessorKey: "id",
				enableColumnFilter: false,
				size: 50,
				cell: (props: any) => {
					const id = props.row.original.id
					const handleClick = () => {
						handleCheckbox(id)
					}

					return (
						<DLCheckbox
							eleTestId={`checkbox-${id}`}
							clickHandler={() => handleClick()}
							color="primary"
							isChecked={id === partialStore.selectedProj}
						/>
					)
				},
				// cell: ({ row }: any) => (
				// 	<div className="px-1">
				// 		<IndeterminateCheckbox
				// 			{...{
				// 				checked: row.getIsSelected(),
				// 				disabled: !row.getCanSelect(),
				// 				indeterminate: row.getIsSomeSelected(),
				// 				onChange: row.getToggleSelectedHandler(),
				// 			}}
				// 		/>
				// 	</div>
				// ),
			},
			{
				header: "Period Type",
				accessorKey: "periodName",
				size: 80,
				filterFn: multiSelectFilter,
				cell: (row: any) => {
					return (
						<div data-testid="period-name-cell">
							{row.getValue()}
						</div>
					)
				},
			},
			{
				header: "Year",
				accessorKey: "financialYear",
				size: 82,
				filterFn: yearFilter,
				cell: (row: any) => {
					return (
						<div data-testid="financial-year-cell">
							{row.getValue()}
						</div>
					)
				},
			},
			{
				header: "Group",
				accessorKey: "groupName",
				filterFn: multiSelectFilter,
				cell: (row: any) => {
					const size = row.column.getSize()
					return (
						<StyledColumn
							data-testid="group-cell"
							style={{ width: size }}
						>
							{row.getValue()}
						</StyledColumn>
					)
				},
			},
			{
				header: "Client",
				accessorKey: "clientName",
				size: 150,
				filterFn: multiSelectFilter,
				cell: (row: any) => {
					const size = row.column.getSize()
					return (
						<StyledColumn
							data-testid="client-cell"
							style={{ width: size }}
						>
							{row.getValue()}
						</StyledColumn>
					)
				},
			},
			{
				header: "Client Alias ID",
				accessorKey: "clientAliasId",
				size: 120,
				cell: (row: any) => {
					const size = row.column.getSize()
					return (
						<StyledColumn
							data-testid="client-id-cell"
							style={{ width: size }}
						>
							{row.getValue()}
						</StyledColumn>
					)
				},
			},
			{
				header: <div className="column-name">Lock Status</div>,
				accessorKey: "lockStatus",
				size: 80,
				cell: (props: any) => {
					const cellId = props.column.id + "-cell"
					return (
						<div data-testid={cellId} className="FR JC">
							{props.row.original.isLocked && (
								<div className="FR AC regular-text">
									<Icon path={mdiLock} size={0.5} />
								</div>
							)}
						</div>
					)
				},
				filterFn: (row: any, columnId: any, value: any) => {
					if (value === "all") return true
					else return row.original.isLocked === (value === "locked")
				},
			},
			{
				header: "Project Name", // NOTE: Keep "project name" for end user
				accessorKey: "title",
				size: 300,
				cell: (props: any) => {
					const projId = props.row.original.id
					const projNumId = idToNumber(projId, IdType.project)
					const projTitle = props.getValue()
					const accessible = props.row.original.accessible
					const size = props.column.getSize()
					return (
						<StyledColumn
							className="project-title-cell"
							data-testid="project-title-cell"
							style={{ fontWeight: 600, width: size }}
						>
							{accessible ? (
								<Link to={`/project/checkin/${projNumId}`}>
									{projTitle}
								</Link>
							) : (
								projTitle
							)}
						</StyledColumn>
					)
				},
			},
			{
				header: "Project ID", // NOTE: Keep "project id" for end user
				accessorKey: "aliasId",
				size: 200,
				cell: (row: any) => {
					const size = row.column.getSize()
					return (
						<StyledColumn
							data-testid="project-aliasId-cell"
							style={{ width: size }}
						>
							{row.getValue()}
						</StyledColumn>
					)
				},
			},
			{
				header: (
					<div className="column-name">
						Project
						<br />
						Version
					</div>
				),
				accessorKey: "version",
				size: 70,
				cell: (props: any) => {
					const projStatus = props.row.original.statusName
					const cellId = props.cell.column.id + "-cell"

					return (
						<div data-testid={cellId}>
							{projStatus}{" "}
							{projStatus !== ProjectStatus.normal
								? " v" + props.getValue()
								: ""}
						</div>
					)
				},
			},
			// Normal project table doesn't show version
			{
				header: "EP",
				accessorKey: "epUsers",
				filterFn: filterMethodForQCandEP,
				cell: (props: any) => {
					const size = props.column.getSize()
					return (
						<StyledColumn
							data-testid="ep-cell"
							style={{ width: size }}
						>
							{props.getValue().length > 0 ? (
								<>
									{props
										.getValue()
										.map((item: any, i: number) => {
											const valueLength =
												props.getValue().length
											return (
												<React.Fragment key={item.name}>
													{item.name}
													{valueLength > 1 &&
														i < valueLength - 1 &&
														", "}
												</React.Fragment>
											)
										})}
								</>
							) : (
								<span>-</span>
							)}
						</StyledColumn>
					)
				},
			},
			{
				header: "QC",
				accessorKey: "qcUsers",
				filterFn: filterMethodForQCandEP,
				cell: (props: any) => {
					const size = props.column.getSize()
					return (
						<StyledColumn
							data-testid="qc-cell"
							style={{ width: size }}
						>
							{props.getValue().length > 0 ? (
								<>
									{props
										.getValue()
										.map((item: any, i: number) => {
											const valueLength =
												props.getValue().length
											return (
												<React.Fragment key={item.name}>
													{item.name}
													{valueLength > 1 &&
														i < valueLength - 1 &&
														", "}
												</React.Fragment>
											)
										})}
								</>
							) : (
								<span>-</span>
							)}
						</StyledColumn>
					)
				},
			},
			{
				header: "Eng Type",
				accessorKey: "engTypeName",
				filterFn: multiSelectFilter,
				cell: (row: any) => {
					const size = row.column.getSize()
					return (
						<StyledColumn
							data-testid="engType-cell"
							style={{ width: size }}
						>
							{row.getValue()}
						</StyledColumn>
					)
				},
			},
			{
				header: (
					<div className="column-name">
						Archive
						<br />
						Date Policy
					</div>
				),
				accessorKey: "archiveDatePolicy",
				size: 82,
				cell: (row: any) => {
					return (
						<div data-testid="archiveDatePolicy-cell">
							{row.getValue()}
						</div>
					)
				},
			},
			{
				header: "Period End Date",
				accessorKey: "periodEndDate",
				size: 100,
				filterFn: dateRangeFilter,
				cell: (row: any) => {
					return (
						<div data-testid="period-end-cell">
							{row.getValue() ? row.getValue() : "-"}
						</div>
					)
				},
			},
			{
				header: (
					<div className="column-name">
						Risk
						<br />
						Assessment
					</div>
				),
				accessorKey: "raName",
				size: 100,
				cell: (row: any) => {
					const size = row.column.getSize()
					return (
						<StyledColumn
							data-testid="risk-assessment-cell"
							style={{ width: size }}
						>
							{row.getValue() ? row.getValue() : "-"}
						</StyledColumn>
					)
				},
			},
			{
				header: (
					<div className="column-name">
						Expected
						<br />
						Report Date
					</div>
				),
				accessorKey: "expectedReportDate",
				size: 100,
				filterFn: dateRangeFilter,
				cell: (row: any) => {
					return (
						<div data-testid="expected-report-date-cell">
							{row.getValue() ? row.getValue() : "-"}
						</div>
					)
				},
			},
			{
				header: (
					<div className="column-name">
						Expected Archive
						<br />
						Deadline Date
					</div>
				),
				accessorKey: "expectedArchiveDate",
				size: 100,
				filterFn: dateRangeFilter,
				cell: (row: any) => {
					return (
						<div data-testid="expected-report-date-cell">
							{row.getValue() ? row.getValue() : "-"}
						</div>
					)
				},
			},
			{
				header: (
					<div className="column-name">
						Final
						<br />
						Report Date
					</div>
				),
				accessorKey: "finalReportDate",
				size: 100,
				filterFn: dateRangeFilter,
				cell: (row: any) => {
					return (
						<div data-testid="final-report-date-cell">
							{row.getValue() ? row.getValue() : "-"}
						</div>
					)
				},
			},
			{
				header: (
					<div className="column-name">
						Final Archive <br />
						Deadline Date
					</div>
				),
				size: 100,
				accessorKey: "finalArchiveDeadlineDate",
				filterFn: dateRangeFilter,
				cell: (row: any) => {
					return (
						<div data-testid="final-archive-deadline-date-cell">
							{row.getValue() ? row.getValue() : "-"}
						</div>
					)
				},
			},
			{
				header: "Last Access Date",
				accessorKey: "lastAccessDate",
				size: 100,
				filterFn: dateRangeFilter,
				cell: (row: any) => {
					return (
						<div data-testid="lastAccessAt-cell">
							{row.getValue() ? row.getValue() : "-"}
						</div>
					)
				},
			},
			{
				header: "Last Access Time",
				accessorKey: "lastAccessTime",
				size: 100,
				cell: (row: any) => {
					return (
						<div data-testid="lastAccessTime-cell">
							{row.getValue() ? row.getValue() : "-"}
						</div>
					)
				},
			},
			{
				header: "Last Access By",
				accessorKey: "lastAccessBy",
				size: 80,
				accessorFn: (props: any) => props.lastAccessBy.name,
				cell: (row: any) => {
					return (
						<div data-testid="lastAccessBy-cell">
							{row.getValue()}
						</div>
					)
				},
			},
			{
				header: "Created Date",
				accessorKey: "createdAt",
				size: 100,
				filterFn: dateRangeFilter,
				cell: (row: any) => {
					return (
						<div data-testid="createdAt-cell">{row.getValue()}</div>
					)
				},
			},
			{
				header: "Created By",
				accessorKey: "createdBy",
				size: 80,
				accessorFn: (props: any) => props.createdBy.name,
				cell: (row: any) => {
					return (
						<div data-testid="createdBy-cell">{row.getValue()}</div>
					)
				},
			},
			{
				header: "Action",
				accessorKey: "actions",
				size: 30,
				cell: (props: any) => {
					const id = props.row.original.id
					const handleClick = (event: any) => {
						partialStore.setSelectedItem({
							id,
						})
						handleContextMenu(event, props.row.original)
					}
					return (
						<div style={{ width: "100%", textAlign: "center" }}>
							<DLButton
								variant="text"
								eleTestId="project-list-context-btn"
								clickHandler={(event: any) =>
									handleClick(event)
								}
							>
								<Icon path={mdiDotsHorizontal} size={0.8} />
							</DLButton>
						</div>
					)
				},
			},
		],
		[]
	)

	return columns
}

export default NormalProjectsTableColumns

export const StyledColumn = styled.div`
	text-overflow: ellipsis;
	overflow: hidden;
	text-wrap: nowrap;
`
