import React from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import { DLContextMenu } from "../../../../../../components/basic-elements"
import ProjTemplateFileCtxMenuOptions from "./file-ctx-menu-options"
import { initialClickPoint } from "../../../../../../common-models/types/dialog.props"
import { PermissionAsObjectProps } from "../../../../../../common-models/permission"

export default observer(function ProjTemplateFileCtxMenu({
	partialStore,
	permission,
}: {
	partialStore: any
	permission: PermissionAsObjectProps
}) {
	const orgStore = useOrgStore()
	const templateStore = orgStore.orgProjTemplates

	const handleClose = () => {
		orgStore.orgProjTemplates.setFileClickPoint(initialClickPoint)
	}

	return (
		<DLContextMenu
			eleId="proj-templates-file-ctx-menus"
			clickPoint={{
				mouseX: orgStore.orgProjTemplates.fileClickPoint.mouseX,
				mouseY: orgStore.orgProjTemplates.fileClickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={ProjTemplateFileCtxMenuOptions(
				templateStore.selectedTemplate.id,
				handleClose,
				permission
			)}
			hasDivider={[1, 3, 4]}
		/>
	)
})
