// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import FileSaver from "file-saver"
import { ConsoleLog } from "../../../../../../components/basic-elements"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - loading, success, fail
 *
 */

const ExportArchiveDiagnosisPdf = (self: any) => ({
	exportArchiveDiagnosisPdf() {
		// 0.
		const actionName = "exportArchiveDiagnosisPdf"
		// 1.
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 999,
			color: MessageColorType.green,
			open: true,
			// autoHide: true,
		})
		// 3.
		/**
		 * PARAMETER or PAYLOAD READY
		 */
		//
		self.downloadArchiveDiagnosisPdf()
			.then((response: any) => {
				if (response.status === 200) {
					ConsoleLog([actionName, "__response ", response])
					// const contentType = response.headers["content-type"]
					//
					const fetchedFile = new Blob([response.data], {
						type: "application/pdf",
					})
					FileSaver.saveAs(fetchedFile, "fileName.pdf") // empty pdf is getting downloaded

					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						// autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default ExportArchiveDiagnosisPdf
