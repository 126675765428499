// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const GetWPReferences = (self: any) => ({
	getWPReferences(projectId: string) {
		// 0. set actionName
		const actionName = "getWPReferences"
		// 1. set response cases
		// 2. set response
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		const clientId = idToNumber(projectId, IdType.project)
		const payload = {
			ClientId: clientId,
			// FisYear: periodEndDate,
			// Lang: "en",
		}

		// 3) request API call to Model
		self.readWPReferences(payload)
			.then((response: any) => {
				if (response) {
					// if success
					if (response.status === 200 && response.data.Status === 1) {
						//
						const fetchedData = response.data
						let wpRefs: any[] = []
						fetchedData.WorkReferences.map((item: any) => {
							wpRefs.push({
								wpRefId: item.WRID.toString(),
								wpRef: item.WorkRef,
								wpRefDesc: item.WorkRefDescription,
							})
						})
						self.setWPReferences(wpRefs)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage:
								"Successfully fetched workpaper references",
							color: MessageColorType.blue,
							open: false,
							autoHide: true,
							autoHideDuration: 3000,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999, // temp
							customMessage: "Failed",
							color: MessageColorType.orange,
							open: true,
							autoHide: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetWPReferences
