import { types } from "mobx-state-tree"
//
import ReadRationale from "./model-actions/read-rationale"
import DeleteRationale from "./model-actions/delete-rationale"
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
import UpdateRationale from "./model-actions/update-rationale"
import { RationaleApiProps } from "./rationales.apis"
import RequestDownloadRationales from "./model-actions/request-download-rationales"

const RationalesModel = ({
	apiRead,
	apiUpdate,
	apiRemove,
	apiDownload,
}: RationaleApiProps) =>
	types
		.model({})
		.actions((self, read = apiRead) => ReadRationale(self, read))
		.actions((self, update = apiUpdate) => UpdateRationale(self, update))
		.actions((self, remove = apiRemove) => DeleteRationale(self, remove))
		.actions((self, download = apiDownload) =>
			RequestDownloadRationales(self, download)
		)
		// common parts
		.actions(CommonModelActions)

export default RationalesModel
