// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const AddPassedAdjustment = (self: any) => ({
	addPassedAdjustment(
		projectId: string,
		ajeName: string,
		ajeType: string,
		wpRefId: string,
		tbId: number
	) {
		// 0. set actionName
		const actionName = "addPassedAdjustment"
		// 1. set response cases
		// 2. set response
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		const projectNumberId = idToNumber(projectId, IdType.project)

		const payload = {
			adjDescription: ajeName,
			adjustmentType: ajeType,

			clientId: projectNumberId,
			// FinancialYear: "2020-09-10"
			tbnameId: tbId,
			// UserID: 861
			wprid: parseInt(wpRefId),
		}

		// 3) request API call to Model
		self.createPassedAdjustment(payload)
			.then((response: any) => {
				if (response) {
					// if success
					if (response.status === 200 && response.data.Status === 1) {
						//
						const fetchedData = response.data
						const leng = self.passedAdjustments.length
						let ajes = {
							ajeDesc: fetchedData.adjDescription,
							ajeType: fetchedData.adjustmentType,
							ajeId: fetchedData.id.toString(),
							extension: fetchedData.Extension,
							isOpen: true, // not getting from BE
							mediaId: fetchedData.mediaId.toString(),
							mediaType: fetchedData.mediaType,
							openedBy: "xxx",
							ajeNumber: leng + 1,
							wpDesc: fetchedData.wpDescription,
							wpRef: fetchedData.wref,
						}
						self.pushItemToPassedAdjustment(ajes)
						self.setAddAdjustmentDialogOpen(false)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage:
								"Successfully added passed adjustments",
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
							autoHideDuration: 3000,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999, // temp
							customMessage: "Failed",
							color: MessageColorType.orange,
							open: true,
							autoHide: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default AddPassedAdjustment
