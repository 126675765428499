// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"

const GetFinancialPresentation = (self: any) => ({
	getFinancialPresentation(
		tbNameId: number,
		year: number
		// projectId: string
	) {
		// 0. set actionName
		const actionName = "getFinancialPresentation"
		// 1. set response cases
		// 2. set response
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		// const clientId = idToNumber(projectId, IdType.project)
		const payload = {
			tbNameId,
			year,
			// clientId,
		}

		// 3) request API call to Model
		self.readFinancialPresentation(payload)
			.then((response: any) => {
				if (response) {
					// if success
					if (response.status === 200) {
						//
						const fetchedData =
							response.data.financialStatementsList

						const fsList: any[] = []

						fetchedData.map((item: any) => {
							// const fsId = item.id.toString()
							item.financlaiGroupsList.map((groupItem: any) => {
								const fgId = groupItem.id.toString()
								groupItem.financialSubGroupsList.map(
									(subGroupItem: any) => {
										const fsgId = subGroupItem.id.toString()
										subGroupItem.leadsheetYearList.map(
											(lsItem: any) => {
												const subGroupList = {
													id: null,
													grandParentId: fgId,
													grandParentName:
														groupItem.name,
													parentId: fsgId,
													parentName:
														subGroupItem.name,
													type: "Lead Sheet",
													name: lsItem.name,
													consolidated: {
														currentYear:
															lsItem.currentYearConsolidation,
														previousYear:
															lsItem.previousYearConsolidation,
														previousYear1:
															lsItem.previousYear1Consolidation,
													},
													combined: {
														currentYear:
															lsItem.currentYear,
														previousYear:
															lsItem.previousYear,
														previousYear1:
															lsItem.previousYear1,
													},
												}
												fsList.push(subGroupList)
											}
										)
									}
								)
							})
						})
						console.log(fsList, "fsList")
						self.setFSList(fsList)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage:
								"Successfully fetched financial presentation",
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
							autoHideDuration: 3000,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999, // temp
							customMessage: "Failed",
							color: MessageColorType.orange,
							open: true,
							autoHide: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetFinancialPresentation
