import React from "react"
import { PageContainer } from "../../../components/app-frame-elements"

export default function CoAList() {
	return (
		<PageContainer fullWidth hasToolbar={false} pageTools={<div />}>
			<p>CoA List Components was removed to restart.</p>
			<p>
				You can find previous component in the 2020 react conversion
				project.
			</p>
		</PageContainer>
	)
}
