import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import findOrgRoleId from "../../../../../temporary-data/org-side/find-org-roleId"
import {
	idToString,
	IdType,
	idToNumber,
} from "../../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const GetGroupUsers = (self: any) => ({
	getGroupUsers(userType: any, groupId: any) {
		// 0.
		const actionName = "getGroupUsers"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. call API
		let type
		if (userType === "internal") {
			type = 1
		}
		if (userType === "external") {
			type = 2
		}
		const params = {
			Type: type,
			groupId: idToNumber(groupId, IdType.group),
		}
		self.readGroupUsers(params)
			.then((response: any) => {
				// console.log(actionName, "__response: ", response)
				if (response.status === 200 && response.data.Status === 1) {
					const defaultUsers = response.data.UsersList
					let reOrganizedUsers: any[] = []
					defaultUsers.map((user: any) => {
						reOrganizedUsers.push({
							id: idToString(user.UserID, IdType.user),
							aliasId: user.UserName,
							name: user.Name,
							email: user.Email,
							orgRole: findOrgRoleId(user.AccessTypeId, 0),
							orgTitle: user.Title,
							status: user.IsGrayed ? "deactivated" : "active",
						})
					})
					if (userType === "internal") {
						self.setInternalGroupUsers(reOrganizedUsers)
					} else {
						self.setExternalGroupUsers(reOrganizedUsers)
					}
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: false,
					})
				} else {
					// set fail case response (200 but fail)
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetGroupUsers
