import { types, applySnapshot } from "mobx-state-tree"
import { Rationale } from "./data-models/rationales.data-models"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
// ---------- response cases
// separated actions
import GetRationales from "./view-model-actions/get-rationales"
import { format, differenceInDays } from "date-fns"
import RemoveRationale from "./view-model-actions/remove-rationale"
import { initialStore } from "./rationales.provider"
import EditRationale from "./view-model-actions/edit-rationale"
import DownloadRationales from "./view-model-actions/download-rationales"
import {
	getTzDate,
	getTzDateAndTime2,
} from "../../../../../library/converters/date-utc-converter"
import { DnTFormatProps } from "../../../../../common-models/types/common-props"

const RationalesViewModel = types
	.model({
		finalReportDate: types.union(types.undefined, types.string), // TODO: variable name need update
		modifiedFilesCount: types.optional(types.number, 0),
		rationalesCount: types.optional(types.number, 0),
		rationaleList: types.array(Rationale),
		rationaleEditDialogOpen: false,
		selectedParentId: "",
		needRefresh: true,
		// ---------- common models
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setFinalReportDate(date: string) {
			// TODO: is Date type better?
			// TODO: variable name need update
			self.finalReportDate = date
		},
		setModifiedFilesCount(count: number) {
			self.modifiedFilesCount = count
		},
		setRationalesCount(count: number) {
			self.rationalesCount = count
		},
		setSelectedParentId(id: string) {
			self.selectedParentId = id
		},
		// control the list
		resetList() {
			self.rationaleList.length = 0
		},
		pushItemToList(item: any) {
			self.rationaleList.push(item)
		},
		resetRationale(itemId: string) {
			const target = self.rationaleList.find(
				(item) => item.rationaleId === itemId
			)
			if (target) {
				target.rationaleDescription = ""
				target.rationaleBy = ""
				target.rationaleAt = ""
				target.rationaleId = "0"
			}
		},
		updateRationaleStore(contents: string, fileId: string) {
			const target = self.rationaleList.find(
				(item) => item.podId === fileId
			)
			if (target) {
				target.rationaleDescription = contents
			}
		},
		setRationaleEditDialogOpen(request?: boolean) {
			self.rationaleEditDialogOpen =
				request ?? !self.rationaleEditDialogOpen
		},
	}))
	.views((self) => ({
		// TODO: variable name need update
		// below one seems not be used
		formattedFinalReportDate(dntFormat?: DnTFormatProps) {
			let formattedDate
			if (dntFormat) {
				formattedDate =
					self.finalReportDate &&
					getTzDate({
						date: self.finalReportDate,
						timeZone: dntFormat.timeZone,
						dateFormat: dntFormat.dateFormat,
					})
			} else {
				formattedDate =
					self.finalReportDate &&
					format(new Date(self.finalReportDate), "MM/dd/yyyy") +
						"(UTC)"
			}

			return formattedDate
		},
		formattedList(dntFormat: DnTFormatProps) {
			let formattedList: any[] = []

			self.rationaleList.map((item) => {
				const formattedModifiedAt = item.modifiedAt
					? getTzDateAndTime2({
							date: item.modifiedAt,
							dntFormat,
					  })
					: ""
				const formattedRationaleAt = item.rationaleAt
					? getTzDateAndTime2({
							date: item.rationaleAt,
							dntFormat,
					  })
					: ""

				formattedList.push({
					...item,
					modifiedAt: formattedModifiedAt,
					rationaleAt: formattedRationaleAt,
				})
			})
			return formattedList
		},
	}))
	.views((self) => ({
		viewRationale(parentId: string, dntFormat: DnTFormatProps) {
			const target = self
				.formattedList(dntFormat)
				.find((item) => item.podId === parentId)
			// ConsoleLog("target", target)
			return target
		},
		get completedRationaleCount() {
			return self.rationaleList.filter(
				(item: any) => item.rationaleId !== "0"
			).length
		},
		get diff() {
			if (self.finalReportDate) {
				const today = new Date()
				const finalReportDate = new Date(self.finalReportDate)
				const diff = differenceInDays(today, finalReportDate) + 1
				return diff
			} else {
				return "Please set the final report date"
			}
		},
		get diffBy() {
			const today = new Date()
			if (self.finalReportDate) {
				const finalReportDate = new Date(self.finalReportDate)
				const diff = differenceInDays(today, finalReportDate) + 1
				return diff
			} else {
				return "Please set the final report date"
			}
		},
		get getRationaleWpLength() {
			return self.rationaleList.filter((item: any) => item.type === "WP")
				.length
		},
		get getRationalePfLength() {
			return self.rationaleList.filter((item: any) => item.type === "PF")
				.length
		},
		viewRationaleFiles() {
			return self.rationaleList
		},
	}))
	// AGER (Add, Get, Edit, Remove)
	.actions(GetRationales)
	.actions(EditRationale)
	.actions(RemoveRationale)
	.actions(DownloadRationales)
	.actions(Refresh)
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default RationalesViewModel
