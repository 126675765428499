import React from "react"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import { PageHeader } from "../../../../../components/app-frame-elements"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import {
	DLSpinnerCenterAligned,
	DLButton,
} from "../../../../../components/basic-elements"
import PostTable from "../../../../../service-modules/post/components/PostTable"
import { Link } from "react-router-dom"
import { Icon } from "@mdi/react"
import { mdiPlus } from "@mdi/js"
import { Sector } from "@datalobby/types"
import NotificationCtxMenu from "../../../../organization-side/notifications/sub-components/NotiListCtxMenu"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

export default observer(function BoardList({
	permission,
	dataStatus,
}: {
	permission: PermissionAsObjectProps
	dataStatus: ActionStatus
}) {
	const store = useRootStore()
	const projStore = useProjStore()
	const defaultUrl = projStore.checkin.defaultUrl
	// const globalNotiStore = store.notis
	const boardStore = projStore.board
	const menuId = DLProjSubMenus.board

	const openCtxMenu = (e: any, rowInfo: any) => {
		e.preventDefault()
		boardStore.setClickPoint({
			mouseX: e.clientX - 2,
			mouseY: e.clientY - 4,
		})
		boardStore.setSelectedItem(rowInfo.original.id)
	}

	const contentsAreaHeight = store.ui.contentsAreaHeight
	const dntFormat = store.global.getDntFormat
	const projBoards = boardStore.viewProjBoardList(dntFormat, [])
	return (
		<>
			<PageHeader noPadding>
				<div>
					Total Notification:{" "}
					<span data-testid="total-noti-count">
						{projBoards.length}
					</span>
				</div>
				{permission.create && (
					<Link to={`${defaultUrl}/note-and-comments/board/add`}>
						<DLButton
							eleTestId="add-noti-btn"
							startIcon={<Icon path={mdiPlus} size={0.8} />}
							color="primary"
						>
							Add
						</DLButton>
					</Link>
				)}
			</PageHeader>
			{dataStatus === ActionStatus.loading && <DLSpinnerCenterAligned />}
			{dataStatus === ActionStatus.success && (
				<PostTable
					data={projBoards}
					userId="10"
					fixToTopCount={10}
					contentsAreaHeight={contentsAreaHeight}
					openCtxMenu={openCtxMenu}
					sector={Sector.proj}
					projDefaultUrl={defaultUrl}
				/>
			)}
			{boardStore.clickPoint.mouseX && (
				<NotificationCtxMenu
					permission={permission}
					partialStore={boardStore}
					sector={Sector.proj}
					menuId={menuId}
				/>
			)}
		</>
	)
})
