import {
	ActionStatus,
	MessageColorType,
	ProgressStatus,
} from "../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../components/basic-elements"
import { DLMenuId } from "../../../../components/combined-elements/file-tree/dl-file-tree-props"
import {
	idToNumber,
	idToString,
	IdType,
} from "../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 */

const GetSignOffLockHistory = (self: any) => ({
	getSignOffLockHistory({
		activityType,
		projId,
		target,
		fileId,
		menuId,
	}: {
		activityType: string
		projId: string
		target: string
		fileId: string
		menuId: DLMenuId
	}) {
		// 0. set actionName
		const actionName = "getSignOffLockHistory"
		// 1.
		// 2. set responses
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. request API call

		const projectId = idToNumber(projId, IdType.project)
		const targetId = idToNumber(fileId, IdType.file)
		if (typeof projectId === "string") {
			ConsoleLog("Invalid project Id..." + projId)
			return
		}
		console.log("getSignOffLockHistory, projNumId:", {
			activityType,
			projectId,
			target,
			targetId,
		})

		self.readSignOffLockHistory(
			activityType,
			projectId,
			target,
			targetId,
			menuId
		)
			.then((response: any) => {
				ConsoleLog([actionName, response.data])
				if (response.status === 200 && response.data.Status === 1) {
					// if success
					// 1) reset the stored list

					const items = response.data.History
					let histories: any[] = []
					items.map((history: any) => {
						histories.push({
							activityName: history.ActivityName,
							attentionStatus:
								history.AttentionStatus === 1
									? ProgressStatus.todo
									: history.AttentionStatus === 2
									? ProgressStatus.inProgress
									: ProgressStatus.done,
							fileId: idToString(history.FileId, IdType.file),
							fileName: history.FileName,
							refName: history.RefNum,
							signOffLock: history.SignOffLock,
							signOffLockModifiedBy: {
								id:
									history.SignOffLockModifiedBy?.UserId.toString() ||
									"",
								aliasId:
									history.SignOffLockModifiedBy?.UserAlias ||
									"",
								name:
									history.SignOffLockModifiedBy?.UserName ||
									"",
								roleId:
									history.SignOffLockModifiedBy?.RoleId.toString() ||
									"",
								roleName:
									history.SignOffLockModifiedBy?.RoleName ||
									"",
								roleAliasName:
									history.SignOffLockModifiedBy?.RoleAlias ||
									"",
								statusInProj:
									history.SignOffLockModifiedBy?.StatusInProject.toString() ||
									"",
								statusInOrg:
									history.SignOffLockModifiedBy?.StatusInOrg.toString() ||
									"",
							},
							signOffLockModifiedDate:
								history.SignOffLockModifiedDate,
							createdDate: history.FileCreatedDate,
							createdBy: {
								id:
									history.HistoryCreatedBy?.UserId.toString() ||
									"",
								aliasId:
									history.HistoryCreatedBy?.UserAlias || "",
								name: history.HistoryCreatedBy?.UserName || "",
								roleId:
									history.HistoryCreatedBy?.RoleId.toString() ||
									"",
								roleName:
									history.HistoryCreatedBy?.RoleName || "",
								roleAliasName:
									history.HistoryCreatedBy?.RoleAlias || "",
								statusInProj:
									history.HistoryCreatedBy?.StatusInProject.toString() ||
									"",
								statusInOrg:
									history.HistoryCreatedBy?.StatusInOrg.toString() ||
									"",
							},
						})
					})

					self.setSignOffLockUnlockHistory(histories)
					// when success
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						customMessage: response.data.Message,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetSignOffLockHistory
