import React from "react"
import { observer } from "mobx-react-lite"
import { DLContextMenu } from "../../../../../../components/basic-elements"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import { initialClickPoint } from "../../../../../../common-models/types/dialog.props"
import {
	DLIcon,
	DLIconName,
	CommonIconSize,
} from "../../../../../../components/basic-elements/icons/common-icons"
import { ConsoleLog } from "../../../../../../components/basic-elements"
import { PermissionAsObjectProps } from "../../../../../../common-models/permission"

export default observer(function ProjTemplateCtxMenu({
	permission,
}: {
	permission: PermissionAsObjectProps
}) {
	const orgStore = useOrgStore()
	const templateStore = orgStore.orgProjTemplates
	const templateId = templateStore.selectedTemplate.id

	const handleClose = () => {
		templateStore.setTemplateClickPoint(initialClickPoint)
	}

	const handleEditTemplate = () => {
		templateStore.setTemplateEditMode(true)
	}

	const handleDuplicateTemplate = () => {
		templateStore.duplicateProjTemplate(templateId)
	}

	const handleDeleteTemplate = () => {
		if (templateId) {
			templateStore.removeProjTemplate(templateId)
		} else {
			ConsoleLog([
				"(ProjTemplateCtxMenu) Cannot find the right click target:",
				templateId,
			])
		}
	}

	const handleDownloadTemplate = () =>
		templateStore.downloadAllAsZip({ templateId })

	return (
		<DLContextMenu
			eleId="proj-template-ctx-menus"
			clickPoint={{
				mouseX: templateStore.templateClickPoint.mouseX,
				mouseY: templateStore.templateClickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={ProjTemplateCtxMenuOptions({
				permission,
				handleEditTemplate,
				handleDeleteTemplate,
				handleDuplicateTemplate,
				handleDownloadTemplate,
			})}
		/>
	)
})

const ProjTemplateCtxMenuOptions = ({
	permission,
	handleEditTemplate,
	handleDeleteTemplate,
	handleDuplicateTemplate,
	handleDownloadTemplate,
}: {
	permission: PermissionAsObjectProps
	handleEditTemplate: () => void
	handleDeleteTemplate: () => void
	handleDuplicateTemplate: () => void
	handleDownloadTemplate: () => void
}) => {
	return [
		{
			label: "Edit Project Template",
			value: "ctx-edit-proj-template",
			available: permission.update,
			clickEvent: () => handleEditTemplate(),
			icon: (
				<DLIcon
					name={DLIconName.edit}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		{
			label: "Duplicate Project Template",
			value: "ctx-duplicate-proj-template",
			available: permission.create,
			clickEvent: () => handleDuplicateTemplate(),
			icon: (
				<DLIcon
					name={DLIconName.duplicate}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		{
			label: "Delete Project Template",
			value: "ctx-remove-proj-template",
			available: permission.delete,
			clickEvent: () => handleDeleteTemplate(),
			icon: (
				<DLIcon
					name={DLIconName.delete}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		{
			label: "Download all as Zip",
			value: "ctx-download-template",
			available: permission.export,
			clickEvent: () => handleDownloadTemplate(),
			icon: (
				<DLIcon
					name={DLIconName.download}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
	]
}
