import React, { useState, useCallback } from "react"
import styled from "styled-components"

import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import { Icon } from "@mdi/react"
import { mdiBookMultiple, mdiDrag, mdiCancel, mdiCheck } from "@mdi/js"

import { DLOrgSubMenus } from "../../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import {
	DLIconButton,
	DLInputField,
} from "../../../../../../components/basic-elements"
import {
	DLIcon,
	DLIconName,
} from "../../../../../../components/basic-elements/icons/common-icons"
import { PermissionAsObjectProps } from "../../../../../../common-models/permission"
import sharedRegEx from "../../../../../../library/sharedRegEx"

export default observer(function TemplateRowController({
	node,
	handleClick,
	handleRightClick,
	handleEdit,
	handleDelete,
	handleDuplicate,
	permission,
}: {
	node: any
	handleClick: (template: {
		id: string
		parentId: string
		title: string
	}) => void
	handleRightClick: (
		e: any,
		template: {
			id: string
			parentId: string
			title: string
		}
	) => void
	handleEdit: ({
		templateId,
		title,
	}: {
		templateId: string
		title: string
	}) => void
	handleDelete: (templateId: string) => void
	handleDuplicate: (templateId: string) => void
	permission: PermissionAsObjectProps
}) {
	const orgStore = useOrgStore()
	const templateStore = orgStore.orgProjTemplates
	const moduleId = DLOrgSubMenus.proj_templates
	//

	const [showButtons, setShowButtons] = useState(false)
	const handleMouseEnter = () => {
		setShowButtons(true)
	}
	const handleMouseLeave = () => {
		setShowButtons(false)
	}
	//
	// const [editMode, setEditMode] = useState(false)
	const setEditMode = (request?: boolean) => {
		templateStore.setTemplateEditMode(request)
	}
	const [editModeTitle, setEditModeTitle] = useState(node.title)
	const handleEditMode = useCallback(
		(e: any, request: boolean, title?: string) => {
			e.preventDefault()
			e.target.focus()

			title && setEditModeTitle(title)
			console.log(title, "title")
			handleClick(node)
			setEditMode(request)
		},
		[]
	)

	const handleRefresh = () => {
		orgStore.orgProjTemplates.getProjTemplateFirstFolders(node.id)
	}

	const downloadAllAsZip = templateStore.downloadAllAsZip

	const templateId = node.id
	const title = node.title
	const rowWidth = orgStore.orgProjTemplates.viewNavWidth - 92
	const selected = orgStore.orgProjTemplates.selectedTemplate.id === node.id

	let canDrag = permission.update && orgStore.orgProjTemplates.canDrag

	let isDuplicatedName =
		orgStore.orgProjTemplates.isDuplicatedTemplateName(editModeTitle)
	let warningMsg = ""
	if (isDuplicatedName) {
		warningMsg = "Same name already exist"
	} else if (
		editModeTitle !== "" &&
		!sharedRegEx.noSpecialCharacters.test(editModeTitle)
	) {
		warningMsg = "Cannot use special characters"
	} else if (
		editModeTitle === "" ||
		editModeTitle.replace(/ /g, "").length === 0
	) {
		warningMsg = "Please input the name"
	}

	const testId = title.toLowerCase().replace(/ /g, "-")

	let editMode = templateStore.templateEditMode
	const currentTargetId = templateStore.selectedTemplate.id

	return (
		<StyledTemplateRow
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			style={{ width: rowWidth }}
			className="template-row"
			data-testid={`template-row-${testId}`}
		>
			{editMode && currentTargetId === templateId ? (
				<div className="FR AC edit-mode">
					<div className="FC">
						<DLInputField
							eleTestId="update-template-title-input"
							eleValue={editModeTitle}
							eleHandleChange={(e: any) =>
								setEditModeTitle(e.target.value)
							}
						/>
						{editModeTitle !== title && (
							<div className="warning-msg">{warningMsg}</div>
						)}
					</div>
					{permission.update && (
						<DLIconButton
							eleTestId="submit-updated-template-name"
							tooltipText="Update"
							tooltipPlacement="top"
							variant="contained"
							clickHandler={() => {
								handleEdit({
									templateId,
									title: editModeTitle,
								})
								setEditMode(false)
							}}
							size="small"
							color="primary"
							disabled={
								isDuplicatedName ||
								editModeTitle === title ||
								editModeTitle.trim() === ""
								// TODO: show warning message when the title is duplicated
							}
						>
							<Icon path={mdiCheck} size={0.6} />
						</DLIconButton>
					)}
					<DLIconButton
						eleTestId="cancel-update-template-name"
						tooltipText="Cancel"
						tooltipPlacement="top"
						clickHandler={(e: any) => handleEditMode(e, false)}
						size="small"
					>
						<Icon path={mdiCancel} size={0.6} />
					</DLIconButton>
				</div>
			) : (
				<div
					className="left-side"
					onClick={() => handleClick(node)}
					onContextMenu={(e: any) => handleRightClick(e, node)}
				>
					{canDrag && (
						<Icon path={mdiDrag} size={0.8} className="drag-icon" />
					)}

					<div className="row-icon">
						<Icon path={mdiBookMultiple} size={0.7} />
					</div>

					<div className="title-text">{title}</div>
				</div>
			)}
			<div className={`FR AC JSB file-row ${selected && "selected"}`}>
				{!editMode && (
					<div className="right-side">
						{showButtons && (
							<div className="buttons-container FR AC">
								<DLIconButton
									eleTestId="refresh-template"
									tooltipText="Refresh Project Template"
									clickHandler={(e: any) => {
										handleRefresh()
									}}
								>
									<DLIcon
										name={DLIconName.refresh}
										noMargin
									/>
								</DLIconButton>
								{permission.update && (
									<DLIconButton
										eleTestId="template-edit-btn-in-tree-nav"
										tooltipText="Edit Template"
										clickHandler={(e: any) => {
											handleEditMode(e, true, title)
										}}
									>
										<DLIcon
											name={DLIconName.edit}
											noMargin
										/>
									</DLIconButton>
								)}
								{permission.create && (
									<DLIconButton
										eleTestId="template-duplicate-btn-in-tree-nav"
										tooltipText="Duplicate Template"
										clickHandler={() =>
											handleDuplicate(templateId)
										}
									>
										<DLIcon
											name={DLIconName.duplicate}
											// size={0.7}
											noMargin
										/>
									</DLIconButton>
								)}
								{permission.delete && (
									<DLIconButton
										eleTestId="template-delete-btn-in-tree-nav"
										tooltipText="Delete Template"
										clickHandler={() =>
											handleDelete(templateId)
										}
									>
										<DLIcon
											name={DLIconName.delete}
											// size={0.7}
											noMargin
										/>
									</DLIconButton>
								)}
								{permission.export && (
									<div>
										<DLIconButton
											eleTestId="download-all-as-zip"
											clickHandler={() => {
												let proceed = window.confirm(
													"Do you want to download all as zip?"
												)
												if (!proceed) {
													return
												} else {
													downloadAllAsZip({
														templateId,
													})
												}
											}}
										>
											<DLIcon
												name={DLIconName.download}
												noMargin
												tooltipText={
													"Download all as ZIP"
												}
											/>
										</DLIconButton>
									</div>
								)}
							</div>
						)}
					</div>
				)}
			</div>
		</StyledTemplateRow>
	)
})

const StyledTemplateRow = styled.div`
	cursor: pointer;
	.title-text {
		margin-left: 0.6rem;
	}
	.button-container {
		opacity: 0;
		transition: 0.4s;
	}
	:hover {
		.button-container {
			opacity: 1;
		}
	}
	.file-row {
		width: 100%;
		.left-side {
			.drag-icon {
			}
			.title-text {
				margin-left: 0.6rem;
			}
		}
		.right-side {
			.buttons-container {
				opacity: 0;
				transition: 0.4s;
			}
		}
		:hover {
			.buttons-container {
				opacity: 1;
			}
		}
		&.selected {
			font-weight: 700;
			color: ${(props) => props.theme.emphasis};
		}
	}
	.warning-msg {
		color: ${(props) => props.theme.warning};
	}
`
