import React from "react"
import { observer } from "mobx-react-lite"
import { PageStoreProvider, usePageStore } from "./EntityInformation.store"
import {
	PageContainer,
	PageHeader,
} from "../../../components/app-frame-elements"

export default observer(function EntityInformation() {
	return (
		<PageStoreProvider>
			<PageContainer
				pageTools={<EntityInfoPageToolbar />}
				fullWidth
				fixedHeight
			>
				<PageHeader>
					<div>Total Entities: </div>
					{/* <DLBasicDialog
							openBtn={
								<DLButton
									variant="text"
									color="primary"
									eleTestId="add subsidiary button"
									startIcon={<Icon path={mdiPlus} size={1} />}
								>
									Add Subsidiary
								</DLButton>
							}
							closeBtnText="Cancel"
							dialogId="xxx"
							dialogContents={<CreateEntityDialogContents />}
							dialogHeaderText="Add a Subsidiary"
							actionBtnText="Add"
							actionHandler={() =>
								alert(
									"Please wait. This function is in progress"
								)
							}
							maxWidth="md"
							fullWidth={true}
						/> */}
				</PageHeader>
			</PageContainer>
		</PageStoreProvider>
	)
})

const EntityInfoPageToolbar = () => {
	const pageStore = usePageStore()

	return (
		<div>
			Filters will come | {pageStore.entityInformationStore.test} |{" "}
		</div>
	)
}

// const columns = (onRemove: any) => {
// 	return [
// 		{
// 			Header: <SortingIconHeader title="Type" />,
// 			accessor: "type",
// 			minWidth: 90,
// 			style: {
// 				paddingLeft: 16,
// 			},
// 			Cell: (row: any) => {
// 				const { type } = row.original
// 				return (
// 					<div>
// 						{/* TODO: Update required */}
// 						{type ? (
// 							<DLChip
// 								eleTestId="egqw"
// 								size="small"
// 								label="Subsidiary"
// 							/>
// 						) : (
// 							<DLChip
// 								eleTestId="egqw"
// 								size="small"
// 								color="primary"
// 								label="Parent"
// 							/>
// 						)}
// 					</div>
// 				)
// 			},
// 		},
// 		{
// 			Header: <SortingIconHeader title="Is Major" />,
// 			accessor: "isMajorSubsidiary",
// 			minWidth: 90,
// 			style: {
// 				paddingLeft: 16,
// 			},
// 			Cell: (row: any) => {
// 				const { isMajorSubsidiary } = row.original
// 				return <div>{isMajorSubsidiary ? "Major" : ""}</div>
// 			},
// 		},
// 		{
// 			Header: <SortingIconHeader title="Name" />,
// 			accessor: "businessName",
// 			minWidth: 180,
// 			Cell: (row: any) => {
// 				const id = row.original.id
// 				return (
// 					<Link
// 						to={`/information/entity-list/${id}`}
// 						style={{
// 							width: "100%",
// 							height: "100%",
// 							display: "flex",
// 							alignItems: "center",
// 						}}
// 					>
// 						{row.value}
// 					</Link>
// 				)
// 			},
// 		},
// 		{
// 			Header: <SortingIconHeader title="Address" />,
// 			accessor: "address",
// 		},
// 		{
// 			Header: <SortingIconHeader title="Main Business" />,
// 			accessor: "mainBusiness",
// 		},
// 		// {
// 		// 	Header: <SortingIconHeader title="Latest FY Ending Balance" />,
// 		// 	accessor: "latestFiscalYearEndingBalance",
// 		// 	Cell: (row: any) => {
// 		// 		return <div>{row.value.toLocaleString()}</div>
// 		// 	}
// 		// },
// 		{
// 			Header: <SortingIconHeader title="Relationship" />,
// 			accessor: "relationship",
// 		},
// 		// {
// 		//   Header: <SortingIconHeader title="Location" />,
// 		//   accessor: "location"
// 		// },
// 		// {
// 		//   Header: <SortingIconHeader title="Currency" />,
// 		//   accessor: "currency",
// 		//   minWidth: 60
// 		// },
// 		// {
// 		//   Header: <SortingIconHeader title="TimeZone" />,
// 		//   accessor: "timeZone",
// 		//   minWidth: 60
// 		// },
// 		{
// 			Header: "T.B.",
// 			accessor: "connectedTB",
// 			minWidth: 50,
// 		},
// 		{
// 			Header: <SortingIconHeader title="CoA" />,
// 			accessor: "connectedCoA",
// 			minWidth: 65,
// 			Cell: (row: any) => {
// 				const coaId = row.row.coaId
// 				return (
// 					<div>
// 						{coaId && (
// 							<Link
// 								to={`/information/coa-list/${coaId}`}
// 								style={{
// 									color: coaId ? "black" : "#e5e5e5",
// 								}}
// 							>
// 								CoA
// 							</Link>
// 						)}
// 					</div>
// 				)
// 			},
// 		},
// 		{
// 			Header: <div className="FR JC">Action</div>,
// 			accessor: "",
// 			minWidth: 50,
// 			Cell: (row: any) => {
// 				const id = row.value.id
// 				const [anchorEl, setAnchorEl] = useState(null)
// 				const handleClick = (event: any) => {
// 					setAnchorEl(event.currentTarget)
// 				}
// 				const handleClose = () => {
// 					setAnchorEl(null)
// 				}

// 				return (
// 					<div>
// 						<DLIconButton
// 							aria-label="more"
// 							clickHandler={handleClick}
// 							eleTestId="entity-information-context-menu"
// 						>
// 							<Icon path={mdiDotsVertical} size={1} />
// 						</DLIconButton>
// 						<Menu
// 							id="entity-list-context-menu"
// 							anchorEl={anchorEl}
// 							keepMounted
// 							open={Boolean(anchorEl)}
// 							onClose={handleClose}
// 						>
// 							<MenuItem onClick={handleClose}>Edit</MenuItem>
// 							<MenuItem onClick={() => onRemove(id)}>
// 								Remove
// 							</MenuItem>
// 						</Menu>
// 					</div>
// 				)
// 			},
// 		},
// 	]
// }
