import React from "react"
import { useLocation } from "react-router-dom"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import { DLI18nProps } from "../../../../common-models/types/common-props"

export default observer(function OrgHeaderTitle({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	let location = useLocation()
	const orgStore = useOrgStore()

	let pathname = ""

	if (
		location.pathname ===
		"/organization/organization-setup/organization-user-list/detail"
	) {
		pathname = "/organization/organization-setup/organization-user-list"
		// NOTE: because this is the only one case working like this, use simple condition. But if the similar case be added more, need to consider better way
	} else {
		pathname = location.pathname
	}

	const items = pathname.split("/")
	const pahtName2 = "/" + items[1] + "/" + items[2]

	const currentMenu = orgStore.checkin.accessibleFlatMenus.find(
		// (menu) => menu.url === pahtName2 // pathname
		(menu) => menu.url === items[2] // pahtName2
	)
	// console.log(
	// 	"orgStore.checkin.accessibleFlatMenus",
	// 	orgStore.checkin.accessibleFlatMenus
	// )
	console.log("currentMenu", currentMenu)

	let currentMainMenuTitle: string | undefined
	const currentSubMenu = currentMenu?.subMenus.find(
		(menu) => menu.url === items[3]
	)

	const currentSubMenuTitle = currentSubMenu?.title
	const currentSubMenuId = currentSubMenu?.id
	console.log(currentSubMenuId, "currentSubMenu")

	if (currentMenu?.menuGroup) {
		// when current menu is not a menu group (when it has parent menu)
		// show the parent menu group title in the header
		const currentMainMenu = orgStore.checkin.accessibleFlatMenus.find(
			(menu) => menu.id === currentMenu.menuGroup
		)
		currentMainMenuTitle = currentMainMenu?.title
	} else {
		// when current menu has no menu group = current menu is menu group itself
		currentMainMenuTitle = currentMenu?.title
	}

	// let menuTitle = "Cannot find menu title"
	// if (currentMenu?.id) {
	// 	menuTitle = i18n[currentMenu?.id]
	// } else if (currentMainMenuTitle) {
	// 	menuTitle = currentMainMenuTitle
	// }

	return (
		<>
			<h2 data-testid="org-header-menu-title">{currentMainMenuTitle}</h2>
			{currentMenu && currentMenu?.subMenus?.length > 1 && (
				<span style={{ fontSize: "1rem", marginLeft: "5px" }}>{`${
					!!currentSubMenuTitle ? `> ${currentSubMenuTitle}` : ""
				}`}</span>
			)}

			{/* {currentMenu?.id !== "Search" && (
                <OrgHeaderRefresh
                    i18n={i18n}
                    currentSubMenuId={
                        currentSubMenuId || currentMenu?.subMenus[0]?.id
                    }
                />
            )} */}
		</>
	)
})
