import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import {
	UploadFilesForm,
	AddNewFileForm,
	AddFileFromLibForm,
	AddTemplateForm,
} from "."
import DLRadioGroup from "../../../../basic-elements/radio-button/DLRadioGroup"
import { DLDialog } from "../../../../basic-elements"
import {
	FileScreenDialog,
	FileUploadFromType,
} from "../../../../../service-modules/file-module/data-models/dl-file-control-model"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { LocalFileProps } from "../../../../../service-modules/file-module/data-models/file.data-props"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { DLFileProps } from "../../../../../service-modules/file-module/data-models/file.data-model"
import { initialDLFileAsFolder } from "../../../../../service-modules/file-module/data-models/initial-file"
import styled from "styled-components"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { getProjIdFromUrl } from "../../../../../library/api-requests/request-get-others"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"

const CreateFileDialog = observer(
	({
		partialStore,
		setLocalFiles,
		localFiles,
		resetLibSelectedItem,
		i18n,
		userRole,
		permission,
	}: {
		partialStore: any
		setLocalFiles: any // to reset the local files when close the dialog
		localFiles: LocalFileProps[]
		resetLibSelectedItem?: () => void
		i18n: DLI18nProps
		userRole: string
		permission: PermissionAsObjectProps
	}) => {
		const dialogName = FileScreenDialog.createFile
		const open = partialStore.fileTreeDialogOpenStatus[dialogName]

		const setOpen = (request: boolean) => {
			partialStore.setFileTreeDialogOpen(dialogName, request)

			if (!request) {
				// when close the dialog,
				// reset the file upload from info
				// reset the selected library item
				setLocalFiles([])
				partialStore.setFileUploadFrom(undefined)
				if (partialStore.storeName !== DLOrgSubMenus.proj_templates) {
					resetLibSelectedItem && resetLibSelectedItem()
				}
			}
		}

		const menuId = partialStore.storeName
		// const folderNotExist = partialStore.folderNotExist

		const getParentFolder = () => {
			const selectedItems = partialStore.selectedItems
			console.log(selectedItems, "selectedFolder")
			let folderId = ""
			if (selectedItems.length === 1) {
				const target = selectedItems[0]
				if (target.isParent) {
					folderId = target.id
				} else if (!target.isParent) {
					folderId = partialStore.getItemInfo(target.id)?.parentId
				}
				return folderId
			} else {
				return null
			}
		}

		console.log(getParentFolder(), "getParentFolder")
		const parentId = getParentFolder()
		// from container
		let projId = ""
		if (partialStore.storeName === DLOrgSubMenus.proj_templates) {
			// Org > Library > Project Template
			projId = partialStore.selectedTemplate.id
		} else {
			// In a Project
			const projIdFromUrl = getProjIdFromUrl()
			if (projIdFromUrl !== null) {
				projId = projIdFromUrl
			} else {
				alert("Cannot find project ID")
			}
		}

		const [folderInfo, setFolderInfo] = useState<DLFileProps | undefined>(
			initialDLFileAsFolder
		)
		const [currentForm, setCurrentForm] = useState("upload")
		const handleFormTypeChange = (event: any) => {
			setCurrentForm(event.target.value)
		}

		useEffect(() => {
			// to prepare the duplication checking of file name and alias ID
			if (parentId) {
				let folder = partialStore.getItemInfo(parentId)
				setFolderInfo(folder)
				if (!folder.expanded) {
					partialStore.getChildrenOfFolder(parentId)
					partialStore.toggleExpanded(parentId, true)
				}
			}
		}, [parentId])

		useEffect(() => {
			if (uploadFrom === FileUploadFromType.temp) {
				setCurrentForm("fileTemplate")
			}
		}, [])

		const uploadFrom = partialStore.fileUploadFrom

		const optionTestIds = {
			upload: "upload-file-from-local",
			add: "add-single-file",
			lib: "add-file-from-lib",
			temp: "add-file-from-temp",
		}

		const optionLabels = {
			upload: i18n.twUploadFiles || "Upload Files",
			add: i18n.twAddANewFile || "Add a New File",
			lib: "from File Library",
			temp: "from Project Template",
		}

		let createFileTabs = [
			{
				value: "upload",
				optionTestId: optionTestIds.upload,
				label: optionLabels.upload,
			},
			{
				value: "individual",
				optionTestId: optionTestIds.add,
				label: optionLabels.add,
			},
			{
				value: "fileTemplate",
				optionTestId: optionTestIds.temp,
				label: "from Project Template",
			},
			// {
			// 	value: "fileLibrary",
			// 	optionTestId: optionTestIds.lib,
			// 	label: optionLabels.lib,
			// },
		]

		if (
			(menuId === DLProjSubMenus.wp &&
				uploadFrom !== FileUploadFromType.temp) ||
			menuId === DLProjSubMenus.pf ||
			menuId === DLOrgSubMenus.proj_templates
		) {
			createFileTabs = createFileTabs.slice(0, 2)
		} else if (uploadFrom === FileUploadFromType.local) {
			createFileTabs = createFileTabs.slice(0, 1)
		} else if (uploadFrom === FileUploadFromType.temp) {
			createFileTabs = createFileTabs.slice(2)
		}

		return (
			<DLDialog
				eleTestId="create-file-dialog"
				isOpen={open}
				setIsOpen={setOpen}
				showOpenBtn={false}
				showCloseBtn={true}
				dialogTitle={i18n.twAddFile || "Add file"}
				dialogContents={
					<StyledCreateFileFormsContainer className="form-container">
						{currentForm !== "fileTemplate" && (
							<DLRadioGroup
								items={createFileTabs}
								selectedValue={currentForm}
								eleOnChange={handleFormTypeChange}
								itemDirection="row"
								eleClassName="create-file-method-radios"
							/>
						)}

						<div className="contents-area">
							{currentForm === "upload" && (
								<UploadFilesForm
									parentInfo={folderInfo}
									localFiles={localFiles}
									partialStore={partialStore}
									i18n={i18n}
									userRole={userRole}
								/>
							)}
							{currentForm === "individual" && (
								<AddNewFileForm
									menuId={menuId}
									projId={projId}
									parentInfo={folderInfo}
									partialStore={partialStore}
									addSingleFile={partialStore.addSingleFile}
									i18n={i18n}
									userRole={userRole}
								/>
							)}
							{currentForm === "fileTemplate" && (
								<AddTemplateForm
									menuId={menuId}
									projId={projId}
									parentInfo={folderInfo}
									partialStore={partialStore}
									folderId={folderInfo?.id}
									i18n={i18n}
									permission={permission}
								/>
							)}
							{currentForm === "fileLibrary" && (
								<AddFileFromLibForm
									menuId={menuId}
									projId={projId}
									parentInfo={folderInfo}
									partialStore={partialStore}
									folderId={folderInfo?.id}
									i18n={i18n}
									permission={permission}
								/>
							)}
						</div>
					</StyledCreateFileFormsContainer>
				}
				cancelBtnText=""
				actionBtn=""
				maxWidth="md"
				fullWidth={false}
				draggable
			/>
		)
	}
)

const StyledCreateFileFormsContainer = styled.div`
	min-height: 400px;
	width: 700px;
	transition: 0.4s;
	.folder-selector-container {
		margin-top: 1rem;
		.folder-label {
			margin-right: 2rem;
		}
		.folder-selector {
			border: 1px solid lightgray;
			border-radius: 4px;
			transition: 0.4s;
			padding: 0.4rem 1rem;
			cursor: pointer;
			.folder-name {
				margin-right: 1rem;
			}
			:hover {
				border: 1px solid black;
			}
		}
	}
	.create-file-method-radios {
		margin-left: 1rem;
	}
	.contents-area {
		/* to contain same height for all tabs  */
		height: calc(80vh - 6rem);
		/* @media (max-height: 699px) {
			height: 80vh;
		}
		@media (min-height: 700px) {
			height: 80vh;
		} */
	}
	.input-section {
		margin-bottom: 0.4rem;
		.label {
			min-width: 10rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`

export default CreateFileDialog
