import React from "react"
import { Link, useHistory } from "react-router-dom"
// stores
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
//
import { DLBadge, ConsoleLog } from "../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiSync } from "@mdi/js"
import styled from "styled-components"
import { DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { useLobbyStore } from "../../../stores/lobby-store/lobby-store.provider"
import { DLI18nTemp } from "../../basic-elements/i18n/DLI18n"
import { MessageColorType } from "../../../common-models/enumerations/common-enums"
import keyboardJS from "keyboardjs"
export interface Props {
	id: string
	title: string
	hasUpdate?: boolean
	url?: string
}

export default function DLOrgTab({ id, title, hasUpdate, url }: Props) {
	const store = useRootStore()
	const lobbyStore = useLobbyStore()
	const orgStore = useOrgStore()
	const history = useHistory()

	/**
	 *
	 * 1. my page
	 * 2. notifications
	 * 3. dashboard
	 * 4. resource mgmt
	 * 5. archive mgmt
	 * 6. governance
	 * 7. projects
	 * 8. library
	 * 9. trashes
	 * 10. setup
	 * 11. org mgmt
	 *
	 */

	const userId = orgStore.checkin.orgUserId

	const refreshActions = [
		// 1. my page
		{
			menuId: DLOrgSubMenus.recent,
			refresh: () => orgStore.recent.refresh(),
		},
		{
			menuId: DLOrgSubMenus.remind_report,
			refresh: () =>
				orgStore.projects.customRefresh(
					DLOrgSubMenus.remind_report,
					true
				),
		},
		{
			menuId: DLOrgSubMenus.remind_archiving,
			refresh: () =>
				orgStore.projects.customRefresh(
					DLOrgSubMenus.remind_archiving,
					true
				),
		},
		{
			menuId: DLOrgSubMenus.admin_remind_report,
			refresh: () =>
				orgStore.projects.customRefresh(
					DLOrgSubMenus.remind_report,
					true
				),
		},
		{
			menuId: DLOrgSubMenus.admin_remind_archiving,
			refresh: () =>
				orgStore.projects.customRefresh(
					DLOrgSubMenus.remind_archiving,
					true
				),
		},
		// Assigned
		{
			menuId: DLOrgSubMenus.assignedNormal,
			refresh: () =>
				orgStore.projects.customRefresh(
					DLOrgSubMenus.assignedNormal,
					true
				),
		},
		{
			menuId: DLOrgSubMenus.assignedReplica,
			refresh: () =>
				orgStore.projects.customRefresh(
					DLOrgSubMenus.assignedReplica,
					true
				),
		},
		{
			menuId: DLOrgSubMenus.assignedForceArchivedProjects,
			refresh: () =>
				orgStore.projects.customRefresh(
					DLOrgSubMenus.assignedForceArchivedProjects,
					true
				),
		},
		{
			menuId: DLOrgSubMenus.assignedArchived,
			refresh: () =>
				orgStore.projects.customRefresh(
					DLOrgSubMenus.assignedArchived,
					true
				),
		},
		{
			menuId: DLOrgSubMenus.assignedUnarchived,
			refresh: () =>
				orgStore.projects.customRefresh(
					DLOrgSubMenus.assignedUnarchived,
					true
				),
		},
		{
			menuId: DLOrgSubMenus.assignedArchivedReadonlyProjects,
			refresh: () =>
				orgStore.projects.customRefresh(
					DLOrgSubMenus.assignedArchivedReadonlyProjects,
					true
				),
		},
		// 2. notifications
		{
			menuId: DLOrgSubMenus.org_noti,
			refresh: () => {
				store.notis.refresh()
				orgStore.noti.refresh()
			},
		},
		// 3. dashboard
		{
			menuId: DLOrgSubMenus.dash_table_summary,
			refresh: () => orgStore.orgDashboard.refresh(),
		},
		{
			menuId: DLOrgSubMenus.dash_chart,
			refresh: () => orgStore.orgDashboard.refresh(),
		},
		{
			menuId: DLOrgSubMenus.dash_graph,
			refresh: () => orgStore.orgDashboard.refresh(),
		},
		// 4. resource mgmt
		// 5. archive mgmt
		{
			menuId: DLOrgSubMenus.am_force_archived,
			refresh: () =>
				orgStore.archiveMgmt.setNeedRefreshForForceArchive(true),
		},
		{
			menuId: DLOrgSubMenus.archived_mgmt,
			refresh: () => orgStore.archiveMgmt.refresh(),
		},
		{
			menuId: DLOrgSubMenus.unarchived_mgmt,
			refresh: () => orgStore.unarchiveMgmt.refresh(),
		},
		// 6. governance (groups)
		{
			menuId: DLOrgSubMenus.groups,
			refresh: () => orgStore.groups.refresh(),
		},
		//
		{
			menuId: DLOrgSubMenus.online_projects,
			refresh: () => orgStore.reportsMgmt.refresh(),
		},
		// 7. projects
		{
			menuId: DLOrgSubMenus.normal_projs,
			refresh: () => {
				orgStore.projects.customRefresh(
					DLOrgSubMenus.normal_projs,
					false
				)
			},
		},
		{
			menuId: DLOrgSubMenus.replicas,
			refresh: () => {
				orgStore.projects.customRefresh(DLOrgSubMenus.replicas, false)
			},
		},
		{
			menuId: DLOrgSubMenus.force_archived,
			refresh: () => {
				orgStore.projects.customRefresh(
					DLOrgSubMenus.force_archived,
					false
				)
			},
		},
		{
			menuId: DLOrgSubMenus.archived,
			refresh: () => {
				orgStore.projects.customRefresh(DLOrgSubMenus.archived, false)
			},
		},
		{
			menuId: DLOrgSubMenus.unarchived,
			refresh: () => {
				orgStore.projects.customRefresh(DLOrgSubMenus.unarchived, false)
			},
		},
		// 8. library
		{
			menuId: DLOrgSubMenus.proj_templates,
			refresh: () => orgStore.orgProjTemplates.refresh(),
		},
		{
			menuId: DLOrgSubMenus.file_lib,
			refresh: () => orgStore.orgFileLib.refresh(),
		},
		// 9. trashes
		{
			menuId: DLOrgSubMenus.file_trash,
			refresh: () => orgStore.trash.refresh(),
		},
		// 10. setup
		{
			menuId: DLOrgSubMenus.setup_users,
			refresh: () => orgStore.setupUsers.refresh(),
			// refresh: () => orgStore.setupUsers.refresh(userId),
		},
		{
			menuId: DLOrgSubMenus.setup_groups,
			refresh: () => orgStore.setupGroups.refresh(),
		},
		{
			menuId: DLOrgSubMenus.setup_clients,
			refresh: () => orgStore.setupClients.refresh(),
		},
		{
			menuId: DLOrgSubMenus.setup_eng_types,
			refresh: () => orgStore.setupEngTypes.refresh(),
		},
		{
			menuId: DLOrgSubMenus.setup_risk,
			refresh: () => orgStore.setupRiskAssessment.refresh(),
		},
		{
			menuId: DLOrgSubMenus.setup_arch_policy,
			refresh: () => orgStore.archPolicy.refresh(),
		},
		{
			menuId: DLOrgSubMenus.setup_sys,
			refresh: () => orgStore.setupDateAndTimeSetting.refresh(),
		},
		//
		{
			menuId: DLOrgSubMenus.readonly_users,
			refresh: () => orgStore.setupUsers.refresh(),
		},
		{
			menuId: DLOrgSubMenus.readonly_groups,
			refresh: () => orgStore.setupGroups.refresh(),
		},
		{
			menuId: DLOrgSubMenus.readonly_clients,
			refresh: () => orgStore.setupClients.refresh(),
		},
		// 11. org Mgmt
		{
			menuId: DLOrgSubMenus.org_mgmt,
			refresh: () => lobbyStore.orgMgmt.refresh(),
		},
	]

	const handleRefresh = (event: any) => {
		event.preventDefault()
		ConsoleLog("DLOrgTab, handleRefresh", id)
		const refreshAction = refreshActions.find((item) => item.menuId === id)
		if (refreshAction) {
			refreshAction.refresh()
		}
	}

	return (
		<StyledOrgDLTab
			className="org-tab"
			onClick={() => history.push(url ? url : "/empty-url")}
		>
			{/* Do not set test-id here. selected tab is controlled on controller */}
			<DLBadge
				content={0}
				eleTestId="org-tab-update-count"
				color={MessageColorType.orange}
			>
				<Link
					to={url ? url : "/empty-url"}
					onClick={() => keyboardJS.pause()}
				>
					<DLI18nTemp
						eleClassName="text-label FR AC JC"
						value={title}
						defaultValue={title}
						id={id}
					/>
					{/* <div className="text-label">{title}</div> */}
				</Link>
			</DLBadge>
			<div
				className="refresh-button FC AC"
				onClick={handleRefresh}
				data-testid={`refresh-btn-on-tab-${id}`}
			>
				<Icon className="update-icon" path={mdiSync} size={0.8} />
			</div>
		</StyledOrgDLTab>
	)
}

const StyledOrgDLTab = styled.div`
	width: 100%;
	font-weight: var(--font-bold);
	min-height: var(--standard-unit);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	transition: 0.4s;
	.text-label {
		/* border: 1px solid orange; */
		padding-left: 6px;
		height: 32px;
	}
	.refresh-button {
		/* border: 1px solid red; */
		width: 1rem;
		margin-right: 0.5rem;
		// overflow: hidden;
		// transition: 0.4s;
		margin-left: var(--spacing-rg);
		// svg.update-icon {
		// 	transition: 0.4s;
		// 	opacity: 0;
		// 	fill: ${(props) => props.theme.textHighEmphasis};
		// }
	}
	// :hover {
	// 	color: ${(props) => props.theme.textHighEmphasis};
	// 	font-weight: var(--font-medium);
	// 	.refresh-button {
	// 		width: 1rem;
	// 		svg.update-icon {
	// 			opacity: 1;
	// 		}
	// 		:hover {
	// 			svg.update-icon {
	// 				fill: ${(props) => props.theme.textHighEmphasis};
	// 			}
	// 		}
	// 	}
	// }
`
