import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog, ConsoleLog } from "../../../../../components/basic-elements"
import { ProjectInfoContainer } from "./ProjectInfoContainer"
import PreProcessPDF from "./PreProcessPDF"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"

export default observer(function PreProcessDialog({ partialStore }: any) {
	const store = useRootStore()
	// const partialStore = orgStore.archiveMgmt

	const dnt = store.global.getDntFormat
	const projectId = partialStore.selectedProj
	const projectInfo = partialStore.getClientInfoById(
		projectId,
		dnt.dateFormat,
		dnt.timeZone,
		dnt.timeFormat
	)

	console.log(partialStore.preConditionData, "projectInfo")

	useEffect(() => {
		partialStore.setPreConditionData({
			projectSize: "",
			isPreProcessed: false,
			preProcessFiles: [],
		})
		// to hide the pdf file list
		partialStore.responses.setResponse("getPreConditionStatus", {
			actionName: "getPreConditionStatus",
			status: ActionStatus.standby,
		})

		partialStore.responses.setResponse("regenPreProcessPdfs", {
			actionName: "regenPreProcessPdfs",
			status: ActionStatus.standby,
		})

		partialStore.getPreConditionStatus(projectId)
	}, [])

	const actionStatus = partialStore.getActionStatus
	const preProcessStatus = partialStore.preConditionData.isPreProcessed

	ConsoleLog("preProcessStatus " + preProcessStatus)
	return (
		<DLDialog
			eleTestId="preprocess-dialog"
			isOpen={partialStore.preProcessDialog}
			setIsOpen={partialStore.setPreProcessDialog}
			showCloseBtn={true}
			dialogTitle="Pre-Process PDF"
			dialogContents={
				<PreProcessDialogForm
					projInfo={projectInfo}
					partialStore={partialStore}
				/>
			}
			maxWidth="sm"
			fullWidth={true}
			cancelBtnText="Cancel"
			showSpinner={
				actionStatus("regenPreProcessPdfs") === "LOADING" ||
				actionStatus("getPreConditionStatus") === "LOADING"
			}
			cannotUpdate={
				actionStatus("regenPreProcessPdfs") === "LOADING" ||
				actionStatus("getPreConditionStatus") === "LOADING"
			}
			// NOTE: Use the action button on the PreProcessPDF component
		/>
	)
})

const PreProcessDialogForm = ({
	projInfo,
	partialStore,
}: {
	projInfo: any
	partialStore: any
}) => {
	return (
		<div>
			<ProjectInfoContainer projInfo={projInfo} />
			<div style={{ height: "3rem" }} />
			<PreProcessPDF
				partialStore={partialStore}
				selectedProj={partialStore.selectedProj}
			/>
			<div style={{ height: "3rem" }} />
		</div>
	)
}
