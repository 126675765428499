import React, { ReactNode, useState, useEffect } from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DLButton from "../buttons/DLButton"
import {
	ActionStatus,
	MessageColorType,
} from "../../../common-models/enumerations/common-enums"
import styled from "styled-components"
import Paper, { PaperProps } from "@material-ui/core/Paper"
import Draggable from "react-draggable"
import Icon from "@mdi/react"
import { mdiClose, mdiTrashCan } from "@mdi/js"
import DLSpinner from "../spinner/DLSpinner"
import DLSystemMsg from "../system-msg/DLSystemMsg"
import DLIconButton from "../buttons/DLIconButton"
import DLSpinnerCenterAligned from "../spinner/DLSpinnerCenterAligned"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import keyboardJS from "keyboardjs"
import { CommonIconSize, DLIcon, DLIconName } from "../icons/common-icons"

interface DialogProps {
	isOpen?: boolean
	setIsOpen?: React.EventHandler<any>
	handleAction?: React.EventHandler<any>
	actionReady?: boolean
	actionStatus?: ActionStatus

	showOpenBtn?: boolean
	showCloseBtn?: boolean
	dialogTitle: string | ReactNode
	dialogContents: ReactNode
	// TODO: Select ReactNode or String
	// dialogError?: ReactNode
	dialogError?: string
	draggable?: boolean

	openBtn?: ReactNode
	cancelBtnText?: string
	actionBtn?: ReactNode | string
	actionBtnType?: "delete" | "restore"

	maxWidth?: WidthTypes
	fullWidth?: boolean
	showSpinner?: boolean
	eleTestId: string
	closeAction?: any

	cannotUpdate?: boolean
	useEnterKeyForSubmit?: boolean
	eleClassName?: string
}

type WidthTypes = "xs" | "sm" | "md" | "lg" | "xl"

function PaperComponent(props: PaperProps) {
	return (
		<Draggable cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	)
}

// [Documentation]
/**
 * ### Note
 * There is an problem for storybook.
 * <br/>Because storybook do not allow to use state in the functional component,
 * <br/>there are no example for remote button usage
 *
 * TODO:
 * What is the purpose of the 'fullWidth' property?
 *
 * TODO:
 * Add padding-right of this dialog (it does not reflect 'button' type button size now)
 */

export default function DLDialog({
	isOpen = undefined,
	setIsOpen = undefined,
	handleAction = undefined,
	actionReady = undefined,
	actionStatus,

	showOpenBtn = true,
	showCloseBtn = false,
	dialogTitle = undefined,
	dialogContents = undefined,
	dialogError = undefined,
	draggable = false,

	openBtn = undefined,
	cancelBtnText = undefined,
	actionBtn = undefined,
	actionBtnType,

	maxWidth = undefined,
	fullWidth = true,
	showSpinner = false,
	eleTestId = "testId-DLDialog",
	closeAction,

	cannotUpdate,
	useEnterKeyForSubmit = false,
	eleClassName,
}: DialogProps) {
	// when 'isOpen' and 'setIsOpen' is not provided
	// NOTE: If you need to close dialog automatically with API response, do not use this internalOpen state
	// 'internalOpen' state is for simple dialog which don't need additional actions.
	const [internalOpen, setInternalOpen] = useState(false)

	useEffect(() => {
		keyboardJS.pause()
	}, [])

	const handleOpen = () => {
		// seems not be used
		keyboardJS.pause()
		if (setIsOpen) {
			setIsOpen(true)
		}
		setInternalOpen(true)
	}

	const handleClose = (event: any) => {
		if (setIsOpen) {
			setIsOpen(false)
		}
		setInternalOpen(false)
		closeAction && closeAction()
	}

	const handleOnKeyDown = (event: any) => {
		if (event.keyCode === 13 && useEnterKeyForSubmit) {
			// 13 is enter key
			if (actionReady !== undefined) {
				actionReady && handleAction && handleAction(event)
			} else {
				handleAction && handleAction(event)
			}
		}
	}

	return (
		<div onKeyDown={(e: any) => handleOnKeyDown(e)}>
			{showOpenBtn && (
				<div className="open-btn" onClick={handleOpen}>
					{openBtn}
				</div>
			)}

			<Dialog
				// id={}
				data-testid={eleTestId}
				open={isOpen ? isOpen : internalOpen}
				onClose={(e: any) => {
					keyboardJS.resume()
					handleClose(e)
				}}
				PaperComponent={draggable ? PaperComponent : undefined}
				// aria-labelledby="draggable-dialog-title"
				// className="dialog-container"
				maxWidth={maxWidth}
				fullWidth={fullWidth}
				className={eleClassName} // @Noah: is this working..???
			>
				<StyledForBgColor>
					{/* TODO: spinner update is requried  */}

					{cannotUpdate && (
						<div
							className="restrict-update"
							style={{
								width: "100%",
								height: "100%",
								backgroundColor: "white",
								opacity: 0.4,
								position: "absolute",
								zIndex: 990,
							}}
						/>
					)}

					<DialogTitle
						className="dl-dialog-header"
						data-testid="dl-dialog-header"
						style={{ cursor: draggable ? "move" : "" }}
					>
						<div className="FR AC JSB">
							{typeof dialogTitle === "object" ? (
								<>{dialogTitle}</>
							) : (
								<div className="FR AC">
									{actionBtnType === "delete" && (
										<DLIcon
											name={DLIconName.delete}
											color="warning"
											size={
												CommonIconSize.dialogHeaderIcon
											}
										/>
									)}
									{dialogTitle}
								</div>
							)}{" "}
							{showCloseBtn && (
								<DLIconButton
									clickHandler={handleClose}
									eleTestId="dialog-close-btn"
									// style={{ cursor: "pointer", zIndex: 999 }}
								>
									<Icon path={mdiClose} size={1} />
								</DLIconButton>
							)}
						</div>
					</DialogTitle>

					{showSpinner && <DLSpinnerCenterAligned absolute />}

					<DialogContent
						className="dl-dialog-content"
						data-testid="dl-dialog-content"
						// style={{ padding: "0 1rem" }}
					>
						{dialogContents}
					</DialogContent>

					{dialogError && (
						<StyledErrorMsg className="dialog-error-msg FR AC">
							<DLSystemMsg
								type={MessageColorType.red}
								msg={dialogError}
							/>
						</StyledErrorMsg>
					)}

					<DialogActions
						className="dl-dialog-actions"
						style={{
							justifyContent: "space-between",
							paddingBottom: "1rem",
						}}
					>
						{cancelBtnText && (
							<DLButton
								variant="text"
								eleTestId="dialog-cancel-btn"
								clickHandler={handleClose}
								disabled={cannotUpdate}
							>
								{cancelBtnText}
							</DLButton>
						)}
						<>
							{typeof actionBtn === "object" ? (
								<>{actionBtn}</>
							) : (
								actionBtn &&
								(actionStatus === ActionStatus.loading ? (
									<DLSpinner />
								) : (
									<DLButton
										eleTestId={`dialog-action-btn-submit`}
										clickHandler={handleAction}
										color={
											actionBtnType === "delete"
												? "warning"
												: "primary"
										}
										// variant={
										// 	actionBtnType === "delete"
										// 		? "text"
										// 		: "contained"
										// }
										disabled={!actionReady || cannotUpdate}
										startIcon={
											actionBtnType === "delete" ? (
												<DLIcon
													name={DLIconName.delete}
													noMargin
												/>
											) : undefined
										}
									>
										{actionBtn}
									</DLButton>
								))
							)}
						</>
					</DialogActions>
				</StyledForBgColor>
			</Dialog>
		</div>
	)
}

const StyledForBgColor = styled.div`
	background-color: ${(props) => props.theme.background};
	color: ${(props) => props.theme.textMediumEmphasis};
`

const StyledErrorMsg = styled.div`
	padding: 8px 24px;
	color: red;
	svg {
		fill: red;
	}
`

export const DeleteBtnForDialog = (deleteAction: any, disabled: boolean) => {
	const store = useRootStore()
	const i18n = store.i18n.globalI18n("DLDialog")
	return (
		<DLButton
			variant="text"
			eleTestId="delete-btn-on-dialog"
			startIcon={<Icon path={mdiTrashCan} size={0.8} />}
			clickHandler={deleteAction}
			color="warning"
			disabled={disabled}
		>
			{i18n.twDelete || "Delete"}
		</DLButton>
	)
}
