import { types } from "mobx-state-tree"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"

const OrgSetupRiskAssessmentUiHelper = types
	.model({
		selectedRiskAssessment: types.string,
		clickPoint: ClickPoint,
		// dialogs
		addRiskAssessmentDialogOpen: false,
		editRiskAssessmentDialogOpen: false,
		removeRiskAssessmentDialogOpen: false,
		cannotRemoveDialogOpen: false,
	})
	.actions((self) => ({
		setSelectedRiskAssessment(riskAssessmentId: string) {
			self.selectedRiskAssessment = riskAssessmentId
		},
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		// dialogs
		setAddRiskAssessmentDialogOpen(request?: boolean) {
			self.addRiskAssessmentDialogOpen =
				request || !self.addRiskAssessmentDialogOpen
		},
		setEditRiskAssessmentDialogOpen(request?: boolean) {
			self.editRiskAssessmentDialogOpen =
				request || !self.editRiskAssessmentDialogOpen
		},

		setRemoveRiskAssessmentDialogOpen(request?: boolean) {
			self.removeRiskAssessmentDialogOpen =
				request || !self.removeRiskAssessmentDialogOpen
		},
		setCannotRemoveDialogOpen(request?: boolean) {
			self.cannotRemoveDialogOpen =
				request || !self.cannotRemoveDialogOpen
		},
	}))

export default OrgSetupRiskAssessmentUiHelper
