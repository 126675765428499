import React, { useEffect, useState } from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import styled from "styled-components"
import { DLSpinner, ConsoleLog } from "../../../../../components/basic-elements"
import {
	ProjectInfoContainer,
	ProjectUsersContainer,
} from "../../../../../components/combined-elements/project-dialogs/ProjectDetailsForm"
import { Tabs, Tab } from "@material-ui/core"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"
import { requestToken } from "../../../../project-side/proj-access-control/store/proj-checkin.apis"
import { saveLatestProjToken } from "../../../../../library/token-utils"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"

enum TABS {
	INFO = 0,
	USERS = 1,
}

export default observer(function ProjectInfo({
	projectId,
	projectInfo,
	height,
}: {
	projectId: string
	projectInfo: any
	height: number | string
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()

	const [actionStatus, setActionStatus] = useState<ActionStatus>(
		ActionStatus.standby
	)

	const actionName = "getProjInfo"
	const moduleId = "OrgArchMgmt"

	const { accessRight, expirationDate, assignedRole } = projectInfo.detail

	const getProjectInfo = () => {
		const userNumId = idToNumber(orgStore.checkin.orgUserId, IdType.user)
		const projNumId = idToNumber(projectId, IdType.project)
		setActionStatus(ActionStatus.loading)
		if (typeof projNumId === "number" && typeof userNumId === "number") {
			requestToken({ UserId: userNumId, ClientId: projNumId }).then(
				(response: any) => {
					if (response.data.status.code === 200) {
						saveLatestProjToken(response.data.data.access_token)
						setActionStatus(ActionStatus.success)
						orgStore.projects.getProjInfo(projectId)
					} else {
						setActionStatus(ActionStatus.fail)
						ConsoleLog(
							"Cannot request the project info. Failed to request project token"
						)
					}
				}
			)
		} else {
			ConsoleLog(
				"Cannot request the project info. Project ID or User ID is incorrect."
			)
		}
	}

	const getCabinetLocationDetails = () => {
		const { parentId: cabinetId } = projectInfo
		orgStore.projects.getCabinetLocation(cabinetId)
	}

	useEffect(() => {
		// orgStore.projects.setProjectDetails(initialProjectDetail) //Reset to initial
		getProjectInfo()
		orgStore.projects.getProjUsersAndFileCount(projectId)
		getCabinetLocationDetails()
	}, [projectId])

	const [selectedTab, setSelectedTab] = useState<TABS>(TABS.INFO)

	const i18n = store.i18n.combineI18n(moduleId)
	const dateFormat = store.global.dateFormat.value
	const timeZone = store.global.timeZone.uiValue
	const fetchingStatus = orgStore.projects.getActionStatus(actionName)
	return (
		<>
			{(fetchingStatus === "LOADING" || actionStatus === "LOADING") && (
				<div
					className="FR AC JC"
					style={{ width: "100%", height: height }}
				>
					<DLSpinner />
				</div>
			)}
			{fetchingStatus === "SUCCESS" && actionStatus === "SUCCESS" && (
				<StyledProjectInfo data-testid="project-info">
					<div className="my-info-container">
						<div className="my-info">
							<span className="label">My Access Right:</span>{" "}
							{accessRight}
						</div>
						<div className="my-info">
							<span className="label">My Role: </span>
							{assignedRole}
						</div>
					</div>
					{expirationDate && (
						<p>My Access Expiration: {expirationDate}</p>
					)}
					<Tabs
						value={selectedTab}
						onChange={(e: any, value: any) =>
							setSelectedTab(
								selectedTab === TABS.INFO
									? TABS.USERS
									: TABS.INFO
							)
						}
						className="proj-info-tabs"
						indicatorColor="primary"
						textColor="primary"
					>
						<Tab
							label="Information"
							data-testid="proj-info-tab"
							className={
								"proj-info-tab " +
								(selectedTab === TABS.INFO ? "selected" : "")
							}
						/>
						<Tab
							label="Project Users"
							data-testid="proj-users-tab"
							className={
								"proj-info-tab " +
								(selectedTab === TABS.USERS ? "selected" : "")
							}
						/>
					</Tabs>
					{selectedTab === TABS.INFO && (
						<ProjectInfoContainer
							projectDetails={orgStore.projects.formattedProjectDetails(
								dateFormat,
								timeZone
							)}
							i18n={i18n}
							locationDetails={
								orgStore.projects.cabinetLocationDetails
							}
							partialStore={orgStore.projects}
						/>
					)}
					{selectedTab === TABS.USERS && (
						<ProjectUsersContainer
							projectUserDetails={orgStore.projects.viewProjectUsers()}
							i18n={i18n}
						/>
					)}
				</StyledProjectInfo>
			)}
		</>
	)
})

const StyledProjectInfo = styled.div`
	.my-info-container {
		padding-left: 1rem;
		padding-top: 1rem;
		.my-info {
			margin-bottom: 0.4rem;
			.label {
				font-weight: 600;
			}
		}
	}
	.proj-info-tabs {
		min-height: 2rem;
		.proj-info-tab {
			min-width: 50%;
			border-top: 1px solid transparent;
			border-right: 1px solid transparent;
			border-left: 1px solid transparent;
			border-bottom: 1px solid ${(props) => props.theme.emphasis};
			min-height: 2rem;
			border-top-right-radius: 3px;
			border-top-left-radius: 3px;
			background-color: ${(props) => props.theme.shade05};
			font-size: 0.75rem;
			overflow: hidden;
			color: ${(props) => props.theme.textMediumEmphasis};
			&.selected {
				color: ${(props) => props.theme.emphasis};
				font-weight: ${(props) => props.theme.shared.fontBold};
				border-top: 1px solid ${(props) => props.theme.emphasis};
				border-right: 1px solid ${(props) => props.theme.emphasis};
				border-left: 1px solid ${(props) => props.theme.emphasis};
				border-bottom: 1px solid transparent;
				background-color: ${(props) => props.theme.background};
			}
			span {
				/* for the border bottom highlighter */
				background-color: transparent;
			}
		}
	}

	.info-table {
		margin-left: -1rem;
		padding-right: 1rem;
	}
`
