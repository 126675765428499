import React, { useEffect, useState } from "react"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { DLDialog, ConsoleLog } from "../../../components/basic-elements"
import ProjectDetailsForm from "./ProjectDetailsForm"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { initialProjectDetail } from "../../../service-modules/project-module/data-model/project-detail.data-model"
import { saveLatestProjToken } from "../../../library/token-utils"
import { requestToken } from "../../../screens/project-side/proj-access-control/store/proj-checkin.apis"
import { idToNumber, IdType } from "../../../library/converters/id-converter"

enum TABS {
	INFO = 0,
	USERS = 1,
}

export default observer(function ProjectDetailsDialog({
	partialStore,
}: {
	partialStore: any
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()

	const actionName = "getProjectDetails"
	const moduleId = "OrgArchMgmt"

	const [selectedTab, setSelectedTab] = useState<TABS>(TABS.INFO)

	const getProjectInfo = () => {
		const userNumId = idToNumber(orgStore.checkin.orgUserId, IdType.user)
		const projNumId = idToNumber(partialStore.selectedProj, IdType.project)

		if (typeof projNumId === "number" && typeof userNumId === "number") {
			requestToken({ UserId: userNumId, ClientId: projNumId }).then(
				(response: any) => {
					if (response.data.status.code === 200) {
						saveLatestProjToken(response.data.data.access_token)
						orgStore.projects.getProjInfo(
							partialStore.selectedProj,
							() => {}
						)
					} else if (response.data.status.code === 0) {
						orgStore.projects.getProjectDetails(
							partialStore.selectedProj
						)
					} else {
						ConsoleLog(
							"Cannot request the project info. Failed to request project token"
						)
					}
				}
			)
		} else {
			ConsoleLog(
				"Cannot request the project info. Project ID or User ID is incorrect."
			)
		}
	}

	useEffect(() => {
		const cabinetId = partialStore.selectedCabinetId
		orgStore.projects.getCabinetLocation(cabinetId)
		orgStore.projects.setProjectDetails(initialProjectDetail) //Reset to initial
		getProjectInfo()

		return () => {
			partialStore.setProjectDetailsDialogOpen(false)
		}
	}, [])

	// console.log(
	// 	partialStore.selectedProj,
	// 	partialStore.selectedCabinetId,
	// 	"selectedProj"
	// )

	useEffect(() => {
		if (selectedTab === TABS.USERS) {
			const projectId = partialStore.selectedProj
			// @Noah: initialize the user list!
			orgStore.projects.getProjUsersAndFileCount(projectId)
		}
	}, [selectedTab])

	const i18n = store.i18n.combineI18n(moduleId, "ProjectDtailsDialog")
	const dateFormat = store.global.dateFormat.value
	const timeZone = store.global.timeZone.uiValue
	const actionStatus = orgStore.projects.getActionStatus(actionName)
	const actionStatus2 = orgStore.projects.getActionStatus(
		"getProjUsersAndFileCount"
	)
	const actionStatus3 =
		orgStore.projects.getActionStatus("getCabinetLocation")
	return (
		<StyledDialog>
			<DLDialog
				eleTestId="project-details-dialog"
				isOpen={partialStore.projectDetailsDialogOpen}
				setIsOpen={partialStore.setProjectDetailsDialogOpen}
				// handleAction={handleOnSubmit}
				showCloseBtn={true}
				dialogTitle={i18n.twProjectDetails}
				dialogContents={
					<ProjectDetailsForm
						projectDetails={orgStore.projects.formattedProjectDetails(
							dateFormat,
							timeZone
						)}
						projectUserDetails={orgStore.projects.projectUsers}
						i18n={i18n}
						closeDialog={partialStore.setProjectDetailsDialogOpen}
						selectedTab={selectedTab}
						setSelectedTab={setSelectedTab}
					/>
				}
				maxWidth="lg"
				fullWidth={true}
				showSpinner={
					actionStatus === "LOADING" ||
					actionStatus2 === "LOADING" ||
					actionStatus3 === "LOADING"
				}
				cannotUpdate={
					actionStatus === "LOADING" ||
					actionStatus2 === "LOADING" ||
					actionStatus3 === "LOADING"
				}
			/>
		</StyledDialog>
	)
})

const StyledDialog = styled.div`
	.MuiDialog-container {
		height: 580px;
	}
	.dl-dialog-content {
		height: 440px !important;
	}
`
