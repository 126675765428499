import React from "react"
import styled from "styled-components"

const CommentsPageToolbar = () => {
	return (
		<StyledCommentsPageToolbar className="FR AC">
			{/* <DLSingleSelect
				eleTestId="comment-place-selection"
				eleClassName="select"
				withLabel
				label="Comment on"
				eleValue="all"
				optionList={[
					{ value: "all", name: "All" },
					{ value: "wp", name: "Workpapers" },
					{ value: "pf", name: "Permanent Files" }
				]}
			/>
			<DLSingleSelect
				eleTestId="comment-type-selection"
				eleClassName="select"
				withLabel
				label="Comment By"
				eleValue="all"
				optionList={[
					{ value: "all", name: "All" },
					{ value: "qc", name: "QC Comment" },
					{ value: "r", name: "Review Comment" }
				]}
			/> */}
		</StyledCommentsPageToolbar>
	)
}

const StyledCommentsPageToolbar = styled.div`
	.select {
		margin-right: 1rem;
	}
`

export default CommentsPageToolbar
