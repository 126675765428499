import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import {
	DLButton,
	DLExpansionPanelSingle,
	InputWithLabel,
	DLInputField,
	DLDatepickerWithoutInput,
} from "../../../../../../components/basic-elements"
import { useRootStore } from "../../../../../../stores/root-store/root-store.provider"
import FileSelectionDialog from "../file-selection-dialog/FileSelectionDialog"

export default observer(function ChecklistDialogContent({
	partialStore,
	selectedProjId,
}: {
	partialStore: any
	selectedProjId: string
}) {
	const store = useRootStore()
	const [checklistItems, setChecklistItems] = useState(
		JSON.parse(JSON.stringify(partialStore.checklistDetail.items))
	)
	const [collapsed, setCollapsed] = useState(true)

	const handleChange = () => {}
	const handleDateInput = (name: any, value: any) => {}

	const handleChangeFile = (itemId: string, event: any) => {
		const choosedFiles = Array.from(event.target.files)
		const items = [...checklistItems]
		const value = items.map((item: any) => {
			if (item.id === itemId) {
				const requirements = item.requirements.map((req: any) => {
					if (req.type === "FILES") {
						return {
							...req,
							value: choosedFiles,
						}
					} else return req
				})
				return { ...item, requirements }
			} else return item
		})

		setChecklistItems(value)
	}

	const dateFormat = store.global.getDntFormat.dateFormat

	const [currentChecklistItem, setCurrentChecklistItem] = useState<
		string | undefined
	>(undefined)
	const handleSelectWPFiles = (itemId: string) => {
		setCurrentChecklistItem(itemId)
		partialStore.setOpenFileSelectionDialog(true)
	}

	const handleFileChange = (
		itemId: string,
		title: string,
		aliasId: string
	) => {
		const items = [...checklistItems]
		console.log(items, title, aliasId)
		const value = items.map((item: any) => {
			if (item.id === itemId) {
				const requirements = item.requirements.map((req: any) => {
					if (req.type === "DL_FILE") {
						return {
							...req,
							value: `${title} (${aliasId})`,
						}
					} else return req
				})
				return { ...item, requirements }
			} else return item
		})

		setChecklistItems(value)
	}
	return (
		<StyledDialogContent>
			<div className="FR JSB">
				<div>X / {checklistItems.length}</div>
				<DLButton
					eleTestId="expand/collapse"
					clickHandler={() => setCollapsed((prev) => !prev)}
					variant="text"
				>
					Collapse All / Extend All
				</DLButton>
			</div>
			<div>
				{checklistItems.map((item: any, i: number) => {
					return (
						<div key={i} className="checklist-item">
							<ChecklistItemComponent
								checklistItem={item}
								handleChange={handleChange}
								handleDateInput={handleDateInput}
								handleChangeFile={handleChangeFile}
								handleSelectWPFiles={handleSelectWPFiles}
								dateFormat={dateFormat}
								collapsed={collapsed}
							/>
						</div>
					)
				})}
				{partialStore.openFileSelectionDialog && (
					<FileSelectionDialog
						partialStore={partialStore}
						selectedProjId={selectedProjId}
						handleFileChange={handleFileChange}
						currentChecklistItem={currentChecklistItem}
					/>
				)}
			</div>
		</StyledDialogContent>
	)
})

const StyledDialogContent = styled.div`
	.input-section > .with-label > .label {
		min-width: 11rem;
	}
	.checklist-item {
		padding-top: 4px;
	}
`

const ChecklistItemComponent = ({
	checklistItem,
	handleChange,
	handleDateInput,
	handleChangeFile,
	handleSelectWPFiles,
	dateFormat,
	collapsed,
}: {
	checklistItem: any
	handleChange: any
	handleDateInput: any
	handleChangeFile: any
	handleSelectWPFiles: any
	dateFormat: string
	collapsed: boolean
}) => {
	return (
		<DLExpansionPanelSingle
			eleTestId={`testid-${checklistItem.id}`}
			eleClassName={checklistItem.id}
			title={<h4>{checklistItem.name}</h4>}
			eleExpanded={collapsed}
		>
			<div className="form">
				{checklistItem.requirements.map((item: any, i: number) => {
					return (
						<div key={i}>
							{item.type === "DL_FILE" && (
								<div className="input-section FR">
									<InputWithLabel
										label={item.name}
										required={item.isRequired}
										eleClassName="with-label"
									>
										<DLInputField
											eleTestId={`testid-${item.id}`}
											eleName={item.name}
											eleValue={item.value}
											eleHandleChange={handleChange}
										/>
									</InputWithLabel>
									<DLButton
										eleTestId="select-files-btn"
										color="primary"
										clickHandler={() =>
											handleSelectWPFiles(
												checklistItem.id
											)
										}
									>
										Select WP Files
									</DLButton>
								</div>
							)}
							{item.type === "TEXT" && (
								<div className="input-section FR">
									<InputWithLabel
										label={item.name}
										required={item.isRequired}
										eleClassName="with-label"
									>
										<DLInputField
											eleTestId={`testid-${item.id}`}
											eleName={item.name}
											eleValue={item.value}
											eleHandleChange={handleChange}
										/>
									</InputWithLabel>
								</div>
							)}
							{item.type === "DATE" && (
								<div className="input-section FR">
									<InputWithLabel
										label={item.name}
										required={item.isRequired}
										eleClassName="with-label"
									>
										<div
											className="FR AC"
											style={{ paddingTop: 6 }}
										>
											<DLDatepickerWithoutInput
												eleTestId={`testid-${item.id}`}
												onChange={(date: any) =>
													handleDateInput(
														"signoff",
														date
													)
												}
												autoOk
												format={dateFormat}
											/>
											<div
												className="date-info FR AC"
												data-testid="signoff-date-display"
												style={{
													marginLeft: "1rem",
													opacity: 0.3,
												}}
											>
												{/*{inputs.expectedReportDate !== ""
													? getFormattedEOD({
															date: inputs.expectedReportDate,
															timeZone: timeZone.uiValue,
															timeFormat,
															dateFormat,
															tzLabel: timeZone.value,
													  })*/}
												"Please select a date"
											</div>
										</div>
									</InputWithLabel>
								</div>
							)}
							{item.type === "FILES" && (
								<div className="input-section FR">
									<InputWithLabel
										label={item.name}
										eleClassName="with-label"
									>
										<DLButton eleTestId="choose-files-btn">
											<label className="file-upload-btn-wrapper">
												Select File
												<input
													type="file"
													name="file"
													hidden
													onChange={(e) =>
														handleChangeFile(
															checklistItem.id,
															e
														)
													}
													multiple
													data-testid="file-input"
												/>
											</label>
										</DLButton>
									</InputWithLabel>
								</div>
							)}
						</div>
					)
				})}
			</div>
		</DLExpansionPanelSingle>
	)
}
