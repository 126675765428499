import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { FileScreenDialog } from "../../../../../service-modules/file-module/data-models/dl-file-control-model"
import { DLDialog } from "../../../../../components/basic-elements"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import styled from "styled-components"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { getProjectUsers } from "../../workpaper-files/store/wp.apis"
import { useAsync } from "../../../../../library/useAsync"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"

const FileHistoryDialog = observer(
	({ partialStore }: { partialStore: any }) => {
		const store = useRootStore()
		const projStore = useProjStore()
		const objectId = partialStore.selectedFile
		const objInfo = partialStore.getItemInfo(objectId)
		const projId = projStore.checkin.projId
		const menuId = partialStore.storeName
		const dntFormat = store.global.getDntFormat

		const getFileHistory = (props: any) => {
			console.log(props, "dataUsers")
			partialStore.getFileHistory({
				activityId: 0,
				projId,
				target: "File",
				fileId: objInfo.id,
				menuId,
				users: props.Users,
			})
		}

		const [{ data, isLoading }, getUsers] = useAsync(getProjectUsers, {
			onSuccess: getFileHistory,
		})
		const projectId = idToNumber(projId, IdType.project)

		useEffect(() => {
			getUsers({ projectId })
		}, [])

		console.log(data, isLoading, "objInfo")
		const dialogName = FileScreenDialog.FileHistoryDialog
		const setOpen = () => {
			partialStore.setFileTreeDialogOpen(dialogName)
		}

		const open = partialStore.fileTreeDialogOpenStatus[dialogName]

		return (
			<DLDialog
				eleTestId="file-history-dialog"
				isOpen={open}
				setIsOpen={setOpen}
				showOpenBtn={false}
				showCloseBtn={true}
				dialogTitle="File History"
				dialogContents={
					<>
						<div style={{ marginBottom: 20 }}>
							<b>Target File:</b> ({objInfo.aliasId}){" "}
							{objInfo.title}
						</div>
						<FileHistoryTabel
							data={partialStore.getFileHistories(dntFormat)}
						/>
					</>
				}
				openBtn={<div />}
				// cancelBtnText="Cancel"
				actionReady={false}
				// handleAction={downloadPDF}
				maxWidth="md"
				showSpinner={
					partialStore.getActionStatus("getFileHistory") === "LOADING"
				}
			/>
		)
	}
)

const FileHistoryTabel = ({ data }: { data: any }) => {
	return (
		<StyledSignOff>
			<ReactTableV8
				tableColumns={columns}
				data={data}
				hasPagination={false}
				showPageSetting={false}
				// height={contentHeight + 20}
				menuId="FileHistory"
			/>
		</StyledSignOff>
	)
}

const StyledSignOff = styled.div`
	table {
		thead > tr > th {
			padding: 0;
			.header {
				min-height: 2rem;
				padding-left: 8px;
				justify-content: center;
			}
		}
		tbody {
			.MuiTableCell-root {
				padding: 8px;
			}
		}
	}
`

const columns = [
	{
		header: "Date",
		accessorKey: "createdDate",
	},
	{
		header: "By",
		accessorKey: "createdBy",
	},
	{
		header: "Description",
		accessorKey: "description",
	},
	{
		header: "Activity ID",
		accessorKey: "activityId",
	},
]

export default FileHistoryDialog
