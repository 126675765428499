import {
	ActionStatus,
	MessageColorType,
	ProgressStatus,
} from "../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../components/basic-elements"
import { DLMenuId } from "../../../../components/combined-elements/file-tree/dl-file-tree-props"
import {
	idToNumber,
	idToString,
	IdType,
} from "../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 */

const GetFileHistory = (self: any) => ({
	getFileHistory({
		activityId,
		projId,
		target,
		fileId,
		menuId,
		users,
	}: {
		activityId: number
		projId: string
		target: string
		fileId: string
		menuId: DLMenuId
		users: any
	}) {
		// 0. set actionName
		const actionName = "getFileHistory"
		// 1.
		// 2. set responses
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. request API call

		const projectId = idToNumber(projId, IdType.project)
		const targetId = idToNumber(fileId, IdType.file)
		if (typeof projectId === "string") {
			ConsoleLog("Invalid project Id..." + projId)
			return
		}
		console.log("getFileHistory, projNumId:", {
			activityId,
			projectId,
			target,
			targetId,
			users,
		})

		self.readFileHistory(activityId, projectId, target, targetId, menuId)
			.then((response: any) => {
				ConsoleLog([actionName, response.data])
				if (response.status === 200 && response.data.Status === 1) {
					// if success
					// 1) reset the stored list
					const items = response.data.History
					let histories: any[] = []
					items.map((item: any) => {
						const { ActivityId } = item

						if (
							ActivityId.includes("SKIP") ||
							ActivityId === "UPDATE_WITH_THE_SAME_VALUES" ||
							ActivityId === "BY_OTHER_DOMAIN" ||
							ActivityId === "BY_OTHER_SIGN_OFF" ||
							ActivityId === "SHOULD_NOT_HAPPEN" ||
							ActivityId === "HASH_VALUE_OR_SIGNATURE_VALUE" ||
							ActivityId === "UNKNOWN"
						) {
							console.log("PASS ME because it is ", ActivityId)
						} else if (
							(ActivityId == "a15b41c04d03e01f61" &&
								item.MediaId == item.SourceDataId) ||
							(ActivityId == "a15b41c04d03e01f71" &&
								item.MediaId == item.SourceDataId)
						) {
							// Because Delete and Restore history are duplicated by file & media, omit the media side log
							// a15b41c04d03e01f61: Delete File
							// a15b41c04d03e01f71: Restore File
							console.log(
								"PASS ME because it is Delete or Restore history of Media"
							)
							// Below part is pending because force close also make the history_created_by as NULL now
							// } else if (
							// 	ActivityId == "a15b41c04d03e01f92" &&
							// 	(item.HistoryCreatedBy == 0 ||
							// 		item.HistoryCreatedBy == null)
							// ) {
							// 	// a15b41c04d03e01f92: Close the online editor
							// 	// when the close has no created_by, it means the force close action
							// 	// and the force close be logged as a15b41c04d03e01f93 individually
						} else {
							const history = getHistoryCreatedByUser(item, users)
							console.log(history, "history")
							const convertedItem = {
								historyCreatedBy: `(${history.RoleAlias})(${history.UserAlias}) ${history.UserName}`,
								historyCreatedDate: item.HistoryCreatedDate,
								activityId: item.ActivityId,
								activityIdVer: item.ActivityIdVer,

								// srcData: JSON.parse(item.SourceData),
								description: generateFileHistoryDescription(
									item,
									users
								),
							}
							histories.push(convertedItem)
						}
					})

					console.log(histories, "histories")

					self.setFileHistory(histories)
					// when success
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						customMessage: response.data.Message,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetFileHistory

function getHistoryCreatedByUser(item: any, projectUsers: any) {
	let user
	const userIndex = projectUsers.findIndex(
		(user: any) => user.UserId == item.HistoryCreatedBy
	)
	if (userIndex != -1) {
		user = projectUsers[userIndex]
	} else {
		user = {
			RoleAlias: "SA-Not Assigned",
			UserAlias: item.HistoryCreatedBy,
			UserName: item.HistoryCreatedBy,
		}
	}
	const createdBy =
		item.HistoryCreatedBy === 0
			? {
					UserAlias: "System",
					UserName: "System",
			  }
			: user

	return createdBy
}

function generateFileHistoryDescription(data: any, projectUsers: any) {
	const activityId = data.ActivityId

	const srcData = data.SourceData !== "" ? JSON.parse(data.SourceData) : {}

	console.log(srcData, "srcData")
	let roleName = ""
	if (srcData && srcData.role_id) {
		const roleId = srcData.role_id
		roleName =
			roleId === 8
				? "QC"
				: roleId === 6
				? "CP"
				: roleId === 9
				? "EP"
				: roleId === 5
				? "P"
				: roleId === 4
				? "D"
				: roleId === 3
				? "M"
				: roleId === 2
				? "SS"
				: roleId === 1
				? "JS"
				: "?"
	}

	const regEx = /.{1,3}/g
	const activityInfo = activityId.match(regEx) || []
	console.log("activityInfo: ", activityInfo, data.Id)

	// console.log("srcData:", srcData)

	// NOTE: WARNING: REPORTED ISSUE 2023-12-21
	// Because users can add sign-off even if the file is open, when user A opens the file and user B adds sign-off on it, pod_history logs incorrect data, like A save the file in the online editor.
	// related with the DB stored procedure: 'fn_tracking_for_aul_pod_history_aul_media'

	switch (activityInfo[3]) {
		case "d02":
			// d02: FOLDER related things
			// All d02 has e01 now.
			switch (activityInfo[5]) {
				case "f11":
					return "Created a new folder"
				case "f21":
					return "Edited the folder"
				case "f41":
					return "Moved the folder"
				case "f51":
					return "Exported the folder (Download)"
				case "f61":
					return "Deleted the folder"
				case "f71":
					return "Restored the folder"
				case "f81":
					return "Deleted the folder permanently"
				default:
					return "Unknown folder related action"
			}

		case "d03":
			// d03: FILE related things
			switch (activityInfo[4]) {
				case "e01":
					switch (activityInfo[5]) {
						case "f11":
							return "Created a new file"
						//// case "f13":
						//// 	return "Create File - Workpaper Library"
						//// case "f14":
						//// 	return "Create File - Auditprogram Template"
						case "f21":
							return "Updated the reference number"
						case "f22":
							return "Updated the file name (description)"
						case "f23":
							return "Updated the ref num & file name"
						case "f31":
							// because current API side insertion of pod_history cannot include source_data JSON, omit the media_type and file_ext temporarily
							// if you want to know the details, please query on the database
							// return `Replaced the file. (File type: ${srcData.media_type} / Extension: ${srcData.file_ext})`
							return "Replaced the file"
						case "f41":
							return "Move the file to another folder"
						case "f51":
							return "Individual File Download"
						case "f91":
							return "Open the file in the online editor"
						case "f32":
							return "Save the file in the online editor"
						case "f92":
							return "Close the file (online editor)"
						//// case "f94":
						//// Decided to do not show the close request on the pod_history
						//// 	return "Online Editor Close Request (CHANGE IS MADE to aul_request_notifications Table / pfrequest_notifications Table)"
						case "f93":
							return "Force Close of Files without Close Action on Online Editor"
						case "f61":
							return "Deleted File"
						case "f71":
							return "Restored the file"
						case "f81":
							return "Deleted the file permanently"
						default:
							return "Unknown file related action"
					}

				case "e03":
					switch (activityInfo[5]) {
						case "f11":
							return `Assigned ${roleName} Role`
						case "f61":
							// issue 1) Role Un-assignment API insert the log but doesn't include the source data - unable to know the un-assigned role
							// issue 2) Role Un-assignment hard delete the table row and cannot know who did that
							// So display 2 rows temporarily...
							return `Unassigned ${roleName} Role`
						default:
							return "Unknown assign role related action"
					}

				case "e04":
					switch (activityInfo[5]) {
						case "f11":
							return "Prepare the file"
						case "f61":
							return "Un-prepare the file"
						case "f12":
							return "Review the file"
						case "f62":
							return "Un-review the file"
						default:
							return "Unknown sign off related action"
					}

				case "e05":
					switch (activityInfo[5]) {
						case "f21":
							return "Locked the Sign Offs"
						case "f22":
							return "Unlocked the Sign Offs"
						default:
							return "Unknown sign off lock related action"
					}
				case "e06":
					switch (activityInfo[5]) {
						case "f21":
							return "Changed Attention to Completed"
						case "f22":
							return "Changed Attention to In Progress"
						case "f23":
							return "Changed Attention to Not Started"
						default:
							return "Unknown attention status related action"
					}

				case "e07":
					switch (activityInfo[5]) {
						case "f11":
							return "Added Review Comment"
						case "f21":
							return "Updated Review Comment"
						case "f22":
							return "Sign Off the Review Comment (Approve)"
						//// case "f23":
						//// 	return "Sign Off the Review Comment (Not Approved) - Not Existing"
						case "f61":
							return "Deleted Review Comment"
						case "f16":
							return "Added QC Comment"
						case "f26":
							return "Updated QC Comment"
						case "f27":
							return "Sign Off the QC Comment (Approve)"
						//// case "f28":
						//// 	return "Sign Off the QC Comment (Not Approved) - Not Existing"
						case "f66":
							return "Deleted QC Comment"
						case "f71":
							return "Restored the Review Comment"
						case "f81":
							return "Deleted the Review Comment permanently"
						case "f76":
							return "Restored the QC Comment"
						case "f86":
							return "Deleted the QC Comment permanently"

						default:
							return "Unknown comment related action"
					}
				default:
					break
			}
			break

		default:
			return activityInfo.join(" / ")
	}
}
