import { applySnapshot, types } from "mobx-state-tree"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import Responses from "../../../../common-models/responses"
import ResponseSnackbar from "../../../../common-models/response-snackbar"
// common viewModel actions
import {
	CommonViewModelActions,
	ViewResponseHelper,
} from "../../../../common-models/common-view-model-actions"
// separated actions
import Login from "./view-model-actions/login"
import AdminLogin from "./view-model-actions/admin-login"
import Logout from "./view-model-actions/logout"
import GetLobbyDefault from "./view-model-actions/get-lobby-default"
import GetCustomer from "./view-model-actions/get-customer"
import GetAllMenus from "./view-model-actions/get-all-menus"

//  1. language
//  2. country
//  3. lobbyUserId (not exist yet)
//  4. lobbyUserName
//  5. lobbyDefaultEmail
//  6. orgUserIds

export const initialStore = {
	country: "UK",
	language: "en",
	userName: "",
	defaultEmail: "",
	orgUserIds: [],
	lobbyCheckinStatus: ActionStatus.standby,
	isAppAdminUser: false,
	//
	// allowedMenus: [],
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const LobbyCheckinViewModel = types
	.model({
		country: "UK", // WARNING: Only for the store.i18n / DO NOT USE ANYWHERE except for lobby-checkin
		language: "en", // WARNING: Only for the store.i18n / DO NOT USE ANYWHERE except for lobby-checkin
		// userId: types.string, // Not exist yet
		userName: "",
		defaultEmail: "",
		//
		orgUserIds: types.array(types.string), // TBD
		// NOTE: 'lobbyCheckinStatus' is different with login action's result
		// 'lobbyCheckinStatus' below means the default setting related with the lobby sector (global sector)
		lobbyCheckinStatus: types.enumeration<ActionStatus>(
			Object.values(ActionStatus)
		),
		//
		isAppAdminUser: false,
		//
		allowedMenus: types.array(
			types.model({
				id: "",
				available: false,
				index: 0,
				isAmdinMenu: false,
			})
		),
		customerMenus: types.array(
			types.model({
				id: "",
				menuType: "",
				name: "",
				parentId: types.union(types.null, types.string),
				sector: "",
				index: 0,
				urlEdge: "",
				status: "",
				isAdminMenu: false,
				actions: types.array(
					types.model({
						name: "",
						description: "",
					})
				),
			})
		),
		// ---------- common models
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions(GetCustomer)
	.actions((self) => ({
		findMenuByUrl(url: string) {
			return self.customerMenus.find((menu) => menu.urlEdge === url)
		},
		findMenuById(id: string) {
			return self.customerMenus.find((menu) => menu.id === id)
		},
		getParentUrl(id: string) {
			if (
				id.includes("ProjMenu_AuditProgram") ||
				id.includes("ProjMenu_FinalAnalysis")
			) {
				return "files"
			}
			const parentMenuId = self.customerMenus.find(
				(menu) => menu.id === id
			)?.parentId
			const parentMenu = self.customerMenus.find(
				(menu) => menu.id === parentMenuId
			)
			return parentMenu?.urlEdge
		},
		setCustomerMenus(customerMenus: any) {
			self.customerMenus.length = 0
			self.customerMenus = customerMenus
		},
		setAllowedMenus(
			menus:
				| {
						id: string
						available: boolean
						index: number
				  }[]
				| any
		) {
			self.allowedMenus.length = 0
			self.allowedMenus = menus
		},
		setCountry(country: string) {
			self.country = country
		},
		setLanguage(newLanguage: string) {
			self.language = newLanguage
		},
		setUserName(name: string) {
			self.userName = name
		},
		setDefaultEmail(email: string) {
			self.defaultEmail = email
		},
		setOrgUserIds(ids: typeof self.orgUserIds) {
			self.orgUserIds.length = 0
			self.orgUserIds = ids
		},
		setLobbyCheckinStatus(status: ActionStatus) {
			self.lobbyCheckinStatus = status
		},
		setIsAppAdminUser(isAppAdminUser: boolean) {
			self.isAppAdminUser = isAppAdminUser
		},
	}))
	.actions(Login)
	.actions(Logout)
	.actions(AdminLogin)
	.actions(GetLobbyDefault)
	.actions(GetAllMenus)
	// common actions for viewModel
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default LobbyCheckinViewModel
