import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useParams, Link, useRouteMatch, useHistory } from "react-router-dom"
import { DLButton } from "../../../components/basic-elements"
import "easymde/dist/easymde.min.css"
import PostForm from "./PostForm"
import { PermissionAsObjectProps } from "../../../common-models/permission"
import {
	Priority,
	Sector,
	PostType,
	PostAttachmentType,
	CreatePostInputType,
} from "@datalobby/types"
import { ActionStatus } from "../../../common-models/enumerations/common-enums"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import Attachments from "./Attachments"
import styled from "styled-components"
import { CommonLocalFileProps } from "../../../components/basic-elements/buttons/DLLocalFileUploadButton"
import PostLoadingDialog from "./PostLoadingDialog"
import { getProjIdFromUrl } from "../../../library/api-requests/request-get-others"
import { DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

const initialState: PostType = {
	menu: "",
	id: "",
	title: "",
	content: "",
	//
	sector: Sector.org,
	parent: "",
	priority: Priority.medium,
	fixToTop: false,
	attachments: [],
	//
	// whoCanRead: WhoCanRead.all,
	// allowedTargets: [],
	// visitors: [],
	// visitorCount: 0,
	//
	createdBy: "",
	createdAt: "",
	modifiedBy: "",
	modifiedAt: "",
	deleted: false,
}

export type AttachmentProps = {
	file: CommonLocalFileProps
	isNew: boolean
	status: ActionStatus
	// key: string | null
}

export type NewAttachmentPropsForGql = {
	fileName: string
	extension: string
	size: number
	formattedSize: string
	key: string
}

export default observer(function PostAddOrEdit({
	permission,
	sector,
	postById,
	partialStore,
	projDefaultUrl,
}: {
	permission: PermissionAsObjectProps
	sector: Sector
	postById?: any
	partialStore: any
	projDefaultUrl?: string
}) {
	const store = useRootStore()
	let { postId } = useParams<{ postId: string }>()
	let { path } = useRouteMatch()
	let history = useHistory()

	const mode = sector === Sector.org ? path.split("/")[3] : path.split("/")[4]
	let s3Folder = sector === Sector.org ? "org/noti/" : "proj/board/"
	let menuId =
		sector === Sector.org ? DLOrgSubMenus.org_noti : DLProjSubMenus.board
	console.log("s3Folder", s3Folder)

	const links = {
		detail:
			sector === Sector.org
				? "/organization/notifications/detail/"
				: `${projDefaultUrl}/note-and-comments/board/detail/`,
		list:
			sector === Sector.org
				? "/organization/notifications"
				: `${projDefaultUrl}/note-and-comments/board`,
	}

	const [post, setPost] = useState({ ...initialState, sector })
	const [files, setFiles] = useState<AttachmentProps[]>([]) // for attachments

	const {
		title,
		content,
		// priority,
		// fixToTop,
		// attachments,
		// whoCanRead,
		// allowedTargets,
		// createdBy,
	} = post

	// function onChange(e: any) {
	// 	setPost(() => ({ ...post, [e.target.name]: e.target.value }))
	// }

	const userId = localStorage.getItem("orgUserId")
	const parentId =
		sector === Sector.org
			? localStorage.getItem("orgId")
			: sector === Sector.proj
			? getProjIdFromUrl()
			: ""

	useEffect(() => {
		// NOTE: Required initialization
		partialStore.resetS3FilesStatus()
	}, [])

	useEffect(() => {
		if (mode === "edit" && postById) {
			const postContent = postById(postId)
			console.log(
				"edit screen useEffect",
				postById(postId),
				postById,
				postId
			)
			if (postContent) {
				setPost(postContent)
				console.log("postContent", postContent)
				console.log("postContent.attachments", postContent.attachments)
				console.log("useState post:", post)

				let existingFiles: AttachmentProps[] = []
				postContent.attachments.map((item: PostAttachmentType) => {
					// const fileInfo = JSON.parse(item)
					const {
						fileName,
						extension,
						size,
						formattedSize,
						s3ObjKey,
					} = item

					const dummyFile = {
						file: {
							file: null,
							id: s3ObjKey,
							name: fileName,
							extension,
							type: "",
							size,
							formattedSize,
							lastModified: null,
						},
						isNew: false,
						status: ActionStatus.success,
					}
					console.log("dummyFile", dummyFile)
					existingFiles.push(dummyFile)
				})
				setFiles(files.concat(existingFiles))
				console.log("-----files:", files)

				console.log("updated files in useEffect?", files)
			} else {
				alert("Cannot find the post")
			}
		}
	}, []) //[mode])

	const [actionStatus, setActionStatus] = useState<ActionStatus>(
		ActionStatus.standby
	)

	let hasNewFile = files.filter((file) => file.isNew).length > 0
	const handleFiles = (localFiles: CommonLocalFileProps[]) => {
		let newLocalFiles: AttachmentProps[] = []
		localFiles.map((item: CommonLocalFileProps) => {
			newLocalFiles.push({
				file: item,
				isNew: true,
				status: ActionStatus.standby,
				// key: null
			})
		})
		setFiles(files.concat(newLocalFiles))
	}

	/**
	 *
	 * @param id:string
	 *
	 * when user click file remove icon on the add or edit screen,
	 * - when add screen, it just removes the file from useState only
	 * - when edit screen
	 * --- if it is a new file from user's local, it removes the file from useState data only
	 * --- if it is an existing file, it removes the useState data and S3 object together
	 *
	 * when the target file is existing one (not a new from a local),
	 * the param 'id' has 'key' value (because to display the file list, set key as id in the list. If you need, please update)
	 */
	const removeFileFromScreen = (id: string) => {
		// NOTE: do not remove the s3 file here. That should happen with update function
		// If user do not update this post and refresh, attachments should have same items in it without update

		setFiles(files.filter((item: AttachmentProps) => item.file.id !== id))
		console.log("files after remove local file:", files)
	}

	/**
	 * < Upload file to S3 and open the progress dialog >
	 * - If the post has attachments: upload file to S3 and open the progress dialog
	 * - If there are no attachments: run the graphql mutation directly
	 */
	const startAddPost = () => {
		if (!post.title || !post.content) {
			alert("Please input title and contents")
			return
		}
		if (files.length > 0 && hasNewFile) {
			// open the loading dialog
			partialStore.setSavePostDialogOpen(true)

			// call the ... function
			const filesForUpload = files.map((file) => {
				return {
					id: s3Folder + file.file.id,
					fileName: file.file.name,
					fileType: file.file.type,
					extension: file.file.extension,
					file: file.file.file,
					size: file.file.size,
					formattedSize: file.file.formattedSize,
				}
			})
			partialStore.getSignedUrls(filesForUpload)
			// in this case, handleAddPostOnDDB will work on the dialog with
			// getSignedUrl and uploading status
		} else {
			handleAddPostOnDDB([])
		}
	}

	function checkDeletedFiles() {
		let prevFileList: string[] = []
		if (post.attachments) {
			post.attachments.map((attachment) => {
				// const key = JSON.parse(attachment).key
				console.log("prev file's key:", attachment.s3ObjKey)
				// if (key) {
				prevFileList.push(attachment.s3ObjKey)
				// }
			})
		}

		console.log("prevFileList", prevFileList)
		let currentFileList: string[] = []
		files.map((file) => {
			currentFileList.push(file.file.id)
		})
		console.log("currentFileList", currentFileList)

		const deletedFiles = prevFileList.filter(
			(item) => !currentFileList.includes(item)
		)
		return deletedFiles
	}

	/**
	 * < Upload to, or delete from S3 and open the progress dialog >
	 * - Is there any changes on the attachments?
	 * --- YES
	 * ------ open the progress dialog
	 * ------ (if it exist) upload additional files to S3
	 * ------ (if it exist) delete some files from S3
	 * --- NO: update the DDB directly by the graphql mutation
	 */
	const startEditPost = () => {
		// @Noah: Why this sentence is displayed when click the update button?
		if (!post.title || !post.content) {
			alert("Please input title and contents")
			// Instead of return, redirect the user to post list
			return
		}
		/**
		 * Checklist
		 * - Are there some new files?
		 * - Are there some deleted files?
		 * - Are there existing files remained?
		 */

		// const addedFiles = currentFileList.filter(
		// 	(item) => !prevFileList.includes(item)
		// )

		const addedFiles = files.filter((file) => file.isNew)
		const deletedFiles = checkDeletedFiles()
		console.log("files", files)
		console.log("deletedFiles", deletedFiles)
		console.log("addedFiles", addedFiles)

		if (
			(addedFiles.length === 0 && deletedFiles.length === 0) ||
			files.length === 0
		) {
			// No changes on the attachments
			// handleEditPostOnDDB([])
			handleEditPostOnDDB()
		} else {
			if (addedFiles.length > 0) {
				const newFilesForUpload = files
					.filter((file) => file.isNew)
					.map((file) => {
						return {
							id: s3Folder + file.file.id,
							fileName: file.file.name,
							fileType: file.file.type,
							extension: file.file.extension,
							file: file.file.file,
							size: file.file.size,
							formattedSize: file.file.formattedSize,
						}
					})
				partialStore.getSignedUrls(newFilesForUpload)
				// in this case, handleEditPostOnDDB will work on the dialog with
				// getSignedUrl and uploading status
			}
			if (deletedFiles.length > 0) {
				// NOTE: WARNING: @NOAH. you should update this part
				// partialStore.removeFilesFromS3(deletedFiles)
			}
			// handleEditPostOnDDB()
			// and open the loading dialog
			// NOTE: PostLoadingDialog has updatePost also..
			partialStore.setSavePostDialogOpen(true)
			// handleEditPostOnDDB()
		}
	}

	function handleAddPostOnDDB(newFiles: NewAttachmentPropsForGql[]) {
		if (parentId && userId) {
			if (!title || !content) {
				alert("Title and Content cannot be empty")
				return
			}
			setActionStatus(ActionStatus.loading)

			// const id = uuid()
			console.log("new Files in handleAddPostOnDDB:", newFiles)

			let filesForGql: PostAttachmentType[] = []
			if (newFiles.length > 0) {
				newFiles.map((item: NewAttachmentPropsForGql) => {
					const fileInfo = {
						fileName: item.fileName,
						extension: item.extension,
						size: item.size,
						formattedSize: item.formattedSize,
						s3ObjKey: item.key,
					}
					// const stringValue = JSON.stringify(fileInfo)
					// filesForGql.push(stringValue)
					filesForGql.push(fileInfo)
				})
			}
			const newPost: CreatePostInputType = {
				menu: menuId,
				title: post.title,
				content: post.content,
				//
				sector: post.sector,
				parent: parentId,
				priority: post.priority,
				fixToTop: post.fixToTop,
				attachments: filesForGql,
				//
				createdBy: userId,

				// ...post,
				// menu: ,
				// createdBy: userId,
				// parent: parentId,
				// attachments: filesForGql,
				// menu: ,
				// title: ,
				// content: ,
				// sector: ,
				// parent: ,
			}
			// post.menu = menuId
			// // post.id = id
			// post.createdBy = userId
			// post.parent = parentId
			// post.attachments = filesForGql

			console.log("newPost-->", newPost)

			const postAction = (postId: string) =>
				history.replace(`${links.detail}${postId}`)
			partialStore.addPost(newPost, postAction)
		}
	}

	// async function handleEditPostOnDDB(updatedFiles: NewAttachmentPropsForGql[]) {
	function handleEditPostOnDDB() {
		// updated files contain additional files, and no deleted files
		if (parentId && userId) {
			if (!title || !content) {
				alert("Title and Content cannot be empty")
				return
			}

			let filesForGql: PostAttachmentType[] = []
			if (files.length > 0) {
				files.map((item) => {
					const fileInfo = {
						fileName: item.file.name,
						extension: item.file.extension,
						size: item.file.size,
						formattedSize: item.file.formattedSize,
						s3ObjKey: item.file.id,
					}
					// const stringValue = JSON.stringify(fileInfo)
					// filesForGql.push(stringValue)
					filesForGql.push(fileInfo)
				})
			}

			let updatedPost = {
				menu: menuId,
				id: postId,
				title: post.title,
				content: post.content,
				priority: post.priority,
				fixToTop: post.fixToTop,
				attachments: filesForGql,
				// whoCanRead: post.whoCanRead,
				// allowedTargets: post.allowedTargets,
				modifiedBy: userId,
			}

			const postAction = () => history.replace(`${links.detail}${postId}`)
			console.log("updated post:", updatedPost)
			partialStore.editPost(updatedPost, postAction)
		}
	}

	const calcTotalSize = () => {
		let totalFileSize = 0
		files.map((file: AttachmentProps) => {
			totalFileSize = totalFileSize + file.file.size
		})
		console.log("calcTotalSize: ", totalFileSize)
		return totalFileSize
	}

	const contentsAreaHeight = store.ui.contentsAreaHeight
	console.log(" ------ files in observer", files)

	return (
		<StyledNotiAddOrEdit
			style={{
				height: contentsAreaHeight,
				overflowY: "auto",
				padding: "1rem",
			}}
		>
			<h4 data-testid="noti-input-mode">
				{mode === "edit"
					? "Edit Notification"
					: mode === "add"
					? "Add Notification"
					: "Something wrong..."}
			</h4>

			<hr />
			<PostForm post={post} setPost={setPost} />
			<Attachments
				files={files}
				handleFiles={handleFiles}
				removeFile={removeFileFromScreen}
				eleClassName="attachments-container"
			/>
			<div className="btn-container FR JSB AC">
				<Link to={links.list}>
					<DLButton eleTestId="noti-cancel">Cancel</DLButton>
				</Link>
				{mode === "edit" ? (
					<DLButton
						eleTestId="post-edit-submit-btn"
						clickHandler={() => startEditPost()}
						color="primary"
						disabled={
							post.title === "" ||
							post.content === "" ||
							actionStatus === ActionStatus.loading ||
							calcTotalSize() > 100000000 // 100Mb
						}
					>
						Update
					</DLButton>
				) : mode === "add" ? (
					<DLButton
						eleTestId="noti-save-btn"
						clickHandler={() => startAddPost()}
						color="primary"
						disabled={
							post.title === "" ||
							post.content === "" ||
							actionStatus === ActionStatus.loading ||
							calcTotalSize() > 100000000 // 100Mb
						}
					>
						Save
					</DLButton>
				) : (
					<div>???</div>
				)}
			</div>
			{partialStore.savePostDialogOpen && (
				<PostLoadingDialog
					isOpen={partialStore.savePostDialogOpen}
					setOpen={partialStore.setSavePostDialogOpen}
					partialStore={partialStore}
					hasNewFile={hasNewFile}
					// on here, just check the added files only.
					// removed files will be deleted by the permanent delete
					addedFilesLength={
						// files.filter((file) => file.isNew).length
						mode === "add"
							? files.filter((file) => file.isNew).length
							: mode === "edit"
							? files.filter((file) => file.isNew).length
							: // ? files.filter((file) => file.isNew).length +
							  //   checkDeletedFiles().length
							  0
					}
					files={files}
					createNewPost={handleAddPostOnDDB}
					updatePost={handleEditPostOnDDB}
					mode={mode}
				/>
			)}
		</StyledNotiAddOrEdit>
	)
})

const StyledNotiAddOrEdit = styled.div`
	.attachments-container {
		margin: 1rem 0;
	}
`
