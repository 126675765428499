import React, { useEffect } from "react"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { Divider } from "@material-ui/core"
import keyboardJS from "keyboardjs"
import { useRootStore } from "../../../stores/root-store/root-store.provider"

const ITEM_HEIGHT = 48

interface ImenuProps {
	options: { label: string; value: any; clickEvent?: (props: any) => void }[]
	btn: React.ReactElement
	eleTestId: string
	useScroll?: boolean
	hasDivider?: Array<number>
}

export default function DLMenus(props: ImenuProps) {
	const store = useRootStore()
	const bgColor = store.ui.getThemeValue("background")
	const txtColor = store.ui.getThemeValue("textMediumEmphasis")

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)

	useEffect(() => {
		keyboardJS.pause()
	}, [])

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault()
		setAnchorEl(event.currentTarget)
	}

	const handleSelectOption = (optionEvent: any) => {
		optionEvent()
		handleClose()
	}

	const handleClose = () => {
		setAnchorEl(null)
	}
	const { options, btn, eleTestId, useScroll, hasDivider } = props
	return (
		<div className="dl-comp-DLMenus">
			<div onClick={handleClick} className="menu-btn-container-to-open">
				{btn}
			</div>
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={() => {
					keyboardJS.resume()
					handleClose()
				}}
				data-testid={eleTestId}
				PaperProps={{
					style: {
						maxHeight: useScroll ? ITEM_HEIGHT * 4.5 : "none",
						width: 200,
					},
				}}
				MenuListProps={{
					id: eleTestId,
					className: "dl-ctx-menu-testid",
					style: { backgroundColor: bgColor, color: txtColor },
				}}
				// WARNING: DO NOTE REMOVE OR UPDATE this className 'dl-ctx-menu-testid'
				// It is used on test instead of the testid because this component does not support that property
			>
				{options.map((option, index) => {
					return (
						<div className="FC" key={option.value}>
							<MenuItem
								key={option.value}
								onClick={() =>
									handleSelectOption(option.clickEvent)
								}
							>
								{option.label}
							</MenuItem>
							{hasDivider && hasDivider.includes(index) ? (
								<Divider style={{ width: "100%" }} />
							) : (
								<div />
							)}
						</div>
					)
				})}
			</Menu>
		</div>
	)
}
