import React, { useEffect, useCallback } from "react"
import { observer } from "mobx-react-lite"
import ProjInfoUpdateForm from "../../../screens/project-side/project-information/sub-components/ProjInfoUpdateForms"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { ProjInfoUpdateInputProps } from "../../../screens/project-side/project-information/store/data-models/proj-info-update-input-props"
import { addTzToDate } from "../../../library/converters/date-utc-converter"
import { addDays } from "date-fns"

export default observer(function ProjectUpdateForm({
	inputs,
	setInputs,
	partialStore,
}: {
	inputs: ProjInfoUpdateInputProps
	setInputs: any
	partialStore: any
}) {
	const store = useRootStore()

	useEffect(() => {
		// ConsoleLog("groupId", groupId)
		const { groupId } = partialStore.projInfo
		partialStore.getClientsByGroup(groupId)
		partialStore.getEngTypeList()
		partialStore.getRiskAssessmentList()
		partialStore.getProjPeriodTypes()

		partialStore.getArchPolicyPeriodList()
	}, [])

	const handleChange = (event: any) => {
		const target = event.target.id
		const value = event.target.value
		if (target === "fee") {
			const re = /^[0-9\b]+$/
			if (value === "" || re.test(value)) {
				setInputs((inputs: any) => ({
					...inputs,
					[target]: value,
				}))
			}
		} else {
			setInputs((inputs: any) => ({
				...inputs,
				[target]: value,
			}))
		}
		// ConsoleLog("handleChange", inputs)
	}

	const handleDateInput = (name: any, date: any) => {
		const newDate = addTzToDate(date, tzValue)

		setInputs((inputs: any) => ({
			...inputs,
			[name]: newDate,
		}))
		if (name === "periodEndDate") {
			const year = new Date(newDate).getFullYear()
			if (year !== inputs.financialYear) {
				setInputs((inputs: any) => ({
					...inputs,
					financialYear: year,
				}))
			}
		}
		if (name === "expectedReportDate") {
			const days = partialStore.getArchiveDays(inputs.archPolicyPeriodId)
			if (days) {
				changeExpectedArchiveDeadLineDate(days, date) // TODO: ??
			}
		}
		if (name === "finalReportDate") {
			const days = partialStore.getArchiveDays(inputs.archPolicyPeriodId)
			if (days) {
				changeFinalArchiveDeadLineDate(days, date)
			}
		}
	}

	const changeExpectedArchiveDeadLineDate = (days: number, date: string) => {
		const newDate = new Date(date)
		const expectedDate = addDays(newDate, days)

		setInputs((projInfo: any) => ({
			...projInfo,
			expectedArchDeadlineDate: addTzToDate(expectedDate, tzValue),
		}))
	}

	// NOTE: DO NOT USE useCallback! It uses old value and occurs incorrect calculation.
	const changeFinalArchiveDeadLineDate = (days: number, date: any) => {
		if (date !== "" && date !== null) {
			if (days) {
				const newDate = new Date(date)
				const expectedDate = addDays(newDate, days)

				setInputs((projInfo: any) => ({
					...projInfo,
					finalArchDeadlineDate: addTzToDate(expectedDate, tzValue),
				}))
			}
		}
	}

	// NOTE: Org > Projects > Project Information > Edit Project
	const handleSelectArchPolicyPeriod = (e: any) => {
		const newArchPolicyPeriodId = e.target.value
		setInputs((projInfo: any) => ({
			...projInfo,
			archPolicyPeriodId: newArchPolicyPeriodId,
		}))
		const days = partialStore.getArchiveDays(newArchPolicyPeriodId)
		if (days) {
			changeExpectedArchiveDeadLineDate(days, inputs.expectedReportDate) // TODO: ??
			changeFinalArchiveDeadLineDate(days, inputs.finalReportDate) // TODO: ??
		}
	}

	const handleComboBoxInput = useCallback((name: any, value: any) => {
		if (value !== null) {
			setInputs((inputs: any) => ({
				...inputs,
				[name]: value.id,
			}))
			if (name === "clientId") {
				setInputs((projInfo: any) => ({
					...projInfo,
					clientAliasId: value.clientAliasId,
				}))
			}
		} else {
			setInputs((inputs: any) => ({
				...inputs,
				[name]: "",
			}))
		}
	}, [])

	const clientList = partialStore.viewClientsList()

	const engTypeList = partialStore.viewEngTypeOptions()
	const periodList = partialStore.viewPeriodNames()
	const archPolicyPeriods = partialStore.viewArchPolicyPeriodList()
	const raList = partialStore.viewRiskAssessmentOptions()

	const dntFormat = store.global.getDntFormat
	const timeZone = store.global.timeZone
	const tzValue = timeZone.uiValue

	return (
		<ProjInfoUpdateForm
			inputs={inputs}
			// setInputs={setInputs}
			handleChange={handleChange}
			handleSelectArchPolicyPeriod={handleSelectArchPolicyPeriod}
			handleDateInput={handleDateInput}
			handleComboBoxInput={handleComboBoxInput}
			//
			dntFormat={dntFormat}
			//
			clientList={clientList}
			engTypeList={engTypeList}
			periodList={periodList}
			archPolicyPeriods={archPolicyPeriods}
			raList={raList}
		/>
	)
})
