import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import Icon from "@mdi/react"
import { mdiCircleOutline, mdiCircle, mdiAlert } from "@mdi/js"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import styled from "styled-components"
import SignOffCellTooltip from "./SignOffCellTooltip"
import { DLButton } from "../../../basic-elements"
import { DLTooltip } from "@datalobby/components"
import { SignOffDisplayType } from "../../../../service-modules/file-module/data-models/dl-file-control-model"
import {
	SingleSignOffProps,
	SignOffPolicyItemProps,
	OldSignOffType,
} from "../../../../service-modules/file-module/data-models/sign-off.data-model"
import { MessageColorType } from "../../../../common-models/enumerations/common-enums"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import { DnTFormatProps } from "../../../../common-models/types/common-props"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"

const SignOffCell = observer(
	({
		condition, // sign off policy for this column (current sign off column ( = step))
		//
		fileId,
		fileAliasId,
		signOff,
		getColumnSignOffs,
		//
		cellWidth,
		cellType,
		dateFormat,
		isHovered,
		permission,
		isSignOffLocked,
	}: {
		condition: SignOffPolicyItemProps
		//
		fileId: string
		fileAliasId: string
		signOff: (props: SingleSignOffProps) => void
		getColumnSignOffs: (
			fileId: string,
			columnId: string,
			dntFormat: DnTFormatProps
		) => any
		//
		cellWidth: number
		cellType: string
		dateFormat: string
		isHovered: boolean
		permission: PermissionAsObjectProps
		isSignOffLocked: boolean
	}) => {
		const store = useRootStore()
		const projStore = useProjStore()
		const userId = projStore.checkin.userId
		const roleId = projStore.checkin.user.roleId
		const handleSingleSignOff = (e: any) => {
			e.preventDefault()
			const signOffType =
				condition.id === "id_prepare"
					? OldSignOffType.prepare
					: OldSignOffType.review
			signOff({
				userId,
				roleId,
				signOffType,
				forCancel: false,
				fileId,
			})
		}

		const [prerequisiteStatus, setPrerequisiteStatus] = useState(true)
		const dntFormat = store.global.getDntFormat

		useEffect(() => {
			if (
				condition.prerequisite !== null &&
				condition.prerequisite.length !== 0
			) {
				condition.prerequisite.map((id) => {
					const prerequisiteLength = getColumnSignOffs(
						fileId,
						id,
						dntFormat
					).length
					console.log("SignOffCell for ", id)

					if (prerequisiteLength === 0) {
						// if there are no sign off in the prerequisite column
						setPrerequisiteStatus(false)
					}
				})
			}
		}, [])

		// NOTE: TODO: WARNING: store views should returns last sign-off first
		// let lastSignOff = signOffList[0]
		const xxx = getColumnSignOffs(fileId, condition.id, dntFormat)

		let lastSignOff = xxx[0]
		//
		let hasSignOff = false
		//
		let userName = ""
		let signDate = ""
		let signOffModified = false
		let srcModified = false
		//
		/**
		 * SIGN OFF CONDITIONS TODO: How to make this flexible even the role be able to customize
		 */

		let showSignOffBtn = false
		//
		//
		// Check sign off availability
		// TODO: need to check this logic
		// NOTE: anyways, if the cell has sign off, btn is not displayed
		if (condition.availableRoles.includes(roleId) && prerequisiteStatus) {
			if (permission.signOff) {
				showSignOffBtn = true
			}
		}
		// WARNING: "updateTogether" part need update

		//
		if (lastSignOff) {
			userName = lastSignOff.userName
			signDate = lastSignOff.signedAt
			srcModified = lastSignOff.srcModified
			signOffModified = lastSignOff.signOffModified
		}
		// if (signOffList.length !== 0) {
		// 	hasSignOff = true
		// }
		if (xxx.length !== 0) {
			hasSignOff = true
		}

		const btnLabel = condition.shortLabel || condition.label

		// console.log(
		// 	"hasSignOff: ",
		// 	hasSignOff,
		// 	"/xxx: ",
		// 	xxx,
		// 	"/ fileId:",
		// 	fileId
		// )

		return (
			<StyledSignOffCell
				className={`FR AC JC sign-off-cell-container ${
					isHovered && "highlight"
				}`}
				data-testid={`sign-off-cell-${condition.id}-${fileAliasId}`}
				style={{ width: cellWidth }}
			>
				{hasSignOff ? (
					<SignOffCellTooltip
						// signOffs={signOffList}
						signOffs={xxx}
						columnName={condition.label}
					>
						<div style={{ width: "100%" }}>
							{signOffModified && (
								<Icon
									path={mdiAlert}
									size={0.7}
									color={MessageColorType.red}
									data-testid="modified-signoff"
								/>
							)}
							{srcModified && (
								<div
									className="FR AC"
									style={{
										color: MessageColorType.red,
										fontWeight: 700,
										marginRight: 2,
										paddingTop: 4,
									}}
									data-testid="modified-contents"
								>
									*
								</div>
							)}
							{cellType === SignOffDisplayType.min && (
								<div className="sign-off-cell sign-off-circle">
									{userName && !srcModified ? (
										<Icon
											path={mdiCircle}
											size={0.4}
											color="blue"
											data-testid="signoff-blue"
										/>
									) : userName && srcModified ? (
										<Icon
											path={mdiCircle}
											size={0.4}
											color="orange"
											data-testid="signoff-orange"
										/>
									) : userName && signOffModified ? (
										<Icon
											path={mdiCircle}
											size={0.4}
											color="red"
											data-testid="signoff-red"
										/>
									) : (
										<Icon
											path={mdiCircleOutline}
											size={0.4}
											color="gray"
											data-testid="signoff-gray"
										/>
									)}
								</div>
							)}
							{cellType === SignOffDisplayType.nameOnly && (
								<div
									className="sign-off-cell"
									data-testid="signoff-username"
								>
									{userName}
								</div>
							)}
							{cellType === SignOffDisplayType.nameAndDate && (
								<div
									className={
										signOffModified
											? "sign-off-modiifed sign-off-cell FC AC"
											: "sign-off-cell FC"
									}
								>
									<div data-testid="signoff-username">
										{userName}
									</div>
									<div data-testid="signoff-date">
										{signDate}
									</div>
								</div>
							)}
						</div>
					</SignOffCellTooltip>
				) : (
					<div className="FR AC JC" style={{ width: "100%" }}>
						{showSignOffBtn ? (
							<DLButton
								eleClassName="sign-off-btn"
								eleTestId={`sign-off-btn-on-row-${condition.id}-${fileAliasId}`} // ${condition.id}`}
								size="thin"
								clickHandler={handleSingleSignOff}
								eleStyle={{ width: "100%" }}
								disabled={isSignOffLocked}
							>
								{btnLabel}
							</DLButton>
						) : (
							<div
								data-testid={`sign-off-placeholder-${condition.id}-${fileAliasId}`}
								className="signoff-cell-placeholder sign-off-btn FR AC JC"
							>
								{prerequisiteStatus ? (
									<div>{btnLabel}</div>
								) : (
									// TODO: Need to check archive condition also
									// NOTE: QC assigned file requires QC review as mandatory on some engagement type
									<DLTooltip
										title={`Prerequisite ${condition.prerequisite?.map(
											(item) => item
										)} is required`}
									>
										<div className="sign-off-btn preresquisite-is-required">
											{btnLabel}
										</div>
									</DLTooltip>
								)}
							</div>
						)}
					</div>
				)}
			</StyledSignOffCell>
		)
	}
)

const StyledSignOffCell = styled.div`
	height: 100%;
	overflow: hidden;
	&.highlight {
		background-color: ${(props) => props.theme.shade10};
	}
	overflow: hidden;
	height: 100%;
	&.sign-off-cell-container {
		/* margin: 0 0.3rem; */
	}
	/* background-color: pink; */
	.sign-off-cell {
		font-size: ${(props) => props.theme.shared.textRg};
		&.sign-off-modiifed {
			color: red;
		}
		&.sign-off-circle {
			flex-shrink: 0;
			flex-grow: 1;
		}
	}
	.sign-off-btn {
		width: 80%;
		/* font-size: ${(props) => props.theme.shared.textRg}; */
	}
	.signoff-cell-placeholder {
		color: ${(props) => props.theme.shade10};
		border: 1px solid ${(props) => props.theme.projSidebar};
		padding: 4px;
		border-radius: 4px;
		cursor: not-allowed;
	}
`

export default SignOffCell
