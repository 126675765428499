import React from "react"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import { FileScreenDialog } from "../../../../../../service-modules/file-module/data-models/dl-file-control-model"
import {
	IconPlaceholder,
	DLIcon,
	DLIconName,
	CommonIconSize,
} from "../../../../../../components/basic-elements/icons/common-icons"
import { PermissionAsObjectProps } from "../../../../../../common-models/permission"
import { DLOrgSubMenus } from "../../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { useRootStore } from "../../../../../../stores/root-store/root-store.provider"
import { checkFileProps } from "../../../../../../service-modules/file-module/check-file-props-to-open"

const ProjTemplateFileCtxMenuOptions = (
	projId: string,
	handleClose: () => void,
	permission: PermissionAsObjectProps
) => {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const templateStore = orgStore.orgProjTemplates
	//
	const selectedItems = templateStore.selectedItems
	const itemsCount = selectedItems.length
	const fileId = selectedItems[0].id
	const fileInfo = templateStore.getItemInfo(fileId)

	const handleFileDelete = () => {
		if (itemsCount > 1) {
			let proceed = window.confirm(
				`Do you really want to delete ${itemsCount} files?`
			)
			if (proceed) {
				templateStore.removeFiles({ fileIds: selectedItems, index: 0 })
				handleClose()
			}
		} else if (itemsCount === 1) {
			// let fileId = ""
			if (!selectedItems[0].isParent) {
				// fileId = selectedItems[0].id
			} else {
				alert("(FileContextMenuOptions) Selected item is not a file")
			}
			if (fileId !== "") {
				templateStore.removeFile(fileId)
				handleClose()
			}
		} else if (itemsCount <= 0) {
			alert("(FileContextMenuOptions) No selected items")
		}
	}

	const handleFileDuplicate = () => {
		if (itemsCount > 1) {
			alert(`Cannot duplicate multiple files. Please try again`)
		} else if (itemsCount === 1) {
			// const fileId = selectedItems[0].id // TODO:
			templateStore.duplicateProjTemplateFile(fileId)
		} else if (itemsCount <= 0) {
			alert(`No selected files. Please try again`)
		}
	}

	const handleFileDownload = () => {
		if (itemsCount > 1) {
			alert(`Cannot download multiple files. Please try again`)
		} else if (itemsCount === 1) {
			// const fileId = selectedItems[0].id // TODO:
			templateStore.downloadFile(fileId)
		} else if (itemsCount <= 0) {
			alert(`No selected files. Please try again`)
		}
	}

	const handleFileReplace = () => {
		if (itemsCount > 1) {
			alert(`Cannot replace multiple files. Please try again`)
		} else if (itemsCount === 1) {
			templateStore.setFileTreeDialogOpen(FileScreenDialog.replaceFile)
		} else if (itemsCount <= 0) {
			alert(`No selected files. Please try again`)
		}
	}

	const handleFileOpen = () => {
		if (itemsCount === 1) {
			const userInfo = {
				id: orgStore.checkin.orgUserId,
				isReadonly: orgStore.checkin.orgInfo.isSuperAdmin,
				isExternal: orgStore.checkin.orgInfo.isExternalUser,
			}

			const fileProps = checkFileProps({
				orgId: store.global.currentOrg.id,
				projId,
				fileInfo,
				userInfo,
				asReadonly: false, // before update the 'open as readonly', default is 'false'
			})

			console.log(fileProps, "fileProps")

			if (fileProps && fileProps.useEditor) {
				store.editor.openEditor(fileProps.props)
			} else if (fileProps && fileProps.useEditor === false) {
				templateStore.openFileWithoutEditor(fileProps?.props)
			} else {
				console.log("What should I do on here..?")
			}
		} else if (itemsCount > 1) {
			alert(`Cannot open multiple files. Please try again`)
		} else if (itemsCount <= 0) {
			alert(`No selected files. Please try again`)
		}
	}

	return [
		{
			label: "File Download (d)",
			value: "ctx-download-file",
			available: permission.export,
			disabled: itemsCount > 1,
			clickEvent: handleFileDownload,
			icon: (
				<DLIcon
					name={DLIconName.download}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		{
			label: "Open with Online Editor (w)",
			value: "ctx-open",
			available: permission.read,
			clickEvent: handleFileOpen,
			icon: <IconPlaceholder />,
		},
		{
			label: "Replace (s)",
			value: "ctx-replace-file",
			available: permission.update,
			disabled: itemsCount > 1,
			clickEvent: handleFileReplace,
			icon: (
				<DLIcon
					name={DLIconName.replace}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		{
			label: "Edit File Info (e)",
			value: "ctx-edit-file",
			available: permission.update,
			disabled: itemsCount > 1,
			clickEvent: () => {
				templateStore.setFileTreeDialogOpen(
					FileScreenDialog.fileEdit,
					true
				)
			},
			icon: (
				<DLIcon
					name={DLIconName.edit}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		{
			label: "Role - Assign / Unassign (a)",
			value: "ctx-assign",
			available: permission.update,
			clickEvent: () => {
				templateStore.setFileTreeDialogOpen(
					FileScreenDialog.assign,
					true
				)
			},
			icon: <IconPlaceholder />,
		},
		{
			label: itemsCount > 1 ? `Delete (${itemsCount}) (x)` : "Delete (x)",
			value: "ctx-delete-file",
			available: permission.delete,
			clickEvent: handleFileDelete,
			icon: (
				<DLIcon
					name={DLIconName.delete}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},

		// {
		// 	label: "Duplicate",
		// 	value: "ctx-duplicate-file",
		// 	available: permission.create,
		// 	disabled: itemsCount > 1,
		// 	clickEvent: handleFileDuplicate,
		// 	icon: <IconPlaceholder />,
		// },
	]
}

export default ProjTemplateFileCtxMenuOptions
