import React from "react"
// for store
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
// components
import {
	DLDialog,
	DLDialogHeader,
	DLButton,
} from "../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { mdiTrashCan } from "@mdi/js"
import Icon from "@mdi/react"
import {
	DLIcon,
	DLIconName,
	CommonIconSize,
} from "../../../../../components/basic-elements/icons/common-icons"

export default observer(function RemoveClientDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	const orgStore = useOrgStore()
	const target = orgStore.setupClients.selectedItems

	const actionName = "removeClient"
	const deleteClient = () => {
		if (target.length === 1) {
			orgStore.setupClients.removeClient(target[0])
		} else if (target.length === 0) {
			alert("No selected items. Please try again")
		} else if (target.length > 1) {
			const clients = JSON.parse(JSON.stringify(target))
			orgStore.setupClients.removeClients(clients, 0)
		}
	}

	const { tsRemoveClientMsg, twRemoveClient } = i18n
	const { twCancel, twDelete } = i18n
	const targetInfo = orgStore.setupClients.getClientInfoById(target[0])

	return (
		<DLDialog
			eleTestId="client-delete-dialog"
			isOpen={orgStore.setupClients.removeClientDialogOpen}
			setIsOpen={orgStore.setupClients.setRemoveClientDialogOpen}
			showCloseBtn={true}
			showOpenBtn={false}
			dialogTitle={
				<DLDialogHeader
					icon={
						<DLIcon
							name={DLIconName.delete}
							size={CommonIconSize.dialogHeaderIcon}
						/>
					}
					dialogName={twRemoveClient}
					targetName={
						target.length === 1
							? targetInfo
								? targetInfo.clientName
								: "(Cannot find target)"
							: target.length === 0
							? "No selected client. Please try again"
							: target.length > 1
							? target.length + " Clients"
							: "(error) Please try again"
					}
				/>
			}
			dialogContents={<span>{tsRemoveClientMsg}</span>}
			actionReady={true}
			// handleAction={deleteClient}
			// actionBtn={twDelete}
			actionBtn={
				<DLButton
					variant="text"
					eleTestId="delete-client-btn"
					startIcon={<Icon path={mdiTrashCan} size={0.8} />}
					clickHandler={deleteClient}
					color="warning"
				>
					{twDelete}
				</DLButton>
			}
			cancelBtnText={twCancel}
			cannotUpdate={
				orgStore.setupClients.getActionStatus(actionName) === "LOADING"
			}
		/>
	)
})
