import { AxiosResponse } from "axios"
import {
	ReadGroupUsersApiProps,
	ReadClientsByGroupApiProps,
} from "../../../service-modules/group-module/org-group-shared-apis"
import {
	ReadProjectUsersApiProps,
	ReadProjectDetailsApiProps,
} from "../../../service-modules/project-module/org-project-shared-apis"
import { TOKEN_TYPE } from "../../../library/api-requests/shared-for-api-requests"
import {
	getRequest,
	putRequest,
	postRequest,
} from "../../../library/api-requests"
import { UpdateProj } from "../../../screens/project-side/project-information/store/proj-info.apis"

export async function read(payload: {
	FinancialYear: number
	PageIndex: number
	PageSize: number
	ProjectType: number
	PTMAssignedOnly: boolean
	// EngagementPartner: "",
	// EngagementTypeID: 0,
	// QualityControl: "",
	// SearchText: "",
	// GroupId: 0,
	// ClientMasterId: 0,
	// periodID: 0,
}) {
	const response = await postRequest({
		url: "/GetProjects",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function readRfProjects(params: { groupId: number }) {
	const endpoint = "/GetClientsByGroup" // NOTE: This 'clients' means projects
	const response = await getRequest({
		url: endpoint,
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export type CreateProjectProps = {
	ArchiveDatePolicyId: number
	CabinetId: number
	ClientMasterID: number
	ClientShortName: string // project business ID (project alias ID)
	EngagementID: number
	EngagementUserId: number
	ExpectedArchiveDeadLineDate: string // "2021-02-14T00:00:00+05:30"
	Fee: string
	FiscalYearEnd: string // "2020-11-30T00:00:00+05:30"
	GroupID: number
	LinkName: string // project title
	PeriodID: number
	RiskAssessmentId: number
	RoleId: number
	TemplateId: number // 0 means blank template, other numbers mean template ID
	UserID: number
	YearValue: number
	// NOTE: THIS DATE VALUE IS FOR API CALL. DO NOT UPDATE YET
	archivedDeadlineDate: string
	expectedIssuanceDate: string // "2020-12-31T00:00:00+05:30" // TODO: variable name need update
	finalIssuanceDate: string // TODO: variable name need update
	isUserRoleForwardSelected: boolean
	movecurrentyeartopreviousyear: number // 0 means false
}

export type CreateHardCopyOnlyProjectProps = {
	stage: "Normal" // ? Can users create other stage projects??? (ex. Can users create archived project in hardcopy option..?)
	isLocked: boolean
	//
	groupId: string
	cabinetId: string
	//
	clientId: string
	engagementTypeId: string
	//
	periodTypeId: string
	//
	name: string
	aliasId: string
	//
	year: number
	periodEndDate: string
	//
	expectedReportDate: string
	finalReportDate: string
	//
	archivePolicyPeriod: string
	expectedArchiveDeadlineDate: string
	finalArchiveDeadlineDate: string
	//
	creationTemplate: "HARD_COPY_ONLY"
	// creationInfo: ""
	//
	createdBy: string
	// createdAt: new Date()
	//
	ownerUserId: string
	otherUsers: { id: string; signOffRole: string; accessRight: string }[]
}

export async function createProject(payload: CreateProjectProps) {
	const endpoint = "/CreateClient"
	const response = await postRequest({
		url: endpoint,
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function readPeriodNames() {
	const response = await getRequest({
		url: "/GetPeriodNames",
		params: {},
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function readProjInfo(params: { ClientId: number }) {
	const response = await getRequest({
		url: "/GetClientInfo",
		params,
		tokenType: TOKEN_TYPE.latestProjToken,
	})

	return response
}

export async function updateProjInfo(payload?: UpdateProj) {
	const response = await putRequest({
		url: "/EditClient",
		params: payload,
		tokenType: TOKEN_TYPE.latestProjToken,
	})

	return response
}

export function readEngType(payload: { PageIndex?: number; PageSize: number }) {
	const response = postRequest({
		url: "/GetEngagementType",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function readRiskAssessment(payload: {
	PageIndex?: number
	PageSize: number
}) {
	const response = postRequest({
		url: "/GetRiskAssessments",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function createHardCopyOnlyProject(payload: any) {
	// @Noah - TODO:
	const response = postRequest({
		url: "/PLEASE_UPDATE_ME",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function preConditionStatus(clientId: any) {
	const response = await getRequest({
		url: `/PreConditionCheckStatus?clientId=${clientId}`,
		params: {},
		// tokenType: TOKEN_TYPE.orgToken,
		// WARNING: This is temporary solution. MUST UDPATE AFTER BACKEND SIDE UPDATE
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function forceArchive(payload: {
	ClientId: number
	Reason: string
}) {
	const response = await postRequest({
		url: "/WorkspaceForceArchive",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function readCabinetLocation(cabinetId: number) {
	const response = await getRequest({
		url: `/OrganizationCabinetsGetCabinetLocation`,
		params: {
			cabinetId,
		},
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export type OrgProjectApiProps = {
	apiRead: (payload: {
		FinancialYear: number
		PageIndex: number
		PageSize: number
		ProjectType: number
		PTMAssignedOnly: boolean
		// EngagementPartner: "",
		// EngagementTypeID: 0,
		// QualityControl: "",
		// SearchText: "",
		// GroupId: 0,
		// ClientMasterId: 0,
		// periodID: 0,
	}) => Promise<AxiosResponse<any>>
	//
	apiCreateProject: (
		payload: CreateProjectProps
	) => Promise<AxiosResponse<any>>
	apiCreateHardCopyOnlyProject: (
		payload: CreateHardCopyOnlyProjectProps
	) => Promise<AxiosResponse<any>>
	apiReadArchPolicyPeriodList: () => Promise<AxiosResponse<any>>
	apiReadClients: (
		params: ReadClientsByGroupApiProps
	) => Promise<AxiosResponse<any>>
	apiDeleteProject: (payload: {
		ClientId: number
		CabinetId: number
		Type: string
	}) => Promise<AxiosResponse<any>>
	apiReadRfProjects: (params: {
		groupId: number
	}) => Promise<AxiosResponse<any>>
	apiReadUsers: (
		params: ReadGroupUsersApiProps
	) => Promise<AxiosResponse<any>>
	apiReadPeriodNames: () => Promise<AxiosResponse<any>>
	apiReadProjectUsersAndFileCount: (
		payload: ReadProjectUsersApiProps
	) => Promise<AxiosResponse<any>>
	apiReadProjectDetails: (
		payload: ReadProjectDetailsApiProps
	) => Promise<AxiosResponse<any>>
	apiReadProjInfo: (params: {
		ClientId: number
	}) => Promise<AxiosResponse<any>>
	apiUpdateProjInfo: (payload?: UpdateProj) => Promise<AxiosResponse<any>>
	apiReadEngType: (payload: {
		PageIndex?: number
		PageSize: number
	}) => Promise<AxiosResponse<any>>
	apiReadRiskAssessment: (payload: {
		PageIndex?: number
		PageSize: number
	}) => Promise<AxiosResponse<any>>
	apiCheckPreConditionStatus: (clientId: any) => Promise<AxiosResponse<any>>
	apiReadCabinetLocation: (cabinetId: number) => Promise<AxiosResponse<any>>
	apiForceArchive: (payload: {
		ClientId: number
		Reason: string
	}) => Promise<AxiosResponse<any>>
}
