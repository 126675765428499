import React from "react"
import { observer } from "mobx-react-lite"
import { DLObjectPropsForComment } from "../../../common-models/types/comment-props"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import styled from "styled-components"

export default observer(function CommentTargetObjectInfo({
	object,
}: {
	object: DLObjectPropsForComment
}) {
	const { belongsTo, title, parentTitle, aliasId, extension } = object

	return (
		<StyledCommentTargetObjectInfo>
			<div className="menu-info" data-testid="menu-info">
				{belongsTo === DLProjSubMenus.wp
					? "Workpaper Files"
					: belongsTo === DLProjSubMenus.pf
					? "Permanent Files"
					: "(Unknown Menu)"}
			</div>
			<div className="obj-title" data-testid="obj-title">
				Title: {title}
			</div>
			{/* <div data-testid="folder">Folder: {parentTitle}</div> */}
			<div data-testid="aliasId">Ref.Num: {aliasId}</div>
			{/* <div>format: {format}</div> */}
			{/* <div data-testid="extension">extension: {extension}</div> */}
		</StyledCommentTargetObjectInfo>
	)
})

const StyledCommentTargetObjectInfo = styled.div`
	background-color: ${(props) => props.theme.secondary};
	padding: 1rem;
	.menu-info {
		opacity: 0.6;
	}
	.obj-title {
		font-weight: 600;
		margin-bottom: 0.4rem;
	}
`
