import React from "react"
import { Route } from "react-router-dom"

import OrgProjTemplates from "./org-proj-templates/OrgProjTemplates"

export default function OrgLibrary() {
	return (
		<>
			<Route
				exact
				path="/organization/templates-and-library"
				component={OrgProjTemplates}
			/>
			<Route
				exact
				path="/organization/templates-and-library/templates"
				component={OrgProjTemplates}
			/>
			{/* <Route
				exact
				path="/organization/templates-and-library/library"
				component={OrgFileLibController}
			/> */}
		</>
	)
}
