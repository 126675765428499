import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import {
	DLDialog,
	DLComboBox,
} from "../../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import styled from "styled-components"
import { DLCheckbox } from "@datalobby/components/lib"
import { getPeriodAbbrById } from "../../../../../../common-models/enumerations/org-related-enums"
import { ChecklistRecordType } from "@datalobby/types"

export default observer(function AddChecklistDialog({
	i18n,
	partialStore,
}: {
	i18n: DLI18nProps
	partialStore: any
}) {
	const orgStore = useOrgStore()

	const [selectedTemplate, setSelectedTemplate] = useState<String | null>(
		null
	)

	const orgIdForCdk = "org" + orgStore.checkin.orgInfo.orgId.slice(5)
	// useEffect(() => {
	// 	const checklistId = orgStore.reportsMgmt.getSelectedChecklistId()
	// 	orgStore.setupEngTypes.getChecklistTemplates(orgIdForCdk)

	// 	if (checklistId) {
	// 		partialStore.getChecklists({ orgId: orgIdForCdk, id: checklistId })
	// 	} else {
	// 		// TODO:
	// 	}
	// }, [])

	// const selectedProjId = partialStore.selectedProjId
	const projInfo = partialStore.viewSelectedProj()
	const checklistTemplates = orgStore.setupEngTypes.checklistTemplates
	const projType = projInfo.engTypeId.replace("engTypeId", "projType")

	console.log(projInfo)

	const getProjectId = () => {
		const { periodId, clientId, financialYear, periodEndDate } = projInfo
		const period = getPeriodAbbrById(periodId)
		const client = clientId.replace("clientId", "client")
		const ped = `ped${periodEndDate}`
		return `${financialYear}#${period}#${projType}#${client}#${ped}`
	}

	const handleSave = () => {
		const {
			id,
			aliasId,
			title,
			archiveAt,
			archiveBy,
			engTypeId,
			engTypeName,
			periodId,
			periodName,
			statusName,
			isLocked,
		} = projInfo
		const projectId = getProjectId()
		const workspaceId = projInfo.id.replace("projId", "ws")
		const sourceId = selectedTemplate
		const createdBy = orgStore.checkin.orgUserId

		const wsInfo = {
			stage: statusName,
			isLocked: isLocked,
			archiveDate: archiveAt || null,
			archivedBy: archiveBy,
		}

		const projectInfo = {
			id,
			aliasId,
			name: title,
			typeId: engTypeId,
			typeName: engTypeName,
			periodType: {
				id: periodId,
				name: periodName,
			},
			owners: null,
			higherQCs: null,
			incharges: null,
			users: null,
		}

		const {
			periodEndDate,
			expectedReportDate,
			finalReportDate,
			expectedArchiveDate,
			finalArchiveDeadlineDate,
		} = projInfo

		const timelineInfo = {
			periodEndDate,
			archivePolicyPeriod: null,
			expectedReportDate,
			finalReportDate,
			expectedArchiveDate,
			finalArchiveDeadlineDate,
		}

		const clientInfo = {
			id: projInfo.clientId,
			aliasId: projInfo.clientAliasId,
			name: projInfo.clientName,
		}

		partialStore.addChecklist({
			projectId,
			workspaceId,
			sourceId,
			destinationType: ChecklistRecordType.list,
			orgId: orgIdForCdk,
			createdBy,
			wsInfo,
			projInfo: projectInfo,
			timelineInfo,
			engInfo: null,
			clientInfo,
		})
	}

	const recommended = partialStore.getConnectedChecklistByProjId(projType)
	const [recommendedTemplate, setRecommendedTemplates] = useState(recommended)

	const data = {
		projectName: projInfo.title,
		projectType: projInfo.engTypeName,
		checklistTemplates,
		recommendedTemplate,
		setSelectedTemplate,
	}

	const handleSelectRecommendedTemplate = (id: string) => {
		const data = [...recommendedTemplate]
		const alreadySelected = data.find((item: any) => item.selected)?.id
		const index = data.findIndex((item: any) => item.id === id)
		if (id === alreadySelected) {
			data[index].selected = !data[index].selected
			setSelectedTemplate(null)
		} else {
			data[index].selected = true
			setSelectedTemplate(id)
		}

		setRecommendedTemplates(data)
	}

	const isLoading =
		partialStore.getActionStatus("getChecklistCollections") === "LOADING"

	return (
		<DLDialog
			eleTestId="add-checklist-dialog"
			isOpen={partialStore.openAddChecklistDialog}
			setIsOpen={partialStore.setOpenAddChecklistDialog}
			showCloseBtn={true}
			dialogTitle="Add Checklist"
			dialogContents={
				<AddChecklistContent
					data={data}
					handleSelectRecommendedTemplate={
						handleSelectRecommendedTemplate
					}
				/>
			}
			maxWidth="sm"
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionBtn="Save"
			actionReady={!!selectedTemplate}
			handleAction={handleSave}
			showSpinner={isLoading}
			cannotUpdate={isLoading}
		/>
	)
})

type AddChecklistDialogContentsData = {
	projectName: string
	projectType: string // existing engagement type
	checklistTemplates: {
		id: string
		name: string
	}[]
	recommendedTemplate: {
		id: string
		name: string
		selected: boolean
	}[]
	setSelectedTemplate: any
}

export const AddChecklistContent = ({
	data,
	handleSelectRecommendedTemplate,
}: {
	data: AddChecklistDialogContentsData
	handleSelectRecommendedTemplate: any
}) => {
	const {
		projectName,
		projectType,
		checklistTemplates,
		recommendedTemplate,
		setSelectedTemplate,
	} = data

	return (
		<StyledDialogContents>
			<div className="section-1">
				<div className="FR">
					<div className="label">Project Name:</div>
					{projectName}
				</div>
				<div className="FR">
					<div className="label">Engagement Type:</div>
					{projectType}{" "}
				</div>
			</div>

			<div className="section-2">
				{recommendedTemplate?.length ? (
					<>
						<span>
							This engagement type has recommended checklist
							template. Please check the checkbox if you want to
							use it.
						</span>
						{recommendedTemplate.map((item: any) => (
							<div className="FR recommended-checklist">
								<DLCheckbox
									eleClassName="recommended-checklist-checkbox"
									eleTestId="recommended-checklist-checkbox"
									eleName="recommendedChecklistTemplate"
									clickHandler={() =>
										handleSelectRecommendedTemplate(item.id)
									}
									isChecked={item.selected}
								/>
								<div>{item.name}</div>
							</div>
						))}
					</>
				) : (
					<span>
						This engagement type does not have recommended checklist
						template. Please select from below templates
					</span>
				)}
			</div>

			<div className="section-3">
				<div>Select a Checklist Template</div>
				<DLComboBox
					eleTestId="connect-checklist-template"
					eleClassName="select-checklist-template"
					withoutLabel
					placeholder={"Please select a checklist template"}
					options={checklistTemplates}
					getOptionLabel={(option: any) => option.name}
					onChange={(item: any) => setSelectedTemplate(item.id)}
					variant="outlined"
					eleDisabled={recommendedTemplate?.some(
						(item: any) => item.selected
					)}
				/>
			</div>
		</StyledDialogContents>
	)
}

const StyledDialogContents = styled.div`
	.section-1 {
		.label {
			font-weight: bold;
			min-width: 8rem;
		}
		margin-bottom: 1rem;
		line-height: 2rem;
	}
	.section-2 {
		margin-bottom: 1rem;
		.recommended-checklist {
			margin-top: 1rem;
		}
	}
	.section-3 {
		line-height: 2rem;
	}
`
