import React, { useEffect, useState } from "react"
import { PageContainer } from "../../../../components/app-frame-elements"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import styled from "styled-components"
import {
	DLDatepicker,
	DLIconButton,
	DLSingleSelect,
} from "../../../../components/basic-elements"
import AuditProgramTableColumns from "./AuditProgramTableColumns"
import AuditProgramActionBtns from "./AuditProgramActionBtns"
import ReactTableV8 from "../../../../components/basic-elements/tables/DLReactTable"
import DLDateRangepicker from "../../../../components/basic-elements/date-picker/DLDateRangePicker"
import { Table, Column } from "@tanstack/react-table"
import { InputFieldForTableV8 } from "../../../../components/basic-elements/tables/InputFieldForTable"
import * as XLSX from "xlsx"
import AddNewStepDialog from "./AddNewStepDialog"
import RoleAssignDialog from "./RoleAssignDialog"
import { displayedProjRoles } from "../../../../temporary-data/project-side/find-proj-role"
import AssignUserDialog from "./AssignUserDialog"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { useParams } from "react-router-dom"
import { IdType, idToNumber } from "../../../../library/converters/id-converter"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import {
	DLIcon,
	DLIconName,
} from "../../../../components/basic-elements/icons/common-icons"
import Icon from "@mdi/react"
import { mdiCheck, mdiClose } from "@mdi/js"
import { format } from "date-fns"

export default observer(function AuditProgramEditor({
	partialStore,
	fromDialog,
}: {
	partialStore: any
	fromDialog: boolean
}) {
	const store = useRootStore()
	const projStore = useProjStore()
	let { parentId } = useParams<{ parentId: string }>()

	const dntFormat = store.global.getDntFormat
	const dateFormat = dntFormat.dateFormat
	const contentsHeight = store.ui.contentsAreaHeight

	const [data, setData] = useState<any>([])
	const [selectedFile, setSelectedFile] = useState(null)
	const [open, setOpen] = useState(false)
	const [openRoleAssign, setOpenRoleAssign] = useState(false)
	const [openAssignUserDialog, setOpenAssignUserDialog] = useState(false)
	const [type, setType] = useState("Prepare")
	const [selectedId, setSelectedId] = useState(null)
	const wprId: any = idToNumber(parentId, IdType.file)
	const needRefresh = projStore.apg.needRefresh

	useEffect(() => {
		if (needRefresh) {
			projStore.apg.getAllAuditSteps(wprId)
		}
	}, [needRefresh])

	const auditSteps = projStore.apg.formattedAuditSteps(
		dateFormat,
		dntFormat.timeZone
	)

	const actionStatus = projStore.apg.getActionStatus

	const getStatus = actionStatus("getAllAuditSteps")
	const addStatus = actionStatus("addAuditStep")
	const removeStatus = actionStatus("removeAuditStep")

	useEffect(() => {
		setData(auditSteps)
	}, [
		getStatus === "SUCCESS",
		addStatus === "SUCCESS",
		removeStatus === "SUCCESS",
	])

	function handleFile(e: any) {
		var file = e.target.files[0]
		var reader = new FileReader()

		reader.onload = function (e) {
			if (e.target) {
				if (
					e.target.result !== null &&
					typeof e.target.result !== "string"
				) {
					var data = new Uint8Array(e.target.result)
					var workbook = XLSX.read(data, { type: "array" })

					const jsonData = XLSX.utils.sheet_to_json(
						workbook.Sheets.Sheet1,
						{ raw: false }
					)

					console.log(jsonData, "jsonData")
					let organizedList: any = []
					const hasEmptyFields = jsonData.every((item: any) =>
						Object.keys(item).includes("auditSteps")
					)

					if (!hasEmptyFields) {
						alert(
							"Excel file contains empty fields. Please input correct data."
						)
						return false
					}
					jsonData.map((item: any, i: any) => {
						organizedList.push({
							clientId: `fileId${i + 1}`,
							legend: item.legend,
							auditSteps: item.auditSteps,
							assertions: item.assertions,
							notes: item.notes,
							ref: item.ref,
							preparer: item.preparer,
							preparerDueDate: item.preparerDueDate,
							reviewer: item.reviewer,
							reviewerDueDate: item.reviewerDueDate,
						})
					})

					setData(organizedList)
				}
			} else {
				console.log("event target is null")
			}
		}
		reader.readAsArrayBuffer(file)
	}

	const handleSelectFilesFromLocal = (e: any) => {
		handleFile(e)
		// partialStore.setBulkClientFileReadStatus(ActionStatus.loading)
	}

	const handleRemove = (rowId: string) => {
		let proceed = window.confirm("Do you want to delete this audit step?")
		if (!proceed) {
			return
		} else {
			projStore.apg.removeAuditStep({
				AuditProgramId: rowId,
				WorkPaperId: wprId,
			})
		}
	}

	const handleEdit = (rowId: any) => {
		setSelectedId(rowId)
		setOpen(true)
	}

	const handleAdd = () => {
		setSelectedId(null)
		setOpen(true)
	}

	const assignableWPRefs = projStore.apg.getAssignableWPRefs()
	const assignableUsers = projStore.apg.getAssignableUsers()

	const handleUpdate = (inputs: any, callback: any) => {
		const payload = {
			AuditProgramId: inputs.auditProgramId,
			WorkPaperId: wprId,
			AuditStepWorkPaperReference: inputs.apgWPRef,
			AuditSteps: inputs.auditSteps,
			Note: inputs.note,
			Assertions: inputs.assertions,
			Legend: inputs.legend,
			PreparerDueDate: inputs.preparerDueDate
				? format(new Date(inputs.preparerDueDate), "yyyy-MM-dd")
				: "",
			ReviewerDueDate: inputs.reviewerDueDate
				? format(new Date(inputs.reviewerDueDate), "yyyy-MM-dd")
				: "",
			Preparer: inputs.preparerUserId === 0 ? "" : inputs.preparerUserId,
			Reviewer: inputs.reviewerUserId === 0 ? "" : inputs.reviewerUserId,
		}

		projStore.apg.editAuditStep(payload, callback)
	}

	function RenderEditableFileName(props: any) {
		const steps = JSON.parse(JSON.stringify([...data]))
		const targetFile = steps.find(
			(step: any) =>
				step.auditProgramId === props.row.original.auditProgramId
		)
		const columnId = props.cell.column.id

		const [value, setValue] = useState(targetFile[columnId])
		const [isEditing, setIsEditing] = useState(false)
		const [showIcon, setShowIcon] = useState(false)

		const onChange = (e: any) => {
			setValue(e.target.value)
		}

		console.log(value, "value")

		const onEdit = () => {
			setIsEditing(true)
		}

		const onCancel = () => {
			setValue(targetFile[columnId])
			setIsEditing(false)
		}

		const handleDateInput = (name: string, date: string) => {
			setValue(date)
		}

		const onSave = () => {
			setIsEditing(false)
			const auditProgramId = props.row.original.auditProgramId

			const updated = steps.map((step: any) =>
				step.auditProgramId === auditProgramId
					? {
							...step,
							[columnId]: value,
					  }
					: step
			)

			handleUpdate(
				updated.find((i: any) => i.auditProgramId === auditProgramId),
				() => setData(updated)
			)

			// updateMyData(index, id, value)
		}
		const size = props.column.getSize()
		return (
			<div className="FC" style={{ minWidth: size }}>
				<div
					style={{ position: "relative" }}
					onMouseLeave={() => setShowIcon(false)}
				>
					{isEditing ? (
						<div className="FR">
							{(() => {
								switch (columnId) {
									case "apgWPRef":
										return (
											<DLSingleSelect
												eleTestId="select-ref"
												eleValue={value}
												eleOnChange={onChange}
												eleName="apgWPRef"
												eleFullWidth
												withLabel={false}
												placeholder="Select Ref"
												optionList={[
													{
														name: "[No Selection]",
														value: "",
													},
													...assignableWPRefs,
												]}
											/>
										)
									case "preparerUserId":
									case "reviewerUserId":
										return (
											<DLSingleSelect
												eleTestId={columnId}
												eleValue={value}
												eleOnChange={onChange}
												eleName={columnId}
												eleFullWidth
												withLabel={false}
												placeholder="Select User"
												optionList={[
													{
														name: "[No Selection]",
														value: 0,
													},
													...assignableUsers,
												]}
											/>
										)
									case "preparerDueDate":
									case "reviewerDueDate":
										return (
											<DLDatepicker
												eleTestId={columnId}
												eleName={columnId}
												selected={
													value === "" ? null : value
												}
												onChange={(date) =>
													handleDateInput(
														columnId,
														date
													)
												}
												format={dateFormat}
												clearable={true}
											/>
										)
									default:
										return (
											<input
												value={value}
												onChange={onChange}
											/>
										)
								}
							})()}
							<DLIconButton
								eleTestId="save-btn"
								tooltipText="save"
								clickHandler={onSave}
							>
								<Icon path={mdiCheck} />
							</DLIconButton>
							<DLIconButton
								eleTestId="cancel-btn"
								tooltipText="cancel"
								clickHandler={onCancel}
							>
								<Icon path={mdiClose} />
							</DLIconButton>
						</div>
					) : (
						<div
							onMouseEnter={() => setShowIcon(true)}
							onMouseLeave={() => setShowIcon(false)}
							className="FR AC JSB"
							style={{ height: 20 }}
						>
							{columnId === "preparerUserId" ||
							columnId === "reviewerUserId" ? (
								<>
									{
										assignableUsers?.find(
											(i: any) => i.value === value
										)?.name
									}
								</>
							) : (
								<>{targetFile[columnId]}</>
							)}
							{/* {targetFile[columnId]} */}
							{showIcon && (
								<DLIconButton
									eleTestId="edit-btn"
									tooltipText="edit"
									clickHandler={onEdit}
								>
									<DLIcon name={DLIconName.edit} />
								</DLIconButton>
							)}
						</div>
					)}
				</div>
				{/* <div
					className={className}
					data-testid={`${columnId}-cell`}
					style={{ backgroundColor: "#fafafa", width: "100%" }}
					contentEditable
					suppressContentEditableWarning
					onBlur={(e: any) => {
						const auditProgramId = props.row.original.auditProgramId

						const updated = steps.map((step: any) =>
							step.auditProgramId === auditProgramId
								? {
										...step,
										[columnId]: e.target.innerHTML
											.replace(/&nbsp;/g, " ")
											.trim(),
								  }
								: step
						)

						projStore.apg.setAuditSteps(updated)
					}}
					dangerouslySetInnerHTML={{
						__html:
							targetFile && targetFile[columnId]
								? targetFile[columnId]
								: "",
					}}
				/> */}
			</div>
		)
	}

	const isLoading =
		actionStatus("editAuditStep") === "LOADING" ||
		addStatus === "LOADING" ||
		getStatus === "LOADING"

	return (
		<PageContainer fixedHeight hasToolbar={false} isLoading={isLoading}>
			<StyledAuditProgramEditor>
				<AuditProgramActionBtns
					partialStore={projStore.apg}
					handleAdd={handleAdd}
					selectedFile={selectedFile}
					handleSelectFilesFromLocal={handleSelectFilesFromLocal}
					fromDialog={fromDialog}
					setOpenRoleAssign={setOpenRoleAssign}
					setType={setType}
					setOpenAssignUserDialog={setOpenAssignUserDialog}
					wprId={wprId}
				/>
				<div className="table-container">
					<ReactTableV8
						tableColumns={AuditProgramTableColumns({
							handleRemove,
							handleEdit,
							fromDialog,
							renderEditableFileName: RenderEditableFileName,
						})}
						customFilters={(props: any) =>
							filterComponent({ ...props, dateFormat })
						}
						data={[...data].sort(
							(i: any, j: any) => i.legend - j.legend
						)}
						hasPagination={false}
						showPageSetting={false}
						height={contentsHeight}
						menuId="BulkAddClients"
						// onRowClick={(e: any, row: any) =>
						// 	setSelectedFile(row.auditProgramId)
						// }
						getRowClass={(rowInfo: any) => {
							const id = rowInfo.original.auditProgramId
							let className = ""
							if (selectedFile === id) {
								className = className + " selected"
							}

							return className
						}}
					/>
				</div>
				{/* <div className="FR JR">
					<DLButton
						eleTestId="save-btn"
						variant="outlined"
						color="primary"
						eleClassName="MR"
						disabled={hasChanges}
						// clickHandler={() =>}
					>
						Save Changes
					</DLButton>
					<DLButton
						eleTestId="cancel-btn"
						variant="outlined"
						color="warning"
						disabled={hasChanges}
						clickHandler={handleCancel}
					>
						Cancel Changes
					</DLButton>
				</div> */}
				{open && (
					<AddNewStepDialog
						open={open}
						setOpen={setOpen}
						selectedId={selectedId}
						fromDialog={fromDialog}
						wprId={wprId}
					/>
				)}
				{openRoleAssign && (
					<RoleAssignDialog
						open={openRoleAssign}
						setOpen={setOpenRoleAssign}
						partialStore={partialStore}
						roleSet={displayedProjRoles}
						type={type}
					/>
				)}

				{openAssignUserDialog && (
					<AssignUserDialog
						open={openAssignUserDialog}
						setOpen={setOpenAssignUserDialog}
						partialStore={partialStore}
						type={type}
					/>
				)}

				<CommonSnackbarComponent
					i18n={{}}
					partialStore={projStore.apg}
				/>
			</StyledAuditProgramEditor>
		</PageContainer>
	)
})

const filterComponent = ({
	column,
	table,
	dateFormat,
}: {
	column: Column<any>
	table: Table<any>
	dateFormat: string
}) => {
	switch (column.id) {
		case "preparerDueDate":
		case "reviewerDueDate":
			const filterValues: any = column.getFilterValue()
			return (
				<DLDateRangepicker
					selectedStartDate={filterValues?.startDate}
					selectedEndDate={filterValues?.endDate}
					eleStartName="startDate"
					eleEndName="endDate"
					onChange={(value: any, name: string) => {
						column.setFilterValue({
							...filterValues,
							[name]: value,
						})
					}}
					format={dateFormat}
				/>
			)

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const StyledAuditProgramEditor = styled.div`
	// padding: 0 1rem;
	.table-container {
		margin-top: 1rem;
	}
	input[type="file"] {
		display: none;
	}
	.textarea {
		-moz-appearance: textfield-multiline;
		-webkit-appearance: textarea;
		// border: 1px solid gray;
		font: medium -moz-fixed;
		font: -webkit-small-control;
		height: 28px;
		overflow: auto;
		padding: 2px;
		resize: both;
		width: 400px;
	}
	.MR {
		margin-right: 8px;
	}
	.audit-program-action-area {
		margin-top: 5px;
	}
	.selected {
		background-color: #758fff;
		:hover {
			background-color: #758fff !important;
		}
	}
`
