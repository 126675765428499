import { compareDesc } from "date-fns"
import { DnTFormatProps } from "../../../../common-models/types/common-props"
import { getTzDateAndTime2 } from "../../../../library/converters/date-utc-converter"
import { FileMenuViewType } from "../../data-models/dl-file-control-model"
import { DLFileProps } from "../../data-models/file.data-model"

const ViewSignOffs = (self: any) => ({
	getSignOffListByColumnId(
		fileId: string,
		columnId: string,
		dntFormat: DnTFormatProps
	) {
		// 1) find workpaper
		const viewType = self.viewType
		let targetFile: DLFileProps | undefined = undefined // initialDLFile
		if (
			viewType === undefined ||
			(viewType !== FileMenuViewType.search &&
				viewType !== FileMenuViewType.qcView)
		) {
			targetFile = self.flatList.find((item: any) => item.id === fileId)
		} else {
			targetFile = self.searchResults.find(
				(item: any) => item.id === fileId
			)
		}
		if (targetFile === undefined) {
			return null
		}

		let targetSignOffs: any[] = []
		let formattedList: any[] = []
		//
		// TODO: WARNING: the conditions below should be updated to check the matched ID instead of the static one
		if (columnId === "id_prepare") {
			targetSignOffs = targetFile.prepareList
		} else if (columnId === "id_review") {
			targetSignOffs = targetFile.reviewList
		} else if (columnId === "id_epReview") {
			targetSignOffs = targetFile.epReviewList
		} else if (columnId === "id_cpReview") {
			targetSignOffs = targetFile.cpReviewList
		} else if (columnId === "id_qcReview") {
			targetSignOffs = targetFile.qcReviewList
		}
		// get prepare list
		const reOrderedList = targetSignOffs.slice().sort(function (a, b) {
			const dateA = new Date(a.signedAt)
			const dateB = new Date(b.signedAt)
			return compareDesc(dateA, dateB)
		})

		reOrderedList.map((signOff: any) => {
			const formattedDate = getTzDateAndTime2({
				date: signOff.signedAt,
				dntFormat,
			})

			formattedList.push({
				...signOff,
				signedAt: formattedDate,
			})
		})
		return formattedList
	},
})

export default ViewSignOffs
