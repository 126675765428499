import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import GetOrgDashboardList from "./view-model-actions/get-org-dashboard-list"
import {
	OrgDashboardItem,
	CardType,
} from "./data-models/dashboard-table-view.data-model"
import { initialStore } from "./org-dashboard.provider"
import TabelFilterViews from "./view-model-views/tabel-view-filters"
import { getTzDate2 } from "../../../../../library/converters/date-utc-converter"
import { ProjectStatus } from "../../../../../common-models/enumerations/project-related-enums"
import DashboardCardViews from "./view-model-views/dashboard-card-views"
import DashboardGraphViews from "./view-model-views/dashboard-graph-views"
import { ConsoleLog } from "../../../../../components/basic-elements"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"
import { DnTFormatProps } from "../../../../../common-models/types/common-props"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"
import {
	CreateProjectMethod,
	CreateProjectInputsModel,
	CreateProjectInputProps,
} from "../../../../../components/combined-elements/create-project/create-project.data-props"
import {
	CommonSelectedItem,
	SelectedItemsSharedActions,
	SelectedItemsSharedViews,
} from "../../../../../common-models/shared-store-code/selected-items-related/selected-items-shared-actions"
import { DLOrgMenuGroups } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

const OrgDashboardViewModel = types
	.model({
		storeName: DLOrgMenuGroups.dashboard,
		orgDashboardList: types.array(OrgDashboardItem),
		//
		showCardDetailView: false,
		cardType: types.enumeration<CardType>(Object.values(CardType)),
		yearFilter: types.array(types.string),
		engTypeFilter: types.array(types.string),
		groupFilter: types.array(types.string),
		epUserFilter: types.array(types.string),
		qcUserFilter: types.array(types.string),
		periodTypeFilter: types.array(types.string),
		//
		// Graph Filters
		yearGraphFilter: types.array(types.string),
		engTypeGraphFilter: types.array(types.string),
		groupGraphFilter: types.array(types.string),
		epUserGraphFilter: types.array(types.string),
		qcUserGraphFilter: types.array(types.string),
		periodTypeGraphFilter: types.array(types.string),
		fromDate: types.union(types.null, types.string),
		toDate: types.union(types.null, types.string),
		//
		hBarDialogTitle: "Projects",
		hBarTableList: types.array(OrgDashboardItem),
		hBarTableDetailsDialogOpen: false,
		detailViewTableTitle: "",
		detailViewTableData: types.array(
			types.model({
				label: types.string,
				total: types.number,
				//
				normal: types.number,
				archived: types.number,
				unarchived: types.number,
				//
				normalLate: types.number,
				archivedLate: types.number,
				unarchivedLate: types.number,
				//
				normalTimely: types.number,
				archivedTimely: types.number,
				unarchivedTimely: types.number,
				//
				normalCannotCalc: types.number,
				archivedCannotCalc: types.number,
				unarchivedCannotCalc: types.number,
			})
		),
		detailViewProgressTableData: types.array(
			types.model({
				status: types.string,
				total: types.number,
				timely: types.number,
				late: types.number,
				cannotCalculate: types.number,
			})
		),
		//
		createProjectMethod: types.enumeration<CreateProjectMethod>(
			Object.values(CreateProjectMethod)
		),
		rfSourceId: "",
		createProjectPresetInputs: types.union(
			CreateProjectInputsModel,
			types.undefined
		),
		selectedItems: types.array(CommonSelectedItem),
		//
		openCreateProjDialog: false,
		projectDetailsDialogOpen: false,
		openDeleteProjDialog: false,
		openAddHardCopyDialog: false,
		selectedProj: "",
		selectedCabinetId: "",
		selectedProjStatus: ProjectStatus.normal,
		clickPoint: ClickPoint,
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setSelectedProj(projId: string) {
			self.selectedProj = projId
		},
		setSelectedCabinetId(cabinetId: string) {
			self.selectedCabinetId = cabinetId
		},
		setSelectedProjStatus(status: ProjectStatus) {
			self.selectedProjStatus = status
		},
		popItemFromProjectList(projId: string, projStatus: string) {
			self.orgDashboardList.splice(
				self.orgDashboardList.findIndex(
					(item: any) => item.id === projId
				),
				1
			)
			// Remove item in dialog
			const indexForDialogTable = self.hBarTableList.findIndex(
				(item: any) => item.id === projId
			)
			if (indexForDialogTable !== -1) {
				self.hBarTableList.splice(indexForDialogTable, 1)
			}
		},
		setProjectDetailsDialogOpen(request?: boolean) {
			self.projectDetailsDialogOpen =
				request ?? !self.projectDetailsDialogOpen
		},
		setOpenAddHardCopyDialog(request?: boolean) {
			self.openAddHardCopyDialog = request ?? !self.openAddHardCopyDialog
		},
		setOpenDeleteProjDialog(request?: boolean) {
			self.openDeleteProjDialog = request ?? !self.openDeleteProjDialog
		},
		setHBarDialogTitle(title: string) {
			self.hBarDialogTitle = title
		},
		setHBarTableList(list: any) {
			self.hBarTableList = list //[...list]
		},
		setOrgDashBoardList(item: any) {
			self.orgDashboardList.length = 0
			self.orgDashboardList = item
		},
		setHBarTableDetailsDialogOpen(request?: boolean) {
			self.hBarTableDetailsDialogOpen =
				request ?? !self.hBarTableDetailsDialogOpen
		},
		setDetailViewTableTitle(title: string) {
			self.detailViewTableTitle = title
		},
		setDetailViewTableData(tableData: any) {
			self.detailViewTableData = tableData
		},
		setDetailViewProgressTableData(data: any) {
			self.detailViewProgressTableData = data
		},
		//
		setShowCardDetailView(show: boolean) {
			self.showCardDetailView = show
		},
		setCardType(type: CardType) {
			self.cardType = type
		},
		setPeriodTypeFilter(periodType: any) {
			self.periodTypeFilter = periodType
		},
		setYearFilter(years: any) {
			ConsoleLog("setYearFilter " + years)
			self.yearFilter = years
		},
		setEngTypeFilter(engTypes: any) {
			self.engTypeFilter = engTypes
		},
		setGroupFilter(groups: any) {
			self.groupFilter = groups
		},
		setEPUserFilter(epUsers: any) {
			self.epUserFilter = epUsers
		},
		setQCUserFilter(qcUsers: any) {
			self.qcUserFilter = qcUsers
		},
		//
		//
		setPeriodTypeGraphFilter(periodType: any) {
			self.periodTypeGraphFilter = periodType
		},
		setYearGraphFilter(years: any) {
			ConsoleLog("setYearFilter " + years)
			self.yearGraphFilter = years
		},
		setEngTypeGraphFilter(engTypes: any) {
			self.engTypeGraphFilter = engTypes
		},
		setGroupGraphFilter(groups: any) {
			self.groupGraphFilter = groups
		},
		setEPUserGraphFilter(epUsers: any) {
			self.epUserGraphFilter = epUsers
		},
		setQCUserGraphFilter(qcUsers: any) {
			self.qcUserGraphFilter = qcUsers
		},
		setFromDate(fromDate: string) {
			self.fromDate = fromDate
		},
		setToDate(toDate: string) {
			self.toDate = toDate
		},
	}))
	.actions((self) => ({
		setCreateProjectPresetInputs(
			inputs: CreateProjectInputProps | undefined
		) {
			self.createProjectPresetInputs = inputs
		},
		setOpenCreateProjDialog(request?: boolean) {
			self.openCreateProjDialog = request ?? !self.openCreateProjDialog
		},
		setRfSourceId(srcId: string) {
			self.rfSourceId = srcId
		},
		setCreateProjectMethod(from: CreateProjectMethod) {
			self.createProjectMethod = from
		},
	}))
	.actions(GetOrgDashboardList)
	.actions(SelectedItemsSharedActions)
	.views(SelectedItemsSharedViews)
	.views((self) => ({
		formattedList(dntFormat: DnTFormatProps) {
			const defaultList = self.orgDashboardList
			// fmt = formatted
			let fmtList: any[] = []

			function formatDate(date: string | null) {
				if (date) {
					return getTzDate2({
						date,
						dntFormat,
					})
				}
				return ""
			}

			defaultList.map((project) => {
				const fmtStartDate = formatDate(project.startDate)
				//
				const fmtExpectedReportDate = formatDate(
					project.expectedReportDate
				)
				const fmtFinalReportDate = formatDate(project.finalReportDate)
				//
				const fmtExpectedArchiveDeadlineDate = formatDate(
					project.expectedArchiveDeadlineDate
				)
				const fmtFinalArchiveDeadlineDate = formatDate(
					project.finalArchiveDeadlineDate
				)
				//
				const fmtArchivedDate = formatDate(project.archivedAt)
				const fmtCreatedDate = formatDate(project.createdAt)

				const fmtProject = {
					...project,
					startDate: fmtStartDate,
					//
					expectedReportDate: fmtExpectedReportDate,
					finalReportDate: fmtFinalReportDate,
					//
					expectedArchiveDeadlineDate: fmtExpectedArchiveDeadlineDate,
					finalArchiveDeadlineDate: fmtFinalArchiveDeadlineDate,
					//
					archivedAt: fmtArchivedDate,
					createdAt: fmtCreatedDate,
				}
				fmtList.push(fmtProject)
			})

			return fmtList
		},
		/**
		 * org > dashboard > table view
		 * @param dntFormat
		 * @param filterData
		 * @returns
		 */
		forExport(dntFormat: DnTFormatProps, filterData?: any) {
			const defaultList = filterData || self.orgDashboardList
			let formattedList: any[] = []

			const isAppAdminUser = JSON.parse(
				localStorage.getItem("isAdminAppUser") || "false"
			)

			function formatDate(date: string | null) {
				if (date) {
					return getTzDate2({
						date,
						dntFormat,
					})
				}
				return "-"
			}

			// fmt: formatted
			defaultList.map((project: any) => {
				/**
				 * Required date information
				 * 1. Period end date
				 * 2. Expected report date
				 * 3. Expected archive deadline date
				 * 4. Final reprot date
				 * 5. Final archive deadline date
				 */
				const fmtPeriodEndDate = formatDate(project.periodEndDate)
				const fmtExpectedReportDate = formatDate(
					project.expectedReportDate
				)
				const fmtExpectedArchiveDeadlineDate = formatDate(
					project.expectedArchiveDeadlineDate
				)
				const fmtFinalReportDate = formatDate(project.finalReportDate)
				const fmtFinalArchiveDeadlineDate = formatDate(
					project.finalArchiveDeadlineDate
				)
				//
				const fmtArchivedDate = formatDate(project.archivedAt)

				let formattedProject: any = {
					// projectId: project.id,
					statusName: project.statusName,
					periodName: project.periodName,
					projectName: project.title,
					financialYear: project.financialYear,
					clientName: project.clientName,
					version: project.version,
					groupName: project.groupName,
					engTypeName: project.engTypeName,
					EPUsers: project.epUsers,
					QCUsers: project.qcUsers,
					riskAssesmentName: project.raName,
					projectAliasId: project.aliasId,
					archPolicyPeriod: project.archPolicyPeriod,
					// ----- common date informations
					PeriodEndDate: fmtPeriodEndDate,
					expectedReportDate: fmtExpectedReportDate,
					expectedArchiveDeadlineDate: fmtExpectedArchiveDeadlineDate,
					finalReportDate: fmtFinalReportDate,
					finalArchiveDeadlineDate: fmtFinalArchiveDeadlineDate,
					// -------------------------
					archivedAt: fmtArchivedDate,
					cpCompletionCount: project.cpCompletionCount,
					cpStatus: project.cpStatus,
					partnerCompletedCount: project.partnerCompletedCount,
					partnerStatus: project.partnerStatus,
					preparedCompletedCount: project.preparedCompletedCount,
					preparedStatus: project.preparedStatus,
					reviewedCompletedCount: project.reviewedCompletedCount,
					reviewedStatus: project.reviewedStatus,
					totalCpCount: project.totalCpCount,
					totalPartnerCount: project.totalPartnerCount,
					totalPreparedCount: project.totalPreparedCount,
					totalReviewedCount: project.totalReviewedCount,
				}
				if (isAppAdminUser) {
					formattedProject = {
						...formattedProject,
						projectId: idToNumber(project.id, IdType.project),
					}
				}
				formattedList.push(formattedProject)
			})

			return formattedList
		},
		viewSelectedProjInfo() {
			const selectedProjInfo = self.orgDashboardList.find(
				(project: any) => project.id === self.selectedProj
			)

			return selectedProjInfo
		},
	}))
	.views(TabelFilterViews)
	.views(DashboardCardViews)
	.views(DashboardGraphViews)

	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default OrgDashboardViewModel
