import React, { useEffect } from "react"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"

import { Divider } from "@material-ui/core"
import styled from "styled-components"
import keyboardJS from "keyboardjs"
import { useRootStore } from "../../../stores/root-store/root-store.provider"

const ITEM_HEIGHT = 48
interface DLMenusWithSingleActionProps {
	remoteButton: React.ReactNode
	menuOptions: {
		label: string // display some text to end user
		value: string // provide value to developers or functions
		icon?: React.ReactNode
	}[]
	handleSelect: any
	eleTestId: string
	useScroll?: boolean
	hasDivider?: Array<number>
}

/**
 * - This Menu Component requires button component
 * - This Menu Component use only one action
 */

export default function DLMenusWithSingleAction({
	remoteButton = <div />,
	menuOptions = [
		{
			label: "",
			value: "",
			icon: undefined,
		},
	],
	handleSelect = undefined,
	eleTestId = "testId-DLMenusWithSingleAction",
	useScroll = false,
	hasDivider = undefined,
}: DLMenusWithSingleActionProps) {
	const store = useRootStore()
	const bgColor = store.ui.getThemeValue("background")
	const txtColor = store.ui.getThemeValue("textMediumEmphasis")

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)

	useEffect(() => {
		keyboardJS.pause()
	}, [])

	const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault()
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleSelectMenu = (selectedValue: any) => {
		handleSelect(selectedValue)
		handleClose()
	}

	return (
		<StyledDLMenusWithSingleAction>
			<div onClick={handleOpen} data-testid={eleTestId}>
				{remoteButton}
			</div>

			<Menu
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={() => {
					keyboardJS.resume()
					handleClose()
				}}
				data-testid={eleTestId}
				PaperProps={{
					style: {
						maxHeight: useScroll ? ITEM_HEIGHT * 4.5 : "none",
						width: 200,
					},
				}}
				MenuListProps={{
					id: eleTestId,
					className: `dl-ctx-menu-testid ${
						open ? "opened" : "closed"
					}`,
					style: { backgroundColor: bgColor, color: txtColor },
				}}
				// WARNING: DO NOTE REMOVE OR UPDATE this className 'dl-ctx-menu-testid' and 'opened'
				// It is used on test instead of the testid because this component does not support that property
				// and 'opened' is also required to distinguish the opened ctx menu
			>
				{menuOptions.map((option, index) => {
					return (
						<div
							className="menu-item-container FC"
							data-testid={`menu-item-${option.value}`}
							key={option.value + index}
						>
							<MenuItem
								className="menu-item FR AC"
								onClick={() => handleSelectMenu(option.value)}
							>
								<div
									className="menu-item-icon FR"
									style={{ marginRight: 4 }}
								>
									{option.icon && option.icon}
								</div>
								<div className="FR AC">{option.label}</div>
							</MenuItem>
							{hasDivider && hasDivider.includes(index) ? (
								<Divider style={{ width: "100%" }} />
							) : (
								""
							)}
						</div>
					)
				})}
			</Menu>
		</StyledDLMenusWithSingleAction>
	)
}

const StyledDLMenusWithSingleAction = styled.div`
	.menu-item {
		font-size: ${(props) => props.theme.shared.textMd};
	}
`
