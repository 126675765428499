import {
	getRequest,
	putRequest,
	deleteRequest,
	postRequest,
} from "../../../library/api-requests"
import { TOKEN_TYPE } from "../../../library/api-requests/shared-for-api-requests"
import { postFormData } from "../../../library/api-requests/request-post-others"
import { DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { DLMenuId } from "../../../components/combined-elements/file-tree/dl-file-tree-props"

/**
 * NOTE:
 * Because create apis have little different props, t
 * it is on the individual api files
 * - wp-file-apis.ts
 * - pf-file-apis.ts
 */

/**
 * * file related api list
 * - create
 * - update --> shared
 * - downalod --> shared
 * - open --> shared
 * - remove --> shared
 */

export type CreateFileApiProps = {
	formData: FormData
	menuId: DLOrgSubMenus | DLProjSubMenus
}
export async function createFile(props: CreateFileApiProps) {
	const { formData, menuId } = props

	let endpoint = ""
	let tokenType = TOKEN_TYPE.orgToken

	if (menuId === DLOrgSubMenus.proj_templates) {
		endpoint = "/CreateTemp"
		tokenType = TOKEN_TYPE.orgToken
	} else if (menuId === DLProjSubMenus.wp) {
		endpoint = "/CreateWPR"
		tokenType = TOKEN_TYPE.projToken
	} else if (menuId === DLProjSubMenus.pf) {
		endpoint = "/CreatePermanentFile"
		tokenType = TOKEN_TYPE.projToken
	}

	const response = await postFormData({
		url: endpoint,
		formData: formData,
		tokenType,
	})

	return response
}

export type UpdateFileApiProps = {
	payload:
		| {
				ClientID: number
				WPDESC: string // file Name
				WPEF: string // file aliasId (ref num)
				WPRID: number // file ID
				// UserID: number
		  }
		| {
				TempName: string // file Name
				TemplateId: number // file ID
				WPREF: string // file aliasId (ref num)
				// UserID: number
		  }
	menuId: DLOrgSubMenus | DLProjSubMenus
}

export async function updateFile(props: UpdateFileApiProps) {
	const { payload, menuId } = props
	let endpoint = ""
	let tokenType = TOKEN_TYPE.projToken

	if (menuId === DLProjSubMenus.wp) {
		endpoint = "/EditWorkPaper"
	} else if (menuId === DLProjSubMenus.pf) {
		endpoint = "/EditPermanentFile"
	} else if (menuId === DLOrgSubMenus.proj_templates) {
		endpoint = "/EditTemplate"
		tokenType = TOKEN_TYPE.orgToken
	}
	const response = await putRequest({
		url: endpoint,
		params: payload,
		tokenType,
	})

	return response
}

//
//
//

export async function open(params: any) {
	const endpoint = "/"
	const response = await getRequest({
		url: endpoint,
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export type DeleteFileApiProps = {
	params:
		| {
				WPRID: number
				Type: string // wp & pf use same type "WorkpaperFile"
				// UserID: number
		  }
		| {
				TemplateId: number
				// UserID: number
		  }
	menuId: DLOrgSubMenus | DLProjSubMenus
}
export async function deleteFile(props: DeleteFileApiProps) {
	let endpoint = ""
	let tokenType = TOKEN_TYPE.projToken
	const { params, menuId } = props

	if (menuId === DLProjSubMenus.wp) {
		endpoint = "/DeleteWPR"
	} else if (menuId === DLProjSubMenus.pf) {
		endpoint = "/DeletePermanentFile"
	} else if (menuId === DLOrgSubMenus.proj_templates) {
		endpoint = "/DeleteTemplate"
		tokenType = TOKEN_TYPE.orgToken
	}

	const response = await deleteRequest({
		url: endpoint,
		params,
		tokenType,
	})

	return response
}

export type ReplaceFileApiProps = {
	formData: FormData
	menuId: DLOrgSubMenus | DLProjSubMenus
}
export async function replaceFile(props: ReplaceFileApiProps) {
	const endpoint = "/ReplaceFile"
	const { formData, menuId } = props
	let tokenType = TOKEN_TYPE.projToken
	if (menuId === DLOrgSubMenus.proj_templates) {
		tokenType = TOKEN_TYPE.orgToken
	}
	const response = await postFormData({
		url: endpoint,
		formData,
		tokenType,
	})

	return response
}

// ClientID: 4130
// FinancialYear: "18-Mar-20"
// ParentMediaID: 285279
// TemplateFileID: 363
// UserID: 10
// WPDesc: "mtech(updatedName)"
// WPEF: "fromLib-1"
// signOffType: [1]
export type CreateFileFromLibProps =
	| {
			// * Normal type file props
			// ClientID: number
			UserID: number
			// FinancialYear: string
			ParentMediaID: number
			TemplateFileID: number
			// UserID: number
			WPDesc: string
			WPEF: string
			signOffType: any[]
			prepare: boolean
			review: boolean
	  }
	| {
			// * APG type file props
			ClientID: number
			UserID: number
			ParentMediaID: number
			TemplateFileID: number
			WPDesc: string
			WPEF: string
			signOffType: any[]
			prepare: boolean
			review: boolean
			// NOTE: diffrent part
			CreationMode: number
	  }

export async function createFileFromLib(payload: CreateFileFromLibProps) {
	const endpoint = "/CreateWPRTemplateInWPR"

	const response = await postRequest({
		url: endpoint,
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export type CreateFileFromTempProps = {
	ClientID: number
	UserID: number
	ParentMediaID: number
	TemplateFileID: number
	WPDesc: string
	WPEF: string
	signOffType: any[]
}

export async function createFileFromTemp(payload: CreateFileFromTempProps) {
	const endpoint = "/WorkspaceWorkPaperCopyFromProjectTemplate"

	const response = await postRequest({
		url: endpoint,
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export enum GetFileInfoApiMenu {
	pf = "PermanentFile",
	wp = "Workpaper",
}

export type SearchFileApiProps = {
	ClientId: number
	FinYear: string
	Type: number
	EnteredText: string
	FileType?: GetFileInfoApiMenu
}
export async function searchFileOrFolder(params: SearchFileApiProps) {
	let endpoint = "/GetSearchDetails"
	let tokenType = TOKEN_TYPE.projToken

	const response = await getRequest({
		url: endpoint,
		params,
		tokenType,
	})

	return response
}

export type ReadAllFilesProps = {
	projectId: number
	menu: "wp" | "pf"
}

export async function readAllFiles(params: ReadAllFilesProps) {
	const response = await getRequest({
		url: "/GetWorkpaperList ",
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export type ReadFileInfoProps = {
	fileNumberId: number
	menu: "wp" | "pf"
}

export async function readFileInfo({ fileNumberId, menu }: ReadFileInfoProps) {
	console.log("readFileInfo in file-system-file-apis")
	const response =
		menu === "wp"
			? await getRequest({
					url: `/WorkspaceWorkPapersFileInfo`,
					params: {
						workPaperId: fileNumberId,
					},
					tokenType: TOKEN_TYPE.projToken,
			  })
			: await getRequest({
					url: `/WorkspacePermanentFilesFileInfo`,
					params: {
						permanentFileId: fileNumberId,
					},
					tokenType: TOKEN_TYPE.projToken,
			  })

	return response
}

export type ReadFileHistoryProps = {
	activityId: number
	projectId: number
	target: string
	targetId: number
	menuId: DLMenuId
}

export async function readFileHistory({
	activityId,
	projectId,
	target,
	targetId,
	menuId,
}: ReadFileHistoryProps) {
	const url =
		menuId === DLProjSubMenus.wp
			? "/GetFilesHistory"
			: "/GetPermanentFilesHistory"
	const response = await getRequest({
		url,
		params: {
			activityId,
			projectId,
			target,
			targetId,
		},
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export type ReadSignOffLockHistoryProps = {
	activityType: string
	projectId: number
	target: string
	targetId: number
	menuId: DLMenuId
}

export async function readSignOffLockHistory({
	activityType,
	projectId,
	target,
	targetId,
	menuId,
}: ReadSignOffLockHistoryProps) {
	const url =
		menuId === DLProjSubMenus.wp
			? "/GetWorkpaperFilesHistory"
			: "/GetPermanentFilesHistory"
	const response = await getRequest({
		url,
		params: {
			activityType,
			projectId,
			target,
			targetId,
		},
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export type UpdateSignOffLockProps = {
	FileId: number
	SignOffLock: boolean
	menuId: DLMenuId
}

export async function updateLock({
	FileId,
	SignOffLock,
	menuId,
}: UpdateSignOffLockProps) {
	const url =
		menuId === DLProjSubMenus.wp
			? "/UpdateWorkpaperFileSignOffLock"
			: "/UpdatePermanentFileSignOffLock"
	const response = await putRequest({
		url,
		params: {
			FileId,
			SignOffLock,
		},
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export type ReadFileLocationProps = {
	clientId: number
	mediaId: number
	mediaType: string
}

export async function readFileLocation({
	clientId,
	mediaId,
	mediaType,
}: ReadFileLocationProps) {
	const response = await postRequest({
		url: "/GetFileLocation",
		params: {
			clientId,
			mediaId,
			mediaType,
		},
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}
