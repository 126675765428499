import {
	ActionStatus,
	MessageColorType,
} from "../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../components/basic-elements"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const GetOpenedFiles = (self: any) => ({
	getOpenedFiles(menuId: DLProjSubMenus) {
		// 0.
		const actionName = "getOpenedFiles"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		let type = menuId === DLProjSubMenus.wp ? 1 : 2 // 1->wp,2->pf
		const payload = { Type: type }
		self.readOpenedFiles(payload)
			.then((response: any) => {
				self.resetOpenedFiles()
				if (response.status === 200 && response.data.Status === 1) {
					ConsoleLog([actionName, "--response", response])
					const openedByUser = response.data.OpenedBySameUser
					const openedByOther = response.data.OpenedByOthers
					openedByUser.map((item: any) => {
						let reOrganizedList = {
							id: item.WPRID.toString(),
							accessRequestedBy:
								item.OpenedFilesRequestList[0]
									?.RequestedUserData || null,
							openedAt: item.OpenedAt,
							openedBy: item.OpenedBy.toString(),
							//openedFilesRequestList: item.OpenedFilesRequestList,
							openedUserRole: item.OpenedUserRole,
							description: item.WPDesc,
							referenceNumber: item.WorkReference,
							selected: false, // for checkbox
						}

						self.pushOpenedBySameUser(reOrganizedList)
					})
					openedByOther.map((item: any) => {
						let reOrganizedList = {
							id: item.WPRID.toString(),
							accessRequestedBy: item.AccessRequestedBy,
							openedAt: item.OpenedAt,
							openedBy: item.OpenedBy.toString(),
							//openedFilesRequestList: item.OpenedFilesRequestList,
							openedUserRole: item.OpenedUserRole,
							description: item.WPDesc,
							referenceNumber: item.WorkReference,
							selected: false, // for checkbox
						}
						self.pushOpenedByOther(reOrganizedList)
					})
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
					})
				} else {
					// set fail case response (200 but fail)
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetOpenedFiles
