import { types, applySnapshot } from "mobx-state-tree"
import { DLProjectModel } from "../../../../../service-modules/project-module/data-model/project-module-data-models"

// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"

import GetRecentProjList from "./view-model.actions/get-recent-projects"
import { initialStore } from "./recent-proj-list.provider"
import { getTzDate } from "../../../../../library/converters/date-utc-converter"
import { ConsoleLog } from "../../../../../components/basic-elements"

const RecentProjViewModel = types
	.model({
		recentProjList: types.array(DLProjectModel),
		//
		needRefresh: true,
		// common models
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setRecentProjList(list: any) {
			self.recentProjList = list
		},
		formattedRecentProjList(dateFormat: string, timeZone: string) {
			const formatUpdatedList = [...self.recentProjList]

			formatUpdatedList.map((project: any) => {
				const formattedCreatedAt = project.createdAt
					? getTzDate({
							date: project.createdAt,
							timeZone,
							dateFormat,
					  })
					: ""

				const formattedReportDate = project.periodEndDate
					? getTzDate({
							date: project.periodEndDate,
							timeZone,
							dateFormat,
					  })
					: ""

				const formattedRecentVisitAt = project.lastAccessAt
					? getTzDate({
							date: project.lastAccessAt,
							timeZone,
							dateFormat,
					  })
					: ""
				// Replace date part
				project.createdAt = formattedCreatedAt
				project.periodEndDate = formattedReportDate
				project.lastAccessAt = formattedRecentVisitAt
			})

			return formatUpdatedList
		},
	}))
	// AGER (Add, Get, Edit, Remove)
	.actions(GetRecentProjList)
	.actions(Refresh)
	// common actions
	.actions((self) => ({
		initializeStore() {
			// ConsoleLog("initialize store - org/recent")
			applySnapshot(self, initialStore)
		},
	}))
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export { RecentProjViewModel }
